import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { notification } from '@/components/antd';

import { CheckOutlined, CloseOutlined } from '@/components/icons';
import { PERMISSION_READ } from '@/constants/permissions';
import { authStore } from '@/stores';
import { IKeyValueObject } from '@/types/common';
import { numericSort } from '@/utils/sort';

export const showError = (err: any) => {
  // eslint-disable-next-line no-console
  console.error(err);
  notification.error({
    message: err.message,
    description: err.response?.data?.error?.message,
    duration: 3,
  });
};

export const booleanFormatted = (value: boolean) => (value
  ? <CheckOutlined style={{ color: 'green' }} />
  : <CloseOutlined style={{ color: 'red' }} />);

export const filterBlockedApprovers = (store, currentApprover:mpg.api.approvers.Approver) => {
  const filteredApprovers = store.items.filter((item) => !item.blocked);

  if (currentApprover?.blocked) {
    return { items: [...filteredApprovers, currentApprover] };
  }
  return { items: filteredApprovers };
};

export const buildDropdownOptionsFromEnum = (enumObject: IKeyValueObject, prefix: string = '') => Object.values(enumObject).map((item: string) => ({
  value: item,
  label: prefix ? <FormattedMessage id={`${prefix}${item}`} /> : item,
}));

export const buildFilterOptionsFromEnum = (enumObject: IKeyValueObject, i18nPrefix: string) => Object.values(enumObject)
  .map((item: string) => ({ text: <FormattedMessage id={`${i18nPrefix}${item}`} />, value: item }));

export const buildFilterOptionsFromEnumWithPermissions = (enumObject: IKeyValueObject, i18nPrefix: string, permissionsForOptions: any) => Object.values(enumObject)
  .map((item: string) => authStore.hasPermissions([[permissionsForOptions[item], PERMISSION_READ]]) && {
    text: <FormattedMessage id={`${i18nPrefix}${item}`} />, value: item,
  })
  .filter((item) => item);

export const buildFilterOptionsFromEnumWithTransformer = (enumObject: IKeyValueObject, transformer: (params: any) => any) => Object.values(enumObject)
  .map((item: string) => ({ text: transformer(item), value: item }));

export const buildingsAreasFormatFn = (item) => {
  const { areaName, buildingName } = item || {};
  return `${areaName} (${buildingName})`;
};

export const buildStoreOptions = (store: any, getter: string | ((item: any) => any), reduceOption = null, reduceOptionValue = null, additionalStore = null, additionalOption = null) => {
  const formatFn = typeof getter === 'string'
    ? (item: any) => item[getter]
    : getter;

  return [...store.items]
    .filter((item) => (reduceOption && reduceOptionValue ? item[reduceOption] === reduceOptionValue : true))
    .sort((aValue, bValue) => {
      const a = (formatFn(aValue) || '').toLowerCase();
      const b = (formatFn(bValue) || '').toLowerCase();

      return numericSort(a, b);
    })
    .map((item: any) => {
      const additionalInfo = additionalStore?.items?.find(({ id }) => id === item[additionalOption]);

      return {
        value: item.id,
        label: additionalInfo
          ? `${formatFn(item)} (${additionalInfo.name})`
          : formatFn(item),
        text: additionalInfo
          ? `${formatFn(item)} (${additionalInfo.name})`
          : formatFn(item),
      };
    });
};

export function getStorePropById<T extends { id: string }>(uid: string, store: any, labelKey: string = 'name') {
  const foundItem = store.items.find(({ id }: T) => id === uid);
  return foundItem && foundItem.hasOwnProperty(labelKey) ? foundItem[labelKey] : uid;
}

export function getStoreItemById<T extends { id: string }>(uid: string, store: any) {
  return store.items.find(({ id }: T) => id === uid);
}

interface GetStorePropByIdWithExtraDataProps {
  additionalLabel: string;
  additionalStore: any;
  labelKey: string;
  store: any;
  uid: string;
}

export function getStorePropByIdWithExtraData<T extends { id: string }>(props: GetStorePropByIdWithExtraDataProps) {
  const {
    uid, store, labelKey = 'name', additionalStore, additionalLabel,
  } = props;

  const foundItem = store.items.find(({ id }: T) => id === uid);
  const additionalInfo = foundItem && additionalStore?.items.find(({ id }) => id === (foundItem && foundItem[additionalLabel]));

  return (foundItem && foundItem.hasOwnProperty(labelKey))
    ? `${foundItem[labelKey]} (${additionalInfo && additionalInfo.name})`
    : uid;
}

export const findItemIdByLabel = (store: any, labelKey: string, value: any, relatedStore?: any, relatedToField?: string, relatedToFieldValue?: string) => {
  const relatedFoundedItem = relatedStore && relatedToFieldValue && relatedStore.items.find((item: any) => item[labelKey] === relatedToFieldValue);

  const foundedItem = relatedToField && relatedFoundedItem
    ? store.items.find((item: any) => item[labelKey] === value && item[relatedToField] === relatedFoundedItem.id)
    : store.items.find((item: any) => item[labelKey] === value);
  return foundedItem && foundedItem.id;
};

export function formatBytes(bytes: number, decimals?: number) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const size = parseFloat((bytes / k ** i).toFixed(dm));

  return `${size} ${sizes[i]}`;
}

const getValue = (value: any) => {
  if (typeof value === 'undefined' || value === '') {
    return null;
  }
  return value;
};

export const getValues = (values: any) => Object.keys(values)
  .reduce((acc: any, key: string) => ({
    ...acc,
    [key]: getValue(values[key]),
  }), {});

export function getValuesDifference(oldValues: any, newValues: any, skipProperties: string[] = []) {
  const changedProperties = Array.from(new Set([...Object.keys(newValues)]))
    .filter((key: string) => {
      if (moment.isMoment(oldValues[key])) {
        return !oldValues[key].isSame(newValues[key]);
      }
      return newValues[key] !== oldValues[key];
    })
    .filter((key: string) => !skipProperties.includes(key));

  const originalItem = changedProperties
    .reduce((acc: any, key: string) => ({
      ...acc,
      [key]: getValue(oldValues[key]),
    }), {});

  const changedItem = changedProperties
    .reduce((acc: any, key: string) => ({
      ...acc,
      [key]: getValue(newValues[key]),
    }), {});

  return {
    originalItem,
    changedProperties,
    changedItem,
  };
}

export const onPrint = (event, printCl: string = 'print') => {
  const root = document.getElementById('root');
  root.classList.add(printCl);
  window.print();
  root.classList.remove(printCl);
};

export const getRoundDecimalNumber = (value: number) => Math.round(value * 100) / 100;

export const buildFilterOptionsForTurnstiles = (turnstiles: any[]) => turnstiles.map((turnstile: string) => (
  { value: `eq:${turnstile}`, text: turnstile === null ? 'Empty' : turnstile }
));

export const getUserFullNameByRecord = (record) => `${record.firstName} ${record.lastName}`;

export const keyPressedNumbers = (event) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};
export const getOptionsFilteredByActive = (initialValue, defaultOptions, store = undefined) => {
  if (Array.isArray(initialValue)) {
    const shiftItems = store.items;
    const fullInitialValues = initialValue.map((shiftId) => {
      const currentShift = shiftItems.find((shift) => shift.id === shiftId);
      if (currentShift.active === true) {
        return {
          value: currentShift.id,
          label: currentShift.name,
          text: currentShift.name,
        };
      }
      return {
        value: currentShift.id,
        label: currentShift.name,
        text: currentShift.name,
        isActive: currentShift.active,
      };
    });
    const optionsMap = new Map()
    const mergedOptions =  [...fullInitialValues, ...defaultOptions]
    mergedOptions.forEach((item)=>{
      if(!optionsMap.has(item.value)){
        optionsMap.set(item.value, item);
      }
    })
    return Array.from(optionsMap.values());
  }
  return initialValue?.active === false
    ? [
        ...defaultOptions,
        {
          value: initialValue.id,
          label: initialValue.name,
          text: initialValue.name,
          isActive: false,
        },
      ]
    : defaultOptions;
};
