import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';

import { UIView } from '@uirouter/react';
import {
  SettingOutlined,
} from '@/components/icons';
import { Button, PageHeader, Space } from '@/components/antd';
import { Table } from '@/components/table';
import {
  PERMISSION_OBJ_SHUTTLE_BUS_REPORT, PERMISSION_READ,
} from '@/constants/permissions';
import {
  agencyStore,
  busShuttleReportStore,
  colorStore,
  shiftStore,
} from '@/stores';
import { useMassUpdateCashedStores } from '@/utils/store';
import { api } from '@/api';

import { getColumns } from './setup';
import ExportButton from '@/pages/common/components/buttons/export-button';
import {withPermissions} from "@/hocs/permission";

const store = busShuttleReportStore;
interface TableRefValue {
  showSettingsModal: React.MouseEventHandler<HTMLElement>
}
interface TableRef {
  current: TableRefValue
}

export const ShuttleReport = observer(() => {
  const { items } = store;
  // {} is needed because it's used in the table/hook
  const tableRef: TableRef = useRef({} as TableRefValue);

  const { loading } = useMassUpdateCashedStores([
    agencyStore,
    colorStore,
    shiftStore,
  ]);

  const columns = getColumns();
  return (
    <>
      <PageHeader
        className="r-antd-page-header"
        extra={[
          <Space align="start">
            <Button
              style={{ margin: '4px 8px 0 8px' }}
              key="setting"
              size="middle"
              icon={<SettingOutlined />}
              onClick={tableRef.current && tableRef.current.showSettingsModal}
            />
            <ExportButton
              store={store}
              exportToExcel={api.busShuttleReports.exportToExcel}
              withPermission={withPermissions([
                [PERMISSION_OBJ_SHUTTLE_BUS_REPORT, PERMISSION_READ]
              ])}
              fileName="shuttle-bus-report"
            />
          </Space>,
        ]}
      />

      <Table
        tableRef={tableRef}
        localStorageKey="shuttleBusReport-table"
        rowKey="id"
        columns={columns}
        dataSource={items}
        store={store}
        checkPermissions
        permissionObj={PERMISSION_OBJ_SHUTTLE_BUS_REPORT}
        loading={store.loading || loading}
        scroll={{ y: '100%' }}
        isAutoloadAllowed={!loading}
      />
      <UIView />
    </>
  );
});
