import React, { useEffect, useState } from 'react';
import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import enUS from 'antd/lib/date-picker/locale/en_US';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';

import { Calendar } from '@/components/calendar';
import { authStore, calendarShiftPlanningStore } from '@/stores';
import { Button, notification, Space } from '@/components/antd';
import { PrinterOutlined } from '@/components/icons';
import { onPrint } from '@/utils/common';
import { getFullDateWithTimeZone } from '@/utils/date';
import { getTranslatedString } from '@/utils';

import { CalendarHeader } from './header';
import { CalendarCell } from './cell';
import { useDbClick } from './hooks';
import { BulkAssignColorsButton, BulkCreateButton, BulkUpdateButton } from './buttons';

interface CalendarViewProps extends UIViewInjectedProps {
  selectedBuilding: any;
  loading: boolean;
}

export const CalendarView = observer(({ selectedBuilding, loading, transition }: CalendarViewProps) => {
  const [selectedDates, setSelectedDates] = useState<moment.Moment[]>([]);
  const [currentDate, setCurrentDate] = useState<moment.Moment>();
  useEffect(() => {
    if (!loading && selectedBuilding) {
      calendarShiftPlanningStore.load(selectedBuilding, currentDate);
    }
  }, [loading, selectedBuilding]);

  const handleDbClick = (date: moment.Moment, event: Event) => {
    const includeEvents = (event.target as HTMLElement).closest('div.m-shift-planning__calendar-cell-event');
    const beforeToday = date.startOf('day').diff(moment(), 'day') < 0;

    if ((beforeToday && !authStore.hasAdminRole()) || includeEvents) {
      return;
    }

    transition.router.stateService.go('base-layout.coordinator-temps-shift-planning.bulk-create', {
      selectedDates: [date],
      buildingId: selectedBuilding?.id,
    });
  };

  useDbClick({ handleDbClick, deps: [selectedBuilding?.id] });

  const handleMultiSelect = (dates) => {
    const filteredDates = (dates || []).filter((date) => date.startOf('day').diff(moment(), 'day') >= 0);
    setSelectedDates(authStore.hasAdminRole() ? dates : filteredDates);
  };

  const handleAddEvents = () => {
    transition.router.stateService.go('base-layout.coordinator-temps-shift-planning.bulk-create', {
      selectedDates,
      buildingId: selectedBuilding?.id,
    });
  };

  const handleUpdateEvents = () => {
    const checkOnSameDate = (date, eventStart) => {
      const dateWithTZ = getFullDateWithTimeZone(eventStart, selectedBuilding?.timezone);
      return date.isSame(dateWithTZ, 'day');
    };

    const selectedRowKeys = calendarShiftPlanningStore.items
      .filter(({ eventStart }) => selectedDates.some((date) => checkOnSameDate(date, eventStart)))
      .map(({ id }) => id);

    if (!selectedRowKeys.length) {
      notification.warn({
        message: getTranslatedString('shiftPlanning.bulk-update-warn-msg-title'),
        description: getTranslatedString('shiftPlanning.bulk-update-warn-msg'),
        duration: 3,
      });
    }

    transition.router.stateService.go('base-layout.coordinator-temps-shift-planning.bulk-edit', {
      selectedRowKeys,
      buildingId: selectedBuilding?.id,
    });
  };

  const handleAssignColorsEvents = () => {
    const checkOnSameDate = (date, eventStart) => {
      const dateWithTZ = getFullDateWithTimeZone(eventStart, selectedBuilding?.timezone);
      return date.isSame(dateWithTZ, 'day');
    };

    const selectedRowKeys = calendarShiftPlanningStore.items
      .filter(({ eventStart }) => selectedDates.some((date) => checkOnSameDate(date, eventStart)))
      .map(({ id }) => id);

    if (!selectedRowKeys.length) {
      notification.warn({
        message: getTranslatedString('shiftPlanning.bulk-update-warn-msg-title'),
        description: getTranslatedString('shiftPlanning.bulk-update-warn-msg'),
        duration: 3,
      });
    }

    transition.router.stateService.go('base-layout.coordinator-temps-shift-planning.bulk-assign-colors', {
      selectedRowKeys,
      buildingId: selectedBuilding?.id,
    });
  };

  const HeaderRender = ({ value, onChange }) => (
    <CalendarHeader
      value={value}
      onChange={onChange}
      locale={enUS.lang}
      generateConfig={momentGenerateConfig}
      extra={[
        <Space>
          <BulkCreateButton disabled={!selectedDates.length} onClick={handleAddEvents} />
          <BulkAssignColorsButton disabled={!selectedDates.length} onClick={handleAssignColorsEvents} />
          <BulkUpdateButton disabled={!selectedDates.length} onClick={handleUpdateEvents} />
        </Space>,
        <Button
          className="m-shift-planning__calendar-print-btn"
          key="print"
          size="large"
          icon={<PrinterOutlined />}
          onClick={(e) => onPrint(e, 'm-shift-planning__calendar-print')}
        />,
      ]}
    />
  );

  const handlePanelChange = (date: moment.Moment) => {
    setCurrentDate(date);
    calendarShiftPlanningStore.load(selectedBuilding, date);
  };

  const handleEvent = (id: string) => {
    transition.router.stateService.go('base-layout.coordinator-temps-shift-planning.edit', {
      shiftPlanningId: id,
    });
  };

  const dateCellRender = (date: moment.Moment) => {
    const dateShiftPlanning = calendarShiftPlanningStore.getDateShiftPlanning(date);
    return (
      <CalendarCell filteredDates={dateShiftPlanning} selectedBuilding={selectedBuilding} handleEvent={handleEvent} />
    );
  };

  return (
    <Calendar
      headerRender={HeaderRender}
      loading={loading || calendarShiftPlanningStore.loading}
      dateCellRender={dateCellRender}
      onMultiSelect={handleMultiSelect}
      onPanelChange={handlePanelChange}
    />
  );
});
