import React from 'react';
import { authStore } from '@/stores';

export function withPermissions(permissions: [string, number][]) {
  return (Component: React.FC<any>) => (props: Record<string, unknown>) => authStore.hasPermissions(permissions) && React.createElement(Component, { ...props });
}

export function withAtLeastOnePermission(permissions: [string, number][]) {
  return (Component: React.FC<any>) => (props: Record<string, unknown>) => authStore.hasAtLeastOnePermission(permissions) && React.createElement(Component, { ...props });
}
