import { action, makeObservable, observable } from 'mobx';

export interface SearchParams {
  entity?: string;
  type?: string;
  isShowHeaderSearch?: boolean;
}

export class SearchStore {
  @observable entity: string = '';

  @observable type: string = '';

  @observable isShowHeaderSearch: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action setEntity(entity: string) {
    this.entity = entity;
  }

  @action setType(type: string) {
    this.type = type;
  }

  @action setIsShowGlobalSearch(isShow: boolean) {
    this.isShowHeaderSearch = isShow;
  }

  @action setSearchParams({ type, entity, isShowHeaderSearch }: SearchParams) {
    this.entity = entity;
    this.type = type;
    this.isShowHeaderSearch = isShowHeaderSearch;
  }

  @action clearParams() {
    this.entity = '';
    this.type = '';
    this.isShowHeaderSearch = false;
  }
}
