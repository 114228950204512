import { UIView, UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import { Drawer, Tabs } from 'antd';
import { FormattedMessage } from 'react-intl';
import { EmployeeForm } from '../form/update-form';
import { EmployeeAuditView } from './employee-audit';
import { EmployeeMarkupAuditView } from '../employee-markup/employee-markups-audit';
import { SIDEBAR_WIDTH } from '@/constants';
import { EmployeeMarkupsView } from '../employee-markup/employee-markups';
import { authStore } from '@/stores';
import { PERMISSION_OBJ_EMPLOYEE, PERMISSION_OBJ_EMPLOYEE_MARKUP, PERMISSION_READ } from '@/constants/permissions';
import { EmployeeDetailsView } from '../constants';
import { useEmployeeForm } from '@/pages/common/hooks';
import { BillingType } from '@/types/enums';
import { Form } from '@/components/antd';

interface IProps extends UIViewInjectedProps {
  entity: string;
  permission: string;
  markupPermission: string;
  getFormFields: any;
}

export const EmployeeDetails = observer(
  ({ transition, entity, permission, markupPermission, getFormFields }: IProps) => {
    const { employeeId } = transition.router.globals.params;
    const { view } = transition.targetState().params();
    const [formRef] = Form.useForm();

    const onClose = () => {
      transition.router.stateService.go(`base-layout.${entity}`);
    };
    const { formData, formDataLoading, loadFormData, billingType } = useEmployeeForm(employeeId, formRef);
    return (
      <>
        <Drawer visible onClose={onClose} width={window.innerWidth - SIDEBAR_WIDTH}>
          <Tabs destroyInactiveTabPane defaultActiveKey={view}>
            {authStore.hasPermissions([[PERMISSION_OBJ_EMPLOYEE, PERMISSION_READ]]) && (
              <>
                <Tabs.TabPane tab={<FormattedMessage id="employee.details-tab" />} key={EmployeeDetailsView.Form}>
                  <EmployeeForm
                    transition={transition}
                    entity={entity}
                    getFormFields={getFormFields}
                    formRef={formRef}
                    formData={formData}
                    formDataLoading={formDataLoading}
                    loadFormData={loadFormData}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<FormattedMessage id="employee.audit-tab" />} key={EmployeeDetailsView.Audit}>
                  <EmployeeAuditView employeeId={employeeId} entity={entity} permission={permission} />
                </Tabs.TabPane>
              </>
            )}
            {!formDataLoading && billingType !== undefined &&
              billingType !== BillingType.NonBillable &&
              authStore.hasPermissions([[PERMISSION_OBJ_EMPLOYEE_MARKUP, PERMISSION_READ]]) && (
                <>
                  <Tabs.TabPane tab={<FormattedMessage id="employee.markups-tab" />} key={EmployeeDetailsView.Markups}>
                    <EmployeeMarkupsView employeeId={employeeId} transition={transition} entity={entity} />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={<FormattedMessage id="employee.markups-logs-tab" />}
                    key={EmployeeDetailsView.MarkupsLogs}
                  >
                    <EmployeeMarkupAuditView employeeId={employeeId} entity={entity} permission={markupPermission} />
                  </Tabs.TabPane>
                </>
              )}
          </Tabs>
        </Drawer>
        <UIView />
      </>
    );
  },
);
