import React from 'react';
import { FormattedMessage } from 'react-intl';

import { agencyStore, areaStore, buildingStore } from '@/stores';
import {
  buildFilterOptionsFromEnum,
  buildFilterOptionsFromEnumWithPermissions,
  buildStoreOptions,
  getRoundDecimalNumber,
  getStorePropById,
} from '@/utils/common';
import { getColumnSearchFields, getColumnTimeFields } from '@/components/table/filters';
import { PERMISSION_OBJ_EMPLOYEE, PERMISSION_OBJ_PERMANENT_EMPLOYEE } from '@/constants/permissions';
import { EmployeeType, WorkScheduleType } from '@/types/enums';

export const getColumns = (weeks: string[] = [], isAdditionalColumnHidden = false) => {
  const columns = [
    {
      title: <FormattedMessage id="workingLogs.visitorId" />,
      dataIndex: 'visitorId',
      ...getColumnSearchFields(),
      sorter: true,
    },
    {
      title: <FormattedMessage id="workingLogs.employeeType" />,
      dataIndex: 'employeeType',
      filters: buildFilterOptionsFromEnumWithPermissions(EmployeeType, 'workingLogs.employeeType.', {
        [EmployeeType.Temporary]: PERMISSION_OBJ_EMPLOYEE,
        [EmployeeType.Permanent]: PERMISSION_OBJ_PERMANENT_EMPLOYEE,
      }),
      filterMultiple: false,
      sorter: true,
      render: (employeeType: string) =>
        employeeType && <FormattedMessage id={`workingLogs.employeeType.${employeeType}`} />,
      hidden: isAdditionalColumnHidden,
    },
    {
      title: <FormattedMessage id="workingLogs.workSchedule" />,
      dataIndex: 'workSchedule',
      filters: buildFilterOptionsFromEnum(WorkScheduleType, 'workingLogs.workSchedule.'),
      filterMultiple: false,
      sorter: true,
      render: (workSchedule: string) =>
        workSchedule && <FormattedMessage id={`workingLogs.workSchedule.${workSchedule}`} />,
      hidden: isAdditionalColumnHidden,
    },
    {
      title: <FormattedMessage id="workingLogs.visitorFirstName" />,
      dataIndex: 'visitorFirstName',
      sorter: true,
      ...getColumnSearchFields(),
    },
    {
      title: <FormattedMessage id="workingLogs.visitorMiddleName" />,
      dataIndex: 'visitorMiddleName',
      sorter: true,
      ...getColumnSearchFields(),
    },
    {
      title: <FormattedMessage id="workingLogs.visitorLastName" />,
      dataIndex: 'visitorLastName',
      sorter: true,
      ...getColumnSearchFields(),
    },
    {
      title: <FormattedMessage id="workingLogs.weekOf" />,
      dataIndex: 'weekOf',
      filters: weeks.map((week: string) => ({ value: week, text: week })),
      sorter: true,
    },
    {
      title: <FormattedMessage id="workingLogs.areaId" />,
      dataIndex: 'employeeAreaId',
      filters: buildStoreOptions(areaStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.areas.Area>(itemId, areaStore),
      hidden: isAdditionalColumnHidden,
    },
    {
      title: <FormattedMessage id="workingLogs.billableTimeHours" />,
      dataIndex: 'billableTimeHours',
      sorter: true,
    },
    {
      title: <FormattedMessage id="workingLogs.billableTimeHoursFloat" />,
      dataIndex: 'billableTimeHoursFloat',
      sorter: true,
      ...getColumnTimeFields(),
      render: (value) => getRoundDecimalNumber(value),
    },
    {
      title: <FormattedMessage id="workingLogs.grossTimeHours" />,
      dataIndex: 'grossTimeHours',
      sorter: true,
    },
    {
      title: <FormattedMessage id="workingLogs.grossTimeHoursFloat" />,
      dataIndex: 'grossTimeHoursFloat',
      sorter: true,
      ...getColumnTimeFields(),
      render: (value) => getRoundDecimalNumber(value),
    },
    {
      title: <FormattedMessage id="workingLogs.overtimeHours" />,
      dataIndex: 'overtimeHours',
      sorter: true,
    },
    {
      title: <FormattedMessage id="workingLogs.overtime" />,
      dataIndex: 'overtimeHoursFloat',
      sorter: true,
      ...getColumnTimeFields(),
      render: (value) => getRoundDecimalNumber(value),
    },
    {
      title: <FormattedMessage id="workingLogs.agencyId" />,
      dataIndex: 'agencyId',
      filters: buildStoreOptions(agencyStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.agencies.Agency>(itemId, agencyStore),
    },
    {
      title: <FormattedMessage id="workingLogs.agencyInternalId" />,
      dataIndex: 'agencyInternalId',
      sorter: true,
      ...getColumnSearchFields(),
    },
    {
      title: <FormattedMessage id="workingLogs.employeeBuildingId" />,
      dataIndex: 'buildingId',
      filters: buildStoreOptions(buildingStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.buildings.Building>(itemId, buildingStore),
    },
    // {
    //   title: <FormattedMessage id={'workingLogs.raw'} />,
    //   dataIndex: 'raw',
    //   sorter: true,
    //   render: (value: string) => value && <CheckOutlined style={{ color: 'green' }} />
    // },
  ];
  return columns.filter((column) => !column.hidden);
};
