import { request } from '@/utils/request';
import { ApiResponse, WrappedResult } from './types';

export default {
  getAddresses(params?: mpg.api.employees.GetParams): ApiResponse<WrappedResult<mpg.api.addresses.Address[]>> {
    return request({
      method: 'get',
      url: '/addresses',
      params,
    });
  },
  getAgencies(params?: mpg.api.employees.GetParams): ApiResponse<WrappedResult<mpg.api.agencies.Agency[]>> {
    return request({
      method: 'get',
      url: '/agencies',
      params,
    });
  },
  getAreas(params?: mpg.api.employees.GetParams): ApiResponse<WrappedResult<mpg.api.areas.Area[]>> {
    return request({
      method: 'get',
      url: '/areas',
      params,
    });
  },
  getBreaktimes(params?: mpg.api.employees.GetParams): ApiResponse<WrappedResult<mpg.api.breaktimes.Breaktime[]>> {
    return request({
      method: 'get',
      url: '/breaktimes',
      params,
    });
  },
  getBuildings(params?: mpg.api.employees.GetParams): ApiResponse<WrappedResult<mpg.api.buildings.Building[]>> {
    return request({
      method: 'get',
      url: '/buildings',
      params,
    });
  },
  getBuildingsAreas(params?: mpg.api.employees.GetParams): ApiResponse<WrappedResult<mpg.api.buildingsAreas.BuildingsAreas[]>> {
    return request({
      method: 'get',
      url: '/buildings-areas',
      params,
    });
  },
  getColors(params?: mpg.api.employees.GetParams): ApiResponse<WrappedResult<mpg.api.colors.Colors[]>> {
    return request({
      method: 'get',
      url: '/colors',
      params,
    });
  },
  getLanguages(params?: mpg.api.employees.GetParams): ApiResponse<WrappedResult<mpg.api.languages.Language[]>> {
    return request({
      method: 'get',
      url: '/languages',
      params,
    });
  },
  getShifts(params?: mpg.api.employees.GetParams): ApiResponse<WrappedResult<mpg.api.shifts.Shift[]>> {
    return request({
      method: 'get',
      url: '/shifts',
      params,
    });
  },
  getPositions(params?: mpg.api.employees.GetParams): ApiResponse<WrappedResult<mpg.api.positions.Position[]>> {
    return request({
      method: 'get',
      url: '/positions',
      params,
    });
  },
  getSupervisors(params?: mpg.api.supervisors.GetParams): ApiResponse<WrappedResult<mpg.api.supervisors.Supervisor[]>> {
    return request({
      method: 'get',
      url: '/supervisors',
      params,
    });
  },
  getMusterStations(params?: mpg.api.musterStations.GetParams): ApiResponse<WrappedResult<mpg.api.musterStations.MusterStation[]>> {
    return request({
      method: 'get',
      url: '/muster-stations',
      params,
    });
  },
  getMusterStationCaptains(params?: mpg.api.musterStationCaptains.GetParams): ApiResponse<WrappedResult<mpg.api.musterStationCaptains.MusterStationCaptain[]>> {
    return request({
      method: 'get',
      url: '/muster-station-captains',
      params,
    });
  },
  getLines(params?: mpg.api.lines.GetParams): ApiResponse<WrappedResult<mpg.api.lines.Line[]>> {
    return request({
      method: 'get',
      url: '/lines',
      params,
    });
  },
};
