import React from 'react';
import { UIView } from '@uirouter/react';
import { Layout as AntdLayout, Layout } from '@/components/antd';
import { Header } from '../header';

const { Content } = AntdLayout;

const PerformanceReportLayout = () => (
  <Layout className="performance-report-layout">
    <Header />
    <Content>
      <UIView />
    </Content>
  </Layout>
);

export const states = [
  {
    name: 'performance-report-layout',
    component: PerformanceReportLayout,
    data: {
      requiresAuth: false,
    },
  },
];
