import React, { useState } from 'react';

import { Button, InputNumber, Menu, Select, Space } from '@/components/antd';
import { keyPressedNumbers } from '@/utils/common';

const { Option } = Select;

const getOptions = () => [
  {
    label: '>',
    value: 'gt',
  },
  {
    label: '>=',
    value: 'gte',
  },
  {
    label: '=',
    value: 'eq',
  },
  {
    label: '<',
    value: 'lt',
  },
  {
    label: '<=',
    value: 'lte',
  },
];

export const TimeFilter = ({ isIntegerStepValue, setSelectedKeys, confirm, convertToMinutes }) => {
  const [operator, setOperator] = useState<string>(getOptions()[0].value);
  const [time, setTime] = useState<number>(0);

  const onClear = () => {
    setSelectedKeys([]);
    confirm();
  };

  const onTimeChange = (selectedTime: number) => {
    setTime(selectedTime);
  };

  const onOperatorChange = (selectedOperator: string) => {
    setOperator(selectedOperator);
  };

  const onApply = () => {
    const filterValue = convertToMinutes ? 60 * time : time;
    setSelectedKeys([`${operator}:${filterValue}`]);
    confirm();
  };

  const getPopupContainer = () => document.getElementById('time-popup-container');
  const disabled = !operator || !time?.toString().length;

  return (
    <Menu id="time-popup-container">
      <Menu.Item key="1">
        <Select
          style={{
            width: 80,
          }}
          defaultValue={operator}
          getPopupContainer={getPopupContainer}
          allowClear
          showSearch={false}
          optionFilterProp="children"
          onChange={onOperatorChange}
          value={operator}
        >
          {getOptions().map((item: any) => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
        <InputNumber
          required
          type="number"
          value={time}
          onKeyPress={isIntegerStepValue && keyPressedNumbers}
          onChange={onTimeChange}
          step={isIntegerStepValue ? '1' : '0.01'}
          decimalSeparator="."
        />
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2">
        <Space>
          <Button onClick={onClear} type="link" disabled={disabled}>
            Reset Filters
          </Button>
          <Button onClick={onApply} shape="round" type="primary" disabled={disabled}>
            Apply
          </Button>
        </Space>
      </Menu.Item>
    </Menu>
  );
};
