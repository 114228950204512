import {
  buildDropdownOptionsFromEnum,
  buildStoreOptions,
  getOptionsFilteredByActive,
  getRoundDecimalNumber
} from '@/utils/common';
import { agencyStore, breaktimeStore, buildingStore, colorStore, shiftStore, supervisorStore } from '@/stores';
import { EmployeeStatus } from '@/types/enums';

export const getFormFields = (initValues) => [
  {
    name: 'visitorId',
    componentName: 'input',
    params: {
      disabled: true,
      i18nLabel: 'workingLogs.visitorId',
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'agencyId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'workingLogs.agencyId',
      options: buildStoreOptions(agencyStore, 'name'),
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'visitorFirstName',
    componentName: 'input',
    params: {
      i18nLabel: 'workingLogs.visitorFirstName',
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'visitorLastName',
    componentName: 'input',
    params: {
      i18nLabel: 'workingLogs.visitorLastName',
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'visitorMiddleName',
    componentName: 'input',
    params: {
      i18nLabel: 'workingLogs.visitorMiddleName',
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'checkInTurnStile',
    componentName: 'input',
    params: {
      i18nLabel: 'workingLogs.checkInTurnStile',
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'checkInTime',
    componentName: 'input',
    params: {
      i18nLabel: 'workingLogs.checkInTime',
      disabled: true,
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'checkInDate',
    componentName: 'input',
    params: {
      i18nLabel: 'workingLogs.checkInDate',
      // format: 'YYYY-MM-DD h:mm:ss a',
      disabled: true,
    },
    rules: [
      // { required: true },
    ],
  },
  // {
  //   name: 'checkInRawDate',
  //   componentName: 'datepicker',
  //   params: {
  //     i18nLabel: 'workingLogs.checkInRawDate',
  //     showTime: true,
  //   },
  //   rules: [
  //     // { required: true },
  //   ],
  // },
  {
    name: 'checkOutTime',
    componentName: 'input',
    params: {
      i18nLabel: 'workingLogs.checkOutTime',
      disabled: true,
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'workingHours',
    componentName: 'input-number',
    params: {
      disabled: true,
      i18nLabel: 'workingLogs.time',
      formatter: getRoundDecimalNumber,
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'checkOutDate',
    componentName: 'input',
    params: {
      i18nLabel: 'workingLogs.checkOutDate',
      format: 'YYYY-MM-DD h:mm:ss a',
      disabled: true,
    },
    rules: [
      // { required: true },
    ],
  },
  // {
  //   name: 'checkOutRawDate',
  //   componentName: 'datepicker',
  //   params: {
  //     i18nLabel: 'workingLogs.checkOutRawDate',
  //     showTime: true,
  //   },
  //   rules: [
  //     // { required: true },
  //   ],
  // },
  {
    name: 'buildingId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'workingLogs.buildingId',
      options: buildStoreOptions(buildingStore, 'name'),
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'actualBuildingId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'workingLogs.actualBuildingId',
      options: buildStoreOptions(buildingStore, 'name'),
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'buildingsAreasId',
    componentName: 'input',
    params: {
      i18nLabel: 'workingLogs.buildingsAreasId',
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'supervisorId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'workingLogs.supervisorId',
      options: getOptionsFilteredByActive(initValues?.supervisor, buildStoreOptions(supervisorStore, 'name', 'active', true)),
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'shiftPlannedId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'workingLogs.shiftPlannedId',
      options: getOptionsFilteredByActive(initValues?.shiftPlanned, buildStoreOptions(shiftStore, 'name', 'active', true)),
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'shiftAssignedId',
    componentName: 'input',
    params: {
      i18nLabel: 'workingLogs.shiftAssignedId',
      disabled: true,
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'colorAssignedId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'workingLogs.colorAssignedId',
      options: getOptionsFilteredByActive(initValues?.colorAssigned, buildStoreOptions(colorStore, 'name', 'active', true)),
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'colorActualId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'workingLogs.colorActualId',
      options: buildStoreOptions(colorStore, 'name'),
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'weekOf',
    componentName: 'input',
    params: {
      i18nLabel: 'workingLogs.weekOf',
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'breakTimes',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'workingLogs.breakTimes',
      options: buildStoreOptions(breaktimeStore, 'name'),
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'status',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'workingLogs.status',
      options: buildDropdownOptionsFromEnum(EmployeeStatus, 'employees.status.'),
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'raw',
    componentName: 'switch',
    params: {
      i18nLabel: 'workingLogs.raw',
    },
    rules: [
      // { required: true },
    ],
  },
];
