import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { PERMISSION_CREATE, PERMISSION_OBJ_EMPLOYEE_MARKUP } from '@/constants/permissions';
import { Table } from '@/components/table';
import { employeeMarkupsStore } from '@/stores';
import { useTableDataByKey } from '@/utils/hooks';
import { getColumns } from './setup';
import { withPermissions } from '@/hocs/permission';
import { Button } from '@/components/antd';
import { PlusOutlined } from '@/components/icons';

const store = employeeMarkupsStore;

const CreateButton = withPermissions([[PERMISSION_OBJ_EMPLOYEE_MARKUP, PERMISSION_CREATE]])(({ onClick }) => (
  <Button onClick={onClick} type="primary" shape="round" icon={<PlusOutlined />}>
    <span>
      <FormattedMessage id="employeeMarkup.add-new" />
    </span>
  </Button>
));

interface IProps extends UIViewInjectedProps {
  employeeId: string;
  entity: string;
}
export const EmployeeMarkupsView = observer(({ employeeId, transition, entity }: IProps) => {
  const {
    additionalStore,
    dataSource,
    loading: additionalDataLoading,
    onChangeTable,
  } = useTableDataByKey({ employeeId, sort: 'createdAt:-1' }, employeeMarkupsStore, getColumns({ employeeId, entity }));

  const onCreate = () => {
    transition.router.stateService.go(`base-layout.${entity}.edit.create-employee-markup`);
  };

  return (
    <>
      <CreateButton key="create" onClick={onCreate} />
      <br />
      <br />
      <Table
        id="employee-markups"
        rowKey="id"
        columns={getColumns({ employeeId, entity })}
        dataSource={dataSource}
        store={additionalStore}
        checkPermissions
        permissionObj={PERMISSION_OBJ_EMPLOYEE_MARKUP}
        loading={store.loading || additionalDataLoading}
        scroll={{ y: '100%' }}
        customHeight="80vh"
        onChange={onChangeTable}
      />
    </>
  );
});
