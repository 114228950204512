import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CloseOutlined, CheckOutlined, MinusOutlined } from '@/components/icons';
import { minutesToStringHours } from '@/utils/time';

export const BooleanValueComponent = ({ value }: any) => {
  if (value) {
    return <CheckOutlined style={{ color: 'green' }} />;
  }

  if (value === false) {
    return <CloseOutlined style={{ color: 'red' }} />;
  }

  if (value === null) {
    return <MinusOutlined />;
  }

  return null;
};

export const TimesheetStatusComponent = ({ value }: any) => {
  if (value) {
    return <CheckOutlined style={{ color: 'green' }} />;
  }

  if (value === null || value === false) {
    return <MinusOutlined />;
  }

  return null;
};

export const BooleanValueRender = (value: any) => <BooleanValueComponent value={value} />;

export const TimesheetStatusRender = (value: any) => <TimesheetStatusComponent value={value} />;

export const NumberAsBooleanValueRender = (value: string, i18nPrefix: string) => {
  switch (value) {
    case '0':
      return <FormattedMessage id={`${i18nPrefix}${value}`} />;
    case '1':
      return <FormattedMessage id={`${i18nPrefix}${value}`} />;
    default:
      break;
  }
};

export const BillableTimeRender = (value: number | null) =>
  value === null ? BooleanValueRender(value) : minutesToStringHours(value);
