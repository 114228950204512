import { useEffect, useState } from 'react';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { api } from '@/api';
import { showError } from '@/utils/common';
import { BillingType } from '@/types/enums';

export const useEmployeeForm = (employeeId: string, formRef: FormInstance) => {
  const [formData, setFormData] = useState<mpg.api.employees.Employee>();
  const [formDataLoading, setFormDataLoading] = useState<boolean>(false);
  const [billingType, setBillingType] = useState<BillingType>();
  const loadFormData = async () => {
    api.employees
      .get(employeeId)
      .source.then((item) => {
        const newBillingType = (item.data.agency.isNonBillable && BillingType.NonBillable) || item.data.billingType
        setBillingType(newBillingType);
        formRef.setFieldsValue({
          billingType: (item.data.agency.isNonBillable && BillingType.NonBillable) || item.data.billingType,
        });
        return setFormData(item.data);
      })
      .catch(showError)
      .finally(() => setFormDataLoading(false));
  };
  useEffect(() => {
    if (!employeeId) {
      return;
    }
    setFormDataLoading(true);

    // TODO: Why are there 2 api calls?
    api.employees
      .get(employeeId)
      .source.then((item) => setFormData(item.data))
      .catch(showError)
      .finally(() => setFormDataLoading(false));
    loadFormData();
  }, [employeeId]);
  return { formData, formDataLoading, billingType, loadFormData };
};
