import { api } from '@/api';
import { shiftPlanningPermsStore } from '@/stores';

import { parseSettings } from './csv-parser-setup';

export const useBulkUpdate = () => {
  const importRequest = (parsedItems: mpg.api.shiftPlanning.ShiftPlanningParams[]) => api.shiftPlanningPerms.bulkCreate({ items: parsedItems }).source;

  const storeRefreshRequest = () => shiftPlanningPermsStore.refresh();

  return {
    importRequest,
    parseSettings,
    storeRefreshRequest,
  };
};
