import { LocalStorageTokenProvider, LocalStorage2faTokenProvider } from '@/utils/access-token-provider';

import { api } from '@/api';
import { formatWorkingLogs } from '@/utils/data-formatters';
import { AuthStore } from './auth';
import { CachedStorage, CollectionStore, StoreDataLoader } from './collection';
import { LocaleStore } from './locale';
import { SearchStore } from './search';
import { EmployeePerformanceStore } from './employee-performance';
import { SettingsStore } from './settings';
import {CalendarShiftPlanningStore} from "@/stores/calendar-store";
import { DayTimesheetStore } from "@/stores/day-timesheet-store";

export const settingsStore = new SettingsStore();

export const authStore = new AuthStore(
  new LocalStorageTokenProvider(),
  new LocalStorage2faTokenProvider(),
  settingsStore,
);

export const localeStore = new LocaleStore();
export const searchStore = new SearchStore();
export const employeePerformanceStore = new EmployeePerformanceStore();
export const calendarShiftPlanningStore = new CalendarShiftPlanningStore()
export const dayTimesheetStore = new DayTimesheetStore()

export const rolesStore = new CollectionStore<mpg.api.roles.RoleId, mpg.api.roles.Role, mpg.api.roles.GetParams>(
  new StoreDataLoader(api.roles.getAll),
  new CachedStorage(),
);

export const employeeStore = new CollectionStore<
  mpg.api.employees.EmployeeId,
  mpg.api.employees.Employee,
  mpg.api.employees.GetParams
>(new StoreDataLoader(api.employees.getAll), new CachedStorage());

export const permanentEmployeeStore = new CollectionStore<
  mpg.api.employees.EmployeeId,
  mpg.api.employees.Employee,
  mpg.api.employees.GetParams
>(new StoreDataLoader(api.employees.getAll), new CachedStorage());

export const approversStore = new CollectionStore<
  mpg.api.approvers.ApproverId,
  mpg.api.approvers.Approver,
  mpg.api.approvers.GetParams
>(new StoreDataLoader(api.users.getAll), new CachedStorage());

export const employeeAdditionalStore = new CollectionStore<
  mpg.api.employees.EmployeeId,
  mpg.api.employees.Employee,
  mpg.api.employees.EmployeeAuditGetParams
>(new StoreDataLoader(api.employees.audit), new CachedStorage());

export const workingLogStore = new CollectionStore<
  mpg.api.workingLogs.WorkingLogId,
  mpg.api.workingLogs.WorkingLog,
  mpg.api.workingLogs.GetParams
>(new StoreDataLoader(api.workingLogs.getAll, formatWorkingLogs), new CachedStorage());

export const billingsDetailsStore = new CollectionStore<
  mpg.api.workingLogs.WorkingLogId,
  mpg.api.workingLogs.WorkingLog,
  mpg.api.workingLogs.GetParams
>(new StoreDataLoader(api.workingLogs.getReport, formatWorkingLogs), new CachedStorage());

export const billingStore = new CollectionStore<
  mpg.api.workingLogs.WorkingLogId,
  mpg.api.workingLogs.WorkingLogReport,
  mpg.api.workingLogs.GetParams
>(new StoreDataLoader(api.workingLogs.getReportAll), new CachedStorage());

export const busShuttleReportStore = new CollectionStore<
  mpg.api.busShuttleReports.BusShuttleReportId,
  mpg.api.busShuttleReports.BusShuttleReport,
  mpg.api.busShuttleReports.GetParams
>(new StoreDataLoader(api.busShuttleReports.getAll), new CachedStorage());

export const shiftReportStore = new CollectionStore<
  mpg.api.shiftReports.ShiftReportId,
  mpg.api.shiftReports.ShiftReport,
  mpg.api.shiftReports.GetParams
>(new StoreDataLoader(api.shiftReports.getAll), new CachedStorage());
export const temporaryShiftReportStore = new CollectionStore<
  mpg.api.shiftReports.ShiftReportId,
  mpg.api.shiftReports.ShiftReport,
  mpg.api.shiftReports.GetParams
>(new StoreDataLoader(api.shiftReports.getTemporaryEmployeesShiftReports), new CachedStorage());
export const permanentShiftReportStore = new CollectionStore<
  mpg.api.shiftReports.ShiftReportId,
  mpg.api.shiftReports.ShiftReport,
  mpg.api.shiftReports.GetParams
>(new StoreDataLoader(api.shiftReports.getPermanentEmployeesShiftReports), new CachedStorage());
export const shiftReportDetailsStore = new CollectionStore<
  mpg.api.shiftReports.ShiftReportId,
  mpg.api.workingLogs.WorkingLog,
  mpg.api.workingLogs.GetParams
>(new StoreDataLoader(api.workingLogs.getAll, formatWorkingLogs), new CachedStorage());

export const temporaryEmployeesAttendanceReportStore = new CollectionStore<
  mpg.api.shiftReports.ShiftReportId,
  mpg.api.shiftReports.ShiftReport,
  mpg.api.shiftReports.GetParams
>(new StoreDataLoader(api.shiftReports.getTemporaryEmployeesAttendance), new CachedStorage());
export const permanentEmployeesAttendanceReportStore = new CollectionStore<
  mpg.api.shiftReports.ShiftReportId,
  mpg.api.shiftReports.ShiftReport,
  mpg.api.shiftReports.GetParams
>(new StoreDataLoader(api.shiftReports.getPermanentEmployeesAttendance), new CachedStorage());
export const attendanceReportStore = new CollectionStore<
  mpg.api.shiftReports.ShiftReportId,
  mpg.api.shiftReports.ShiftReport,
  mpg.api.shiftReports.GetParams
>(new StoreDataLoader(api.shiftReports.getAttendanceAll), new CachedStorage());

export const attendanceReportDetailsStore = new CollectionStore<
  mpg.api.shiftReports.ShiftReportId,
  mpg.api.shiftReports.ShiftReport,
  mpg.api.shiftReports.GetParams
>(new StoreDataLoader(api.shiftReports.getAttendance), new CachedStorage());

export const addressStore = new CollectionStore<
  mpg.api.addresses.AddressId,
  mpg.api.addresses.Address,
  mpg.api.addresses.GetParams
>(new StoreDataLoader(api.addresses.getAll), new CachedStorage(60 * 5));

export const agencyStore = new CollectionStore<
  mpg.api.agencies.AgencyId,
  mpg.api.agencies.Agency,
  mpg.api.agencies.GetParams
>(new StoreDataLoader(api.dictionaries.getAgencies), new CachedStorage(60 * 5));

export const areaStore = new CollectionStore<mpg.api.areas.AreaId, mpg.api.areas.Area, mpg.api.areas.GetParams>(
  new StoreDataLoader(api.dictionaries.getAreas),
  new CachedStorage(60 * 5),
);

export const breaktimeStore = new CollectionStore<
  mpg.api.breaktimes.BreaktimeId,
  mpg.api.breaktimes.Breaktime,
  mpg.api.breaktimes.GetParams
>(new StoreDataLoader(api.dictionaries.getBreaktimes), new CachedStorage(60 * 5));

export const buildingsAreasStore = new CollectionStore<
  mpg.api.buildingsAreas.BuildingsAreasId,
  mpg.api.buildingsAreas.BuildingsAreas,
  mpg.api.buildingsAreas.GetParams
>(new StoreDataLoader(api.dictionaries.getBuildingsAreas), new CachedStorage(60 * 5));

export const buildingStore = new CollectionStore<
  mpg.api.buildings.BuildingId,
  mpg.api.buildings.Building,
  mpg.api.buildings.GetParams
>(new StoreDataLoader(api.dictionaries.getBuildings), new CachedStorage(60 * 5));

export const colorStore = new CollectionStore<mpg.api.colors.ColorsId, mpg.api.colors.Colors, mpg.api.colors.GetParams>(
  new StoreDataLoader(api.dictionaries.getColors),
  new CachedStorage(60 * 5),
);

export const languageStore = new CollectionStore<
  mpg.api.languages.LanguageId,
  mpg.api.languages.Language,
  mpg.api.languages.GetParams
>(new StoreDataLoader(api.dictionaries.getLanguages), new CachedStorage(60 * 5));

export const positionStore = new CollectionStore<
  mpg.api.positions.PositionId,
  mpg.api.positions.Position,
  mpg.api.positions.GetParams
>(new StoreDataLoader(api.dictionaries.getPositions), new CachedStorage(60 * 5));

export const shiftStore = new CollectionStore<mpg.api.shifts.ShiftId, mpg.api.shifts.Shift, mpg.api.shifts.GetParams>(
  new StoreDataLoader(api.dictionaries.getShifts),
  new CachedStorage(60 * 5),
);

export const supervisorStore = new CollectionStore<
  mpg.api.supervisors.SupervisorId,
  mpg.api.supervisors.Supervisor,
  mpg.api.supervisors.GetParams
>(new StoreDataLoader(api.dictionaries.getSupervisors), new CachedStorage(60 * 5));

export const musterStationsStore = new CollectionStore<
  mpg.api.musterStations.MusterStationId,
  mpg.api.musterStations.MusterStation,
  mpg.api.musterStations.GetParams
>(new StoreDataLoader(api.dictionaries.getMusterStations), new CachedStorage(60 * 5));

export const musterStationCaptainsStore = new (class extends CollectionStore<
  mpg.api.musterStationCaptains.MusterStationCaptainId,
  mpg.api.musterStationCaptains.MusterStationCaptain,
  mpg.api.musterStationCaptains.GetParams
> {
  constructor() {
    super(new StoreDataLoader(api.dictionaries.getMusterStationCaptains), new CachedStorage(60 * 5));
  }

  fillStore(
    items: mpg.api.musterStationCaptains.MusterStationCaptain[],
    count?: number,
  ): mpg.api.musterStationCaptains.MusterStationCaptain[] {
    if (Array.isArray(items)) {
      return super.fillStore([...items, { id: null, name: 'N/A' }], (count || items.length) + 1);
    }
    return super.fillStore(items, count);
  }
})();

export const linesStore = new CollectionStore<mpg.api.lines.LineId, mpg.api.lines.Line, mpg.api.lines.GetParams>(
  new StoreDataLoader(api.dictionaries.getLines),
  new CachedStorage(60 * 5),
);

export const permanentEmployeeStandardHoursStore = new CollectionStore<
  mpg.api.permanentEmployees.PermanentEmployeeId,
  mpg.api.permanentEmployees.PermanentEmployee,
  mpg.api.permanentEmployees.GetParams
>(new StoreDataLoader(api.permanentEmployeesStandardHours.getAll), new CachedStorage());

export const permanentEmployeeStandardHoursAdditionalStore = new CollectionStore<
  mpg.api.permanentEmployees.PermanentEmployeeId,
  mpg.api.permanentEmployees.PermanentEmployee,
  mpg.api.permanentEmployees.PermanentEmployeeAuditGetParams
>(new StoreDataLoader(api.permanentEmployeesStandardHours.audit), new CachedStorage());

export const permanentEmployeeShiftWorkStore = new CollectionStore<
  mpg.api.permanentEmployees.PermanentEmployeeId,
  mpg.api.permanentEmployees.PermanentEmployee,
  mpg.api.permanentEmployees.GetParams
>(new StoreDataLoader(api.permanentEmployeesShiftWork.getAll), new CachedStorage());

export const permanentEmployeeShiftWorkAdditionalStore = new CollectionStore<
  mpg.api.permanentEmployees.PermanentEmployeeId,
  mpg.api.permanentEmployees.PermanentEmployee,
  mpg.api.permanentEmployees.PermanentEmployeeAuditGetParams
>(new StoreDataLoader(api.permanentEmployeesShiftWork.audit), new CachedStorage());

export const ptoStore = new CollectionStore<mpg.api.ptos.PTOId, mpg.api.ptos.PTO, mpg.api.ptos.GetParams>(
  new StoreDataLoader(api.ptos.getAll),
  new CachedStorage(),
);

export const lockerStore = new CollectionStore<
  mpg.api.lockers.LockerId,
  mpg.api.lockers.Locker,
  mpg.api.lockers.GetParams
>(new StoreDataLoader(api.lockers.getAll), new CachedStorage());

export const shiftPlanningTempsStore = new CollectionStore<
  mpg.api.shiftPlanning.ShiftPlanningId,
  mpg.api.shiftPlanning.ShiftPlanning,
  mpg.api.shiftPlanning.GetParams
>(new StoreDataLoader(api.shiftPlanningTemps.getAll), new CachedStorage());

export const shiftPlanningPermsStore = new CollectionStore<
  mpg.api.shiftPlanning.ShiftPlanningId,
  mpg.api.shiftPlanning.ShiftPlanning,
  mpg.api.shiftPlanning.GetParams
>(new StoreDataLoader(api.shiftPlanningPerms.getAll), new CachedStorage());

export const employeeMarkupsStore = new CollectionStore<
  mpg.api.employeeMarkups.EmployeeId,
  mpg.api.employeeMarkups.EmployeeMarkup,
  mpg.api.employeeMarkups.GetParams
>(new StoreDataLoader(api.employeeMarkups.getAll), new CachedStorage());

export const employeeMarkupAuditStore = new CollectionStore<
  mpg.api.employeeMarkups.EmployeeMarkupAuditId,
  mpg.api.employeeMarkups.EmployeeMarkupsAudit,
  mpg.api.employeeMarkups.EmployeeMarkupAuditGetParams
>(new StoreDataLoader(api.employeeMarkups.audit), new CachedStorage());

export const weekTimesheetStore = new CollectionStore<
  mpg.api.weekTimesheet.WeekTimesheetId,
  mpg.api.weekTimesheet.WeekTimesheet,
  mpg.api.weekTimesheet.GetParams
>(new StoreDataLoader(api.weekTimesheet.getAll), new CachedStorage());

// export const dayTimesheetStore = new CollectionStore<
//   mpg.api.dayTimesheet.DayTimesheetId,
//   mpg.api.dayTimesheet.DayTimesheet,
//   mpg.api.dayTimesheet.GetParams
// >(new StoreDataLoader(api.dayTimesheet.getAll), new CachedStorage());

export const dayTimesheetAuditStore = new CollectionStore<
  mpg.api.dayTimesheet.DayTimesheetId,
  mpg.api.dayTimesheet.DayTimesheet,
  mpg.api.dayTimesheet.GetParams
>(new StoreDataLoader(api.dayTimesheet.audit), new CachedStorage());

export const dayTimesheetWorkingLogsStore = new CollectionStore<
  mpg.api.dayTimesheet.DayTimesheetId,
  mpg.api.dayTimesheet.DayTimesheetWorkingLog,
  mpg.api.dayTimesheet.WorkingLogsParams
>(new StoreDataLoader(api.dayTimesheet.getWorkingLogs, formatWorkingLogs), new CachedStorage());

export const buildingHostStore = new CollectionStore<
  mpg.api.buildingHost.BuildingHostId,
  mpg.api.buildingHost.BuildingHost,
  mpg.api.buildingHost.GetParams
>(new StoreDataLoader(api.buildingHost.getAllBuildingHosts), new CachedStorage());
