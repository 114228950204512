import { UIViewInjectedProps } from '@uirouter/react';
import { useMassUpdateCashedStores } from '@/utils/store';
import { agencyStore, areaStore, buildingsAreasStore, buildingStore } from '@/stores';
import { Billings } from '@/pages/common/components/billings/components/table';

const entity = 'coordinator-billings';

export const CoordinatorBillings = ({ transition }: UIViewInjectedProps) => {
  const { loading } = useMassUpdateCashedStores([agencyStore, buildingsAreasStore, buildingStore, areaStore]);
  return <Billings loading={loading} transition={transition} entity={entity} />;
};
