import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { api } from '@/api';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { ptoStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValueForBulkUpdate } from '@/utils/controller';
import { PERMISSION_OBJ_PTO } from '@/constants/permissions';

import { getFormFields } from './setup';
import { DrawerForm } from '@/components/drawer-form';
import { SIDEBAR_WIDTH } from '@/constants';

const store = ptoStore;
interface IBulkForm extends UIViewInjectedProps {
  entity: string;
}
export const BulkForm = observer(({ entity, transition }: IBulkForm) => {
  const { selectedRowKeys = [] } = transition.targetState().params();

  const onClose = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };

  const initValues = {};

  const title = getTranslatedString('ptos.bulk-update');

  const formFields = getFormFields({
    initValues,
    isBulkEdit: true,
  }).map((item) => ({ ...item, rules: item.rules.filter(({ required }) => !required) }));

  const allowedFields = getAllowedFormFields(formFields, false, PERMISSION_OBJ_PTO);

  const resourceController = withoutRepeatValueForBulkUpdate(initValues, {
    create: (values: any) => {
      const items = selectedRowKeys.map((id: string) => ({
        ...values,
        id,
        from: values.from && values.from.utc().startOf('day').toISOString(),
        to: values.to && values.to.utc().endOf('day').toISOString(),
      }));

      return api.ptos.bulkUpdate({ items }).source.then(() => store.refresh());
    },
  });

  useEffect(() => {
    if (!selectedRowKeys.length) {
      onClose();
    }
  }, []);

  return (
    <DrawerForm
      onClose={onClose}
      title={title}
      initValues={initValues}
      formFields={allowedFields}
      resourceController={resourceController}
      loaderCondition={false}
      width={(window.innerWidth - SIDEBAR_WIDTH) / 2}
    />
  );
});
