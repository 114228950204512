export enum TimesheetType {
  Week = 'week',
  Day = 'day',
}

export enum TimesheetPageState {
  BaseLayout = 'base-layout.timesheet',
  DayTimesheetTable = 'base-layout.timesheet.timesheet-day',
  DayTimesheetDetails = 'base-layout.timesheet.timesheet-day.details',
  DayTimesheetEditForm = 'base-layout.timesheet.timesheet-day.edit',
}

export enum DayTimesheetDetailsView {
  WorkLogs = 'working-logs',
  Audit = 'audit',
}
