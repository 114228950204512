import React from 'react';

const withFirstLetterUppercase = (str: string = '') => {
  if (typeof str === 'string') {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return '';
};

export function getResponseError(err: any) {
  try {
    const responseMessage = err.response?.data?.error.message;

    if (responseMessage) {
      if (Array.isArray(responseMessage)) {
        return responseMessage.map((e: string, i: number) => <div key={i}>{withFirstLetterUppercase(e)}</div>);
      }

      return responseMessage;
    }

    return withFirstLetterUppercase(err.message);
  } catch (error) {
    return 'Error occurred!';
  }
}
