import { PERMISSION_OBJ_TIMESHEET, PERMISSION_READ } from '@/constants/permissions';
import { Timesheet } from './components/table';
import { DayTimesheet } from './components/day-timesheet';
import { EditDayTimesheetForm } from './components/edit-day-timesheet-form';
import { TimesheetPageState } from './enums';
import { DayTimesheetDetails } from './components/day-timesheet-details';

export const states = [
  {
    name: TimesheetPageState.BaseLayout,
    url: '/timesheet',
    component: Timesheet,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_TIMESHEET, PERMISSION_READ]],
    },
  },
  {
    name: TimesheetPageState.DayTimesheetTable,
    url: '/timesheet/:id',
    component: DayTimesheet,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_TIMESHEET, PERMISSION_READ]],
    },
  },
  {
    name: TimesheetPageState.DayTimesheetDetails,
    url: '/:recordId?{view}',
    component: DayTimesheetDetails,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_TIMESHEET, PERMISSION_READ]],
    },
  },
  {
    name: TimesheetPageState.DayTimesheetEditForm,
    url: ':recordId/edit',
    component: EditDayTimesheetForm,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_TIMESHEET, PERMISSION_READ]],
    },
  },
];
