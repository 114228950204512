import {
  PERMISSION_OBJ_SHIFT_PLANNING_PERMANENT,
  PERMISSION_CREATE,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';

import { TableView } from './components/table';
import { Form } from './components/form';
import { BulkForm } from './components/form/bulk-edit';

export const states = [
  {
    name: 'base-layout.coordinator-perms-shift-planning',
    url: '/perms-shift-planning',
    component: TableView,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_SHIFT_PLANNING_PERMANENT, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-perms-shift-planning.create',
    url: '/new',
    component: Form,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_SHIFT_PLANNING_PERMANENT, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-perms-shift-planning.edit',
    url: '/:shiftPlanningId/edit',
    component: Form,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_SHIFT_PLANNING_PERMANENT, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-perms-shift-planning.bulk-edit',
    url: '/bulk-update',
    component: BulkForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_SHIFT_PLANNING_PERMANENT, PERMISSION_UPDATE],
      ],
    },
  },
];
