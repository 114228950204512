export const definitions = {
  'asPlanned:true': 'Showed up as planned',
  'asPlanned:false': 'Did not show up as planned',
  'isDifferentShift:true': 'Showed up in a different shift',
  'isDifferentShift:false': 'Showed up in a correct shift',
  'isDifferentBuilding:true': 'Showed up in a different building',
  'isDifferentBuilding:false': 'Showed up in a correct building',
  'notShowedUp:true': 'Planned but did not show up',
  'notShowedUp:false': 'Showed up',
};

export const filtersSetup = [
  {
    name: 'asPlanned',
    componentName: 'switch',
    params: {
      asDropdown: true,
      i18nLabel: 'shiftReports.showedUpAsPlanned',
    },
    rules: [],
  },
  {
    name: 'isDifferentShift',
    componentName: 'switch',
    params: {
      asDropdown: true,
      i18nLabel: 'shiftReports.showedUpInDifferentShift',
    },
    rules: [],
  },
  {
    name: 'isDifferentBuilding',
    componentName: 'switch',
    params: {
      asDropdown: true,
      i18nLabel: 'shiftReports.showedUpInDifferentBuilding',
    },
    rules: [],
  },
  {
    name: 'notShowedUp',
    componentName: 'switch',
    params: {
      asDropdown: true,
      i18nLabel: 'shiftReports.plannedButDidNotShowUp',
    },
    rules: [],
  },
  {
    name: 'date',
    componentName: 'date-range-picker',
    params: {
      // format: 'YYYY-MM-DD h:mm:ss ',
      i18nLabel: 'shiftReports.date',
    },
    rules: [],
  },
];
