import { getFormattedParams, request } from '@/utils/request';
import { ApiResponse, WrappedResult } from './types';

export default {
  getAll(params?: mpg.api.approvers.GetParams): ApiResponse<WrappedResult<mpg.api.approvers.Approver[]>> {
    return request({
      method: 'GET',
      url: '/users/approvers',
      params: getFormattedParams(params),
    });
  },
  getUserData(): ApiResponse<WrappedResult<mpg.api.users.User>> {
    return request({
      method: 'GET',
      url: '/users/current',
    });
  },
  updateUserData(data: mpg.api.users.User): ApiResponse<WrappedResult<mpg.api.users.User>> {
    return request({
      method: 'PATCH',
      url: '/users/current',
      data,
    });
  },
};
