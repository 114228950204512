import { request } from '@/utils/request';
import { ApiResponse, WrappedResult } from './types';

export default {
  getAll(params?: mpg.api.addresses.GetParams): ApiResponse<WrappedResult<mpg.api.addresses.Address[]>> {
    return request({
      method: 'GET',
      url: '/addresses',
      params,
    });
  },
  create(data: mpg.api.addresses.AddressParams): ApiResponse<WrappedResult<mpg.api.addresses.Address>> {
    return request({
      method: 'POST',
      url: '/addresses',
      data,
    });
  },
  update(id: mpg.api.addresses.AddressId, data: mpg.api.addresses.AddressParams): ApiResponse<WrappedResult<mpg.api.addresses.Address>> {
    return request({
      method: 'PATCH',
      url: `/addresses/${id}`,
      data,
    });
  },
  delete(id: mpg.api.addresses.AddressId): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'DELETE',
      url: `/addresses/${id}`,
    });
  },
};
