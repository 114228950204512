import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import moment from 'moment';

import { getAllowedFormFields } from '@/components/generic-form/utils';
import { agencyStore, breaktimeStore, buildingStore, colorStore, shiftStore, supervisorStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { useFormDataById } from '@/utils/hooks';
import { useMassUpdateCashedStores } from '@/utils/store';
import {
  PERMISSION_OBJ_WORKING_LOG_TEMP_SHIFT,
  PERMISSION_OBJ_WORKING_LOG_PERM_SHIFT,
  PERMISSION_OBJ_WORKING_LOG_PERM_STANDARD,
} from '@/constants/permissions';
import { DrawerForm } from '@/components/drawer-form';
import { formatWorkingLog } from '@/utils/data-formatters';

import { getFormFields } from './setup';
import { EmployeeType, WorkScheduleType } from '@/types/enums';

const entity = 'workingLogs';

interface IForm extends UIViewInjectedProps {
  pageType: string;
}

export const Form = observer(({ pageType, transition }: IForm) => {
  const isCreate = transition.router.globals.current.name === `base-layout.${pageType}-${entity}.create`;
  const { workingLogId } = transition.router.globals.params;

  const { formData, loading: formDataLoading } = useFormDataById<mpg.api.workingLogs.WorkingLog>(
    workingLogId,
    entity,
    formatWorkingLog,
  );

  const { loading } = useMassUpdateCashedStores([
    agencyStore,
    breaktimeStore,
    buildingStore,
    colorStore,
    shiftStore,
    supervisorStore,
    // workingLogStore
  ]);

  const onBack = () => {
    transition.router.stateService.go(`base-layout.${pageType}-${entity}`);
  };

  const initValues =
    !isCreate && formData
      ? {
          ...formData,
          checkInRawDate: formData.checkInRawDate && moment(formData.checkInRawDate),
          checkOutRawDate: formData.checkOutRawDate && moment(formData.checkOutRawDate),
          buildingsAreasId: formData.buildingsAreas && formData.buildingsAreas.areaName,
        }
      : {};

  const { visitorFirstName = '', visitorLastName = '' } = formData || {};

  const title = isCreate
    ? getTranslatedString('workingLogs.create-new')
    : getTranslatedString('workingLogs.edit-title', { 0: ` ${visitorFirstName} ${visitorLastName}` });
  const formFields = getFormFields(initValues);

  const { employeeType, workSchedule } = formData || {};
  let relatedObj: string;
  switch (employeeType) {
    case EmployeeType.Temporary:
      switch (workSchedule) {
        case WorkScheduleType.Shift:
          relatedObj = PERMISSION_OBJ_WORKING_LOG_TEMP_SHIFT;
          break;
      }
      break;
    case EmployeeType.Permanent:
      switch (workSchedule) {
        case WorkScheduleType.ShiftWork:
          relatedObj = PERMISSION_OBJ_WORKING_LOG_PERM_SHIFT;
          break;
        case WorkScheduleType.StandardHours:
          relatedObj = PERMISSION_OBJ_WORKING_LOG_PERM_STANDARD;
          break;
      }
      break;
  }
  const allowedFields = getAllowedFormFields(formFields, isCreate, relatedObj);

  return (
    <DrawerForm
      resourceId={workingLogId}
      className="form-grid-wrapper"
      title={title}
      initValues={initValues}
      formFields={allowedFields}
      // resourceController={resourceController}
      loaderCondition={formDataLoading || loading}
      layout="vertical"
      onClose={onBack}
    />
  );
});
