import { UIView, UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import { Drawer, Tabs } from 'antd';
import { useCallback, useEffect } from 'react';
import { SIDEBAR_WIDTH } from '@/constants';

import { DayTimesheetAuditView } from './audit';
import { WorkingLogsView } from './day-timesheet-working-logs';
import { DayTimesheetDetailsView, TimesheetPageState } from '../../enums';

export const DayTimesheetDetails = observer(({ transition }: UIViewInjectedProps) => {
  const { recordId } = transition.router.globals.params;
  const { view } = transition.targetState().params();

  const onClose = () => {
    transition.router.stateService.go(TimesheetPageState.DayTimesheetTable);
  };

  const handleTabChange = useCallback(
    (key: DayTimesheetDetailsView) => {
      transition.router.urlService.url(`${transition.router.urlService.path()}?view=${key}`, true);
    },
    [transition],
  );

  useEffect(() => {
    if (!view) {
      handleTabChange(DayTimesheetDetailsView.WorkLogs);
    }
  }, [view, handleTabChange]);

  return (
    <>
      <Drawer visible onClose={onClose} width={window.innerWidth - SIDEBAR_WIDTH}>
        <Tabs destroyInactiveTabPane defaultActiveKey={view} onChange={handleTabChange}>
          <Tabs.TabPane
            tab={<FormattedMessage id="timesheet.workingLogs-tab" />}
            key={DayTimesheetDetailsView.WorkLogs}
          >
            <WorkingLogsView recordId={recordId} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={<FormattedMessage id="timesheet.audit-tab" />} key={DayTimesheetDetailsView.Audit}>
            <DayTimesheetAuditView recordId={recordId} />
          </Tabs.TabPane>
        </Tabs>
      </Drawer>
      <UIView />
    </>
  );
});
