import * as signalR from '@microsoft/signalr';

class SignalRService {
  private hubConnection: signalR.HubConnection;

  private url: string;

  private handlersNames: string[] = [];

  constructor(connectionUrl: string) {
    this.url = connectionUrl;
  }

  setUpHub(onConnected?: () => void) {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.url, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect([1000, 2000, 3000])
      .build();

    return this.hubConnection.start().then(
      () => {
        onConnected?.();
      },
    );
  }

  stopHub() {
    if (this.hubConnection) {
      this.unsubscribeHandlers();
      this.hubConnection.stop().then();
    }
  }

  setHandlers(handlers: { [methodName: string]: (...params: any[]) => any }) {
    if (this.hubConnection && handlers) {
      Object.keys(handlers).forEach((methodName: string) => {
        this.hubConnection.on(methodName, handlers[methodName]);
        this.handlersNames.push(methodName);
      });
    }
  }

  unsubscribeHandlers() {
    if (this.hubConnection) {
      this.handlersNames.forEach((methodName: string) => this.hubConnection.off(methodName));
      this.handlersNames = [];
    }
  }
}

export default SignalRService;
