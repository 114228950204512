import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import { Drawer } from '@/components/drawer';
import { Table } from '@/components/table';
import { getColumns } from '@/pages/common/components/working-logs/components/table/setup';
import {
  agencyStore,
  breaktimeStore,
  buildingStore,
  colorStore,
  shiftStore,
  shiftReportDetailsStore,
  supervisorStore,
  buildingsAreasStore,
} from '@/stores';
import { getTranslatedString } from '@/utils';
import { formatShiftReport } from '@/utils/data-formatters';
import { useFormDataById, useTableDataByKey } from '@/utils/hooks';
import { useMassUpdateCashedStores } from '@/utils/store';
import { SIDEBAR_WIDTH } from '@/constants';
import ExportButton from '@/pages/common/components/buttons/export-button';
import { api } from '@/api';
import { withPermissions } from '@/hocs/permission';

const entity = 'shiftReports';
interface IProps extends UIViewInjectedProps {
  permission: [string, number];
  currentPage: string;
}
export const Form = observer(({ permission, currentPage, transition }: IProps) => {
  const { shiftReportId } = transition.router.globals.params;
  const params = { shiftReportId: [`eq:${shiftReportId}`] };

  const { loading } = useMassUpdateCashedStores([
    agencyStore,
    breaktimeStore,
    buildingStore,
    colorStore,
    shiftStore,
    supervisorStore,
    buildingsAreasStore,
  ]);

  const { formData, loading: formDataLoading } = useFormDataById<mpg.api.shiftReports.ShiftReport>(
    shiftReportId,
    entity,
    formatShiftReport,
  );

  const { firstName = '', lastName = '', visitorId } = formData || {};

  const columns = useMemo(
    () =>
      getColumns().map((column) => ({
        ...column,
        sorter: false,
        filters: null,
        filterDropdown: null,
      })),
    [],
  );

  const {
    dataSource,
    loading: dataSourceLoading,
    additionalStore: store,
    onChangeTable,
  } = useTableDataByKey(params, shiftReportDetailsStore, columns);

  const title = getTranslatedString('shiftReports.edit-title', { 0: ` ${firstName} ${lastName}` });
  // const reportPage = isCoordinator ? `coordinator-${entity}` : `agency-${entity}`;
  const onBack = () => {
    transition.router.stateService.go(`base-layout.${currentPage}-${entity}`);
  };
  return (
    <Drawer onClose={onBack} visible title={title} width={window.innerWidth - SIDEBAR_WIDTH}>
      <ExportButton
        store={store}
        exportToExcel={api.workingLogs.exportToExcel}
        withPermission={withPermissions([permission])}
        fileName={`shiftReport-${visitorId}`}
      />
      <Table
        rowKey="id"
        columns={columns}
        dataSource={dataSource || []}
        store={store}
        checkPermissions
        permissionObj={permission[0]}
        loading={store.loading || formDataLoading || dataSourceLoading || loading}
        scroll={{ y: '100%' }}
        customHeight="87vh"
        onChange={onChangeTable}
      />
    </Drawer>
  );
});
