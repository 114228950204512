import { request } from '@/utils/request';
import { ApiResponse, WrappedResult } from './types';

export default {
  get(params?: mpg.api.search.Params): ApiResponse<WrappedResult<mpg.api.search.ResponseItem[]>> {
    return request({
      method: 'get',
      url: '/search',
      params,
    });
  },
};
