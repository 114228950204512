export const en = {
  'contact.header': 'Contact info',

  'contacts.header': 'My contacts',
  'contacts.create': 'New contact',
  'contacts.edit': 'Edit contact',

  'welcome.header': 'Welcome page',
  'welcome.login-form.email': 'Email',
  'welcome.login-form.password': 'Password',

  'events.header': 'My events',
  'events.create': 'New event',
  'events.edit': 'Edit event',

  'home.header': 'Home',
  'profile.header': 'My Profile',
  'profile.logout': 'Log Out',
  'settings.header': 'My settings',

  'login.change-password.header': 'Change Password',
  'login.forgot-password.header': 'Forgot Password',
  'login.forgot-password.button.send': 'Send me link',
  'login.forgot-password.button.reset': 'e-mail reset instructions',
  'login.forgot-password.response.true': 'Link has been successfully sent to ',
  'login.forgot-password.response.false': 'Something went wrong. Please try again',
  'login.forgot-password.description': "Please put your email and we'll send you reset password link",
  'login.change-password.response.true': 'Password has been successfully changed. Please login to the system',

  'registration.header': 'Free Member Registration',
  'registration.form.country': 'Country',
  'registration.form.language': 'Language',
  'registration.button.continue': 'Continue to Setup your Account',
  'registration.button.sign-in': 'Sign In',
  'registration.text.acknowledge':
    'By creating an account, you acknowledge that you have read our Privacy Policy and agree to our Terms of Use.',
  'registration.text.registered': 'Already have an Account?',

  'validation.messages.required': 'Required',
  'validation.messages.length': 'Length should be {0} - {1} symbols',
  'validation.messages.email': 'Entered value is not a valid email',

  'button.update': 'Update',
  'button.add-rate': 'Add Rate',
  'button.assign-colors': 'Assign Teams',
  'button.remove': 'Remove',
  'button.submit': 'Submit',
  'button.login': 'Login',
  'button.back': 'Back',
  'button.cancel': 'Cancel',
  'button.settings': 'Settings',
  'button.forgot-login': 'Forgot Password',
  'button.forgot-cancel': 'Cancel',
  'button.registration': 'Free Member Registration',
  'button.upload': 'Upload From CSV',

  'menu.admin': 'Admin',
  'menu.addresses': 'Address Book',
  'menu.agency-employees': 'Agency Temps Employees',
  'menu.coordinator-employees': 'Coordinator Temps Employees',
  'menu.agency-working-logs': 'Agency WorkLogs',
  'menu.coordinator-working-logs': 'Coordinator WorkLogs',
  'menu.agency-billings': 'Agency Billings',
  'menu.coordinator-billings': 'Coordinator Billings',
  'menu.agency-shift-reports': 'Agency ShiftReports',
  'menu.coordinator-shift-reports': 'Coordinator ShiftReports',
  'menu.agency-attendance-reports': 'Agency Attendance',
  'menu.coordinator-attendance-reports': 'Coordinator Attendance',
  'menu.coordinator-permanents-standard-hours': 'Coordinator Perms Standard Hours',
  'menu.coordinator-permanents-shift-work': 'Coordinator Perms Shift Work',
  'menu.agency-ptos': 'Agency Approved Time Off',
  'menu.coordinator-ptos': 'Coordinator Approved Time Off',
  'menu.coordinator-lockers': 'Coordinator Lockers',
  'menu.coordinator-temps-shift-planning': 'Coordinator Temps Shift Planning',
  'menu.coordinator-perms-shift-planning': 'Coordinator Perms Shift Planning',
  'menu.shuttleBusReport': 'Shuttle Bus Reports',
  'menu.timesheet': 'Timesheet billing',
  'menu.dashboard': 'Dashboard',
  'menu.building-host': 'Location Host',

  // Billing type
  'billingType': 'Billing Type',
  'billingType.NonBillable': 'Non Billable',
  'billingType.ProductionFloor': 'Production Floor',
  'billingType.BillingOnPremise': 'Billing On Premise',
  'billingType.Shifts': 'Schedules',

  // Common pages
  'common.access-denied.title': 'Access Denied',
  'common.access-denied.p1': "You don't have permissions to access this page.",
  'common.access-denied.p2': 'For more details, please contact customer support.',
  'common.back-home': 'Back Home',
  'common.warning': 'Warning',
  'common.search': 'Search...',
  'common.note': 'Note',

  // Table
  'table.actions': 'Actions',
  'table.edit': 'Edit',
  'table.delete': 'Delete',
  'table.unassign': 'Unassign',
  'table.report': 'Report',
  'table.notes': 'Notes',
  'table.view': 'View',

  // Admin
  'admin.title': 'Admin page',

  // Addresses
  'addresses.add-new': 'Add Address',
  'addresses.create-new': 'Create Address',
  'addresses.address-number': 'Address #{0}',
  'addresses.name': 'Name',
  'addresses.type': 'Type',
  'addresses.country': 'Country',
  'addresses.city': 'City',
  'addresses.street': 'Street',
  'addresses.street2': 'Street 2',

  // Attachments
  'attachments.attachments': 'Attachments',
  'attachments.title': 'Attachments for #{0}',
  'attachments.uploading': 'Uploading',
  'attachments.start-upload': 'Start Upload',
  'attachments.name': 'Name',
  'attachments.created': 'Created',
  'attachments.Size': 'Size',

  // Employees
  'employees.delete-warning-desc': 'Are you sure you want to delete these Temporary Employees?',
  'employees.add-new': 'Add Temp Employee',
  'employees.export-to-excel': 'Export to excel',
  'employees.export-to-email': 'Export to email',
  'employees.export-to-csv': 'Export to CSV',
  'employees.export': 'Export',
  'employees.create-new': 'Create Temporary Employee',
  'employees.employee-number': 'Employee #{0}',
  'employees.bulk-update': 'Bulk Update',
  'employees.bulk-add-rate': 'Bulk Add Rate',
  'employees.agencyInternalId': 'Agency Internal Id',
  'employees.status': 'Status',
  'employees.status.new': 'New',
  'employees.status.temporary': 'Temporary',
  'employees.status.permanent': 'Permanent',
  'employees.status.terminated': 'Terminated',
  'employees.status.terminated_agency': 'Terminated by Agency',
  'employees.statusUpdatedBy': 'Status last updated, by',
  'employees.statusUpdatedOn': 'Status last updated, on',
  'employees.firstName': 'First Name',
  'employees.middleName': 'Middle Name',
  'employees.lastName': 'Last Name',
  'employees.dobKey': 'dob Key',
  'employees.photoUrl': 'Photo Url',
  'employees.photo': 'Photo',
  'employees.agencyId': 'Agency',
  'employees.approverId': 'Approver',
  'employees.areaId': 'Cost Center',
  'employees.accessToId': 'Designated access only',
  'employees.accessToId.0': 'no',
  'employees.accessToId.1': 'yes',
  'employees.position': 'Job Title',
  'employees.supervisorId': 'Supervisor',
  'employees.shift': 'Schedule',
  'employees.colorId': 'Team',
  'employees.line': 'Line',
  'employees.startDate': 'Start Date',
  'employees.orientationDate': 'Orientation Date',
  'employees.building': 'Location',
  'employees.trainedSlitter': 'Trained Slitter',
  'employees.trainedOverwrap': 'Trained Overwrap',
  'employees.locker': 'Locker Name',
  'employees.email': 'Email',
  'employees.birthday': 'Date of Birth',
  'employees.phone': 'Phone',
  'employees.address1': 'Address1',
  'employees.address2': 'Address2',
  'employees.city': 'City',
  'employees.zipcode': 'Zipcode',
  'employees.invitationLanguageId': 'Invitation Language',
  'employees.breakTimes': 'Break Times',
  'employees.lunchTime': 'Lunch Time',
  'employees.parkingTag': 'Parking Tag #',
  'employees.term': 'Term',
  'employees.termDescription': 'Term Description',
  'employees.crewName': 'Crew Name',
  'employees.comment': 'Comment',
  'employees.kioskPinCode': 'friendlyway ID',
  'employees.musterStations': 'Muster Station',
  'employees.musterStationCaptains': 'Muster Station Captain',
  'employees.employeeType': 'Employee Type',
  'employees.employeeType.permanent': 'Permanent',
  'employees.employeeType.temporary': 'Temporary',
  'employees.workSchedule': 'Work Schedule',
  'employees.workSchedule.standardHours': 'Standard Hours',
  'employees.workSchedule.shift': 'Schedule',
  'employees.employees-export': 'Export',
  'employees.employees-base-rate-export': 'Export base rate',
  'employees.isNonBillable': 'Non Billable',
  'employees.shiftType': 'Shift Type',
  'employees.shiftType.day': 'Day',
  'employees.shiftType.night': 'Night',

  'employeeMarkup.create-new': 'Create new employee markup',
  'employeeMarkup.update': 'Update employee markup',
  'employeeMarkup.effectiveDate': 'Date effective',
  'employeeMarkup.baseRate': 'Base Rate',
  'employeeMarkup.add-new': 'Add New Rate',
  'employee.details-tab': 'Temporary Employee Details',
  'employee.audit-tab': 'Temporary Employee Logs',
  'employee.markups-tab': 'Employee Rates',
  'employee.markups-logs-tab': 'Employee Rates logs',
  'employees.hasEmployeeUser': 'Registered on the web portal',
  'employees.inviteToWebPortalConfirmation': 'Are you sure to invite employee to the web portal?',
  'employees.revokeFromWebPortalConfirmation': 'Are you sure to revoke employee from the web portal?',


  'employees.inviteButtonTitle': 'Invite to the web portal',
  'employees.revokeButtonTitle': 'Revoke from the web portal',
  'employees.bulkInviteToWebPortalTitle': 'Invite',
  'employees.bulkRevokeFromWebPortalTitle': 'Revoke',
  'employees.bulkInviteToWebPortalDescription':
    'Are you sure to invite {count} selected employee(s) to the web portal?',
  'employees.bulkRevokeFromWebPortalDescription':
    'Are you sure to revoke {count} selected employee(s) from the web portal?',
  'employees.bulkWebPortalResultTitle': 'Result',
  'employees.bulkInviteToWebPortalResult':
    '{resultCount} from {count} employee(s) successfully invited to the web portal',
  'employees.bulkRevokeFromWebPortalResult':
    '{resultCount} from {count} employee(s) successfully revoked from the web portal',



  // Export
  'export.sendToEmailMessage':
    'The export request was successfully processed. If you do not receive the download link to your email within 15 minutes, please try again.',
  'export.export-to-excel': 'Export to excel',

  // Dashboard
  'dashboard.title': 'TEMP Employees in the roster',
  'dashboard.new': 'New',
  'dashboard.temporary': 'Temporary',
  'dashboard.permanent': 'Permanent',
  'dashboard.menu-title': 'All employees',
  'dashboard.all-agencies': 'All agencies',
  'dashboard.all-buildings': 'All buildings',

  // Working Log
  'workingLogs.delete-warning-desc': 'Are you sure you want to delete these WorkLogs?',
  'workingLogs.add-new': 'Add Working Log',
  'workingLogs.create-new': 'Create Working Log',
  'workingLogs.edit-title': 'Working Log #{0}',
  'workingLogs.bulk-update': 'Bulk Update',
  'workingLogs.visitorId': 'friendlyway ID',
  'workingLogs.employeeType': 'Employee Type',
  'workingLogs.employeeType.permanent': 'Permanent',
  'workingLogs.employeeType.temporary': 'Temporary',
  'workingLogs.workSchedule': 'Work Schedule',
  'workingLogs.workSchedule.standardHours': 'Standard Hours',
  'workingLogs.workSchedule.shiftWork': 'Shift Work',
  'workingLogs.workSchedule.shift': 'Schedule',
  'workingLogs.agencyId': 'Agency',
  'workingLogs.visitorFirstName': 'First Name',
  'workingLogs.visitorLastName': 'Last Name',
  'workingLogs.visitorMiddleName': 'Middle Name',
  'workingLogs.checkInTurnStile': 'Checkin Turnstile',
  'workingLogs.checkInTime': 'Checkin Time',
  'workingLogs.checkInDate': 'Checkin Date',
  'workingLogs.checkInRawDate': 'Checkin Raw Date',
  'workingLogs.checkOutTime': 'Checkout Time',
  'workingLogs.checkOutDate': 'Checkout Date',
  'workingLogs.checkOutTurnStile': 'Checkout Turnstile',
  'workingLogs.checkOutRawDate': 'Checkout Raw Date',
  'workingLogs.actualBuildingId': 'Actual Location',
  'workingLogs.buildingId': 'Shift Location',
  'workingLogs.buildingsAreasId': 'Building Area',
  'workingLogs.employeeBuildingId': 'Location',
  'workingLogs.supervisorId': 'Supervisor',
  'workingLogs.shiftPlannedId': 'Schedule',
  'workingLogs.shiftAssignedId': 'Actual shift',
  'workingLogs.colorAssignedId': 'Scheduled Team',
  'workingLogs.colorActualId': 'Actual Team',
  'workingLogs.weekOf': 'Week of',
  'workingLogs.breakTimes': 'BreakTime',
  'workingLogs.status': 'Status',
  'workingLogs.raw': 'Raw',
  'workingLogs.workingHours': 'Time, hours',
  'workingLogs.overtimeHours': 'Overtime, hours',
  'workingLogs.overtime': 'Overtime, hours (Dec)',
  'workingLogs.autoCheckOut': 'Auto CheckOut',
  'workingLogs.agencyInternalId': 'Agency Internal Id',
  'workingLogs.time': 'Time, hours (Dec)',
  'workingLogs.sendToEmailMessage':
    'The export request was successfully processed. If you do not receive the download link to your email within 15 minutes, please try again.',
  'workingLogs.billableTimeHours': 'Billable Time, hours',
  'workingLogs.billableTimeHoursFloat': 'Billable Time, hours (dec)',
  'workingLogs.grossTimeHours': 'Gross time, hours',
  'workingLogs.grossTimeHoursFloat': 'Gross time, hours (dec)',
  'workingLogs.areaId': 'Cost Center',

  // Shift Report
  'shiftReports.visitorId': 'friendlyway ID',
  'shiftReports.employeeType': 'Employee Type',
  'shiftReports.employeeType.permanent': 'Permanent',
  'shiftReports.employeeType.temporary': 'Temporary',
  'shiftReports.firstName': 'First Name',
  'shiftReports.middleName': 'Middle Name',
  'shiftReports.lastName': 'Last Name',
  'shiftReports.status': 'Status',
  'shiftReports.agency': 'Agency',
  'shiftReports.date': 'Date',
  'shiftReports.shiftBuilding': 'Shift Location',
  'shiftReports.workerBuilding': 'Workers Location',
  'shiftReports.shift': 'Schedule',
  'shiftReports.color': 'Team',
  'shiftReports.workersPlannedShift': 'Workers planned schedule',
  'shiftReports.workersPlannedColor': 'Workers planned team',
  'shiftReports.timeWorked': 'Time worked',
  'shiftReports.grossTime': 'Gross time (in the building)',
  'shiftReports.netTime': 'Net time (on production floor)',
  'shiftReports.idleTime': 'Idle Time (GROSS - NET)',
  'shiftReports.overAllowedBreak': 'OVER ALLOWED BREAK',
  'shiftReports.showedUpAsPlanned': 'Showed up as planned',
  'shiftReports.showedUpInDifferentShift': 'Showed up in a different shift',
  'shiftReports.showedUpInDifferentBuilding': 'Showed up in a different location',
  'shiftReports.plannedButDidNotShowUp': 'Planned but did not show up',
  'shiftReports.shiftStart': 'Shift start',
  'shiftReports.actualArrival': 'Actual arrival',
  'shiftReports.lateArrival': 'Late arrival',
  'shiftReports.lateByHours': 'Late by (hours)',
  'shiftReports.shiftEnd': 'Shift end',
  'shiftReports.actualEnd': 'Actual end',
  'shiftReports.earlyDeparture': 'Early departure',
  'shiftReports.earlyByHours': 'Early by (hours)',
  'shiftReports.autoCheckOut': 'Auto CheckOut',
  'shiftReports.create-new': 'Create Shift Report',
  'shiftReports.edit-title': 'Shift Report #{0}',
  'shiftReports.temporary-tab': 'Temporary Employees',
  'shiftReports.permanents-tab': 'Permanent Employees',

  // Shuttle Bus Report
  'shuttleBusReport.registrationDate': 'Registration Date',
  'shuttleBusReport.registrationTime': 'Registration Time',
  'shuttleBusReport.employeeFirstName': 'First Name',
  'shuttleBusReport.employeeMiddleName': 'Middle Name',
  'shuttleBusReport.employeeLastName': 'Last Name',
  'shuttleBusReport.employeeType': 'Employee Type',
  'shuttleBusReport.employeeType.permanent': 'Permanent',
  'shuttleBusReport.employeeType.temporary': 'Temporary',
  'shuttleBusReport.agencyId': 'Agency',
  'shuttleBusReport.agencyName': 'Agency Name',
  'shuttleBusReport.shiftId': 'Schedule',
  'shuttleBusReport.externalId': 'friendlyway ID',
  'shuttleBusReport.shiftName': 'Shift Name',
  'shuttleBusReport.colorId': 'Team',
  'shuttleBusReport.export-to-excel': 'Export to excel',

  // User Settings
  'user-settings.items-per-page': 'Items by page',
  'user-settings.vacation-status': 'On vacation',
  'user-settings.dump-data': 'Dump data',
  'user-settings.title': 'Settings',

  // Profile
  'profile.title': 'Profile',
  'profile.email': 'Email',
  'profile.name': 'Name',
  'profile.role': 'Role',
  'profile.roles': 'Roles',

  'profile.oldPassword': 'Old Password',
  'profile.newPassword': 'New Password',
  'profile.repeatNewPassword': 'Repeat New Password',
  'profile.change-password': 'Change password',

  'switch.true': 'Yes',
  'switch.false': 'No',

  // Audit
  'audit.user': 'User',
  'audit.userName': 'User Name',
  'audit.tableName': 'Table Name',
  'audit.action': 'Action',
  'audit.createdAt': 'Date',
  'audit.changedFields': 'Changed Fields',
  'audit.property': 'Property',
  'audit.originalData': 'Original Data',
  'audit.newData': 'New Data',
  'audit.audit-number': 'Audit',
  'audit.detailedView': 'Detailed view',
  'audit.changesOnly': 'Changes only',

  'systemInfo.employee-details':'Employee Details',
  'systemInfo.employeeId':'Employee Id',
  'systemInfo.id':'Id',
  'systemInfo.updatedAt':'Updated At',
  'systemInfo.accessTo': 'Access To',
  'systemInfo.agency-details': 'Agency Details',
  'systemInfo.approver-details': 'Approver Details',
  'systemInfo.area-details': 'Area Details',
  'systemInfo.attachment-details': 'Attachment Details',
  'systemInfo.attachmentId': 'Attachment Id',
  'systemInfo.billingType': 'Billing Type',
  'systemInfo.breakTime-details': 'Break Time Details',
  'systemInfo.breakTimeId': 'Break Time Id',
  'systemInfo.building-details': 'Building Details',
  'systemInfo.buildingId': 'Building Id',
  'systemInfo.color-details': 'Team Details',
  'systemInfo.employeeUser-details': 'Employee User Details',
  'systemInfo.employeeUserId': 'Employee user Id',
  'systemInfo.firstWorkingLogDate': 'First Working Log Date',
  'systemInfo.invitationLanguage-details': 'Invitation Language Details',
  'systemInfo.line-details': 'Line Details',
  'systemInfo.lineId': 'Line Id',
  'systemInfo.locker-details': 'Locker Details',
  'systemInfo.lockerId': 'Locker Id',
  'systemInfo.musterStation-details': 'Muster Station Details',
  'systemInfo.musterStationCaptain-details': 'Muster Station Captain Details',
  'systemInfo.musterStationCaptainId': 'Muster Station Captain Id',
  'systemInfo.musterStationId': 'Muster Station  Id',
  'systemInfo.picture': 'Picture',
  'systemInfo.position-details': 'Position Details',
  'systemInfo.positionId': 'Position Id',
  'systemInfo.shift-details': 'Shift Details',
  'systemInfo.shiftId': 'Shift Id',
  'systemInfo.createdAt': 'Created At',
  'systemInfo.correctedAt': 'Corrected At',
  'systemInfo.startDate': 'Start Date',
  'systemInfo.occupation': 'Occupation',
  'systemInfo.lunchUnpaidCorrectedTime': 'Total Lunch Unpaid Corrected Time, Hours',
  'systemInfo.weekTimesheet-details': 'Week Timesheet Details',

  'systemInfo.approverId': 'Approver Id',
  'systemInfo.areaId': 'Area Id',
  'systemInfo.colorId': 'Team Id',
  'systemInfo.designatedAccessOnly': 'Designated AccessOnly',
  'systemInfo.invitationLanguageId': 'Invitation Language Id',
  'systemInfo.supervisor-details': 'Supervisor Details',
  'systemInfo.supervisorId': 'Supervisor Id',
  'systemInfo.terminateDescription': 'Terminate Description',
  'systemInfo.trainedOverwrap': 'Trained Overwrap',
  'systemInfo.trainedSlitter': 'Trained Slitter',

  'systemInfo.bathPaidCorrectedTime': 'Total Bathroom Corrected Paid Time, Hours',
  'systemInfo.brakePaidCorrectedTime': 'Total Break Corrected Paid Time, Hours',
  'systemInfo.correctionDescription': 'Correction Description',
  'systemInfo.firstCheckIn': 'First Check In',
  'systemInfo.lunchPaidCorrectedTime': 'Total Lunch Corrected Paid Time, Hours',
  'systemInfo.notBillableTime': 'Not Billable Time',
  'systemInfo.permanentEmployee-details': 'Permanent Employee Details',
  'systemInfo.permanentEmployeeId': 'Permanent Employee Id',
  'systemInfo.shiftPlanned-details': 'Shift Planned Details',
  'systemInfo.temporaryEmployee-details': 'Temporary Employee Details',
  'systemInfo.temporaryEmployeeId': 'Temporary Employee Id',
  'systemInfo.transitPaidCorrectedTime': 'Total Transit Corrected Paid Time, Hours',
  'systemInfo.weekTimesheetId': 'Week Timesheet Id',
  'systemInfo.workingBillableCorrectedTime': 'Billable Corrected time, Hours',




  // 2FA
  'login-2fa.button.check': 'Check code',
  'login-2fa.button.resend': 'Resend code',
  'login-2fa.description': 'Verification code has been sent to your email',
  'login-2fa.error-message': 'Try again. New code has been sent to your email',
  'login-2fa.remaining-attemts': 'Remaining attempts: ',
  'login-2fa.block-message': 'Unfortunately you temporary block. Try again in 2 hours',

  '2fa.title': 'Two Factor Authentication',
  '2fa.select-type': 'Select type',
  '2fa.put-phone': 'Put phone number',
  '2fa.put-password': 'Put password',
  '2fa.button.activate': 'Activate',
  '2fa.button.deactivate': 'Deactivate',
  '2fa.button.send-code': 'Send code',

  '2fa-type.code': 'Code',
  '2fa-type.email': 'Email',
  '2fa-type.sms': 'Sms',

  // Attendance Report
  'attendanceReports.employeeType': 'Employee Type',
  'attendanceReports.employeeType.permanent': 'Permanent',
  'attendanceReports.employeeType.temporary': 'Temporary',
  'attendanceReports.fullName': 'Full Name',
  'attendanceReports.firstName': 'First Name',
  'attendanceReports.lastName': 'Last Name',
  'attendanceReports.currentColors': 'Current Teams',
  'attendanceReports.currentShift': 'Current Schedule',
  'attendanceReports.workerBuilding': 'Location',
  'attendanceReports.currentStatus': 'Current status',
  'attendanceReports.kioskID': 'friendlyway ID',
  'attendanceReports.agency': 'Agency',
  'attendanceReports.attendance': 'Attendance',
  'attendanceReports.timesWorkedUnder': 'Times worked under 10 HRs',
  'attendanceReports.timesLate': 'Times > 1.5 HRs Late',
  'attendanceReports.timesLeftEarly': 'Times left > 1 HR early',
  'attendanceReports.timesOverAllowedBreak': 'Times Over Allowed Break',
  'attendanceReports.edit-title': 'Attendance Report #{0}',
  'attendanceReports.date': 'Date',

  // PermanentsStandardHours
  'permanentsStandardHours.delete-warning-desc': 'Are you sure you want to delete these Permanent Employees?',
  'permanentsStandardHours.add-new': 'Add Perm Employee',
  'permanentsStandardHours.export-to-excel': 'Export to excel',
  'permanentsStandardHours.sendToEmailMessage':
    'The export request was successfully processed. If you do not receive the download link to your email within 15 minutes, please try again.',
  'permanentsStandardHours.employeeId': 'friendlyway ID',
  'permanentsStandardHours.wiegangId': 'Wiegang ID',
  'permanentsStandardHours.employeeType': 'Employee Type',
  'permanentsStandardHours.employeeType.permanent': 'Permanent',
  'permanentsStandardHours.employeeType.temporary': 'Temporary',
  'permanentsStandardHours.workSchedule': 'Work Schedule',
  'permanentsStandardHours.workSchedule.standardHours': 'Standard Hours',
  'permanentsStandardHours.workSchedule.shiftWork': 'Shift Work',
  'permanentsStandardHours.workSchedule.shift': 'Schedule',
  'permanentsStandardHours.status': 'Status',
  'permanentsStandardHours.status.new': 'New',
  'permanentsStandardHours.status.temporary': 'Temporary',
  'permanentsStandardHours.status.permanent': 'Permanent',
  'permanentsStandardHours.status.terminated': 'Terminated',
  'permanentsStandardHours.status.terminated_agency': 'Terminated by Agency',
  'permanentsStandardHours.firstName': 'First Name',
  'permanentsStandardHours.middleName': 'Middle Name',
  'permanentsStandardHours.lastName': 'Last Name',
  'permanentsStandardHours.email': 'Email',
  'permanentsStandardHours.phone': 'Phone',
  'permanentsStandardHours.company': 'Company',
  'permanentsStandardHours.company.MPG': 'MPG',
  'permanentsStandardHours.occupation': 'Occupation',
  'permanentsStandardHours.startDate': 'Start Date',
  'permanentsStandardHours.building': 'Building',
  'permanentsStandardHours.musterStations': 'Muster Station',
  'permanentsStandardHours.musterStationCaptains': 'Muster Station Captain',
  'permanentsStandardHours.isMusterStationCaptain': 'Is Muster Station Captain',
  'permanentsStandardHours.locker': 'Locker Name',
  'permanentsStandardHours.birthday': 'Birthday',
  'permanentsStandardHours.statusUpdatedBy': 'Status last updated, by',
  'permanentsStandardHours.statusUpdatedOn': 'Status last updated, on',
  'permanentsStandardHours.export-to-email': 'Export to email',
  'permanentsStandardHours.export-to-csv': 'Export to CSV',
  'permanentsStandardHours.export': 'Export',
  'permanentsStandardHours.create-new': 'Create Permanent Employee',
  'permanentsStandardHours.employee-number': 'Permanent Employee #{0}',
  'permanentsStandardHours.bulk-update': 'Bulk Update',
  'permanentsStandardHours.parkingTag': 'Parking Tag',
  'permanentsStandardHours.termDescription': 'Term Description',
  'permanentsStandardHours.photoUrl': 'Photo Url',
  'permanentsStandardHours.photo': 'Photo',

  // PermanentsShiftWork
  'permanentsShiftWork.delete-warning-desc': 'Are you sure you want to delete these Permanent Employees?',
  'permanentsShiftWork.add-new': 'Add Perm Employee',
  'permanentsShiftWork.export-to-excel': 'Export to excel',
  'permanentsShiftWork.sendToEmailMessage':
    'The export request was successfully processed. If you do not receive the download link to your email within 15 minutes, please try again.',
  'permanentsShiftWork.employeeId': 'friendlyway ID',
  'permanentsShiftWork.wiegangId': 'Wiegang ID',
  'permanentsShiftWork.employeeType': 'Employee Type',
  'permanentsShiftWork.employeeType.permanent': 'Permanent',
  'permanentsShiftWork.employeeType.temporary': 'Temporary',
  'permanentsShiftWork.workSchedule': 'Work Schedule',
  'permanentsShiftWork.workSchedule.standardHours': 'Standard Hours',
  'permanentsShiftWork.workSchedule.shiftWork': 'Shift Work',
  'permanentsShiftWork.workSchedule.shift': 'Schedule',
  'permanentsShiftWork.status': 'Status',
  'permanentsShiftWork.status.new': 'New',
  'permanentsShiftWork.status.temporary': 'Temporary',
  'permanentsShiftWork.status.permanent': 'Permanent',
  'permanentsShiftWork.status.terminated': 'Terminated',
  'permanentsShiftWork.status.terminated_agency': 'Terminated by Agency',
  'permanentsShiftWork.firstName': 'First Name',
  'permanentsShiftWork.middleName': 'Middle Name',
  'permanentsShiftWork.lastName': 'Last Name',
  'permanentsShiftWork.email': 'Email',
  'permanentsShiftWork.phone': 'Phone',
  'permanentsShiftWork.company': 'Company',
  'permanentsShiftWork.company.MPG': 'MPG',
  'permanentsShiftWork.areaId': 'Cost Center',
  'permanentsShiftWork.position': 'Job Title',
  'permanentsShiftWork.supervisorId': 'Supervisor',
  'permanentsShiftWork.shift': 'Schedule',
  'permanentsShiftWork.colorId': 'Team',
  'permanentsShiftWork.building': 'Location',
  'permanentsShiftWork.musterStations': 'Muster Station',
  'permanentsShiftWork.musterStationCaptains': 'Muster Station Captain',
  'permanentsShiftWork.isMusterStationCaptain': 'Is Muster Station Captain',
  'permanentsShiftWork.line': 'Line',
  'permanentsShiftWork.trainedSlitter': 'Trained Slitter',
  'permanentsShiftWork.trainedOverwrap': 'Trained Overwrap',
  'permanentsShiftWork.locker': 'Locker Name',
  'permanentsShiftWork.birthday': 'Birthday',
  'permanentsShiftWork.invitationLanguageId': 'Invitation Language',
  'permanentsShiftWork.termDescription': 'Term Description',
  'permanentsShiftWork.designatedAccessOnly': 'Designated access only',
  'permanentsShiftWork.designatedAccessOnly.false': 'false',
  'permanentsShiftWork.designatedAccessOnly.true': 'true',
  'permanentsShiftWork.parkingTag': 'Parking Tag',
  'permanentsShiftWork.statusUpdatedBy': 'Status last updated, by',
  'permanentsShiftWork.statusUpdatedOn': 'Status last updated, on',
  'permanentsShiftWork.export-to-email': 'Export to email',
  'permanentsShiftWork.export-to-csv': 'Export to CSV',
  'permanentsShiftWork.export': 'Export',
  'permanentsShiftWork.create-new': 'Create Permanent Employee',
  'permanentsShiftWork.employee-number': 'Permanent Employee #{0}',
  'permanentsShiftWork.bulk-update': 'Bulk Update',
  'permanentsShiftWork.photoUrl': 'Photo Url',
  'permanentsShiftWork.photo': 'Photo',
  'permanentsShiftWork.shiftType': 'Shift Type',
  'permanentsShiftWork.shiftType.day': 'Day',
  'permanentsShiftWork.shiftType.night': 'Night',

  // performance report
  'employee-performance.header': 'TIMESHEET INQUIRY',
  'employee-performance.range': 'DATA RANGE',
  'employee-performance.datepicker-label': 'Choose Week of:',
  'employee-performance.worked-hours': 'Hours Worked',
  'employee-performance.total-billable-time': 'TOTAL HOURS',
  'employee-performance.billable-hours': 'Billable Hours',
  'employee-performance.base-billable-time': 'BASE BILLABLE HOURS',
  'employee-performance.overtime-hours': 'OVERTIME HOURS',
  'employee-performance.discipline': 'Discipline',
  'employee-performance.approval-status': 'PLEASE NOTE THAT TOTAL BILLABLE HOURS MIGHT STILL NEED TO BE ADJUSTED AND APPROVED BY YOUR SUPERVISOR ACCORDING TO MPG TIMEKEEPING GUIDELINES',
  'employee-performance.missed-shifts': 'Missed Shifts:',
  'employee-performance.under-works-shifts': 'Under Work Shifts:',
  'employee-performance.approval-true': 'Approved',
  'employee-performance.approval-false': 'Not Approved',
  'employee-performance.print': 'Print',
  'employee-performance.show-details': 'Show Detailed Records',
  'employee-performance.detailed-weekly-report.to-weekly-report': 'Back To The Main Report',
  'employee-performance.date': 'Date',
  'employee-performance.shiftAssigned': 'Schedule',
  'employee-performance.building': 'Location',
  'employee-performance.grossTime': 'Gross time',
  'employee-performance.netTime': 'Net time',
  'employee-performance.totalAdditionTime': 'Additional time',
  'employee-performance.totalBillableTime': 'Total billable time',
  'employee-performance.lateArrival': 'Late for the shift',
  'employee-performance.earlyDeparture': 'Early departure',
  'employee-performance.notShowedUp': 'Planned but did not show up',
  'employee-performance.send-email': 'Send to my email',
  'employee-performance.send-email-success': 'Email successfully sent',
  'employee-performance.send-email-failed-error': 'Failed to send email',
  'employee-performance.send-email-again-error': 'Email already sent',
  'employee-performance.match-error': 'Invalid ID',
  'employee-performance.scanner-error': 'Scanner is not available',
  'employee-performance.unexpected-error': 'Unexpected error',
  'employee-performance.invalid-barcode': 'Invalid barcode',
  'employee-performance.invalid-pin': 'Invalid ID',
  'employee-performance.pin-label': 'Type your ID',
  'employee-performance.back-button': 'Back',
  'employee-performance.login-button': 'Log in',
  'employee-performance.scan-text': 'SCAN YOUR BARCODE',
  'employee-performance.printing-text': 'Data processing, please wait.',
  'employee-performance.printing-error': 'Print error',
  'employee-performance.reference': 'FOR REFERENCE ONLY',


  // PTOS
  'ptos.delete-warning-desc': 'Are you sure you want to delete these PTOS?',
  'ptos.add-new': 'Add PTO',
  'ptos.export-to-excel': 'Export to excel',
  'ptos.sendToEmailMessage':
    'The export request was successfully processed. If you do not receive the download link to your email within 15 minutes, please try again.',
  'ptos.export-to-email': 'Export to email',
  'ptos.export-to-csv': 'Export to CSV',
  'ptos.export': 'Export',
  'ptos.create-new': 'Create PTO',
  'ptos.pto-number': 'PTO #{0}',
  'ptos.bulk-update': 'Bulk Update',
  'ptos.employeeId': 'friendlyway ID',
  'ptos.firstName': 'First Name',
  'ptos.lastName': 'Last Name',
  'ptos.from': 'Date From',
  'ptos.to': 'Date To',
  'ptos.approval': 'Approval',
  'ptos.chooseEmployee': 'Choose Employee',
  'ptos.supervisorId': 'Supervisor',
  'ptos.shift': 'Schedule',
  'ptos.colorId': 'Team',
  'ptos.agencyId': 'Agency',
  'ptos.areaId': 'Cost Center',
  'ptos.buildingId': 'Location',

  // Lockers
  'lockers.employeeId': 'friendlyway ID',
  'lockers.employeeType': 'Employee Type',
  'lockers.employeeType.permanent': 'Permanent',
  'lockers.employeeType.temporary': 'Temporary',
  'lockers.employeeBuilding': 'Employee Location',
  'lockers.employeeStatus': 'Employee Status',
  'lockers.firstName': 'First Name',
  'lockers.lastName': 'Last Name',
  'lockers.dateLockerAssigned': 'Date Locker Assigned',
  'lockers.number': '№',
  'lockers.name': 'Locker Name',
  'lockers.serialNumber': 'Locker Serial #',
  'lockers.seriesNumber': 'Locker Series',
  'lockers.code1': 'Code 1',
  'lockers.code2': 'Code 2',
  'lockers.code3': 'Code 3',
  'lockers.code4': 'Code 4',
  'lockers.code5': 'Code 5',
  'lockers.status': 'Status',
  'lockers.status.active': 'Active',
  'lockers.status.inactive': 'Inactive',
  'lockers.isAssigned': 'Is Assaigned',
  'lockers.comment': 'Comment',
  'lockers.add-new': 'Add Locker',
  'lockers.delete-warning-desc': 'Are you sure you want to delete these Lockers?',
  'lockers.unassign-warning-desc': 'Are you sure you want to unassign these Lockers?',
  'lockers.unassign-form-warning-desc':
    'Changes on the form will not be saved! Are you sure you want to unassign these Lockers?',
  'lockers.sendToEmailMessage':
    'The export request was successfully processed. If you do not receive the download link to your email within 15 minutes, please try again.',
  'lockers.export-to-excel': 'Export to excel',
  'lockers.export-to-email': 'Export to email',
  'lockers.export-to-csv': 'Export to CSV',
  'lockers.export': 'Export',
  'lockers.bulk-update': 'Bulk Update',
  'lockers.locker-number': 'Locker #',
  'lockers.logs': 'Locker logs',
  'lockers.view-logs': 'View locker logs',
  'lockers.chooseEmployee': 'Choose Employee',

  // Shift Planning
  'shiftPlanning.title': 'Title',
  'shiftPlanning.status': 'Status',
  'shiftPlanning.status.running': 'Running',
  'shiftPlanning.status.upcoming': 'Upcoming',
  'shiftPlanning.status.finished': 'Finished',
  'shiftPlanning.description': 'Description',
  'shiftPlanning.eventRange': 'Event Date Range',
  'shiftPlanning.eventTimeRange': 'Event Time Range',
  'shiftPlanning.eventStart': 'Event Start',
  'shiftPlanning.eventEnd': 'Event End',
  'shiftPlanning.building': 'Location',
  'shiftPlanning.version': 'Version',
  'shiftPlanning.version.multiShiftsEventsPerDay': 'Multi-shifts events per day',
  'shiftPlanning.version.oneShiftsEventsPerDay': 'One-shift events per day',
  'shiftPlanning.employeeType': 'Employee Type',
  'shiftPlanning.employeeType.permanent': 'Permanent',
  'shiftPlanning.employeeType.temporary': 'Temporary',
  'shiftPlanning.workSchedule': 'Work Schedule',
  'shiftPlanning.workSchedule.permanent.standardHours': 'Standard Hours',
  'shiftPlanning.workSchedule.permanent.shiftWork': 'Shift Work',
  'shiftPlanning.workSchedule.temporary.shift': 'Schedule',
  'shiftPlanning.workSchedule.temporary.standardHours': 'Service',
  'shiftPlanning.additionalTime': 'Additional Time (hours)',
  'shiftPlanning.colors': 'Teams',
  'shiftPlanning.shifts': 'Schedules',
  'shiftPlanning.add-new': 'Add Shift Planning',
  'shiftPlanning.auto-create': 'Auto-Create',
  'shiftPlanning.auto-create.extended': 'Auto-Create Extended',
  'shiftPlanning.auto-create.delete-events': 'Delete Events',
  'shiftPlanning.auto-create.building.all': 'All',
  'shiftPlanning.auto-create-new': 'Auto-Create Shift Planning',
  'shiftPlanning.auto-create-new.extended': 'Auto-Create Shift Planning Extended',
  'shiftPlanning.auto-create-new.delete-events': 'Delete Events',
  'shiftPlanning.create-new': 'Create Shift Planning',
  'shiftPlanning.delete-warning-desc': 'Are you sure you want to delete these Shifts?',
  'shiftPlanning.sendToEmailMessage':
    'The export request was successfully processed. If you do not receive the download link to your email within 15 minutes, please try again.',
  'shiftPlanning.export-to-excel': 'Export to excel',
  'shiftPlanning.export-to-email': 'Export to email',
  'shiftPlanning.export-to-csv': 'Export to CSV',
  'shiftPlanning.export': 'Export',
  'shiftPlanning.bulk-update': 'Bulk Update',
  'shiftPlanning.bulk-update-warn-msg-title': 'Bulk Update Warning',
  'shiftPlanning.bulk-update-warn-msg': 'No events to update!',
  'shiftPlanning.bulk-assign-colors': 'Bulk Assign Teams',
  'shiftPlanning.shift-number': 'Shift Planning #',
  'shiftPlanning.logs': 'Shift Planning logs',
  'shiftPlanning.view-logs': 'View Shift Planning logs',

  // Upload
  'upload.size-error': 'File size too big',
  'upload.type-error': 'File type is incorrect',

  // timesheet
  'timesheet.isEdited': 'Edited',
  'timesheet.visitorId': 'friendlyway ID',
  'timesheet.firstName': 'First Name',
  'timesheet.middleName': 'Middle Name',
  'timesheet.lastName': 'Last Name',
  'timesheet.weekOf': 'Week of',
  'timesheet.timesheetDate': 'Date',
  'timesheet.employeeType': 'Employee Type',
  'timesheet.employeeType.permanent': 'Permanent',
  'timesheet.employeeType.temporary': 'Temporary',
  'timesheet.shiftPlannedId': 'Schedule',
  'timesheet.agencyId': 'Agency',
  'timesheet.workingBillableTime': 'Billable time, Hours',
  'timesheet.workingBillableTimeDec': 'Billable time, Hours (Dec)',
  'timesheet.brakePaidTime': 'Total Break Paid Time, Hours',
  'timesheet.bathPaidTime': 'Total Bathroom Paid Time, Hours',
  'timesheet.lunchPaidTime': 'Total Lunch Paid Time, Hours',
  'timesheet.lunchUnpaidTime': 'Total Lunch Unpaid Time, Hours',
  'timesheet.transitPaidTime': 'Total Transit Paid Time, Hours',
  'timesheet.totalAdditionTime': 'Total Additional Time, Hours',
  'timesheet.totalAdditionTimeDec': 'Total Additional Time, Hours (Dec)',
  'timesheet.baseBillableTime': 'Base Billable, Hours (Dec)',
  'timesheet.totalBillableTime': 'Total Billable, Hours',
  'timesheet.totalBillableTimeDec': 'Total Billable, Hours (Dec)',
  'timesheet.overtimeTime': 'Overtime, Hours',
  'timesheet.overtimeTimeDec': 'Overtime, Hours (Dec)',
  'timesheet.baseRate': 'Base Rate, $',
  'timesheet.overtimeRate': 'Overtime Rate, $',
  'timesheet.agencyInternalId': 'Agency Internal ID',
  'timesheet.baseBillingAmount': 'Base Billing Amount, $',
  'timesheet.overtimeBillingAmount': 'Overtime Billing Amount, $',
  'timesheet.totalAmount': 'Total, $',
  'timesheet.form.transitPaidTime': 'Total Transit Paid Time',
  'timesheet.form.lunchPaidTime': 'Total Lunch Paid Time',
  'timesheet.form.lunchUnpaidTime': 'Total Lunch Unpaid Time',
  'timesheet.form.bathPaidTime': 'Total Bathroom Paid Time',
  'timesheet.form.brakePaidTime': 'Total Break Paid Time',
  'timesheet.form.workingBillableTime': 'Billable time',
  'timesheet.form.correctionDescription': 'Correction Description',
  'timesheet.form.editDayTimesheet': 'Edit Day Timesheet',
  'timesheet.workingLogs-tab': 'Working logs',
  'timesheet.audit-tab': 'Timesheet logs',
  'timesheet.approveActionTitle': 'Approve',
  'timesheet.unapproveActionTitle': 'Unapprove',
  'timesheet.approveActionConfirmation': 'Are you sure to approve?',
  'timesheet.unapproveActionConfirmation': 'Are you sure to unapprove?',
  'timesheet.approverName': 'Approved By',
  'timesheet.approveStatus': 'Approve Status',
  'timesheet.bulkApproveTitle': 'Approve',
  'timesheet.bulkUnapproveTitle': 'Unapprove',
  'timesheet.bulkApproveButtonTitle': 'Approve',
  'timesheet.bulkUnapproveButtonTitle': 'Unapprove',
  'timesheet.bulkApproveDescription': 'Are you sure to approve {count} selected timesheet(s)?',
  'timesheet.bulkUnapproveDescription': 'Are you sure to unapprove {count} selected timesheet(s)?',
  'timesheet.approveResultTitle': 'Result',
  'timesheet.bulkApproveResult': '{resultCount} from {count} timesheet(s) successfully approved',
  'timesheet.bulkUnapproveResult': '{resultCount} from {count} timesheet(s) successfully unapproved',
  'timesheet.backButton': 'Back to timesheet',
  'timesheet.export-to-excel': 'Export to excel',
  'timesheet.week-timesheet-export': 'Export',
  'timesheet.week-daily-details-export': ' Export week daily details',
  'timesheet.week-export-with-restricted-fields': ' Export week with restricted fields',
  'timesheet.magnit-export': ' Export Magnit',
  'timesheet.employeeAreaId': 'Cost Center',
  'timesheet.employeeBuildingId': 'Location',
  'timesheet.weekOf-select': 'Select week',
  'timesheet.form.editAdditionalInformation': `
    Minimal billable time a day to use additional time: <b>{minBillableTime}</b>
    {br}
    Break Paid Time: <b>{breakPaidTime}</b>
    {br}
    Bathroom Brk Paid Time: <b>{bathroomPaidTime}</b>
    {br}
    Lunch Paid Time: <b>{launchPaidTime}</b>
  `,
  'timesheet.employeeAreaName': 'Cost Center',
  'timesheet.employeeBuildingName': 'Location',

  // building-hosts
  'building-hosts.create-title': 'Create Location Host',
  'building-hosts.update-title': 'Update Location Host',
  'building-hosts.perm-employee': 'Permanent Employee',
  'building-hosts.buildings': 'Locations',
  'building-hosts.firstName': 'First name',
  'building-hosts.lastName': 'Last name',
  'building-hosts.actions': 'Actions',
  'building-hosts.create-button': 'Add Location Host',

  // modal
  'modal.yes': 'yes',
  'modal.no': 'no',

  batchActions: 'Batch Actions',
};
