import { UIView, UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useMemo } from 'react';

import { api } from '@/api';
import { DrawerForm } from '@/components/drawer-form';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { PERMISSION_OBJ_PERMANENT_EMPLOYEE } from '@/constants/permissions';
import { Table } from '@/components/table';
import { getAdditionalColumns } from '@/pages/coordinator/permanent-standard-hours/components/form/setup';
import {
  agencyStore,
  areaStore,
  breaktimeStore,
  colorStore,
  permanentEmployeeStandardHoursStore,
  permanentEmployeeStandardHoursAdditionalStore,
  languageStore,
  positionStore,
  shiftStore,
  supervisorStore,
} from '@/stores';
import {
  Company, EmployeeStatus, EmployeeType, WorkScheduleType,
} from '@/types/enums';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';
import { useFormDataById, useTableDataByKey } from '@/utils/hooks';
import { useMassUpdateCashedStores } from '@/utils/store';
import { lockerFormatted } from '@/utils/locker';

import { getFormFields } from './setup';

const entity = 'coordinator-permanentsStandardHours';
const store = permanentEmployeeStandardHoursStore;

const View = ({
                additionalDataLoading,
                additionalStore,
                columns,
                dataSource,
                formDataLoading,
                loading,
                onChangeTable,
              }) => (
  <>
    <h3 className="additional-title">Permanent Employee logs</h3>
    <Table
      id="additional-content"
      rowKey="id"
      columns={columns}
      dataSource={dataSource}
      store={additionalStore}
      checkPermissions
      permissionObj={PERMISSION_OBJ_PERMANENT_EMPLOYEE}
      loading={formDataLoading || additionalDataLoading || loading}
      scroll={{ y: '100%' }}
      customHeight="80vh"
      onChange={onChangeTable}
    />
  </>
);

export const Form = observer(({ transition }: UIViewInjectedProps) => {
  const isCreate = transition.router.globals.current.name === `base-layout.${entity}.create`;
  const { permanentStandardHoursId } = transition.router.globals.params;
  const {
    formData,
    loading: formDataLoading,
  } = useFormDataById<mpg.api.permanentEmployees.PermanentEmployee>(permanentStandardHoursId, 'permanentEmployeesStandardHours');

  const { loading } = useMassUpdateCashedStores([
    agencyStore,
    areaStore,
    breaktimeStore,
    colorStore,
    languageStore,
    positionStore,
    shiftStore,
    supervisorStore,
  ]);

  const columns = useMemo(() => getAdditionalColumns()
    .map((column) => ({
      ...column,
      sorter: false,
      filters: null,
      filterDropdown: null,
    }
    )), []);

  const {
    additionalStore,
    dataSource,
    loading: additionalDataLoading,
    onChangeTable,
  } = useTableDataByKey(permanentStandardHoursId, permanentEmployeeStandardHoursAdditionalStore, columns);

  const onBack = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };

  const initValues = !isCreate && formData
    ? {
      ...formData,
      birthday: formData.birthday && moment(formData.birthday),
      startDate: formData.startDate && moment(formData.startDate),
      statusUpdatedOn: formData.statusUpdatedOn && moment(formData.statusUpdatedOn),
      locker: lockerFormatted(formData.locker),
    }
    : {
      status: EmployeeStatus.Perm,
      employeeType: EmployeeType.Permanent,
      workSchedule: WorkScheduleType.StandardHours,
      company: Company.MPG,
    };

  const { firstName = '', lastName = '' } = formData || {};

  const title = isCreate
    ? getTranslatedString('permanentsStandardHours.create-new')
    : `${firstName} ${lastName}`;

  const formFields = getFormFields({ initValues, isCreate });
  const allowedFields = getAllowedFormFields(formFields, isCreate, PERMISSION_OBJ_PERMANENT_EMPLOYEE);

  const resourceController = withoutRepeatValue(initValues, {
    create: (values: mpg.api.permanentEmployees.PermanentEmployeeStandardHoursParams) => api.permanentEmployeesStandardHours.create({ ...values }).source
      .then(() => store.refresh()),
    update: (values: mpg.api.permanentEmployees.PermanentEmployeeStandardHoursParams) => api.permanentEmployeesStandardHours.update(formData && formData.id, { ...values }).source
      .then(() => store.refresh()),
  });

  return (
    <>
      <DrawerForm
        resourceId={permanentStandardHoursId}
        className="form-grid-wrapper"
        title={title}
        initValues={initValues}
        formFields={allowedFields}
        resourceController={resourceController}
        loaderCondition={formDataLoading || additionalDataLoading || loading}
        layout="vertical"
        onClose={onBack}
        additionalInformation={!isCreate && {
          anchorTitle: 'View permanent employee logs',
          View: <View
            dataSource={dataSource}
            columns={columns}
            additionalStore={additionalStore}
            additionalDataLoading={additionalDataLoading}
            onChangeTable={onChangeTable}
            formDataLoading={formDataLoading}
            loading={loading}
          />,
        }}
      />
      <UIView />
    </>
  );
});


