export const numericSort = (a: string, b: string) => {
  if (a === 'n/a') {
    return 1;
  }

  if (b === 'n/a') {
    return -1;
  }

  return a.localeCompare(b, undefined, { numeric: true });
};
