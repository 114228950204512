import { UIView, UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { DrawerForm } from '@/components/drawer-form';

import { useDayTimesheetForm } from './hooks';
import { EditDayTimesheetAdditionalInformation } from './additional-information';

export const EditDayTimesheetForm = observer(({ transition }: UIViewInjectedProps) => {
  const {
    resourceController,
    formFields,
    loading,
    initValues,
    onBack,
    recordId,
    dayTimesheet,
    canEdit,
  } = useDayTimesheetForm({ transition });

  return (
    <>
      <DrawerForm
        resourceId={recordId}
        className="form-grid-wrapper"
        title={<FormattedMessage id="timesheet.form.editDayTimesheet" />}
        initValues={initValues}
        formFields={formFields}
        resourceController={resourceController}
        loaderCondition={loading}
        layout="vertical"
        onClose={onBack}
        customDisabled={!canEdit}
        additionalInformation={{
          View: <EditDayTimesheetAdditionalInformation dayTimesheet={dayTimesheet} />,
        }}
      />
      <UIView />
    </>
  );
});
