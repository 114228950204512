import moment, { Moment } from 'moment/moment';
import { PartOfDay } from '@/types/enums';
import { DATE_WITHOUT_TIME_FORMAT } from '@/utils/moment';

export const getDateWithTimeZone = (date: string, timezone: string) => {
  const d = new Date(date);
  return date && timezone && d.toLocaleString('en-US', {
    timeZone: timezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const getFullDateWithTimeZone = (date: string, timezone: string) => {
  const d = new Date(date);
  return date && timezone && d.toLocaleString('en-US', {
    timeZone: timezone,
  });
};

export const getDateWithPartOfDay = (date: Moment): string => {
  const halfOfDay = 12;
  const partOfDay = date.hour() >= halfOfDay ? PartOfDay.Night : PartOfDay.Day;
  return moment(date).format(`ddd [${partOfDay}], MM/DD/YYYY`);
};

export const timesheetDateTransformer = {
  to: (data?: string): number | null => {
    if (data) {
      return parseInt(moment(data, 'MM/DD/YYYY').format('YYYYMMDD'), 10);
    }

    if (data === 'null') {
      return null;
    }
  },
  from: (data: number | null): string | null => (data ? moment(`${data}`, 'YYYYMMDD').format('MM/DD/YYYY') : null),
};

export const getWeekDateRange = (week: number) => {
  const from = moment(String(week));
  const to = moment(from).add(6, 'days');
  return `${from.format(DATE_WITHOUT_TIME_FORMAT)} - ${to.format(DATE_WITHOUT_TIME_FORMAT)}`
}
