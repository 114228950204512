import { UIView, UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';

import { api } from '@/api';
import { DrawerForm } from '@/components/drawer-form';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { PERMISSION_OBJ_EMPLOYEE_MARKUP } from '@/constants/permissions';
import { employeeMarkupsStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';
import { useFormDataById } from '@/utils/hooks';

import { getFormFields } from './setup';

const store = employeeMarkupsStore;

interface IProps extends UIViewInjectedProps {
  entity: string;
}

export const EmployeeMarkupForm = observer(({ transition, entity }: IProps) => {
  const isCreate = transition.router.globals.current.name === `base-layout.${entity}.edit.create-employee-markup`;
  const { employeeId, markupId } = transition.router.globals.params;
  const { formData, loading: formDataLoading } = useFormDataById<mpg.api.employeeMarkups.EmployeeMarkup>(
    markupId,
    'employeeMarkups',
  );

  const onBack = () => {
    transition.router.stateService.go(`base-layout.${entity}.edit`);
  };

  const initValues =
    !isCreate && formData
      ? {
          baseRate: formData.baseRate,
          effectiveDate: moment(formData.effectiveDate),
        }
      : {
          effectiveDate: moment(new Date()),
        };

  const title = getTranslatedString(isCreate ? 'employeeMarkup.create-new' : 'employeeMarkup.update');

  const formFields = getFormFields();
  const allowedFields = getAllowedFormFields(formFields, isCreate, PERMISSION_OBJ_EMPLOYEE_MARKUP);

  const resourceController = withoutRepeatValue(initValues, {
    create: (values: mpg.api.employeeMarkups.EmployeeMarkupParams) =>
      api.employeeMarkups.create({ ...values, employeeId }).source.then(() => store.refresh()),
    update: (values: mpg.api.employeeMarkups.UpdateEmployeeMarkupParams) =>
      api.employeeMarkups.update(formData && formData.id, { ...values }).source.then(() => store.refresh()),
  });

  return (
    <>
      <DrawerForm
        resourceId={markupId}
        className="form-grid-wrapper"
        title={title}
        initValues={initValues}
        formFields={allowedFields}
        resourceController={resourceController}
        loaderCondition={formDataLoading}
        layout="vertical"
        onClose={onBack}
        resetFieldsBeforeDrawerOpened
      />
      <UIView />
    </>
  );
});
