import { FormattedMessage } from 'react-intl';
import React from 'react';
import { Button } from '@/components/antd';
import { DownloadOutlined } from '@/components/icons';
import { ApiResponse, WrappedResult } from '@/api/types';
import { useExport } from '@/pages/common/components/buttons/hooks';

interface CreateButtonProps {
  onClick: (e: any) => void;
  loading?: boolean;
  style?: any;
  disabled?: boolean;
}

interface IExportButton {
  store: any;
  exportToExcel: (params?: any) => ApiResponse<WrappedResult<Blob>>;
  exportToEmail?: (params?: any) => ApiResponse<WrappedResult<Blob>>;
  withPermission: (Component: React.FC<any>) => (props: Record<string, unknown>) => React.FunctionComponentElement<any>;
  additionalParams?: { [key: string]: string };
  fileName: string;
}

const ExportButton = ({
  store,
  exportToExcel,
  exportToEmail,
  withPermission,
  additionalParams,
  fileName,
}: IExportButton) => {
  const { exportMode, onExport, exportButtonDisabled } = useExport(
    store,
    exportToExcel,
    fileName,
    exportToEmail,
    additionalParams,
  );

  const ExportButtonComponent = ({ onClick, loading, style = {}, disabled }: CreateButtonProps) => (
    <Button
      style={style}
      onClick={onClick}
      type="primary"
      shape="round"
      icon={<DownloadOutlined />}
      loading={loading}
      disabled={disabled}
    >
      <span>
        <FormattedMessage id="employees.export-to-excel" />
      </span>
    </Button>
  );

  const ExportToExcelButtonWithPerm = withPermission(ExportButtonComponent);

  return (
    <ExportToExcelButtonWithPerm
      style={{ marginRight: 4 }}
      loading={exportMode === 'xlsx'}
      key="export to excel button"
      onClick={() => onExport('xlsx')}
      disabled={store.count === 0 ? true : exportButtonDisabled}
    />
  );
};

export default ExportButton;
