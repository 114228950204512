import {
  buildStoreOptions,
} from '@/utils/common';
import {
  agencyStore,
} from '@/stores';

export const getFiltersSetup = () => [
  {
    name: 'agencyId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'attendanceReports.agency',
      options: buildStoreOptions(agencyStore, 'name'),
      mode: "multiple",
    },
    rules: [],
  },
  {
    name: 'date',
    componentName: 'date-range-picker',
    params: {
      i18nLabel: 'attendanceReports.date',
    },
    rules: [
      {
        required: true,
        message: 'Please select date',
      },
    ],
  },
];
