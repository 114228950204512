import React, { useEffect } from 'react';
import moment from 'moment';
import {
  Radio, Button, Menu, DatePicker, Space,
} from '@/components/antd';

const { RangePicker } = DatePicker;

const modes = {
  init: 'init',
  range: 'range',
  notDefined: 'not-defined',
};
export const mapToExtremeTimeValues = (arr) => arr.map((e, index) => {
  switch (index) {
    case 0:
      return moment(e).set({
        h: 0, m: 0, s: 0, ms: 0,
      });
    case 1:
      return moment(e).set({
        h: 23, m: 59, s: 59, ms: 999,
      });
    default:
      return null;
  }
});
export const mapToMoment = (arr) => arr.map((e) => moment(e));
export const mapToString = (arr) => arr.map((e) => e.toISOString());

export const DateFilter = (props) => {
  const {
    setSelectedKeys, confirm, clearFilters, selectedKeys, showTime, isNotDefinedExists = true,
  } = props;
  const [mode, setMode] = React.useState(selectedKeys.length ? modes.range : modes.init);

  useEffect(() => {
    if (mode === modes.notDefined) {
      setSelectedKeys(['null']);
    }

    if (mode === modes.range) {
      const start = moment().startOf('month');
      const end = moment().endOf('month');
      setSelectedKeys(mapToString([start, end]));
    }
  }, [mode]);

  const onChangeMode = (e) => {
    setMode(e.target.value);
  };

  const onApply = () => {
    confirm();
  };
  const onChange = (e) => {
    if (e === null) {
      setSelectedKeys([]);
    } else if (!showTime) {
      const [start, end] = e;
      start.set({
        h: 0, m: 0, s: 0, ms: 0,
      }).toISOString();
      end.set({
        h: 23, m: 59, s: 59, ms: 999,
      }).toISOString();
      setSelectedKeys(mapToString([start, end]));
    } else {
      setSelectedKeys(mapToString(e));
    }
  };

  const resetFilters = () => {
    clearFilters();
    confirm();
    setMode(isNotDefinedExists ? modes.init : modes.range);
  };

  return (
    isNotDefinedExists
      ? (
        <Menu>
          <Menu.Item key="0">
            <Radio.Group onChange={onChangeMode} value={mode}>
              <Radio value={modes.range}>Range</Radio>
              {isNotDefinedExists && <Radio value={modes.notDefined}>Not defined</Radio> }
            </Radio.Group>
          </Menu.Item>

          {mode === 'range' && (
          <Menu.Item key="1">
            <RangePicker
              showTime={showTime}
              value={selectedKeys && mapToMoment(selectedKeys)}
              onChange={onChange}
            />
          </Menu.Item>
          )}
          <Menu.Divider />
          <Menu.Item key="2">
            <Space>
              <Button onClick={resetFilters} type="link" disabled={!(selectedKeys && selectedKeys.length)}>
                Reset Filters
              </Button>
              <Button onClick={onApply} shape="round" type="primary">Apply</Button>
            </Space>
          </Menu.Item>
        </Menu>
      )
      : null
  );
};
