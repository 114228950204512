import { UIViewInjectedProps } from '@uirouter/react';
import { useMassUpdateCashedStores } from '@/utils/store';
import { agencyStore, buildingStore } from '@/stores';
import { Billings } from '@/pages/common/components/billings/components/table';

const entity = 'agency-billings';

export const AgencyBillings = ({ transition }: UIViewInjectedProps) => {
  const { loading } = useMassUpdateCashedStores([agencyStore, buildingStore]);

  return <Billings loading={loading} entity={entity} transition={transition} />;
};
