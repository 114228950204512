import { Transition } from '@uirouter/react';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Table } from '@/components/table';

import { DAY_TABLE_STORAGE_KEY } from '../../constants';
import { dayTimesheetStore } from '@/stores';

export const DayTimesheetTable = observer(
  ({
    transition,
    tableRef,
    weekTimesheetId,
  }: {
    weekTimesheetId: string;
    tableRef: any;
    weeks: number[];
    transition: Transition;
  }) => {
    const { loadTimesheetList, columns, list, loading, onDoubleClick } = dayTimesheetStore;
    useEffect(() => {
      loadTimesheetList(weekTimesheetId);
    }, []);
    return (
      <Table
        tableRef={tableRef}
        localStorageKey={DAY_TABLE_STORAGE_KEY}
        rowKey="id"
        columns={columns}
        pagination={false}
        dataSource={list}
        checkPermissions
        loading={loading}
        scroll={{ y: '100%' }}
        onRow={(record) => ({ onDoubleClick: () => onDoubleClick(record, transition) })}
      />
    );
  },
);
