import React, { useState } from 'react';
import { Resizable } from 'react-resizable';
import { Table as AntdTable, TableProps as AntdTableProps } from '@/components/antd';

import { CollectionStore, RelCollectionStore } from '@/stores/collection';
import { Drawer } from '@/components/drawer';

import { useTableSettings, usePermissions, useStoreIntegration } from './hooks';
import { SettingsTable } from './settings-table';
import { FiltersTable, applyFiltersToColumns } from '@/components/table/filters-table';

interface TableProps extends AntdTableProps<any> {
  tableRef?: any;
  store?: RelCollectionStore<string | number, any, any> | CollectionStore<string | number, any, any>;
  fillStore?: (params: any) => Promise<any>;
  checkPermissions?: boolean;
  permissionObj?: string;
  relId?: string | number;
  localStorageKey?: string;
  customHeight?: string;
  filtersSetup?: any[];
  initialFilters?: Record<string, any>;
  hasRequiredFilterParam?: boolean;
  requiredFilterParam?: any;
  isAutoloadAllowed?: boolean;
  initialSorters?: {
    columnKey: string,
    field: string,
    order: "ascend" | "descend",
  };
}

const ResizableTitle = (props) => {
  const { onResize, width, onClick, ...restProps } = props;
  const [resizing, setResizing] = useState(false);

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResizeStart={() => setResizing(true)}
      onResizeStop={() => {
        setTimeout(() => {
          setResizing(false);
        });
      }}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th
        onClick={(...args) => {
          if (!resizing && onClick) {
            onClick(...args);
          }
        }}
        {...restProps}
      />
    </Resizable>
  );
};

export const Table = (props: TableProps) => {
  const { allowedColumns } = usePermissions(props);
  const {
    customHeight,
    filtersSetup,
    isAutoloadAllowed = false,
  } = props;

  const {
    closeModal,
    isModalOpen,
    closeFilterModal,
    isFilterModalOpen,
    defaultSettings,
    settings,
    setSettings,
    sorters,
    setSorters,
    filters,
    setFilters,
    shownColumns,
  } = useTableSettings({ ...props, allowedColumns: allowedColumns.filter(item => !item.hidden) });

  const { onChange, pagination } = useStoreIntegration({
    ...props,
    filters,
    sorters,
    setFilters,
    setSorters,
    isAutoloadAllowed
  });

  return (
    <>
      <Drawer onClose={closeModal} visible={isModalOpen} title="Table settings">
        <SettingsTable
          value={settings}
          setValue={setSettings}
          columns={allowedColumns.filter(item => !item.hidden)}
          defaultSettings={defaultSettings}
        />
      </Drawer>
      <FiltersTable
        filters={filters}
        setFilters={setFilters}
        filtersSetup={filtersSetup}
        onClose={closeFilterModal}
        visible={isFilterModalOpen}
        title="Table filters"
      />
      <AntdTable
        className="c-table"
        style={{
          ['--custom-height' as string]: customHeight,
        }}
        showSorterTooltip={false}
        {...props}
        columns={applyFiltersToColumns(shownColumns.filter(item => !item.hidden), filters, sorters)}
        onChange={onChange}
        pagination={pagination}
        bordered
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
      />
    </>
  );
};
