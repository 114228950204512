import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { api } from '@/api';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import {
  buildingStore,
  lockerStore,
} from '@/stores';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValueForBulkUpdate } from '@/utils/controller';
import { useMassUpdateCashedStores } from '@/utils/store';
import { PERMISSION_OBJ_LOCKER } from '@/constants/permissions';

import { getFormFields } from './setup';
import { DrawerForm } from '@/components/drawer-form';
import { SIDEBAR_WIDTH } from '@/constants';

const entity = 'coordinator-lockers';
const store = lockerStore;
const LOCAL_STORAGE_KEY_BUILDING = 'COORDINATORS_LOCKERS_SELECTED_BUILDING';

export const BulkForm = observer(({ transition }: UIViewInjectedProps) => {
  const { selectedRowKeys = [] } = transition.targetState().params();
  const lastSelectedBuildingId = localStorage.getItem(LOCAL_STORAGE_KEY_BUILDING);
  const { loading } = useMassUpdateCashedStores([buildingStore]);

  const onClose = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };

  const initValues = {};

  const title = getTranslatedString('lockers.bulk-update');

  const formFields = getFormFields({
    initValues,
    isBulkEdit: true,
  })
    .map((item) => ({ ...item, rules: item.rules.filter(({ required }) => !required) }));

  const allowedFields = getAllowedFormFields(formFields, false, PERMISSION_OBJ_LOCKER);

  const resourceController = withoutRepeatValueForBulkUpdate(initValues, {
    create: (values: any) => {
      const items = selectedRowKeys.map((id: string) => ({ ...values, id, buildingId: lastSelectedBuildingId }));
      return api.lockers.bulkUpdate({ items }).source.then(() => store.refresh());
    },
  });

  useEffect(() => {
    if (!selectedRowKeys.length) {
      onClose();
    }
  }, []);

  return (
    <DrawerForm
      onClose={onClose}
      title={title}
      initValues={initValues}
      formFields={allowedFields}
      resourceController={resourceController}
      loaderCondition={loading}
      width={(window.innerWidth - SIDEBAR_WIDTH) / 2}
    />
  );
});
