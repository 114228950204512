import { requestWithoutAuth } from '@/utils/request';

const deviceBaseUrl = 'http://localhost:8000/api';
const printersUrl = `${deviceBaseUrl}/printers`;
const DEFAULT_PERIPHERY = 'default';

const playerAPI = {
  async getPrinterStatus() {
    const url = `${printersUrl}/${DEFAULT_PERIPHERY}/status`;
    return requestWithoutAuth({
      method: 'get',
      url,
    });
  },

  getPrinterInfo() {
    const url = `${printersUrl}/${DEFAULT_PERIPHERY}`;
    return requestWithoutAuth({
      method: 'get',
      url,
    });
  },

  printBadge(formData: FormData) {
    return fetch(`${printersUrl}/printImage`, {
      method: 'POST',
      body: formData,
    });
  }
}

export default playerAPI;
