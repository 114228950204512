import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Space } from '@/components/antd';

import { api } from '@/api';
import { DeleteAction, DeleteActionProps, EditAction, EditActionProps } from '@/components/table/actions';
import { getColumnSearchFields, getColumnDateFields } from '@/components/table/filters';
import { PERMISSION_OBJ_PTO, PERMISSION_READ, PERMISSION_UPDATE } from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { agencyStore, areaStore, buildingStore, colorStore, ptoStore, shiftStore, supervisorStore } from '@/stores';
import { buildStoreOptions, getStorePropById, showError } from '@/utils/common';
import { getDateWithTimeZone } from '@/utils/date';

interface PagePermission {
  pagePermission: string;
}

const PermissionEditAction = ({ pagePermission, uiSref }: EditActionProps & PagePermission) => {
  const ActionWithPermission = withPermissions([
    [pagePermission, PERMISSION_READ],
    [PERMISSION_OBJ_PTO, PERMISSION_UPDATE],
  ])(EditAction);
  return <ActionWithPermission uiSref={uiSref}/>;
};

const PermissionDeleteAction = ({ pagePermission, onConfirm }: DeleteActionProps & PagePermission) => {
  const ActionWithPermission = withPermissions([
    [pagePermission, PERMISSION_READ],
    [PERMISSION_OBJ_PTO, PERMISSION_UPDATE],
  ])(DeleteAction);
  return <ActionWithPermission onConfirm={onConfirm} />;
};

export const getColumns = (pagePermission: string, entity: string) => {
  const Actions = (_: any, { id }: any) => {
    const onDelete = () => {
      api.ptos
        .delete(id)
        .source.then(() => ptoStore.refresh())
        .catch(showError);
    };
    return (
      <Space>
        <PermissionEditAction
          uiSref={{ to: `base-layout.${entity}.edit`, params: { ptoId: id } }}
          pagePermission={pagePermission}
        />
        <PermissionDeleteAction pagePermission={pagePermission} onConfirm={onDelete} />
      </Space>
    );
  };

  return [
    {
      title: <FormattedMessage id="ptos.employeeId" />,
      dataIndex: 'employeeId',
      ...getColumnSearchFields(),
      sorter: true,
    },
    {
      title: <FormattedMessage id="ptos.firstName" />,
      dataIndex: 'firstName',
      ...getColumnSearchFields(),
      sorter: true,
    },
    {
      title: <FormattedMessage id="ptos.lastName" />,
      dataIndex: 'lastName',
      ...getColumnSearchFields(),
      sorter: true,
    },
    {
      title: <FormattedMessage id="ptos.from" />,
      dataIndex: 'from',
      sorter: true,
      ...getColumnDateFields(false),
      render: (_, record) => getDateWithTimeZone(record.from, record.timezone),
    },
    {
      title: <FormattedMessage id="ptos.to" />,
      dataIndex: 'to',
      sorter: true,
      ...getColumnDateFields(false),
      render: (_, record) => getDateWithTimeZone(record.to, record.timezone),
    },
    {
      title: <FormattedMessage id="ptos.approval" />,
      dataIndex: 'approval',
      ...getColumnSearchFields(),
      sorter: true,
    },
    {
      title: <FormattedMessage id="ptos.buildingId" />,
      dataIndex: 'buildingId',
      render: (itemId: string) =>getStorePropById<mpg.api.buildings.Building>(itemId, buildingStore),
    },
    {
      title: <FormattedMessage id="ptos.shift" />,
      dataIndex: 'shiftId',
      filters: buildStoreOptions(shiftStore, 'name', null, null, buildingStore, 'buildingId'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.shifts.Shift>(itemId, shiftStore)
    },
    {
      title: <FormattedMessage id="ptos.colorId" />,
      dataIndex: 'colorId',
      filters: buildStoreOptions(colorStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.colors.Colors>(itemId, colorStore),
    },
    {
      title: <FormattedMessage id="ptos.areaId" />,
      dataIndex: 'areaId',
      filters: buildStoreOptions(areaStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.areas.Area>(itemId, areaStore),
    },
    {
      title: <FormattedMessage id="ptos.supervisorId" />,
      dataIndex: 'supervisorId',
      filters: buildStoreOptions(supervisorStore, 'name'),
      filterMultiple: true,
      sorter: true,
      minWidth: 150,
      render: (itemId: string) => getStorePropById<mpg.api.supervisors.Supervisor>(itemId, supervisorStore),
    },
    {
      title: <FormattedMessage id="ptos.agencyId" />,
      dataIndex: 'agencyId',
      filters: buildStoreOptions(agencyStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.agencies.Agency>(itemId, agencyStore),
    },
    {
      title: <FormattedMessage id="table.actions" />,
      dataIndex: 'actions',
      render: Actions,
    },
  ];
};
