import Menu from 'antd/lib/menu';
import { Dropdown } from 'antd';
import Button from 'antd/lib/button';
import { FormattedMessage } from 'react-intl';
import { DownloadOutlined, DownOutlined } from '@/components/icons';
import { useExport } from '@/pages/common/components/buttons/hooks';
import { api } from '@/api';

export const DropdownExport = ({ store }) => {
  const { exportMode: employeesExportMode, onExport: onEmployeesExport } = useExport(
    store,
    api.employees.exportToExcel,
    'employees',
    api.employees.exportToEmail,
  );
  const { exportMode: employeesBaseRateExportMode, onExport: onEmployeesBaseRateExport } = useExport(
    store,
    api.employees.exportBaseRateToExcel,
    'employees-base-rate',
    api.employees.exportBaseRateToEmail,
  );
  function handleMenuClick(e) {
    if (e.key === 'export-employees') {
      onEmployeesExport('xlsx');
    } else {
      onEmployeesBaseRateExport('xlsx');
    }
  }
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="export-employees" icon={<DownloadOutlined />}>
        <FormattedMessage id="employees.employees-export" />
      </Menu.Item>
      <Menu.Item key="export-employees-base-rate" icon={<DownloadOutlined />}>
        <FormattedMessage id="employees.employees-base-rate-export" />
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown disabled={employeesExportMode === 'xlsx' || employeesBaseRateExportMode === 'xlsx'} overlay={menu}>
      <Button type="primary" shape="round" icon={<DownOutlined style={{ marginRight: '8px' }} />}>
        <FormattedMessage id="employees.export-to-excel" />
      </Button>
    </Dropdown>
  );
};
