import React from 'react';
import { FormattedMessage } from 'react-intl';

import { RenderAuditField } from "@/pages/common/audit-form/setup";
import {en} from "@/i18n/en";


export const columns = [
  {
    title: <FormattedMessage id="audit.property" />,
    dataIndex: 'key',
    render: (value) => {
      if (en[`permanentsStandardHours.${value}`]) return <FormattedMessage id={`permanentsStandardHours.${value}`} />;

      return <FormattedMessage id={`systemInfo.${value}`} />;

    },
  },
  {
    title: <FormattedMessage id="audit.originalData" />,
    dataIndex: 'originalData',
    render: RenderAuditField,
  },
  {
    title: <FormattedMessage id="audit.newData" />,
    dataIndex: 'newData',
    render: RenderAuditField,
  },
];
