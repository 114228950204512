import { getStorePropById } from '@/utils/common';
import { buildingStore } from '@/stores';

export const lockerFormatted = (locker: mpg.api.lockers.Locker): string => {
  if (locker?.name && locker?.lockerConfig?.buildingId) {
    return `${locker?.name} (${getStorePropById(locker?.lockerConfig?.buildingId, buildingStore)})`;
  }

  return '';
};
