import {
  areaStore,
  buildingStore,
  colorStore,
  positionStore,
  shiftStore,
  languageStore,
  supervisorStore,
  breaktimeStore,
  agencyStore,
  linesStore,
  musterStationsStore,
  musterStationCaptainsStore,
} from '@/stores';

import { findItemIdByLabel } from '@/utils/common';
import { FieldDescription, ParseSettings } from '@/components/import-button';

const booleanMap = (e) => {
  if (e === 'TRUE') {
    return true;
  }
  if (e === 'FALSE') {
    return false;
  }
};

interface FieldValue extends FieldDescription {
  isHidden?: boolean;
}

export const getParseSettings = (isHidden: boolean): ParseSettings => {
  const fields: FieldValue[] = [
    {
      name: 'agencyInternalId',
    },
    {
      name: 'status',
    },
    {
      name: 'firstName',
    },
    {
      name: 'middleName',
    },
    {
      name: 'lastName',
    },
    {
      name: 'dobKey',
    },
    {
      name: 'photoUrl',
    },
    {
      name: 'agencyId',
      svgMap: (e: string) => findItemIdByLabel(agencyStore, 'name', e),
      isHidden,
    },
    {
      name: 'areaId',
      svgMap: (e: string) => findItemIdByLabel(areaStore, 'name', e),
    },
    {
      name: 'positionId',
      svgMap: (e: string) => findItemIdByLabel(positionStore, 'name', e),
    },
    {
      name: 'supervisorId',
      svgMap: (e: string) => findItemIdByLabel(supervisorStore, 'name', e),
    },
    {
      name: 'shiftId',
      svgMap: (e: string, buildingName: string) =>
        findItemIdByLabel(shiftStore, 'name', e, buildingStore, 'buildingId', buildingName),
      relatedToField: 'buildingId',
    },
    {
      name: 'colorId',
      svgMap: (e: string) => findItemIdByLabel(colorStore, 'name', e),
    },
    {
      name: 'line',
      isHidden: !isHidden,
    },
    {
      name: 'lineId',
      svgMap: (e: string, re: string) => findItemIdByLabel(linesStore, 'name', e, buildingStore, 'buildingId', re),
      relatedToField: 'buildingId',
      isHidden,
    },
    {
      name: 'musterStationId',
      svgMap: (e: string, re: string) =>
        findItemIdByLabel(musterStationsStore, 'name', e, buildingStore, 'buildingId', re),
      relatedToField: 'buildingId',
      isHidden,
    },
    {
      name: 'musterStationCaptainId',
      svgMap: (e: string) => findItemIdByLabel(musterStationCaptainsStore, 'name', e),
      isHidden,
    },
    {
      name: 'startDate',
    },
    {
      name: 'orientationDate',
    },
    {
      name: 'buildingId',
      svgMap: (e: string) => findItemIdByLabel(buildingStore, 'name', e),
    },
    {
      name: 'trainedSlitter',
      svgMap: booleanMap,
    },
    {
      name: 'trainedOverwrap',
      svgMap: booleanMap,
    },
    {
      name: 'email',
    },
    {
      name: 'birthday',
    },
    {
      name: 'phone',
    },
    {
      name: 'address1',
    },
    {
      name: 'address2',
    },
    {
      name: 'city',
    },
    {
      name: 'zipcode',
    },
    {
      name: 'invitationLanguageId',
      svgMap: (e: string) => findItemIdByLabel(languageStore, 'name', e),
    },
    {
      name: 'breakTimes',
      svgMap: (e: string) => findItemIdByLabel(breaktimeStore, 'name', e),
    },
    {
      name: 'lunchTime',
    },
    {
      name: 'parkingTag',
    },
    {
      name: 'term',
      svgMap: booleanMap,
    },
    {
      name: 'termDescription',
    },
    {
      name: 'crewName',
    },
    {
      name: 'comment',
    },
    {
      name: 'kioskPinCode',
    },
    {
      name: 'baseRate',
      svgMap: (v) => (v ? Number(v) : v),
    },
    {
      name: 'effectiveDate',
    },
  ];
  const filteredFiled = fields.filter((field) => !field.isHidden);
  return filteredFiled.reduce((acc, { name, svgKey, svgMap, svgValidator, relatedToField }: FieldDescription) => {
    acc[svgKey || name] = {
      key: name,
      map: svgMap,
      validator: svgValidator,
      relatedToField,
    };
    return acc;
  }, {});
};
