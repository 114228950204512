import { AgencyWorkingLogsForm } from './components/form';
import { AgencyWorkingLogs } from './components/table';

import { PERMISSION_OBJ_AGENCY_REPORTING, PERMISSION_READ } from '@/constants/permissions';

export const states = [
  {
    name: 'base-layout.agency-workingLogs',
    url: '/agency-worklogs',
    component: AgencyWorkingLogs,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_AGENCY_REPORTING, PERMISSION_READ]],
    },
  },
  {
    name: 'base-layout.agency-workingLogs.edit',
    url: '/:workingLogId/edit',
    component: AgencyWorkingLogsForm,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_AGENCY_REPORTING, PERMISSION_READ]],
    },
  },
];
