import { UISref } from '@uirouter/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Result } from '@/components/antd';

export const AccessDenied = () => {
  const subTitle = (
    <>
      <FormattedMessage id="common.access-denied.p1" />
      <br />
      <FormattedMessage id="common.access-denied.p2" />
    </>
  );

  const BackHome = (
    <UISref to="base-layout.home">
      <a>
        <Button type="primary">
          <FormattedMessage id="common.back-home" />
        </Button>
      </a>
    </UISref>
  );

  return (
    <Result
      status="403"
      title={<FormattedMessage id="common.access-denied.title" />}
      subTitle={subTitle}
      extra={BackHome}
    />
  );
};
