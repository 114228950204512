import { useState, useEffect } from 'react';
import { UIView, UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { Button, Dropdown, Menu, PageHeader, Select, Tabs } from '@/components/antd';
import { buildingStore, colorStore, shiftStore } from '@/stores';
import { useMassUpdateCashedStores } from '@/utils/store';
import { buildStoreOptions } from '@/utils/common';
import { withPermissions } from '@/hocs/permission';
import { PERMISSION_CREATE, PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY } from '@/constants/permissions';
import { DownOutlined, PlusOutlined } from '@/components/icons';

import { TableView } from './table';
import { CalendarView } from './calendar';

const LOCAL_STORAGE_KEY_BUILDING = 'COORDINATORS_SHIFT_PLANNING_TEMPS_SELECTED_BUILDING';

interface AutoCreateButtonProps {
  onClick: (e: any) => void;
  loading?: boolean;
  style?: any;
  disabled?: boolean;
}

export const AutoCreateButton = withPermissions([[PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY, PERMISSION_CREATE]])(
  ({ onClick, style = {} }: AutoCreateButtonProps) => (
    <Dropdown
      overlay={(
        <Menu onClick={onClick}>
          <Menu.Item key="shiftPlanning.auto-create" icon={<PlusOutlined />}>
            <FormattedMessage id="shiftPlanning.auto-create" />
          </Menu.Item>
          <Menu.Item key="shiftPlanning.auto-create.extended" icon={<PlusOutlined />}>
            <FormattedMessage id="shiftPlanning.auto-create.extended" />
          </Menu.Item>
          <Menu.Item key="shiftPlanning.auto-create.delete-events" icon={<PlusOutlined />}>
            <FormattedMessage id="shiftPlanning.auto-create.delete-events" />
          </Menu.Item>
        </Menu>
      )}
    >
      <Button style={style} type="primary" shape="round" icon={<DownOutlined style={{ marginRight: '8px' }} />}>
        <FormattedMessage id="shiftPlanning.auto-create" />
      </Button>
    </Dropdown>
  ),
);

enum View {
  Table = 'table',
  Calendar = 'calendar',
}

export const ShiftPlanning = observer(({ transition }: UIViewInjectedProps) => {
  const [selectedBuilding, setSelectedBuilding] = useState<mpg.api.buildings.Building>();
  const { view } = transition.targetState().params();

  const { loading } = useMassUpdateCashedStores([buildingStore, colorStore, shiftStore]);

  useEffect(() => {
    if (!loading) {
      const lastSelectedBuildingId = localStorage.getItem(LOCAL_STORAGE_KEY_BUILDING);
      const lastSelectedBuilding =
        lastSelectedBuildingId && buildingStore.items.find(({ id }) => id === lastSelectedBuildingId);
      setSelectedBuilding(lastSelectedBuilding ?? buildingStore.items[0]);
    }
  }, [loading]);

  const onBuildingChange = (buildingId: string) => {
    const building = buildingStore.items.find(({ id }) => id === buildingId);
    setSelectedBuilding(building);
    localStorage.setItem(LOCAL_STORAGE_KEY_BUILDING, buildingId);
  };

  const handleTabChange = (key: string) => {
    transition.router.stateService.go('.', { view: key });
  };

  const handleAutoCreate = (e) => {
    switch (e.key) {
      case 'shiftPlanning.auto-create':
        transition.router.stateService.go('base-layout.coordinator-temps-shift-planning.auto-create', {
          buildingId: selectedBuilding?.id,
        });
        break;
      case 'shiftPlanning.auto-create.extended':
        transition.router.stateService.go('base-layout.coordinator-temps-shift-planning.auto-create-extended');
        break;
      case 'shiftPlanning.auto-create.delete-events':
        transition.router.stateService.go('base-layout.coordinator-temps-shift-planning.auto-create-delete', {
          buildingId: selectedBuilding ? [selectedBuilding.id] : [],
        });
        break;
    }
  };

  const tabBarExtraContent = (
    <PageHeader
      className="r-antd-page-header r-antd-page-header--withoutPadding"
      extra={[
        <div style={{ flex: 1 }}>
          <Select
            showSearch
            placeholder="Select a building"
            onChange={onBuildingChange}
            filterOption={(input, option: any) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            options={buildStoreOptions(buildingStore, 'name')}
            value={selectedBuilding?.id}
            style={{ width: 200 }}
          />
        </div>,
        <AutoCreateButton onClick={handleAutoCreate} style={{ marginRight: 32 }} />,
      ]}
    />
  );
  return (
    <div className="m-shift-planning">
      <Tabs
        onChange={handleTabChange}
        defaultActiveKey={view}
        tabBarExtraContent={tabBarExtraContent}
        destroyInactiveTabPane
      >
        <Tabs.TabPane tab="Table View" key={View.Table}>
          <TableView selectedBuilding={selectedBuilding} transition={transition} loading={loading} />
        </Tabs.TabPane>
        <Tabs.TabPane style={{ overflowY: 'scroll' }} tab="Calendar View" key={View.Calendar}>
          <CalendarView selectedBuilding={selectedBuilding} loading={loading} transition={transition} />
        </Tabs.TabPane>
      </Tabs>
      <UIView />
    </div>
  );
});
