import { UIView, UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useMemo, useState } from 'react';

import { api } from '@/api';
import { DrawerForm } from '@/components/drawer-form';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { Table } from '@/components/table';
import { PERMISSION_CREATE, PERMISSION_OBJ_EMPLOYEE, PERMISSION_OBJ_EMPLOYEE_MARKUP } from '@/constants/permissions';
import { getAdditionalColumns } from './setup';
import {
  agencyStore,
  areaStore,
  breaktimeStore,
  buildingStore,
  colorStore,
  employeeStore,
  employeeAdditionalStore,
  languageStore,
  positionStore,
  shiftStore,
  supervisorStore,
  authStore,
} from '@/stores';
import { BillingType, EmployeeType } from '@/types/enums';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';
import { useBuildingsDependency, useFormDataById, useTableDataByKey } from '@/utils/hooks';
import { useMassUpdateCashedStores } from '@/utils/store';
import { lockerFormatted } from '@/utils/locker';

const store = employeeStore;

const View = ({
  additionalDataLoading,
  additionalStore,
  columns,
  dataSource,
  formDataLoading,
  loading,
  onChangeTable,
}) => (
  <>
    <h3 className="additional-title">Temporary Employee logs</h3>
    <Table
      id="additional-content"
      rowKey="id"
      columns={columns}
      dataSource={dataSource}
      store={additionalStore}
      checkPermissions
      permissionObj={PERMISSION_OBJ_EMPLOYEE}
      loading={formDataLoading || additionalDataLoading || loading}
      scroll={{ y: '100%' }}
      customHeight="80vh"
      onChange={onChangeTable}
    />
  </>
);

interface IProps extends UIViewInjectedProps {
  entity: string;
  permission: string;
  getFormFields: any;
}

export const Form = observer(({ transition, entity, permission, getFormFields }: IProps) => {
  const isCreate = transition.router.globals.current.name === `base-layout.${entity}.create`;
  const { employeeId } = transition.router.globals.params;
  const { formData, loading: formDataLoading } = useFormDataById<mpg.api.employees.Employee>(employeeId, 'employees');
  const [billingType, setBillingType] = useState<BillingType>();

  const { loading } = useMassUpdateCashedStores([
    agencyStore,
    areaStore,
    breaktimeStore,
    buildingStore,
    colorStore,
    languageStore,
    positionStore,
    shiftStore,
    supervisorStore,
  ]);

  const columns = useMemo(
    () =>
      getAdditionalColumns({ entity, permission }).map((column) => ({
        ...column,
        sorter: false,
        filters: null,
        filterDropdown: null,
      })),
    [],
  );

  const {
    additionalStore,
    dataSource,
    loading: additionalDataLoading,
    onChangeTable,
  } = useTableDataByKey(employeeId, employeeAdditionalStore, columns);

  const onBack = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };

  const initValues =
    !isCreate && formData
      ? {
          ...formData,
          birthday: formData.birthday && moment(formData.birthday),
          startDate: formData.startDate && moment(formData.startDate),
          orientationDate: formData.orientationDate && moment(formData.orientationDate),
          statusUpdatedOn: formData.statusUpdatedOn && moment(formData.statusUpdatedOn),
          locker: lockerFormatted(formData.locker),
        }
      : {
          status: 'new',
          employeeType: EmployeeType.Temporary,
          isNonBillable: false,
        };

  const { firstName = '', lastName = '' } = formData || {};

  const title = isCreate ? getTranslatedString('employees.create-new') : `${firstName} ${lastName}`;

  const { linesOptions, musterStationsOptions, shiftsOptions, onChangeBuilding } = useBuildingsDependency(initValues);

  const onBillingTypeChange = (value: BillingType) => {
    setBillingType(value);
  };
  const formFields = getFormFields({
    initValues,
    isCreate,
    onChangeBuilding,
    linesOptions,
    musterStationsOptions,
    shiftsOptions,
    billingType,
    onBillingTypeChange,
  });
  const isEmployeeMarkupFieldsAvailable =
    isCreate && authStore.hasPermissions([[PERMISSION_OBJ_EMPLOYEE_MARKUP, PERMISSION_CREATE]]);

  const allowedFields = getAllowedFormFields(
    formFields,
    isCreate,
    PERMISSION_OBJ_EMPLOYEE,
    isEmployeeMarkupFieldsAvailable ? ['baseRate', 'effectiveDate'] : [],
  );
  const resourceController = withoutRepeatValue(initValues, {
    create: (values: mpg.api.employees.EmployeeParams) =>
      api.employees.create({ ...values }).source.then(() => store.refresh()),
    update: (values: mpg.api.employees.Employee) =>
      api.employees.update(formData && formData.id, { ...values }).source.then(() => store.refresh()),
  });

  return (
    <>
      <DrawerForm
        resourceId={employeeId}
        className="form-grid-wrapper"
        title={title}
        initValues={initValues}
        formFields={allowedFields}
        resourceController={resourceController}
        loaderCondition={formDataLoading || additionalDataLoading || loading}
        layout="vertical"
        onClose={onBack}
        additionalInformation={
          !isCreate && {
            anchorTitle: 'View temporary employee logs',
            View: (
              <View
                dataSource={dataSource}
                columns={columns}
                additionalStore={additionalStore}
                additionalDataLoading={additionalDataLoading}
                onChangeTable={onChangeTable}
                formDataLoading={formDataLoading}
                loading={loading}
              />
            ),
          }
        }
      />
      <UIView />
    </>
  );
});
