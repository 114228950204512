import { Rule } from 'antd/lib/form';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import { Space } from '@/components/antd';
import { ReadAction } from '@/components/table/actions';
import { employeeState } from '@/constants/employee-state';
import { PERMISSION_READ } from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { buildDropdownOptionsFromEnum, buildStoreOptions, filterBlockedApprovers, getOptionsFilteredByActive } from '@/utils/common';
import {
  agencyStore,
  approversStore,
  areaStore,
  breaktimeStore,
  buildingStore,
  colorStore,
  languageStore,
  musterStationCaptainsStore,
  positionStore,
  supervisorStore,
} from '@/stores';
import { getTranslatedString } from '@/utils';
import {
  AccessTo,
  BillingType,
  EmployeeStatus,
  EmployeeType,
  UserRole,
  WorkScheduleTemporaryType,
} from '@/types/enums';
import { approverRule, buildingChanged, emailOrPhoneRequired, musterStationChanged, phone } from '@/utils/form-rules';
import { DateComponent } from '@/components/form-date';

interface Props {
  initValues: Partial<
    Omit<mpg.api.employees.Employee, 'birthday' | 'startDate' | 'orientationDate' | 'statusUpdatedOn' | 'locker'> & {
      birthday: moment.Moment;
      startDate: moment.Moment;
      statusUpdatedOn: moment.Moment;
      locker: string;
      approver: mpg.api.approvers.Approver;
      color: mpg.api.colors.Colors
    }
  >;
  isBulkEdit?: boolean;
  isCreate?: boolean;
  isStatusFieldEditable?: boolean;
  isMusterStationsFieldEditable?: boolean;
  isLineFieldEditable?: boolean;
  isShiftFieldEditable?: boolean;
  onChangeBuilding?: (id: mpg.api.buildings.BuildingId) => void;
  musterStationsOptions?: mpg.api.musterStations.MusterStation[];
  linesOptions?: mpg.api.lines.Line[];
  shiftsOptions?: mpg.api.shifts.Shift[];
  billingType?: BillingType;
  onBillingTypeChange?: (checked: boolean) => void;
  isAgencyNonBillable?: boolean;
}

export const getFormFields = ({
  initValues,
  isBulkEdit = false,
  isStatusFieldEditable = true,
  isCreate = false,
  isMusterStationsFieldEditable = true,
  isLineFieldEditable = true,
  isShiftFieldEditable = true,
  onChangeBuilding,
  musterStationsOptions = [],
  linesOptions = [],
  shiftsOptions = [],
  billingType,
  onBillingTypeChange,
  isAgencyNonBillable,
}: Props) => {
  const { status = '' } = initValues;
  const statusOptions =
    status && !isCreate
      ? employeeState[UserRole.MPG][status]
          .map((employeeStatus: string) => ({
            value: employeeStatus,
            label: getTranslatedString(`employees.status.${employeeStatus}`),
          }))
          .concat([
            {
              value: status,
              label: getTranslatedString(`employees.status.${status}`),
            },
          ])
      : [{ value: EmployeeStatus.New, label: getTranslatedString(`employees.status.${EmployeeStatus.New}`) }];
  return [
    {
      name: 'attachmentId',
      componentName: 'upload',
      conditionalRender: () => !isBulkEdit && !isCreate,
      params: {
        i18nLabel: 'employees.photo',
        style: { gridColumnStart: 1, gridColumnEnd: -1 },
        objectId: initValues.id,
        hasLabel: false,
      },
      rules: [
        // {
        //   required: true,
        //   message: 'Please upload photo'
        // },
      ],
    },
    {
      name: 'kioskPinCode',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'employees.kioskPinCode',
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'employeeType',
      componentName: 'dropdown',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'employees.employeeType',
        options: buildDropdownOptionsFromEnum([EmployeeType.Temporary], 'employees.employeeType.'),
        disabled: true,
      },
      rules: [{ required: true }],
    },
    {
      name: 'workSchedule',
      componentName: 'dropdown',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'employees.workSchedule',
        options: buildDropdownOptionsFromEnum(WorkScheduleTemporaryType, 'employees.workSchedule.'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'agencyInternalId',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'employees.agencyInternalId',
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'agencyId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'employees.agencyId',
        options: buildStoreOptions(agencyStore, 'name'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'status',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'employees.status',
        options: statusOptions,
        disabled: !isStatusFieldEditable,
        description:
          isBulkEdit &&
          !isStatusFieldEditable &&
          'In bulk edit mode you can change status only for records with the same current status',
      },
      rules: [{ required: true }],
    },
    {
      name: 'statusUpdatedBy',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit && !isCreate,
      params: {
        i18nLabel: 'employees.statusUpdatedBy',
        disabled: true,
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'statusUpdatedOn',
      componentName: 'datepicker',
      conditionalRender: () => !isBulkEdit && !isCreate,
      params: {
        i18nLabel: 'employees.statusUpdatedOn',
        showTime: true,
        format: 'MM/DD/YYYY h:mm:ss a',
        disabled: true,
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'invitationLanguageId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'employees.invitationLanguageId',
        options: buildStoreOptions(languageStore, 'name'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'firstName',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'employees.firstName',
      },
      rules: [{ required: true }],
    },
    {
      name: 'middleName',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'employees.middleName',
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'lastName',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'employees.lastName',
      },
      rules: [{ required: true }],
    },
    {
      name: 'email',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'employees.email',
        dependencies: ['phone'],
      },
      rules: [emailOrPhoneRequired, { type: 'email' }] as Rule[],
    },
    {
      name: 'phone',
      componentName: 'phone',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'employees.phone',
        dependencies: ['email'],
      },
      rules: [emailOrPhoneRequired, phone],
    },
    {
      name: 'areaId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'employees.areaId',
        options: getOptionsFilteredByActive(initValues?.area, buildStoreOptions(areaStore, 'name', 'active', true))
      },
      rules: [{ required: true }],
    },
    {
      name: 'positionId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'employees.position',
        options: getOptionsFilteredByActive(initValues?.position, buildStoreOptions(positionStore, 'name', 'active', true))
      },
      rules: [{ required: true }],
    },
    {
      name: 'buildingId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'employees.building',
        options: buildStoreOptions(buildingStore, 'name'),
        dependencies: ['musterStationId'],
        onChange: onChangeBuilding,
      },
      rules: [buildingChanged, { required: true }],
    },
    {
      name: 'approverId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'employees.approverId',
        options: buildStoreOptions(filterBlockedApprovers(approversStore, initValues.approver), 'name'),
      },
      rules: [{ required: true }, approverRule],
    },
    {
      name: 'musterStationId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'employees.musterStations',
        options: musterStationsOptions,
        dependencies: ['buildingId'],
        disabled: !isMusterStationsFieldEditable,
        description:
          isBulkEdit &&
          !isMusterStationsFieldEditable &&
          'In bulk edit mode you can change Muster Station only for records with the same current Building',
      },
      rules: [...(isCreate ? [musterStationChanged] : []), { required: true }],
    },
    {
      name: 'musterStationCaptainId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'employees.musterStationCaptains',
        options: buildStoreOptions(musterStationCaptainsStore, 'name'),
      },
      rules: [{ required: false }],
    },
    {
      name: 'lineId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'employees.line',
        options: linesOptions,
        dependencies: ['buildingId'],
        disabled: !isLineFieldEditable,
        description:
          isBulkEdit &&
          !isLineFieldEditable &&
          'In bulk edit mode you can change Line only for records with the same current Building',
      },
      rules: [{ required: true }],
    },
    {
      name: 'shiftId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'employees.shift',
        options: getOptionsFilteredByActive(initValues?.shift, shiftsOptions),
        dependencies: ['buildingId'],
        disabled: !isShiftFieldEditable,
        description:
          isBulkEdit &&
          !isShiftFieldEditable &&
          'In bulk edit mode you can change Shift only for records with the same current Building',
      },
      rules: [{ required: true }],
    },
    {
      name: 'colorId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'employees.colorId',
        options: getOptionsFilteredByActive(initValues?.color, buildStoreOptions(colorStore, 'name', 'active', true))
      },
      rules: [{ required: true }],
    },
    {
      name: 'supervisorId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'employees.supervisorId',
        options: getOptionsFilteredByActive(initValues?.supervisor, buildStoreOptions(supervisorStore, 'name', 'active', true))
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'breakTimeId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'employees.breakTimes',
        options: buildStoreOptions(breaktimeStore, 'name'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'locker',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit && !isCreate,
      params: {
        i18nLabel: 'employees.locker',
        disabled: true,
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'trainedSlitter',
      componentName: 'switch',
      params: {
        i18nLabel: 'employees.trainedSlitter',
        asDropdown: isBulkEdit,
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'trainedOverwrap',
      componentName: 'switch',
      params: {
        i18nLabel: 'employees.trainedOverwrap',
        asDropdown: isBulkEdit,
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'startDate',
      componentName: 'datepicker',
      params: {
        i18nLabel: 'employees.startDate',
        format: 'MM/DD/YYYY',
      },
      rules: [{ required: true }],
    },
    {
      name: 'orientationDate',
      componentName: 'datepicker',
      params: {
        i18nLabel: 'employees.orientationDate',
        format: 'MM/DD/YYYY',
      },
      rules: [
        // notPastDateIfEdit(initValues),
      ],
    },
    {
      name: 'birthday',
      componentName: 'datepicker',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'employees.birthday',
        format: 'MM/DD/YYYY',
        disabledDate: (current: moment.Moment) => current && current > moment().endOf('day'),
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'dobKey',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'employees.dobKey',
      },
      rules: [
        // { required: true },
      ],
    },
    // {
    //   name: 'lunchTime',
    //   componentName: 'input',
    //   params: {
    //     i18nLabel: 'employees.lunchTime',
    //   },
    //   rules: [
    //     // { required: true },
    //   ],
    // },
    {
      name: 'termDescription',
      componentName: 'input',
      params: {
        i18nLabel: 'employees.termDescription',
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'crewName',
      componentName: 'input',
      params: {
        i18nLabel: 'employees.crewName',
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'accessTo',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'employees.accessToId',
        options: buildDropdownOptionsFromEnum(AccessTo, 'employees.accessToId.'),
      },
      rules: [{ required: true }],
    },
    // {
    //   name: 'address1',
    //   componentName: 'input',
    //   params: {
    //     i18nLabel: 'employees.address1',
    //   },
    //   rules: [
    //     // { required: true },
    //   ],
    // },
    // {
    //   name: 'address2',
    //   componentName: 'input',
    //   params: {
    //     i18nLabel: 'employees.address2',
    //   },
    //   rules: [
    //     // { required: true },
    //   ],
    // },
    // {
    //   name: 'city',
    //   componentName: 'input',
    //   params: {
    //     i18nLabel: 'employees.city',
    //   },
    //   rules: [
    //     // { required: true },
    //   ],
    // },
    // {
    //   name: 'zipcode',
    //   componentName: 'input',
    //   params: {
    //     i18nLabel: 'employees.zipcode',
    //   },
    //   rules: [
    //     // { required: true },
    //   ],
    // },
    {
      name: 'parkingTag',
      componentName: 'input',
      params: {
        i18nLabel: 'employees.parkingTag',
      },
      rules: [
        // { required: true },
      ],
      isUniqueField: true,
    },
    // {
    //   name: 'term',
    //   componentName: 'switch',
    //   params: {
    //     i18nLabel: 'employees.term',
    //   },
    //   rules: [
    //     // { required: true },
    //   ],
    // },
    // {
    //   name: 'comment',
    //   componentName: 'input',
    //   params: {
    //     i18nLabel: 'employees.comment',
    //   },
    //   rules: [
    //     // { required: true },
    //   ],
    // },
    // {
    //   name: 'kioskPinCode',
    //   componentName: 'input',
    //   params: {
    //     i18nLabel: 'employees.kioskPinCode',
    //   },
    //   rules: [
    //     // { required: true },
    //   ],
    // },
    {
      name: 'billingType',
      componentName: 'dropdown',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'billingType',
        options: [
          { value: 'ProductionFloor', label: 'Production Floor' },
          { value: 'BillingOnPremise', label: 'Billing On Premise' },
          { value: 'NonBillable', label: 'Non Billable' },
          { value: 'Shifts', label: 'Schedules' },
        ],
        onChange: onBillingTypeChange,
        disabled: isAgencyNonBillable,
      },
      rules: [],
    },
    {
      name: 'baseRate',
      componentName: 'input-number',
      conditionalRender: () => billingType !== BillingType.NonBillable,
      params: {
        i18nLabel: 'employeeMarkup.baseRate',
        min: 0,
      },
      rules: [{ type: 'number', min: 0 }] as Rule[],
    },
    {
      name: 'effectiveDate',
      componentName: 'datepicker',
      conditionalRender: () => billingType !== BillingType.NonBillable,
      params: {
        i18nLabel: 'employeeMarkup.effectiveDate',
        format: 'MM/DD/YYYY',
      },
      rules: [],
    },
  ];
};

export const getAuditColumns = (permissionObject: string, viewPath: string) => {
  const actions = {
    C: 'Create',
    R: 'Read',
    U: 'Update',
    D: 'Delete',
  };
  const PermissionedReadAction = withPermissions([[permissionObject, PERMISSION_READ]])(ReadAction);

  const Action = (_: any, { action }: any) => <Space>{actions[action] || ''}</Space>;

  const Actions = (_: any, { id }: any) => (
    <Space>
      <PermissionedReadAction uiSref={{ to: viewPath, params: { recordId: id } }} />
    </Space>
  );

  const Difference = (_: any, { newData: recordNewData, originalData: recordOriginalData }: any) => {
    const newData = recordNewData || {};
    const originalData = recordOriginalData || {};

    const keys = Object.keys({ ...newData, ...originalData });

    const changedKeys = keys.filter((key) => newData[key] !== originalData[key]).sort();

    return (
      <Space
        style={{
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {keys.length === changedKeys.length ? 'All' : changedKeys.join(', ')}
      </Space>
    );
  };

  const User = ({ name }: any) => <Space>{name || 'deleted'}</Space>;

  return [
    {
      title: <FormattedMessage id="audit.user" />,
      dataIndex: 'user',
      render: User,
    },
    {
      title: <FormattedMessage id="audit.action" />,
      dataIndex: 'action',
      render: Action,
    },
    {
      title: <FormattedMessage id="audit.createdAt" />,
      dataIndex: 'createdAt',
      render: DateComponent,
    },
    {
      title: <FormattedMessage id="audit.changedFields" />,
      dataIndex: 'changedFields',
      render: Difference,
      minWidth: 350,
    },
    {
      title: <FormattedMessage id="table.actions" />,
      render: Actions,
      minWidth: 80,
    },
  ];
};
