import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';

import { agencyStore, breaktimeStore, buildingStore, colorStore, shiftStore, supervisorStore } from '@/stores';
import { useReportFormData } from '@/utils/hooks';
import { useMassUpdateCashedStores } from '@/utils/store';
import { Drawer } from '@/components/drawer';
import { getColumns } from '@/pages/common/components/working-logs/components/table/setup';
import { Table } from '@/components/table';
import { api } from '@/api';
import {
  PERMISSION_OBJ_WORKING_LOG_TEMP_SHIFT,
  PERMISSION_OBJ_WORKING_LOG_PERM_SHIFT,
  PERMISSION_OBJ_WORKING_LOG_PERM_STANDARD,
  PERMISSION_READ,
} from '@/constants/permissions';
import { PageHeader } from '@/components/antd';
import { SIDEBAR_WIDTH } from '@/constants';
import { withAtLeastOnePermission } from '@/hocs/permission';
import ExportButton from '@/pages/common/components/buttons/export-button';

interface IForm extends UIViewInjectedProps {
  entity: string;
}

export const WorkLogs = observer(({ transition, entity }: IForm) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { billingId } = transition.router.globals.params;
  const params = useMemo(() => JSON.parse(atob(billingId)), [billingId]);

  const { loading } = useMassUpdateCashedStores(
    [agencyStore, breaktimeStore, breaktimeStore, buildingStore, colorStore, shiftStore, supervisorStore],
    [],
    () => setIsLoaded(true),
  );

  const onBack = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };

  const { visitorId = '', visitorFirstName = '', visitorLastName = '' } = params || {};
  const title = `#${visitorId} - ${visitorFirstName} ${visitorLastName}`;

  const columns = useMemo(
    () =>
      getColumns().map((column) => ({
        ...column,
        sorter: false,
        filters: null,
        filterDropdown: null,
      })),
    [],
  );

  const {
    loading: formDataLoading,
    onChangeTable,
    dataSource,
    store,
  } = useReportFormData<mpg.api.workingLogs.WorkingLog>(params, columns, isLoaded);

  return (
    <Drawer onClose={onBack} visible title={title} width={window.innerWidth - SIDEBAR_WIDTH}>
      <PageHeader
        className="r-antd-page-header"
        extra={[
          <ExportButton
            store={store}
            exportToExcel={api.workingLogs.billingsDetailsExportToExcel}
            exportToEmail={api.workingLogs.billingsDetailsExportToEmail}
            withPermission={withAtLeastOnePermission([
              [PERMISSION_OBJ_WORKING_LOG_TEMP_SHIFT, PERMISSION_READ],
              [PERMISSION_OBJ_WORKING_LOG_PERM_SHIFT, PERMISSION_READ],
              [PERMISSION_OBJ_WORKING_LOG_PERM_STANDARD, PERMISSION_READ],
            ])}
            fileName="mpg-working-logs"
          />,
        ]}
      />
      <Table
        rowKey="id"
        columns={columns}
        dataSource={dataSource}
        store={store}
        checkPermissions
        // permissionObj={PERMISSION_OBJ_WORKING_LOG} TODO: Not used in src\components\table\hooks.tsx
        loading={store.loading || formDataLoading || loading}
        scroll={{ y: '100%' }}
        onChange={onChangeTable}
        customHeight="80vh"
      />
    </Drawer>
  );
});
