import { getFormattedParams, request } from '@/utils/request';
import {ApiResponse, Result, WrappedAxiosItemsResult} from './types';

const BASE_PATH = '/employees-markups';

const employeeMarkupApi = {
  getAll(params?: mpg.api.employeeMarkups.GetParams): ApiResponse<WrappedAxiosItemsResult<mpg.api.employeeMarkups.EmployeeMarkup[]>> {
    return request({
      method: 'get',
      url: BASE_PATH,
      params: getFormattedParams(params),
    });
  },
  get(id: mpg.api.employeeMarkups.EmployeeMarkupId): ApiResponse<mpg.api.employeeMarkups.EmployeeMarkup> {
    return request({
      method: 'get',
      url: `${BASE_PATH}/${id}`,
    });
  },
  create(data: mpg.api.employeeMarkups.EmployeeMarkupParams): ApiResponse<mpg.api.employeeMarkups.EmployeeMarkup> {
    return request({
      method: 'post',
      url: BASE_PATH,
      data,
    });
  },
  update(id: mpg.api.employeeMarkups.EmployeeMarkupId, data: mpg.api.employeeMarkups.UpdateEmployeeMarkupParams): ApiResponse<mpg.api.employeeMarkups.EmployeeMarkup> {
    return request({
      method: 'patch',
      url: `${BASE_PATH}/${id}`,
      data: getFormattedParams(data),
    });
  },
  delete(id: mpg.api.employeeMarkups.EmployeeMarkupId): ApiResponse<Result> {
    return request({
      method: 'delete',
      url: `${BASE_PATH}/${id}`,
    });
  },
  audit(params: mpg.api.employeeMarkups.EmployeeMarkupAuditGetParams): ApiResponse<WrappedAxiosItemsResult<mpg.api.employeeMarkups.EmployeeMarkupsAudit[]>> {
    const { id: employeeId, ...decreasedParams } = params;

    return request({
      method: 'get',
      url: `/employees/${employeeId}/employee-markups/audit`,
      params: decreasedParams,
    });
  },
  bulkCreateMarkups(data: { items: mpg.api.employeeMarkups.CreateMarkup[] }): ApiResponse<WrappedAxiosItemsResult<mpg.api.employeeMarkups.EmployeeMarkup[]>> {
    return request({
      method: 'post',
      url: '/employees-markups/bulk/create',
      data,
    });
  },
};

export default employeeMarkupApi;
