import { FormattedMessage } from 'react-intl';
import { Typography } from 'antd';
import { Button, Popconfirm, Space, Tooltip } from '@/components/antd';

import { BillingType, EmployeeType } from '@/types/enums';
import {
  getColumnPresentedFields,
  getColumnSearchFields,
  getColumnTimeFields,
  getOptionsWithoutResetFilterFields,
} from '@/components/table/filters';
import { minutesToHoursDec, minutesToStringHours } from '@/utils/time';
import { timesheetDateTransformer } from '@/utils/date';
import { PERMISSION_OBJ_TIMESHEET, PERMISSION_READ } from '@/constants/permissions';
import { agencyStore, areaStore, buildingStore, shiftStore, weekTimesheetStore } from '@/stores';
import { buildFilterOptionsFromEnum, buildStoreOptions, getStorePropById, showError } from '@/utils/common';
import { ReadAction, stopPropagationHandler } from '@/components/table/actions';
import { withPermissions } from '@/hocs/permission';

import { TimesheetPageState } from '../../enums';
import { getTranslatedString } from '@/utils';
import { CheckOutlined, CloseOutlined } from '@/components/icons';
import { api } from '@/api';
import { BooleanValueRender, BillableTimeRender, TimesheetStatusRender } from '@/components/table/renders';

const PermissionedReadAction = withPermissions([[PERMISSION_OBJ_TIMESHEET, PERMISSION_READ]])(ReadAction);

export const ApproveAction = ({ onConfirm }) => (
  <Popconfirm
    onConfirm={(e) => {
      stopPropagationHandler(e);
      onConfirm();
    }}
    title={getTranslatedString('timesheet.approveActionConfirmation')}
    onCancel={stopPropagationHandler}
  >
    <Tooltip title={getTranslatedString('timesheet.approveActionTitle')}>
      <Button type="primary" danger shape="circle" icon={<CheckOutlined />} onClick={stopPropagationHandler} />
    </Tooltip>
  </Popconfirm>
);

export const UnapproveAction = ({ onConfirm }) => (
  <Popconfirm
    onConfirm={(e) => {
      stopPropagationHandler(e);
      onConfirm();
    }}
    title={getTranslatedString('timesheet.unapproveActionConfirmation')}
    onCancel={stopPropagationHandler}
  >
    <Tooltip title={getTranslatedString('timesheet.unapproveActionTitle')}>
      <Button type="primary" danger shape="circle" icon={<CloseOutlined />} onClick={stopPropagationHandler} />
    </Tooltip>
  </Popconfirm>
);

const WeekTimesheetActions = (_: any, { id, canApprove, isApproved, canUnapprove }: any) => {
  const onApprove = () => {
    api.weekTimesheet
      .approve(id)
      .source.then(() => weekTimesheetStore.refresh())
      .catch(showError);
  };
  const onUnapprove = () => {
    api.weekTimesheet
      .unapprove(id)
      .source.then(() => weekTimesheetStore.refresh())
      .catch(showError);
  };

  return (
    <Space>
      {Boolean(canApprove && !isApproved) && <ApproveAction onConfirm={onApprove} />}
      {Boolean(canUnapprove && isApproved) && <UnapproveAction onConfirm={onUnapprove} />}
      <PermissionedReadAction
        uiSref={{
          to: TimesheetPageState.DayTimesheetTable,
          params: { id },
        }}
      />
    </Space>
  );
};

export const getTimesheetColumn = (weeks: number[]) => {
  const columns: Array<
    Record<string, unknown> & {
      permissionSubject: string;
    }
  > = [
    {
      title: <FormattedMessage id="table.actions" />,
      dataIndex: 'actions',
      fixed: 'left',
      render: WeekTimesheetActions,
      permissionSubject: 'generalData',
    },
    {
      title: <FormattedMessage id="timesheet.visitorId" />,
      dataIndex: 'visitorId',
      ...getColumnSearchFields(),
      sorter: true,
      fixed: 'left',
      permissionSubject: 'employeeDetails',
    },
    {
      title: <FormattedMessage id="timesheet.firstName" />,
      dataIndex: 'firstName',
      sorter: true,
      fixed: 'left',
      ...getColumnSearchFields(),
      permissionSubject: 'employeeDetails',
    },
    {
      title: <FormattedMessage id="timesheet.lastName" />,
      dataIndex: 'lastName',
      sorter: true,
      fixed: 'left',
      ...getColumnSearchFields(),
      permissionSubject: 'employeeDetails',
    },
    {
      title: <FormattedMessage id="timesheet.isEdited" />,
      key: 'isEdited',
      dataIndex: 'isEdited',
      sorter: true,
      permissionSubject: 'generalData',
      render: (value) => BooleanValueRender(value || undefined),
      ...getColumnPresentedFields(),
    },
    {
      title: <FormattedMessage id="timesheet.employeeType" />,
      dataIndex: 'employeeType',
      ...getOptionsWithoutResetFilterFields(EmployeeType, 'timesheet.employeeType.'),
      sorter: true,
      render: (employeeType: string) => <FormattedMessage id={`timesheet.employeeType.${employeeType}`} />,
      permissionSubject: 'employeeDetails',
    },
    {
      title: <FormattedMessage id="timesheet.employeeAreaId" />,
      dataIndex: 'employeeAreaId',
      filters: buildStoreOptions(areaStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.areas.Area>(itemId, areaStore),
      permissionSubject: 'employeeDetails',
    },
    {
      title: <FormattedMessage id="timesheet.employeeBuildingId" />,
      dataIndex: 'employeeBuildingId',
      filters: buildStoreOptions(buildingStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.buildings.Building>(itemId, buildingStore),
      permissionSubject: 'employeeDetails',
    },
    {
      title: <FormattedMessage id="timesheet.shiftPlannedId" />,
      dataIndex: 'shiftPlannedId',
      filters: buildStoreOptions(shiftStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.shifts.Shift>(itemId, shiftStore),
      permissionSubject: 'shiftDetails',
    },
    {
      title: <FormattedMessage id="timesheet.middleName" />,
      dataIndex: 'middleName',
      sorter: true,
      ...getColumnSearchFields(),
      permissionSubject: 'employeeDetails',
    },

    {
      title: <FormattedMessage id="timesheet.weekOf" />,
      dataIndex: 'weekOf',
      filters: weeks.map((value: number) => ({ value, text: timesheetDateTransformer.from(value) })),
      sorter: true,
      render: (value) => timesheetDateTransformer.from(value),
      permissionSubject: 'generalData',
    },
    {
      title: <FormattedMessage id="timesheet.workingBillableTime" />,
      key: 'workingBillableTime',
      dataIndex: 'workingBillableTime',
      sorter: true,
      render: (value) => minutesToStringHours(value),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="timesheet.workingBillableTimeDec" />,
      key: 'workingBillableTimeDec',
      dataIndex: 'workingBillableTime',
      sorter: true,
      ...getColumnTimeFields(false, true),
      render: (value) => minutesToHoursDec(value),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="timesheet.transitPaidTime" />,
      dataIndex: 'transitPaidTime',
      sorter: true,
      render: (value) => BillableTimeRender(value),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="timesheet.brakePaidTime" />,
      dataIndex: 'brakePaidTime',
      sorter: true,
      render: (value) => BillableTimeRender(value),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="timesheet.bathPaidTime" />,
      dataIndex: 'bathPaidTime',
      sorter: true,
      render: (value) => BillableTimeRender(value),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="timesheet.lunchPaidTime" />,
      dataIndex: 'lunchPaidTime',
      sorter: true,
      render: (value) => BillableTimeRender(value),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="timesheet.lunchUnpaidTime" />,
      dataIndex: 'lunchUnpaidTime',
      sorter: true,
      render: (value) => BillableTimeRender(value),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="timesheet.totalAdditionTime" />,
      key: 'totalAdditionTime',
      dataIndex: 'totalAdditionTime',
      sorter: true,
      render: (value) => (value >= 0 ? minutesToStringHours(value) : `-${minutesToStringHours(-value)}`),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="timesheet.totalAdditionTimeDec" />,
      key: 'totalAdditionTimeDec',
      dataIndex: 'totalAdditionTime',
      ...getColumnTimeFields(false, true),
      sorter: true,
      render: (value) => minutesToHoursDec(value),
      permissionSubject: 'times',
    },
    {
      title: (
        <Typography.Text strong>
          <FormattedMessage id="timesheet.totalBillableTime" />
        </Typography.Text>
      ),
      key: 'totalBillableTime',
      dataIndex: 'totalBillableTime',
      sorter: true,
      render: (value) => (
        <Typography.Text strong style={{ fontSize: '16px' }}>
          {minutesToStringHours(value)}
        </Typography.Text>
      ),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="timesheet.totalBillableTimeDec" />,
      key: 'totalBillableTimeDec',
      dataIndex: 'totalBillableTime',
      ...getColumnTimeFields(false, true),
      sorter: true,
      render: (value) => minutesToHoursDec(value),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="timesheet.baseBillableTime" />,
      dataIndex: 'baseBillableTime',
      sorter: true,
      render: (value) => minutesToStringHours(value),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="timesheet.overtimeTime" />,
      key: 'overtimeTime',
      dataIndex: 'overtimeTime',
      sorter: true,
      render: (value) => minutesToStringHours(value),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="timesheet.overtimeTimeDec" />,
      key: 'overtimeTimeDec',
      dataIndex: 'overtimeTime',
      ...getColumnTimeFields(false, true),
      sorter: true,
      render: (value) => minutesToHoursDec(value),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="timesheet.baseRate" />,
      dataIndex: 'baseRate',
      sorter: true,
      render: (value) => `${Math.round(value * 100) / 100} $`,
      permissionSubject: 'billingData',
    },
    {
      title: <FormattedMessage id="timesheet.overtimeRate" />,
      dataIndex: 'overtimeRate',
      sorter: true,
      render: (value) => `${Math.round(value * 100) / 100} $`,
      permissionSubject: 'billingData',
    },
    {
      title: <FormattedMessage id="timesheet.agencyId" />,
      dataIndex: 'agencyId',
      filters: buildStoreOptions(agencyStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.agencies.Agency>(itemId, agencyStore),
      permissionSubject: 'agencyDetails',
    },
    {
      title: <FormattedMessage id="timesheet.agencyInternalId" />,
      dataIndex: 'agencyInternalId',
      ...getColumnSearchFields(),
      sorter: true,
      permissionSubject: 'agencyDetails',
    },
    {
      title: <FormattedMessage id="timesheet.baseBillingAmount" />,
      dataIndex: 'baseBillingAmount',
      sorter: true,
      render: (value) => `${Math.round(value * 100) / 100} $`,
      permissionSubject: 'billingData',
    },
    {
      title: <FormattedMessage id="timesheet.overtimeBillingAmount" />,
      dataIndex: 'overtimeBillingAmount',
      sorter: true,
      render: (value) => `${Math.round(value * 100) / 100} $`,
      permissionSubject: 'billingData',
    },
    {
      title: <FormattedMessage id="timesheet.totalAmount" />,
      dataIndex: 'totalAmount',
      sorter: true,
      permissionSubject: 'billingData',
      render: (value) => `${Math.round(value * 100) / 100} $`,
    },
    {
      title: <FormattedMessage id="billingType" />,
      dataIndex: 'billingType',
      filters: buildFilterOptionsFromEnum(BillingType, 'billingType.'),
      sorter: true,
      permissionSubject: 'employeeDetails',
      render: (value: string) => <FormattedMessage id={`billingType.${value}`} />,
    },
    {
      title: <FormattedMessage id="timesheet.approveStatus" />,
      key: 'approveStatus',
      dataIndex: 'isApproved',
      sorter: true,
      permissionSubject: 'generalData',
      render: (value) => TimesheetStatusRender(value),
      ...getColumnPresentedFields(),
    },
    {
      title: <FormattedMessage id="timesheet.approverName" />,
      dataIndex: 'approverName',
      sorter: true,
      permissionSubject: 'generalData',
      render: (value) => value,
      ...getColumnSearchFields(),
    },
  ];

  return columns.map(({ permissionSubject, ...props }) => ({
    ...props,
    permissions: [
      [PERMISSION_OBJ_TIMESHEET, PERMISSION_READ],
      [`${PERMISSION_OBJ_TIMESHEET}.${permissionSubject}`, PERMISSION_READ],
    ],
  }));
};
