import { UIViewInjectedProps } from '@uirouter/react';
import { PTOs } from '@/pages/common/components/ptos/components/table';
import { PERMISSION_OBJ_AGENCY_PTO } from '@/constants/permissions';

const entity = 'agency-ptos';
const pagePermission = PERMISSION_OBJ_AGENCY_PTO;

export const AgencyPtoTable = ({ transition }: UIViewInjectedProps) => (
  <PTOs entity={entity} pagePermission={pagePermission} transition={transition} />
);
