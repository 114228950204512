import { PERMISSION_CREATE, PERMISSION_READ, PERMISSION_UPDATE } from '@/constants/permissions';
import { authStore } from '@/stores';

import { FormBuilderOptionItem } from './index';

export const getAllowedFormFields = (
  setup: FormBuilderOptionItem[],
  isCreate: boolean,
  obj: string,
  excludedFields: string[] = [],
  relatedObj: string = '',
): FormBuilderOptionItem[] => {
  const permission: number = isCreate ? PERMISSION_CREATE : PERMISSION_UPDATE;

  return setup.map((field: FormBuilderOptionItem) => {
    const permissionField: string = `${obj}.${field.name}`;
    const permissionRead = authStore.hasPermissions([[permissionField, PERMISSION_READ]]);
    const permissionModify = authStore.hasPermissions([[permissionField, permission]]);
    const condPermissionRender: boolean = isCreate ? permissionModify : permissionModify || permissionRead;

    let condPermissionRenderRelated: boolean;
    if (relatedObj.length) {
      const permissionFieldRelated: string = `${relatedObj}.${field.name}`;
      const permissionReadRelated = authStore.hasPermissions([[permissionFieldRelated, PERMISSION_READ]]);
      const permissionModifyRelated = authStore.hasPermissions([[permissionFieldRelated, permission]]);
      condPermissionRenderRelated = isCreate ? permissionModifyRelated : permissionModifyRelated || permissionReadRelated;
    }

    if (excludedFields.includes(field.name)) {
      return field;
    }

    const disabled = (field.params.hasOwnProperty('disabled') && field.params.disabled)
      || (permissionRead && !permissionModify);

    let disabledWithRules;
    if (field.params.hasOwnProperty('disabledWithRules')) {
      disabledWithRules = field.params.disabledWithRules;
      delete field.params.disabledWithRules;
    }

    return {
      ...field,
      rules: disabled ? disabledWithRules ? field.rules : [] : field.rules,
      params: {
        ...field.params,
        // readOnly: (field.params.hasOwnProperty('readOnly') && field.params.readOnly)
        //   || (permissionRead && !permissionModify),
        disabled,
      },
      conditionalRender: (values: any) => (field.hasOwnProperty('conditionalRender')
        ? (field.conditionalRender(values) && condPermissionRender) || (field.conditionalRender(values) && condPermissionRenderRelated)
        : condPermissionRender || condPermissionRenderRelated),
    };
  });
};
