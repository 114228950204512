import { UIView, UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState, useRef } from 'react';
import { TableRowSelection } from 'antd/lib/table/interface';
import { Button, PageHeader, Dropdown, Menu, Modal, notification } from '@/components/antd';
import { DownOutlined, SettingOutlined, PrinterOutlined, SyncOutlined } from '@/components/icons';

import { Table } from '@/components/table';
import {
  PERMISSION_CREATE,
  PERMISSION_DELETE,
  PERMISSION_OBJ_EMPLOYEE,
  PERMISSION_OBJ_EMPLOYEE_MARKUP,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import {
  agencyStore,
  areaStore,
  breaktimeStore,
  buildingStore,
  colorStore,
  employeeStore,
  languageStore,
  linesStore,
  musterStationsStore,
  musterStationCaptainsStore,
  positionStore,
  shiftStore,
  supervisorStore,
  searchStore,
  approversStore,
  authStore,
} from '@/stores';
import { useMassUpdateCashedStores } from '@/utils/store';
import { onPrint, showError } from '@/utils/common';
import { api } from '@/api';
import { getTranslatedString } from '@/utils';

import { getColumns } from './setup';
import { useEmployeeBulkCreate } from './hooks';
import { DropdownExport } from '@/pages/common/components/employees/components/table/dropdown-export';
import ExportButton from '@/pages/common/components/buttons/export-button';
import {
  BulkAddRateButton,
  BulkCreateButton,
  BulkDeleteButton,
  BulkUpdateButton,
  CreateButton,
} from '@/pages/common/components/ptos/components/buttons';

const store = employeeStore;

interface IProps extends UIViewInjectedProps {
  entity: string;
  permission: string;
}

export const Employees = observer(({ transition, entity, permission }: IProps) => {
  searchStore.setSearchParams({
    entity: 'employees',
    isShowHeaderSearch: true,
  });
  const isCoordinatorPage = entity === 'coordinator-employees';
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [fwLoading, setFwLoading] = useState<boolean>(false);
  const tableRef: any = useRef({});
  const { items } = store;

  useEffect(() => {
    musterStationCaptainsStore.reset();
  }, []);

  const { loading } = useMassUpdateCashedStores([
    agencyStore,
    areaStore,
    breaktimeStore,
    buildingStore,
    colorStore,
    languageStore,
    linesStore,
    musterStationsStore,
    musterStationCaptainsStore,
    positionStore,
    shiftStore,
    supervisorStore,
    approversStore,
  ]);

  const { importRequest, parseSettings, storeRefreshRequest } = useEmployeeBulkCreate(isCoordinatorPage);

  const onAdd = () => {
    transition.router.stateService.go(`base-layout.${entity}.create`);
  };

  const onDoubleClick = (record) => {
    transition.router.stateService.go(`base-layout.${entity}.edit`, { employeeId: record.id });
  };

  const onRowSelectionChange = (currentSelectedRowKeys: string[]) => {
    setSelectedRowKeys(currentSelectedRowKeys);
  };

  const onFwSync = () => {
    setFwLoading(true);
    return api.friendlyWay
      .export({ limit: store.count })
      .source.then((data: mpg.api.friendlyWay.Response) => {
        notification.info({
          message: 'Result',
          description: (
            <div>
              <p>
                Errors Count:
                {data.errorsCount}
              </p>
              <p>
                Exported Count:
                {data.exportedCount}
              </p>
            </div>
          ),
          duration: 10,
        });
      })
      .catch(showError)
      .finally(() => setFwLoading(false));
  };

  const rowSelection: TableRowSelection<any> = {
    // preserveSelectedRowKeys: true,
    onChange: onRowSelectionChange,
  };

  const onBulkUpdate = () => {
    transition.router.stateService.go(`base-layout.${entity}.bulk-edit`, { selectedRowKeys });
  };
  const onBulkAddRate = () => {
    transition.router.stateService.go(`base-layout.${entity}.bulk-add-rate`, { selectedRowKeys });
  };
  const onBulkDelete = () => {
    const onOk = () =>
      api.employees
        .bulkDelete({ ids: selectedRowKeys })
        .source.then(() => employeeStore.refresh())
        .catch(showError);

    Modal.confirm({
      title: getTranslatedString('common.warning'),
      content: getTranslatedString('employees.delete-warning-desc'),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk,
    });
  };

  const batchMenu = (
    <Menu>
      <BulkUpdateButton
        permissions={[
          [permission, PERMISSION_READ],
          [PERMISSION_OBJ_EMPLOYEE, PERMISSION_UPDATE],
        ]}
        onClick={onBulkUpdate}
      />
      <BulkAddRateButton
        permissions={[
          [permission, PERMISSION_READ],
          [PERMISSION_OBJ_EMPLOYEE, PERMISSION_CREATE],
        ]}
        onClick={onBulkAddRate}
      />
      <BulkDeleteButton
        permissions={[
          [permission, PERMISSION_READ],
          [PERMISSION_OBJ_EMPLOYEE, PERMISSION_DELETE],
        ]}
        onClick={onBulkDelete}
      />
    </Menu>
  );

  return (
    <>
      <PageHeader
        className="r-antd-page-header"
        extra={[
          <Dropdown key="dropdown" overlay={batchMenu} disabled={!selectedRowKeys.length}>
            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              Batch Actions <DownOutlined />
            </a>
          </Dropdown>,
          <Button key="print" size="middle" icon={<PrinterOutlined />} onClick={onPrint} />,
          <Button
            key="setting"
            icon={<SettingOutlined />}
            size="middle"
            onClick={tableRef.current.showSettingsModal}
          />,
          isCoordinatorPage && (
            <Button
              key="fw"
              icon={<SyncOutlined />}
              shape="round"
              loading={fwLoading}
              type="primary"
              onClick={onFwSync}
            >
              Sync
            </Button>
          ),
          <CreateButton
            key="create button"
            onClick={onAdd}
            i18nId="employees.add-new"
            permissions={[
              [permission, PERMISSION_READ],
              [PERMISSION_OBJ_EMPLOYEE, PERMISSION_CREATE],
            ]}
          />,
          authStore.hasPermissions([[PERMISSION_OBJ_EMPLOYEE_MARKUP, PERMISSION_READ]]) ? (
            <DropdownExport store={employeeStore} />
          ) : (
            <ExportButton
              store={store}
              exportToExcel={api.employees.exportToExcel}
              withPermission={withPermissions([[permission, PERMISSION_READ]])}
              fileName="employees"
            />
          ),
          <BulkCreateButton
            key="bulk create button"
            request={importRequest}
            settings={parseSettings}
            storeRefreshRequest={storeRefreshRequest}
            permissions={[
              [permission, PERMISSION_READ],
              [PERMISSION_OBJ_EMPLOYEE, PERMISSION_CREATE],
            ]}
          />,
        ]}
      />
      <Table
        tableRef={tableRef}
        onRow={(record) => ({
          onDoubleClick: () => onDoubleClick(record),
        })}
        localStorageKey={`${entity}-table`}
        rowSelection={rowSelection}
        rowKey="id"
        columns={getColumns(permission, entity)}
        dataSource={[...items]}
        store={store}
        checkPermissions
        permissionObj={PERMISSION_OBJ_EMPLOYEE}
        loading={store.loading || loading}
        scroll={{ y: '100%' }}
        isAutoloadAllowed={!loading}
        initialSorters={{
          columnKey: 'lastName',
          field: 'lastName',
          order: 'ascend',
        }}
      />
      <UIView />
    </>
  );
});
