import { showError } from '@/utils/common';
import { getTranslatedString } from '@/utils';
import { api } from '@/api';
import { Modal, notification } from '@/components/antd';
import { authStore, weekTimesheetStore } from '@/stores';
import {
  TIMESHEET_PERMISSION_APPROVE_ALL,
  TIMESHEET_PERMISSION_APPROVE_OWN,
  TIMESHEET_PERMISSION_UNAPPROVE_ALL,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { WrappedItemsResult } from '@/api/types';
import { QuestionCircleOutlined } from '@/components/icons';

export const useTimesheetBulkActions = ({
  selectedRowKeys,
  resetSelection,
}: {
  selectedRowKeys: string[];
  resetSelection: () => void;
}) => {
  const canApprove =
    authStore.hasPermissions([[TIMESHEET_PERMISSION_APPROVE_ALL, PERMISSION_UPDATE]]) ||
    authStore.hasPermissions([[TIMESHEET_PERMISSION_APPROVE_OWN, PERMISSION_UPDATE]]);

  const canUnapprove = authStore.hasPermissions([[TIMESHEET_PERMISSION_UNAPPROVE_ALL, PERMISSION_UPDATE]]);

  const wrapAction = (
    action: { source: Promise<WrappedItemsResult<mpg.api.weekTimesheet.WeekTimesheet>> },
    messageKey: string,
  ) => {
    action.source
      .then((data) => {
        notification.info({
          message: getTranslatedString('timesheet.approveResultTitle'),
          description: getTranslatedString(messageKey, {
            count: selectedRowKeys.length,
            resultCount: data?.data?.count,
          }),
          duration: 10,
        });
      })
      .then(resetSelection)
      .then(() => weekTimesheetStore.refresh())
      .catch(showError);
  };

  const bulkApprove = () =>
    wrapAction(api.weekTimesheet.bulkApprove({ ids: selectedRowKeys }), 'timesheet.bulkApproveResult');

  const bulkUnapprove = () =>
    wrapAction(api.weekTimesheet.bulkUnapprove({ ids: selectedRowKeys }), 'timesheet.bulkUnapproveResult');

  const showConfirmationModal = (titleKey, contentKey, onOk) =>
    Modal.confirm({
      icon: <QuestionCircleOutlined />,
      title: getTranslatedString(titleKey),
      content: getTranslatedString(contentKey, { count: selectedRowKeys.length }),
      okText: getTranslatedString('modal.yes'),
      okType: 'primary',
      cancelText: getTranslatedString('modal.no'),
      onOk,
    });

  const onBulkApprove = () =>
    showConfirmationModal('timesheet.bulkApproveTitle', 'timesheet.bulkApproveDescription', bulkApprove);

  const onBulkUnapprove = () =>
    showConfirmationModal('timesheet.bulkUnapproveTitle', 'timesheet.bulkUnapproveDescription', bulkUnapprove);

  return {
    onBulkApprove,
    onBulkUnapprove,
    canApprove,
    canUnapprove,
    bulkApprove,
    bulkUnapprove,
  };
};
