import {
  PERMISSION_BUILDING_HOST,
  PERMISSION_READ,
  PERMISSION_CREATE,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { BuildingPageState } from '@/pages/building-host/enums';
import { BuildingHost } from '@/pages/building-host/components/table';
import { Form } from '@/pages/building-host/components/form';

export const states = [
  {
    name: BuildingPageState.BaseLayout,
    url: '/building-host',
    component: BuildingHost,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_BUILDING_HOST, PERMISSION_READ]],
    },
  },
  {
    name: BuildingPageState.Create,
    url: '/create',
    component: Form,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_BUILDING_HOST, PERMISSION_CREATE]],
    },
  },
  {
    name: BuildingPageState.Update,
    url: '/:buildingHostId/update',
    component: Form,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_BUILDING_HOST, PERMISSION_UPDATE]],
    },
  },
];
