import {
  getFormattedParams,
  request,
  requestTo,
  getParamsWithMappedSort,
  checkParamsWithStartEndFields,
} from '@/utils/request';
import { ApiResponse, WrappedResult } from './types';

const prepareGetAllParams = (params?: mpg.api.shiftReports.GetParams) => {
  const { employeeType, ...paramsWithoutEmployeeType } = params;
  const paramsMap = {
    shiftAssigned: 'shiftStart',
  };
  const paramsWithMappedSort = getParamsWithMappedSort(paramsWithoutEmployeeType, paramsMap);
  return {
    employeeType,
    ...getFormattedParams(paramsWithMappedSort, true),
  };
};

const prepareGetAttendanceAllParams = (params?: mpg.api.shiftReports.GetParams) => {
  const { employeeType, ...paramsWithoutEmployeeType } = params;
  const newParams = checkParamsWithStartEndFields(paramsWithoutEmployeeType);
  return {
    employeeType,
    ...getFormattedParams(newParams, true),
  };
};

export default {
  getAll(params0?: mpg.api.shiftReports.GetParams): ApiResponse<WrappedResult<mpg.api.shiftReports.ShiftReport[]>> {
    return requestTo('shift-reports', params0, '', prepareGetAllParams);
  },
  getPermanentEmployeesShiftReports(
    params?: mpg.api.shiftReports.GetParams,
  ): ApiResponse<WrappedResult<mpg.api.shiftReports.ShiftReport[]>> {
    return requestTo(
      'shift-reports',
      {
        employeeType: 'permanent',
        ...params,
      },
      '',
      prepareGetAllParams,
    );
  },
  getTemporaryEmployeesShiftReports(
    params?: mpg.api.shiftReports.GetParams,
  ): ApiResponse<WrappedResult<mpg.api.shiftReports.ShiftReport[]>> {
    return requestTo(
      'shift-reports',
      {
        employeeType: 'temporary',
        ...params,
      },
      '',
      prepareGetAllParams,
    );
  },
  get(id: mpg.api.shiftReports.ShiftReportId): ApiResponse<WrappedResult<mpg.api.shiftReports.ShiftReport>> {
    return request({
      method: 'get',
      url: `shift-reports/${id}`,
    });
  },
  exportToExcel(params0?: mpg.api.shiftReports.GetParams): ApiResponse<WrappedResult<Blob>> {
    return requestTo('shift-reports', params0, 'export', prepareGetAllParams);
  },
  exportToEmail(params0?: mpg.api.shiftReports.GetParams): ApiResponse<WrappedResult<Blob>> {
    return requestTo('shift-reports', params0, 'export-by-link', prepareGetAllParams);
  },
  exportToExcelPermanentEmployeesShiftReports(
    params?: mpg.api.shiftReports.GetParams,
  ): ApiResponse<WrappedResult<Blob>> {
    return requestTo(
      'shift-reports',
      {
        employeeType: 'permanent',
        ...params,
      },
      'export',
      prepareGetAllParams,
    );
  },
  exportToEmailPermanentEmployeesShiftReports(
    params?: mpg.api.shiftReports.GetParams,
  ): ApiResponse<WrappedResult<Blob>> {
    return requestTo(
      'shift-reports',
      {
        employeeType: 'permanent',
        ...params,
      },
      'export-by-link',
      prepareGetAllParams,
    );
  },
  exportToExcelTemporaryEmployeesShiftReports(
    params?: mpg.api.shiftReports.GetParams,
  ): ApiResponse<WrappedResult<Blob>> {
    return requestTo(
      'shift-reports',
      {
        employeeType: 'temporary',
        ...params,
      },
      'export',
      prepareGetAllParams,
    );
  },
  exportToEmailTemporaryEmployeesShiftReports(
    params?: mpg.api.shiftReports.GetParams,
  ): ApiResponse<WrappedResult<Blob>> {
    return requestTo(
      'shift-reports',
      {
        employeeType: 'temporary',
        ...params,
      },
      'export-by-link',
      prepareGetAllParams,
    );
  },
  getAttendanceAll(
    params?: mpg.api.shiftReports.AttendanceGetParams,
  ): ApiResponse<WrappedResult<mpg.api.shiftReports.Attendance>> {
    return requestTo('shift-reports/attendance', params, '', prepareGetAttendanceAllParams);
  },
  getTemporaryEmployeesAttendance(
    params?: mpg.api.shiftReports.AttendanceGetParams,
  ): ApiResponse<WrappedResult<mpg.api.shiftReports.Attendance>> {
    return requestTo('shift-reports/attendance', {...params, employeeType: 'temporary'}, '', prepareGetAttendanceAllParams);
  },
  getPermanentEmployeesAttendance(
    params?: mpg.api.shiftReports.AttendanceGetParams,
  ): ApiResponse<WrappedResult<mpg.api.shiftReports.Attendance>> {
    return requestTo('shift-reports/attendance', {...params, employeeType: 'permanent'}, '', prepareGetAttendanceAllParams);
  },
  attendanceExportToExcel(params?: mpg.api.shiftReports.AttendanceExportParams): ApiResponse<WrappedResult<Blob>> {
    return requestTo('shift-reports/attendance', params, 'export', prepareGetAttendanceAllParams);
  },
  attendanceExportToEmail(params?: mpg.api.shiftReports.AttendanceExportParams) {
    return requestTo('shift-reports/attendance', params, 'export-by-link', prepareGetAttendanceAllParams);
  },
  exportToExcelPermanentEmployeesAttendanceReport(
    params?: mpg.api.shiftReports.AttendanceExportParams,
  ): ApiResponse<WrappedResult<Blob>> {
    return requestTo(
      'shift-reports/attendance',
      {
        employeeType: 'permanent',
        ...params,
      },
      'export',
      prepareGetAttendanceAllParams,
    );
  },
  exportToEmailPermanentEmployeesAttendanceReport(
    params?: mpg.api.shiftReports.AttendanceExportParams,
  ): ApiResponse<WrappedResult<Blob>> {
    return requestTo(
      'shift-reports/attendance',
      {
        employeeType: 'permanent',
        ...params,
      },
      'export-by-link',
      prepareGetAttendanceAllParams,
    );
  },
  exportToExcelTemporaryEmployeesAttendanceReport(
    params?: mpg.api.shiftReports.AttendanceExportParams,
  ): ApiResponse<WrappedResult<Blob>> {
    return requestTo(
      'shift-reports/attendance',
      {
        employeeType: 'temporary',
        ...params,
      },
      'export',
      prepareGetAttendanceAllParams,
    );
  },
  exportToEmailTemporaryEmployeesAttendanceReport(
    params?: mpg.api.shiftReports.AttendanceExportParams,
  ): ApiResponse<WrappedResult<Blob>> {
    return requestTo(
      'shift-reports/attendance',
      {
        employeeType: 'temporary',
        ...params,
      },
      'export-by-link',
      prepareGetAttendanceAllParams,
    );
  },
  getAttendance(
    params: mpg.api.shiftReports.AttendanceByKeyGetParams,
  ): ApiResponse<WrappedResult<mpg.api.shiftReports.ShiftReport>> {
    const newParams = checkParamsWithStartEndFields(params);

    return request({
      method: 'get',
      url: '/shift-reports/attendance/byKey',
      params: newParams,
    });
  },
};
