import {
  getFormattedParams,
  request,
  paramsWithTimeZone,
  toQueryFilter
} from '@/utils/request';

import { ApiResponse, WrappedItemsResult, WrappedResult } from './types';
import { EmployeeType } from "@/types/enums";



const prepareGetAllParams = (params?: mpg.api.shiftPlanning.GetParams) => {
  const { query, filterParams } = toQueryFilter(params, EmployeeType.Permanent);
  const formattedParams = getFormattedParams(filterParams, true);
  return { query, formattedParams };
};

export default {
  getAll(params?: mpg.api.shiftPlanning.GetParams): ApiResponse<WrappedResult<mpg.api.shiftPlanning.ShiftPlanning[]>> {
    const { query, formattedParams } = prepareGetAllParams(params);
    return request({
      method: 'get',
      url: `/events-calendar?${query}`,
      params: {
        ...formattedParams,
      },
    });
  },

  get(id: mpg.api.shiftPlanning.ShiftPlanningId): ApiResponse<WrappedResult<mpg.api.shiftPlanning.ShiftPlanning>> {
    return request({
      method: 'get',
      url: `/events-calendar/${id}`,
    });
  },
  create(
    data: mpg.api.shiftPlanning.ShiftPlanningParams,
  ): ApiResponse<WrappedResult<mpg.api.shiftPlanning.ShiftPlanning>> {
    return request({
      method: 'post',
      url: '/events-calendar',
      data,
    });
  },
  update(
    id: number,
    data: mpg.api.shiftPlanning.ShiftPlanningParams,
  ): ApiResponse<WrappedResult<mpg.api.shiftPlanning.ShiftPlanning>> {
    return request({
      method: 'patch',
      url: `/events-calendar/${id}`,
      data,
    });
  },
  delete(id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/events-calendar/${id}`,
    });
  },
  bulkCreate(data: {
    items: mpg.api.shiftPlanning.ShiftPlanningParams[];
  }): ApiResponse<WrappedResult<mpg.api.shiftPlanning.ShiftPlanning[]>> {
    return request({
      method: 'post',
      url: '/events-calendar/bulk/create',
      data,
    });
  },
  bulkUpdate(data: {
    items: mpg.api.shiftPlanning.ShiftPlanning[];
  }): ApiResponse<WrappedItemsResult<mpg.api.shiftPlanning.ShiftPlanning[]>> {
    return request({
      method: 'post',
      url: '/events-calendar/bulk/update',
      data,
    });
  },
  bulkDelete(data: { ids: string[] }): ApiResponse<WrappedResult<mpg.api.shiftPlanning.ShiftPlanning>> {
    return request({
      method: 'post',
      url: '/events-calendar/bulk/delete',
      data,
    });
  },
  exportToExcel(params?: mpg.api.shiftPlanning.GetParams): ApiResponse<WrappedResult<Blob>> {
    const { query, formattedParams } = prepareGetAllParams(params);

    return request({
      method: 'post',
      url: `/events-calendar/export?${query}`,
      ...paramsWithTimeZone({ ...formattedParams }),
      config: {
        responseType: 'blob',
      },
    });
  },
  exportToEmail(params?: mpg.api.shiftPlanning.GetParams): ApiResponse<WrappedResult<Blob>> {
    const { query, formattedParams } = prepareGetAllParams(params);

    return request({
      method: 'post',
      url: `/events-calendar/export-by-link?${query}`,
      ...paramsWithTimeZone({ ...formattedParams }),
    });
  },
};
