export const getFormFields = ({
  isTransitTimeNull,
  isBrakePaidTimeNull,
  isBathPaidTimeNull,
  isLunchPaidTimeNull,
  isLunchUnpaidTimeNull,
}) => [
  {
    name: 'workingBillableCorrectedTime',
    componentName: 'timepicker',
    params: {
      i18nLabel: 'timesheet.form.workingBillableTime',
      min: 0,
    },
    rules: [{ required: true }],
  },
  {
    name: 'transitPaidCorrectedTime',
    componentName: 'timepicker',
    params: {
      i18nLabel: 'timesheet.form.transitPaidTime',
      min: 0,
      disabled: isTransitTimeNull === null,
    },
    rules: [{ required: isTransitTimeNull !== null }],
  },
  {
    name: 'brakePaidCorrectedTime',
    componentName: 'timepicker',
    params: {
      i18nLabel: 'timesheet.form.brakePaidTime',
      min: 0,
      disabled: isBrakePaidTimeNull === null,
    },
    rules: [{ required: isBrakePaidTimeNull !== null }],
  },
  {
    name: 'lunchPaidCorrectedTime',
    componentName: 'timepicker',
    params: {
      i18nLabel: 'timesheet.form.lunchPaidTime',
      disabled: isLunchPaidTimeNull === null,
      min: 0,
    },
    rules: [{ required: isLunchPaidTimeNull !== null }],
  },
  {
    name: 'lunchUnpaidCorrectedTime',
    componentName: 'timepicker',
    params: {
      i18nLabel: 'timesheet.form.lunchUnpaidTime',
      min: 0,
      disabled: isLunchUnpaidTimeNull === null,
    },
    rules: [{ required: isLunchUnpaidTimeNull !== null }],
  },
  {
    name: 'bathPaidCorrectedTime',
    componentName: 'timepicker',
    params: {
      i18nLabel: 'timesheet.form.bathPaidTime',
      min: 0,
      disabled: isBathPaidTimeNull === null,
    },
    rules: [{ required: isBathPaidTimeNull !== null }],
  },
  {
    name: 'correctionDescription',
    componentName: 'textarea',
    params: {
      i18nLabel: 'timesheet.form.correctionDescription',
    },
    rules: [{ required: true }],
  },
];
