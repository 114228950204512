import { request } from '@/utils/request';
import { ApiResponse, Result, WrappedResult } from './types';

export default {
  get(params?: any): ApiResponse<WrappedResult<mpg.api.settings.SettingsParams>> {
    return request({
      method: 'get',
      url: '/settings',
      params,
    });
  },
  set(data: mpg.api.settings.SetSettingsParams): ApiResponse<Result> {
    return request({
      method: 'post',
      url: '/settings/set',
      data,
    });
  },
};
