import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import {
  Button, Col, Input, Row, Select,
} from '@/components/antd';
import { withStores } from '@/components/stores-provider';
import { getResponseError } from '@/pages/auth/handlers';
import { authStore, settingsStore } from '@/stores';
import { getTranslatedString } from '@/utils';

const twoFactorAuthTypes = [
  {
    value: 'code',
    label: 'code',
    disabled: true,
  },
  {
    value: 'email',
    label: 'email',
  },
  {
    value: 'sms',
    label: 'sms',
    disabled: true,
  },
];

export const TwoFactorAuth = withStores('authStore', 'settingsStore')(
  observer((
    {
      authStore: {
        profile: {
          email,
        },
      },
      settingsStore: {
        isTwoFactorAuthEnabled,
      },
    }: any,
  ) => {
    const [state, setState] = useState({
      selected2faType: '',
      password: '',
      phone: '',
      message: '',
      code: '',
      isSuccessful: false,
      error: '',
    });

    const setStateValue = (key: string, value: string | boolean) => {
      setState((prev) => ({ ...prev, [key]: value }));
    };

    const isTwoFactorSMS = state.selected2faType === 'sms';
    const isActivateButtonDisabled = !state.password || !state.selected2faType || isTwoFactorSMS ? !state.phone : null;

    const onActivate2faClick = async () => {
      try {
        const response = await authStore.activate2fa({
          email, password: state.password, authType: state.selected2faType, phoneNumber: isTwoFactorSMS ? `+${state.phone}` : '',
        });
        const { message, success } = response;
        setStateValue('message', message);
        setStateValue('isSuccessful', success);
      } catch (err) {
        setStateValue('error', getResponseError(err));
      }
    };

    const onDeactivate2faClick = async () => {
      try {
        const response = await authStore.deactivate2fa({ email, password: state.password });
        const { message, success } = response;
        setStateValue('message', message);
        setStateValue('isSuccessful', success);
      } catch (err) {
        setStateValue('error', getResponseError(err));
      }
    };

    const onSendActivateCodeClick = async () => {
      try {
        const response = await authStore.turnOn2fa({ twoFactorAuthCode: state.code });

        setStateValue('code', '');
        setStateValue('password', '');
        await settingsStore.loadSettings();

        if (response) {
          setStateValue('message', '2FA successful activated');
        }
      } catch (err) {
        setStateValue('error', getResponseError(err));
      }
    };

    const onSendDeactivateCodeClick = async () => {
      try {
        const response = await authStore.turnOff2fa({ twoFactorAuthCode: state.code });

        setStateValue('code', '');
        setStateValue('password', '');
        setStateValue('selected2faType', '');
        await settingsStore.loadSettings();

        if (response) {
          setStateValue('message', '2FA successful deactivated');
        }
      } catch (err) {
        setStateValue('error', getResponseError(err));
      }
    };

    const onSelect2faTypeChange = (value) => setStateValue('selected2faType', value);
    const onPasswordChange = (event) => setStateValue('password', event.target.value);
    const onPhoneChange = (event) => setStateValue('phone', event.target.value);
    const onCodeChange = (event) => setStateValue('code', event.target.value);

    return (
      <>
        {
          isTwoFactorAuthEnabled
            ? (
              <>
                <Row align="middle" className="profile-row">
                  <Col flex="0 0 100px">
                    {getTranslatedString('2fa.put-password')}
                    :
                  </Col>

                  <Col flex="0 0 190px">
                    <Input.Password
                      name="password"
                      type="password"
                      id="password"
                      value={state.password}
                      onChange={onPasswordChange}
                    />
                  </Col>

                  <Col flex="0 0 90px">
                    <Button onClick={onDeactivate2faClick} type="primary" disabled={!state.password}>
                      {getTranslatedString('2fa.button.deactivate')}
                    </Button>
                  </Col>
                </Row>

                {
                  isTwoFactorAuthEnabled && state.isSuccessful
                  && (
                    <>
                      <Row align="middle" className="profile-row">
                        <Col flex="0 0 100px">
                          {getTranslatedString('2fa-type.code')}
                          :
                        </Col>

                        <Col flex="0 0 190px">
                          <Input
                            name="code"
                            type="text"
                            id="code"
                            value={state.code}
                            onChange={onCodeChange}
                          />
                        </Col>

                        <Col flex="0 0 90px">
                          <Button onClick={onSendDeactivateCodeClick} type="primary" disabled={!state.code}>
                            {getTranslatedString('2fa.button.send-code')}
                          </Button>
                        </Col>
                      </Row>

                      <Row className="auth-description">{state.message}</Row>
                    </>
                  )
                }
              </>
            )
            : (
              <>
                <Row align="middle" className="profile-row">
                  <Col flex="0 0 100px">
                    {getTranslatedString('2fa.select-type')}
                    :
                  </Col>

                  <Col flex="0 0 190px">
                    <Select
                      style={{
                        width: 190,
                      }}
                      options={twoFactorAuthTypes}
                      placeholder="select type"
                      onChange={onSelect2faTypeChange}
                      allowClear
                      showSearch
                    />
                  </Col>
                </Row>

                {
                  state.selected2faType === 'sms'
                  && (
                    <Row align="middle" className="profile-row">
                      <Col flex="0 0 100px">
                        {getTranslatedString('2fa.put-phone')}
                        :
                      </Col>

                      <Col flex="0 0 190px">
                        <Input
                          prefix="+"
                          name="phone"
                          type="text"
                          id="phone"
                          value={state.phone}
                          onChange={onPhoneChange}
                        />
                      </Col>
                    </Row>
                  )
                }

                <Row align="middle" className="profile-row">
                  <Col flex="0 0 100px">
                    {getTranslatedString('2fa.put-password')}
                    :
                  </Col>

                  <Col flex="0 0 190px">
                    <Input.Password
                      name="password"
                      type="password"
                      id="password"
                      value={state.password}
                      onChange={onPasswordChange}
                    />
                  </Col>

                  <Col flex="0 0 90px">
                    <Button onClick={onActivate2faClick} type="primary" disabled={isActivateButtonDisabled}>
                      {getTranslatedString('2fa.button.activate')}
                    </Button>
                  </Col>
                </Row>

                {
                  !isTwoFactorAuthEnabled && state.isSuccessful
                  && (
                    <>
                      <Row align="middle" className="profile-row">
                        <Col flex="0 0 100px">
                          {getTranslatedString('2fa-type.code')}
                          :
                        </Col>

                        <Col flex="0 0 190px">
                          <Input
                            name="code"
                            type="text"
                            id="code"
                            value={state.code}
                            onChange={onCodeChange}
                          />
                        </Col>

                        <Col flex="0 0 90px">
                          <Button onClick={onSendActivateCodeClick} type="primary" disabled={!state.code}>
                            {getTranslatedString('2fa.button.send-code')}
                          </Button>
                        </Col>
                      </Row>

                      <Row className="auth-message">{state.message}</Row>
                    </>
                  )
                }
              </>
            )
        }

        {
          state.error
            ? <Row className="auth-error-text">{state.error}</Row>
            : null
        }
      </>
    );
  }),
);
