import React from 'react';

import { Ii18nLabel } from '@/types/common';
import {
  Form, FormItemProps, Switch as AntdSwitch, SwitchProps as AntdSwitchProps, Select,
} from '@/components/antd';
import { getTranslatedString } from '@/utils';

import { getFormItemLabel } from './utils';

const { Option } = Select;

interface SwitchProps extends FormItemProps, AntdSwitchProps, Ii18nLabel {
  name: string;
  children: any;
  formState: any;
  asDropdown?: boolean;
}

const getOptions = () => [
  {
    label: getTranslatedString('switch.true'),
    value: true,
  },
  {
    label: getTranslatedString('switch.false'),
    value: false,
  },
];

export const Switch = ({ name, label, i18nLabel, rules, disabled, asDropdown = false, onChange }: SwitchProps) => {
  const fieldLabel = getFormItemLabel(i18nLabel, label);
  const getPopupContainer = (trigger) => trigger.parentElement;
  const onToggle = (checked, event) => {
    if (onChange) {
      onChange(checked, event);
    }
  };
  if (asDropdown) {
    return (
      <Form.Item name={name} label={fieldLabel} rules={rules}>
        <Select getPopupContainer={getPopupContainer} allowClear showSearch={false} optionFilterProp="children">
          {getOptions().map((item: any) => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    );
  }

  return (
    <Form.Item name={name} label={fieldLabel} rules={rules} valuePropName="checked">
      <AntdSwitch onChange={onToggle} disabled={disabled} />
    </Form.Item>
  );
};
