import React from 'react';
import { DatePicker, Form, FormItemProps } from '@/components/antd';

import { Ii18nLabel } from '@/types/common';

import { getFormItemLabel } from './utils';

const { RangePicker } = DatePicker;

interface DatePickerProps extends FormItemProps, Ii18nLabel {
  showTime?: boolean;
  disabled?: boolean;
  formState: any;
  format: string;
}

export const DateRangePicker = (
  {
    name,
    label,
    i18nLabel,
    rules,
    showTime = false,
    disabled,
    format,
    formState,
    ...props
  }: DatePickerProps,
) => {
  const fieldLabel = getFormItemLabel(i18nLabel, label);

  return (
    <Form.Item name={name} label={fieldLabel} rules={rules}>
      <RangePicker disabled={disabled} showTime={showTime} format={format} {...props} />
    </Form.Item>
  );
};
