import React from 'react';
import {
  BarChartOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  ContainerOutlined,
  FileTextOutlined,
  IdcardOutlined,
  SolutionOutlined,
  TeamOutlined,
  UserSwitchOutlined,
  DatabaseOutlined
} from '@/components/icons';

import { BaseMenuItemProps } from '@/components/menu';
import {
  PERMISSION_OBJ_AGENCY_BILLING,
  PERMISSION_OBJ_AGENCY_REPORTING,
  PERMISSION_OBJ_AGENCY_SHIFT_REPORTING,
  PERMISSION_OBJ_AGENCY_STAFFING,
  PERMISSION_OBJ_COORDINATOR_BILLING,
  PERMISSION_OBJ_COORDINATOR_REPORTING,
  PERMISSION_OBJ_COORDINATOR_SHIFT_REPORTING,
  PERMISSION_OBJ_COORDINATOR_STAFFING,
  PERMISSION_OBJ_COORDINATOR_PERMANENT_EMPLOYEE_STANDARD_HOURS,
  PERMISSION_OBJ_COORDINATOR_PERMANENT_EMPLOYEE_SHIFT_WORK,
  PERMISSION_OBJ_PERMANENT_EMPLOYEE_STANDARD_HOURS,
  PERMISSION_OBJ_PERMANENT_EMPLOYEE_SHIFT_WORK,
  PERMISSION_OBJ_LOCKER,
  PERMISSION_READ,
  PERMISSION_OBJ_COORDINATOR_LOCKER,
  PERMISSION_OBJ_COORDINATOR_PTO,
  PERMISSION_OBJ_AGENCY_PTO,
  PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY,
  PERMISSION_OBJ_SHIFT_PLANNING_PERMANENT,
  PERMISSION_OBJ_SHUTTLE_BUS_REPORT,
  PERMISSION_OBJ_TIMESHEET,
  PERMISSION_BUILDING_HOST,
} from '@/constants/permissions';

export interface IMenuItem extends BaseMenuItemProps {
  key: string;
  menuType?: string;
  parent: string;
  permissions: [string, number][];
  style?: any;
}

export const menuItems: IMenuItem[] = [
  {
    key: 'agency-employees',
    state: 'base-layout.agency-employees',
    icon: <TeamOutlined />,
    messageId: 'menu.agency-employees',
    permissions: [[PERMISSION_OBJ_AGENCY_STAFFING, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'coordinator-employees',
    state: 'base-layout.coordinator-employees',
    icon: <TeamOutlined />,
    messageId: 'menu.coordinator-employees',
    permissions: [[PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'agency-workingLogs',
    state: 'base-layout.agency-workingLogs',
    icon: <FileTextOutlined />,
    messageId: 'menu.agency-working-logs',
    permissions: [[PERMISSION_OBJ_AGENCY_REPORTING, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'coordinator-workingLogs',
    state: 'base-layout.coordinator-workingLogs',
    icon: <FileTextOutlined />,
    messageId: 'menu.coordinator-working-logs',
    permissions: [[PERMISSION_OBJ_COORDINATOR_REPORTING, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'agency-billings',
    state: 'base-layout.agency-billings',
    icon: <ClockCircleOutlined />,
    messageId: 'menu.agency-billings',
    permissions: [[PERMISSION_OBJ_AGENCY_BILLING, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'coordinator-billings',
    state: 'base-layout.coordinator-billings',
    icon: <ClockCircleOutlined />,
    messageId: 'menu.coordinator-billings',
    permissions: [[PERMISSION_OBJ_COORDINATOR_BILLING, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'shuttleBusReport',
    state: 'base-layout.shuttleBusReport',
    icon: <BarChartOutlined />,
    messageId: 'menu.shuttleBusReport',
    permissions: [[PERMISSION_OBJ_SHUTTLE_BUS_REPORT, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'agency-shiftReports',
    state: 'base-layout.agency-shiftReports',
    icon: <BarChartOutlined />,
    messageId: 'menu.agency-shift-reports',
    permissions: [[PERMISSION_OBJ_AGENCY_SHIFT_REPORTING, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'coordinator-shiftReports',
    state: 'base-layout.coordinator-shiftReports',
    icon: <BarChartOutlined />,
    messageId: 'menu.coordinator-shift-reports',
    permissions: [[PERMISSION_OBJ_COORDINATOR_SHIFT_REPORTING, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'agency-attendanceReports',
    state: 'base-layout.agency-attendanceReports',
    icon: <SolutionOutlined />,
    messageId: 'menu.agency-attendance-reports',
    permissions: [[PERMISSION_OBJ_AGENCY_SHIFT_REPORTING, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'coordinator-attendanceReports',
    state: 'base-layout.coordinator-attendanceReports',
    icon: <SolutionOutlined />,
    messageId: 'menu.coordinator-attendance-reports',
    permissions: [[PERMISSION_OBJ_COORDINATOR_SHIFT_REPORTING, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'coordinator-permanentsStandardHours',
    state: 'base-layout.coordinator-permanentsStandardHours',
    icon: <IdcardOutlined />,
    messageId: 'menu.coordinator-permanents-standard-hours',
    permissions: [
      [PERMISSION_OBJ_COORDINATOR_PERMANENT_EMPLOYEE_STANDARD_HOURS, PERMISSION_READ],
      [PERMISSION_OBJ_PERMANENT_EMPLOYEE_STANDARD_HOURS, PERMISSION_READ],
    ],
    parent: 'root',
  },
  {
    key: 'coordinator-permanentsShiftWork',
    state: 'base-layout.coordinator-permanentsShiftWork',
    icon: <UserSwitchOutlined />,
    messageId: 'menu.coordinator-permanents-shift-work',
    permissions: [
      [PERMISSION_OBJ_COORDINATOR_PERMANENT_EMPLOYEE_SHIFT_WORK, PERMISSION_READ],
      [PERMISSION_OBJ_PERMANENT_EMPLOYEE_SHIFT_WORK, PERMISSION_READ],
    ],
    parent: 'root',
  },
  {
    key: 'agency-ptos',
    state: 'base-layout.agency-ptos',
    icon: <CalendarOutlined />,
    messageId: 'menu.agency-ptos',
    permissions: [[PERMISSION_OBJ_AGENCY_PTO, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'coordinator-ptos',
    state: 'base-layout.coordinator-ptos',
    icon: <CalendarOutlined />,
    messageId: 'menu.coordinator-ptos',
    permissions: [[PERMISSION_OBJ_COORDINATOR_PTO, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'coordinator-lockers',
    state: 'base-layout.coordinator-lockers',
    icon: <ContainerOutlined />,
    messageId: 'menu.coordinator-lockers',
    permissions: [
      [PERMISSION_OBJ_COORDINATOR_LOCKER, PERMISSION_READ],
      [PERMISSION_OBJ_LOCKER, PERMISSION_READ],
    ],
    parent: 'root',
  },
  {
    key: 'building-host',
    state: 'base-layout.building-host',
    icon: <DatabaseOutlined />,
    messageId: 'menu.building-host',
    permissions: [[PERMISSION_BUILDING_HOST, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'coordinator-temps-shift-planning',
    state: 'base-layout.coordinator-temps-shift-planning',
    icon: <CalendarOutlined />,
    messageId: 'menu.coordinator-temps-shift-planning',
    permissions: [[PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'coordinator-perms-shift-planning',
    state: 'base-layout.coordinator-perms-shift-planning',
    icon: <CalendarOutlined />,
    messageId: 'menu.coordinator-perms-shift-planning',
    permissions: [[PERMISSION_OBJ_SHIFT_PLANNING_PERMANENT, PERMISSION_READ]],
    parent: 'root',
  },
  {
    key: 'timesheet',
    state: 'base-layout.timesheet',
    icon: <ClockCircleOutlined />,
    messageId: 'menu.timesheet',
    permissions: [[PERMISSION_OBJ_TIMESHEET, PERMISSION_READ]],
    parent: 'root',
  },
];
