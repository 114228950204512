import {ApiResponse, Result, WrappedAxiosItemsResult} from '@/api/types';
import { getFormattedParams, request } from '@/utils/request';

export default {
  getAllBuildingHosts(
    params?: mpg.api.buildingHost.GetParams,
  ): ApiResponse<WrappedAxiosItemsResult<mpg.api.buildingHost.BuildingHost[]>> {
    const formattedParams = getFormattedParams(params);
    return request({
      method: 'get',
      url: `/building-hosts`,
      params: formattedParams,
    });
  },
  createBuildingHost(data: mpg.api.buildingHost.BuildingHostParams): ApiResponse<mpg.api.buildingHost.BuildingHost> {
    return request({
      method: 'post',
      url: `/building-hosts`,
      data,
    });
  },
  get(id: string): ApiResponse<mpg.api.buildingHost.BuildingHost> {
    return request({
      method: 'get',
      url: `/building-hosts/${id}`,
    });
  },
  updateBuildingHost(
    id: string,
    data: mpg.api.buildingHost.BuildingHostParams,
  ): ApiResponse<mpg.api.buildingHost.BuildingHost> {
    return request({
      method: 'patch',
      url: `/building-hosts/${id}`,
      data,
    });
  },
  deleteBuildingHost(id: string): ApiResponse<Result> {
    return request({
      method: 'delete',
      url: `/building-hosts/${id}`,
    });
  },
};
