import { UIView, UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@/components/antd';

import { api } from '@/api';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { PERMISSION_OBJ_EMPLOYEE } from '@/constants/permissions';
import {
  agencyStore,
  areaStore,
  approversStore,
  breaktimeStore,
  buildingStore,
  colorStore,
  employeeStore,
  languageStore,
  positionStore,
  shiftStore,
  supervisorStore,
} from '@/stores';
import { withoutRepeatValue } from '@/utils/controller';
import { useBuildingsDependency } from '@/utils/hooks';
import { useMassUpdateCashedStores } from '@/utils/store';
import { lockerFormatted } from '@/utils/locker';
import { Loader } from '@/components/loader';

import { GenericForm } from '@/components/generic-form';
import { BillingType } from '@/types/enums';

const store = employeeStore;

interface IProps extends UIViewInjectedProps {
  entity: string;
  getFormFields: any;
  formRef: any;
  formData: any
  formDataLoading: any
  loadFormData: any
}

export const EmployeeForm = observer(({ transition, entity, formRef, getFormFields, formData, formDataLoading, loadFormData }: IProps) => {
  const { employeeId } = transition.router.globals.params;
  const isAgencyNonBillable = formData?.agency?.isNonBillable;

  const { loading } = useMassUpdateCashedStores([
    agencyStore,
    areaStore,
    breaktimeStore,
    buildingStore,
    colorStore,
    languageStore,
    positionStore,
    shiftStore,
    supervisorStore,
    approversStore,
  ]);

  const onBack = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };
  const initValues = formData
    ? {
        ...formData,
        birthday: formData.birthday && moment(formData.birthday),
        startDate: formData.startDate && moment(formData.startDate),
        orientationDate: formData.orientationDate && moment(formData.orientationDate),
        statusUpdatedOn: formData.statusUpdatedOn && moment(formData.statusUpdatedOn),
        locker: lockerFormatted(formData.locker),
        billingType: isAgencyNonBillable ? BillingType.NonBillable : formData.billingType,
      }
    : {};
  const { firstName = '', lastName = '' } = formData || {};

  const title = `${firstName} ${lastName}`;

  const { linesOptions, musterStationsOptions, shiftsOptions, onChangeBuilding } = useBuildingsDependency(initValues);

  const formFields = getFormFields({
    initValues,
    isCreate: false,
    onChangeBuilding,
    linesOptions,
    musterStationsOptions,
    shiftsOptions,
    isAgencyNonBillable,
  });
  const allowedFields = getAllowedFormFields(formFields, false, PERMISSION_OBJ_EMPLOYEE);
  const resourceController = withoutRepeatValue(initValues, {
    create: (values: mpg.api.employees.EmployeeParams) =>
      api.employees.create({ ...values }).source.then(() => store.refresh()),
    update: (values: mpg.api.employees.Employee) =>
      api.employees.update(formData && formData.id, { ...values }).source.then(() => store.refresh()),
  });

  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);

  const onStartSubmit = () => {
    setLoadingSubmit(true);
  };

  const onFinishSubmit = () => {
    setLoadingSubmit(false);
    setDisableSubmit(true);
    loadFormData();
  };

  const onFieldsChange = (_, all: any[]) => {
    const disable = all.some(({ errors }: any) => Boolean(errors.length));
    if (disableSubmit !== disable) {
      setDisableSubmit(disable);
    }
  };
  const loaderCondition = formDataLoading || loading;
  const disabled = disableSubmit;

  return (
    <>
      {loaderCondition ? (
        <Loader />
      ) : (
        <>
          <GenericForm
            resourceId={employeeId}
            className="form-grid-wrapper"
            title={title}
            initialValues={initValues}
            formFields={allowedFields}
            resourceController={resourceController}
            layout="vertical"
            formRef={formRef}
            onStartSubmit={onStartSubmit}
            onFinishSubmit={onFinishSubmit}
            onFieldsChange={onFieldsChange}
          />
          <div className="drawer-footer">
            <Button className="drawer-footer-button" shape="round" onClick={onBack}>
              <FormattedMessage id="button.cancel" />
            </Button>
            <Button onClick={formRef.submit} shape="round" type="primary" loading={loadingSubmit} disabled={disabled}>
              <FormattedMessage id="button.submit" />
            </Button>
          </div>
        </>
      )}
      <UIView />
    </>
  );
});
