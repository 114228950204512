import { observer } from 'mobx-react-lite';
import React from 'react';

import { UIView, UIViewInjectedProps } from '@uirouter/react';
import { Tabs } from '@/components/antd';
import {
  PERMISSION_OBJ_SHIFT_REPORT_PERMANENT,
  PERMISSION_OBJ_SHIFT_REPORT_TEMPORARY,
  PERMISSION_READ,
} from '@/constants/permissions';
import { authStore, temporaryEmployeesAttendanceReportStore, permanentEmployeesAttendanceReportStore } from '@/stores';
import { api } from '@/api';
import { getTranslatedString } from '@/utils';

import { getColumns } from './setup';
import { EmployeeType } from '@/types/enums';
import { ShiftReportView } from '@/pages/common/components/shift-reports/components/emuns';
import { AttendanceReportsTab } from "@/pages/common/components/attendance-reports/table/attendance-reports-tab";

interface IProps extends UIViewInjectedProps {
  tabConfig: { entity: string; localStorageKey: string; isEmployeeTypeShown: boolean };
}
export const AttendanceReports = observer(({ tabConfig, transition }: IProps) => {
  const { entity, localStorageKey, isEmployeeTypeShown } = tabConfig;

  const columns = getColumns(isEmployeeTypeShown);
  return (
    <>
      <Tabs destroyInactiveTabPane className="shift-reports">
        {authStore.hasPermissions([[PERMISSION_OBJ_SHIFT_REPORT_TEMPORARY, PERMISSION_READ]]) && (
          <Tabs.TabPane tab={getTranslatedString('shiftReports.temporary-tab')} key={ShiftReportView.Temporary}>
            <AttendanceReportsTab
              store={temporaryEmployeesAttendanceReportStore}
              employeeType={EmployeeType.Temporary}
              columns={columns}
              transition={transition}
              permissionObj={[PERMISSION_OBJ_SHIFT_REPORT_TEMPORARY, PERMISSION_READ]}
              exportToExcel={api.shiftReports.exportToExcelTemporaryEmployeesAttendanceReport}
              exportToEmail={api.shiftReports.exportToEmailTemporaryEmployeesAttendanceReport}
              fileName="attendance-report-temporary"
              entity={entity}
              localStorageKey={localStorageKey}
            />
          </Tabs.TabPane>
        )}
        {authStore.hasPermissions([[PERMISSION_OBJ_SHIFT_REPORT_PERMANENT, PERMISSION_READ]]) && (
          <Tabs.TabPane tab={getTranslatedString('shiftReports.permanents-tab')} key={ShiftReportView.Permanent}>
            <AttendanceReportsTab
              store={permanentEmployeesAttendanceReportStore}
              employeeType={EmployeeType.Permanent}
              columns={columns}
              transition={transition}
              permissionObj={[PERMISSION_OBJ_SHIFT_REPORT_PERMANENT, PERMISSION_READ]}
              exportToExcel={api.shiftReports.exportToExcelPermanentEmployeesAttendanceReport}
              exportToEmail={api.shiftReports.exportToEmailPermanentEmployeesAttendanceReport}
              fileName="attendance-report-permanent"
              entity={entity}
              localStorageKey={localStorageKey}
            />
          </Tabs.TabPane>
        )}
      </Tabs>
      <UIView />
    </>
  );
});
