import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Space } from '@/components/antd';

import { api } from '@/api';
import {
  DeleteAction,
  EditAction,
} from '@/components/table/actions';
import {
  getColumnSearchFields,
  getColumnDateFields,
  getColumnBooleanFields,
} from '@/components/table/filters';
import { BooleanValueRender } from '@/components/table/renders';
import {
  PERMISSION_DELETE,
  PERMISSION_OBJ_COORDINATOR_STAFFING,
  PERMISSION_OBJ_PERMANENT_EMPLOYEE,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import {
  areaStore,
  buildingStore,
  colorStore,
  languageStore,
  linesStore,
  musterStationsStore,
  musterStationCaptainsStore,
  permanentEmployeeShiftWorkStore,
  positionStore,
  shiftStore,
  supervisorStore,
} from '@/stores';
import {
  buildFilterOptionsFromEnum, getStorePropById, getStorePropByIdWithExtraData, showError, buildStoreOptions,
} from '@/utils/common';
import { getDateWithoutTime, getFormattedDate } from '@/utils/moment';
import { EmployeeStatus } from '@/types/enums';
import { lockerFormatted } from '@/utils/locker';

const PermissionedEditAction = withPermissions([
  [PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ],
  [PERMISSION_OBJ_PERMANENT_EMPLOYEE, PERMISSION_UPDATE],
])(EditAction);
const PermissionedDeleteAction = withPermissions([
  [PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ],
  [PERMISSION_OBJ_PERMANENT_EMPLOYEE, PERMISSION_DELETE],
])(DeleteAction);

const Actions = (_: any, { id }: any) => {
  const onDelete = () => {
    api.permanentEmployeesShiftWork.delete(id).source
      .then(() => permanentEmployeeShiftWorkStore.refresh())
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedEditAction
        uiSref={{ to: 'base-layout.coordinator-permanentsShiftWork.edit', params: { permanentShiftWorkId: id } }}
      />
      <PermissionedDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

export const PermEmployeeStatus = {
  [EmployeeStatus.New]: EmployeeStatus.New,
  [EmployeeStatus.Perm]: EmployeeStatus.Perm,
  [EmployeeStatus.Term]: EmployeeStatus.Term,
};

export const getColumns = () => [
  {
    title: <FormattedMessage id="permanentsShiftWork.employeeId" />,
    dataIndex: 'employeeId',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.wiegangId" />,
    dataIndex: 'wiegangId',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.employeeType" />,
    dataIndex: 'employeeType',
    render: (employeeType: string) => <FormattedMessage id={`permanentsShiftWork.employeeType.${employeeType}`} />,
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.workSchedule" />,
    dataIndex: 'workSchedule',
    render: (workSchedule: string) => <FormattedMessage id={`permanentsShiftWork.workSchedule.${workSchedule}`} />,
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.status" />,
    dataIndex: 'status',
    filters: buildFilterOptionsFromEnum(PermEmployeeStatus, 'permanentsShiftWork.status.'),
    filterMultiple: true,
    sorter: true,
    render: (status: string) => <FormattedMessage id={`permanentsShiftWork.status.${status}`} />,
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.firstName" />,
    dataIndex: 'firstName',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.middleName" />,
    dataIndex: 'middleName',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.lastName" />,
    dataIndex: 'lastName',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.email" />,
    dataIndex: 'email',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.phone" />,
    dataIndex: 'phone',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.company" />,
    dataIndex: 'company',
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.areaId" />,
    dataIndex: 'areaId',
    filters: buildStoreOptions(areaStore, 'name'),
    filterMultiple: true,
    sorter: true,
    render: (itemId: string) => getStorePropById<mpg.api.areas.Area>(itemId, areaStore),
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.position" />,
    dataIndex: 'positionId',
    filters: buildStoreOptions(positionStore, 'name'),
    filterMultiple: true,
    sorter: true,
    render: (itemId: string) => getStorePropById<mpg.api.positions.Position>(itemId, positionStore),
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.supervisorId" />,
    dataIndex: 'supervisorId',
    filters: buildStoreOptions(supervisorStore, 'name'),
    filterMultiple: true,
    sorter: true,
    minWidth: 150,
    render: (itemId: string) => getStorePropById<mpg.api.supervisors.Supervisor>(itemId, supervisorStore),
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.colorId" />,
    dataIndex: 'colorId',
    filters: buildStoreOptions(colorStore, 'name'),
    filterMultiple: true,
    sorter: true,
    render: (itemId: string) => getStorePropById<mpg.api.colors.Colors>(itemId, colorStore),
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.building" />,
    dataIndex: 'buildingId',
    filters: buildStoreOptions(buildingStore, 'name'),
    filterMultiple: true,
    sorter: true,
    render: (itemId: string) => getStorePropById<mpg.api.buildings.Building>(itemId, buildingStore),
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.musterStations" />,
    dataIndex: 'musterStationId',
    filters: buildStoreOptions(musterStationsStore, 'name', null, null, buildingStore, 'buildingId'),
    filterMultiple: true,
    sorter: true,
    render: (itemId: string) => getStorePropByIdWithExtraData<mpg.api.musterStations.MusterStation>({
      uid: itemId,
      store: musterStationsStore,
      labelKey: 'name',
      additionalStore: buildingStore,
      additionalLabel: 'buildingId',
    }),
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.musterStationCaptains" />,
    dataIndex: 'musterStationCaptainId',
    filters: buildStoreOptions(musterStationCaptainsStore, 'name'),
    filterMultiple: true,
    sorter: true,
    render: (itemId: string) => getStorePropById<mpg.api.musterStationCaptains.MusterStationCaptain>(itemId, musterStationCaptainsStore),
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.isMusterStationCaptain" />,
    dataIndex: 'isMusterStationCaptain',
    sorter: true,
    render: BooleanValueRender,
    ...getColumnBooleanFields(),
    permissions: [['permanentEmployee.isMusterStationCaptain', PERMISSION_READ]]
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.line" />,
    dataIndex: 'lineId',
    filters: buildStoreOptions(linesStore, 'name', null, null, buildingStore, 'buildingId'),
    filterMultiple: true,
    sorter: true,
    render: (itemId: string) => getStorePropByIdWithExtraData<mpg.api.lines.Line>({
      uid: itemId,
      store: linesStore,
      labelKey: 'name',
      additionalStore: buildingStore,
      additionalLabel: 'buildingId',
    }),
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.shift" />,
    dataIndex: 'shiftId',
    minWidth: 180,
    filters: buildStoreOptions(shiftStore, 'name', null, null, buildingStore, 'buildingId'),
    filterMultiple: true,
    sorter: true,
    render: (itemId: string) => getStorePropById<mpg.api.shifts.Shift>(itemId, shiftStore)

  },
  {
    title: <FormattedMessage id="permanentsShiftWork.shiftType" />,
    dataIndex: 'shiftType',
    filters: [
      { label: 'Day', value: 'day', text: 'Day' },
      { label: 'Night', value: 'night', text: 'Night' },
    ],
    render: (value:string)=> <FormattedMessage id={`permanentsShiftWork.shiftType.${value}`} />,
    filterMultiple: false,
    sorter: true,
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.trainedSlitter" />,
    dataIndex: 'trainedSlitter',
    sorter: true,
    render: BooleanValueRender,
    ...getColumnBooleanFields(),
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.trainedOverwrap" />,
    dataIndex: 'trainedOverwrap',
    sorter: true,
    minWidth: 150,
    render: BooleanValueRender,
    ...getColumnBooleanFields(),
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.locker" />,
    dataIndex: 'locker',
    sorter: true,
    ...getColumnSearchFields(),
    render: lockerFormatted,
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.birthday" />,
    dataIndex: 'birthday',
    ...getColumnDateFields(),
    sorter: true,
    render: (item: string) => getDateWithoutTime(item),
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.invitationLanguageId" />,
    dataIndex: 'invitationLanguageId',
    filters: buildStoreOptions(languageStore, 'name'),
    filterMultiple: true,
    sorter: true,
    minWidth: 150,
    render: (itemId: string) => getStorePropById<mpg.api.languages.Language>(itemId, languageStore),
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.termDescription" />,
    dataIndex: 'terminateDescription',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.designatedAccessOnly" />,
    dataIndex: 'designatedAccessOnly',
    sorter: true,
    render: BooleanValueRender,
    ...getColumnBooleanFields(),
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.parkingTag" />,
    dataIndex: 'parkingTag',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.statusUpdatedBy" />,
    dataIndex: 'statusUpdatedBy',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="permanentsShiftWork.statusUpdatedOn" />,
    dataIndex: 'statusUpdatedOn',
    sorter: true,
    render: (value) => getFormattedDate(value),
    ...getColumnDateFields(true),
  },
  {
    title: <FormattedMessage id="table.actions" />,
    dataIndex: 'actions',
    render: Actions,
  },
];
