import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Button, Form, Input, Divider,
} from '@/components/antd';
import { AUTHORIZED_USER_INITIAL_STATE } from '@/constants';
import { authStore } from '@/stores';

import { getResponseError } from '../handlers';
import { AuthLayout } from './auth-layout';

const Login2fa = ({ onSuccessLogin }: any) => {
  const [state, setState] = useState({
    attempts: 3,
    code: '',
    error: '',
    message: '',
  });

  const setStateValue = (key: string, value: string | number) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };

  const onCodeChange = (event) => setStateValue('code', event.target.value);

  const onResendClick = async () => {
    setStateValue('error', '');
    const response = await authStore.resend2faCode();
    const { message } = response;
    setStateValue('message', message);
  };

  const decreaseAttempts = () => state.attempts - 1;

  const onCheckCodeClick = async () => {
    try {
      setStateValue('message', '');
      await authStore.loginWith2fa(state.code);
      onSuccessLogin();
    } catch (err) {
      setStateValue('attempts', decreaseAttempts());
      setStateValue('error', getResponseError(err));
    }
  };

  return (
    <AuthLayout>
      <h3 className="auth-description"><FormattedMessage id="login-2fa.description" /></h3>
      <Form
        name="login2fa"
        requiredMark="optional"
      >
        <Form.Item
          name="code"
          rules={[{ required: true, message: 'Please input code!' }]}
        >
          <Input
            name="code"
            type="text"
            id="code"
            placeholder="Put code"
            value={state.code}
            onChange={onCodeChange}
          />
        </Form.Item>

        <Divider style={{ marginBottom: 0 }} />

        <Form.Item>
          <div className="auth-link" onClick={onResendClick}>
            <a>
              <span><FormattedMessage id="login-2fa.button.resend" /></span>
            </a>
          </div>
        </Form.Item>

        {
          state && state.message
            ? (
              <Form.Item>
                <div className="auth-message">
                  {state.message}
                </div>
              </Form.Item>
            )
            : null
        }

        {
          state && state.error
            ? (
              <Form.Item>
                <div className="auth-error-text">
                  {state.error}
                </div>
                <div className="auth-error-text">
                  <span><FormattedMessage id="login-2fa.error-message" /></span>
                </div>
              </Form.Item>
            )
            : null
        }

        {
          state && state.attempts < 3
            ? (
              <Form.Item>
                <div className="auth-message">
                  <span><FormattedMessage id="login-2fa.remaining-attemts" /></span>
                  <span>{state.attempts}</span>
                </div>
              </Form.Item>
            )
            : null
        }

        {
          state && !state.attempts
            ? (
              <Form.Item>
                <div className="auth-message">
                  <span><FormattedMessage id="login-2fa.block-message" /></span>
                </div>
              </Form.Item>
            )
            : null
        }

        <Form.Item>
          <Button
            className="auth-button"
            onClick={onCheckCodeClick}
            type="primary"
            htmlType="submit"
            disabled={!state.attempts}
          >
            <FormattedMessage id="login-2fa.button.check" />
          </Button>
        </Form.Item>

      </Form>
    </AuthLayout>
  );
};

export const AuthLogin2faForm = ({ transition }: any) => {
  const onSuccessLogin = () => transition.router.stateService.go(AUTHORIZED_USER_INITIAL_STATE);
  return <Login2fa onSuccessLogin={onSuccessLogin} />;
};
