/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { RadioChangeEvent, Dropdown} from 'antd';

import { Button, Menu, Radio, Space } from '@/components/antd';

export const OptionsWithoutResetFilter = ({ setSelectedKeys, confirm, selectedKeys, items }) => {
  const [selectedValue, setValue] = useState(selectedKeys[0]);

  const onApply = () => {
    setSelectedKeys([selectedValue]);
    confirm();
  };

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  return (
    <>
      <Radio.Group value={selectedValue} onChange={onChange} style={{ padding: '4px 0'}}>
        <Space direction="vertical">
          {items.map(({value, text}) =>
            <div className={`ant-dropdown-menu-item${ selectedValue === value ? ' ant-dropdown-menu-item-selected' : ''}`}>
              <Radio key={value} value={value} >
                {text}
              </Radio>
            </div>
          )}
        </Space>
      </Radio.Group>
      <div className="ant-table-filter-dropdown-btns">
        <Space>
          <Button onClick={onApply} shape="round" type="primary">
            Ok
          </Button>
        </Space>
      </div>
    </>
  );
};

