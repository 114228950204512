import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Space, Tag, Tooltip, Badge,
} from '@/components/antd';

import { api } from '@/api';
import { DeleteAction, EditAction } from '@/components/table/actions';
import { getColumnDateFields, getColumnSearchFields, getColumnTimeFields } from '@/components/table/filters';
import {
  PERMISSION_DELETE,
  PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { buildingStore, colorStore, shiftPlanningTempsStore, shiftStore} from '@/stores';
import {
  ShiftPlanningStatus, ShiftPlanningStatusColor, WorkScheduleTemporaryType,
} from '@/types/enums';
import {
  buildFilterOptionsFromEnum, buildStoreOptions, getRoundDecimalNumber, getStorePropById, showError,
} from '@/utils/common';
import { getFullDateWithTimeZone } from '@/utils/date';
import { PulsatingCircle } from '@/components/pulsating-circle';
import { getTranslatedString } from '@/utils';

const PermissionEditAction = withPermissions([
  [PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY, PERMISSION_UPDATE],
])(EditAction);
const PermissionDeleteAction = withPermissions([
  [PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY, PERMISSION_DELETE],
])(DeleteAction);

const Actions = (_: any, { id }: mpg.api.shiftPlanning.ShiftPlanning) => {
  const onDelete = () => {
    api.shiftPlanningTemps.delete(id).source
      .then(() => shiftPlanningTempsStore.refresh())
      .catch(showError);
  };

  return (
    <Space>
      <PermissionEditAction
        uiSref={{ to: 'base-layout.coordinator-temps-shift-planning.edit', params: { shiftPlanningId: id } }}
      />
      <PermissionDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

const statusFilters = () => Object.keys(ShiftPlanningStatus).map((key) => {
  const value = ShiftPlanningStatus[key];
  const text = (
    <Badge
      color={ShiftPlanningStatusColor[key]}
      text={getTranslatedString(`shiftPlanning.status.${value}`)}
    />
  );

  return {
    text,
    value,
  };
});

const statusRender = (status: ShiftPlanningStatus) => {
  const colors = {
    [ShiftPlanningStatus.Finished]: ShiftPlanningStatusColor.Finished,
    [ShiftPlanningStatus.Running]: ShiftPlanningStatusColor.Running,
    [ShiftPlanningStatus.Upcoming]: ShiftPlanningStatusColor.Upcoming,
  };

  return (
    <Tooltip title={getTranslatedString(`shiftPlanning.status.${status}`)}>
      <div>
        <PulsatingCircle color={colors[status]} />
      </div>
    </Tooltip>
  );
};

export const getColumns = (selectedBuilding) => [
  {
    title: 'building',
    dataIndex: 'building',
    filters: buildStoreOptions(buildingStore, 'name'),
    sorter: true,
    hidden: true,
    visible: false,
    render: (itemId: string) => getStorePropById<mpg.api.buildings.Building>(itemId, buildingStore),
  },
  {
    title: <FormattedMessage id="shiftPlanning.status" />,
    dataIndex: 'status',
    filterMultiple: true,
    filters: statusFilters(),
    minWidth: 80,
    render: statusRender,
  },
  {
    title: <FormattedMessage id="shiftPlanning.title" />,
    dataIndex: 'title',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="shiftPlanning.description" />,
    dataIndex: 'description',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="shiftPlanning.eventStart" />,
    dataIndex: 'eventStart',
    sorter: true,
    ...getColumnDateFields(true),
    render: (_, { eventStart }) => getFullDateWithTimeZone(eventStart, selectedBuilding?.timezone),
  },
  {
    title: <FormattedMessage id="shiftPlanning.eventEnd" />,
    dataIndex: 'eventEnd',
    sorter: true,
    ...getColumnDateFields(true),
    render: (_, { eventEnd }) => getFullDateWithTimeZone(eventEnd, selectedBuilding?.timezone),
  },
  // {
  //   title: <FormattedMessage id={'shiftPlanning.employeeType'} />,
  //   dataIndex: 'employeeType',
  //   filters: buildFilterOptionsFromEnum(EmployeeType, 'shiftPlanning.employeeType.'),
  //   filterMultiple: false,
  //   sorter: true,
  //   render: (employeeType: string) => employeeType &&
  //     <FormattedMessage id={`shiftPlanning.employeeType.${employeeType}`} />,
  // },
  {
    title: <FormattedMessage id="shiftPlanning.workSchedule" />,
    dataIndex: 'workSchedule',
    filters: buildFilterOptionsFromEnum(WorkScheduleTemporaryType, 'shiftPlanning.workSchedule.temporary.'),
    filterMultiple: true,
    sorter: true,
    render: (workSchedule: string) => workSchedule
      && <FormattedMessage id={`shiftPlanning.workSchedule.temporary.${workSchedule}`} />,
  },
  {
    title: <FormattedMessage id="shiftPlanning.additionalTime" />,
    dataIndex: 'additionalTime',
    ...getColumnTimeFields(true),
    sorter: true,
    render: getRoundDecimalNumber,
  },
  {
    title: <FormattedMessage id="shiftPlanning.colors" />,
    dataIndex: 'colorsId',
    filters: buildStoreOptions(colorStore, 'name'),
    filterMultiple: true,
    minWidth: 200,
    render: (itemId: string, record: mpg.api.shiftPlanning.ShiftPlanning) => record.colors.map((color) => (
      <Tag color="blue" key={color.id}>
        {color.name}
      </Tag>
    )),
  },
  {
    title: <FormattedMessage id="shiftPlanning.shifts" />,
    dataIndex: 'shiftsId',
    filters: buildStoreOptions(shiftStore, 'name', 'buildingId', selectedBuilding?.id),
    filterMultiple: true,
    minWidth: 266,
    render: (itemId: string, record: mpg.api.shiftPlanning.ShiftPlanning) => record.shifts.map((shift) => (
      <Tag color="blue" key={shift.id}>
        {shift.name}
      </Tag>
    )),
  },
  {
    title: <FormattedMessage id="table.actions" />,
    dataIndex: 'actions',
    render: Actions,
  },
];
