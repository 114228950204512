import React from 'react';
import { FormattedMessage } from 'react-intl';

import { agencyStore, buildingStore, colorStore, shiftStore } from '@/stores';
import {
  buildFilterOptionsFromEnum,
  buildStoreOptions,
  getRoundDecimalNumber,
  getStorePropById,
  getUserFullNameByRecord,
} from '@/utils/common';

import { getColumnDateFields, getColumnSearchFields, getColumnTimeFields } from '@/components/table/filters';
import { EmployeeStatus } from '@/types/enums';

export const getColumns = (isEmployeeTypeShown: boolean) =>
  [
    isEmployeeTypeShown
      ? {
          title: <FormattedMessage id="attendanceReports.employeeType" />,
          dataIndex: 'employeeType',
          render: (employeeType: string) => <FormattedMessage id={`attendanceReports.employeeType.${employeeType}`} />,
        }
      : null,
    {
      title: <FormattedMessage id="attendanceReports.fullName" />,
      dataIndex: 'fullName',
      render: (_, record) => getUserFullNameByRecord(record),
    },
    {
      title: <FormattedMessage id="attendanceReports.firstName" />,
      dataIndex: 'firstName',
      sorter: true,
      ...getColumnSearchFields(),
    },
    {
      title: <FormattedMessage id="attendanceReports.lastName" />,
      dataIndex: 'lastName',
      sorter: true,
      ...getColumnSearchFields(),
    },
    {
      title: <FormattedMessage id="attendanceReports.currentColors" />,
      dataIndex: 'colorAssignedId',
      filters: buildStoreOptions(colorStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.colors.Colors>(itemId, colorStore),
    },
    {
      title: <FormattedMessage id="attendanceReports.currentShift" />,
      dataIndex: 'shiftPlannedId',
      filters: buildStoreOptions(shiftStore, 'name', null, null, buildingStore, 'buildingId'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.shifts.Shift>(itemId, shiftStore)

    },
    {
      title: <FormattedMessage id="attendanceReports.currentStatus" />,
      dataIndex: 'status',
      filters: buildFilterOptionsFromEnum(EmployeeStatus, 'employees.status.'),
      filterMultiple: true,
      sorter: true,
      render: (status: string) => <FormattedMessage id={`employees.status.${status}`} />,
    },
    {
      title: <FormattedMessage id="attendanceReports.kioskID" />,
      dataIndex: 'visitorId',
      ...getColumnSearchFields(),
      sorter: true,
    },
    {
      title: <FormattedMessage id="attendanceReports.agency" />,
      dataIndex: 'agencyId',
      filters: buildStoreOptions(agencyStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.agencies.Agency>(itemId, agencyStore),
    },
    {
      title: <FormattedMessage id="attendanceReports.workerBuilding" />,
      dataIndex: 'workerBuildingId',
      filters: buildStoreOptions(buildingStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.buildings.Building>(itemId, buildingStore),
    },
    {
      title: <FormattedMessage id="attendanceReports.attendance" />,
      dataIndex: 'attendance',
      sorter: true,
      ...getColumnTimeFields(true),
      render: (value) => getRoundDecimalNumber(value),
    },
    {
      title: <FormattedMessage id="attendanceReports.timesWorkedUnder" />,
      dataIndex: 'timeWorkedUnder10',
      sorter: true,
      ...getColumnTimeFields(true),
      render: (value) => getRoundDecimalNumber(value),
    },
    {
      title: <FormattedMessage id="attendanceReports.timesLate" />,
      dataIndex: 'lateByCount',
      sorter: true,
      ...getColumnTimeFields(true),
      render: (value) => getRoundDecimalNumber(value),
    },
    {
      title: <FormattedMessage id="attendanceReports.timesLeftEarly" />,
      dataIndex: 'earlyByCount',
      sorter: true,
      ...getColumnTimeFields(true),
      render: (value) => getRoundDecimalNumber(value),
    },
    {
      title: <FormattedMessage id="attendanceReports.timesOverAllowedBreak" />,
      dataIndex: 'timesOverAllowedBreak',
      sorter: true,
      ...getColumnTimeFields(true),
      render: (value) => getRoundDecimalNumber(value),
    },
    {
      title: <FormattedMessage id="attendanceReports.date" />,
      dataIndex: 'date',
      sorter: false,
      ...getColumnDateFields(false, false),
      render: () => 'Range',
    },
  ].filter((item) => !!item);
