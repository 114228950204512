import { CoordinatorBillingsWorkLogs } from './components/drawer';
import { CoordinatorBillings } from './components/table';

import { PERMISSION_OBJ_COORDINATOR_BILLING, PERMISSION_READ } from '@/constants/permissions';

export const states = [
  {
    name: 'base-layout.coordinator-billings',
    url: '/billings',
    component: CoordinatorBillings,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_COORDINATOR_BILLING, PERMISSION_READ]],
    },
  },
  {
    name: 'base-layout.coordinator-billings.view',
    url: '/:billingId/view',
    component: CoordinatorBillingsWorkLogs,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_COORDINATOR_BILLING, PERMISSION_READ]],
    },
  },
];
