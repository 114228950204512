import { UIViewInjectedProps } from '@uirouter/react';
import { ShiftReports } from '@/pages/common/components/shift-reports/components/table';
import { Form } from '@/pages/common/components/shift-reports/components/form';

import { PERMISSION_OBJ_AGENCY_SHIFT_REPORTING, PERMISSION_READ } from '@/constants/permissions';

const AgencyForm = ({ transition }: UIViewInjectedProps) => (
  <Form
    currentPage="agency"
    permission={[PERMISSION_OBJ_AGENCY_SHIFT_REPORTING, PERMISSION_READ]}
    transition={transition}
  />
);

const CoordinatorShiftReports = ({ transition }: UIViewInjectedProps) => {
  const tabConfig = {
    entity: 'agency-shiftReports',
    localStorageKey: 'agency-shiftReports-table',
    isEmployeeTypeShown: false,
    isFilterWithOptions: false,
  };
  return <ShiftReports tabConfig={tabConfig} transition={transition} />;
};

export const states = [
  {
    name: 'base-layout.agency-shiftReports',
    url: '/agency-shiftreports',
    component: CoordinatorShiftReports,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_AGENCY_SHIFT_REPORTING, PERMISSION_READ]],
    },
  },
  {
    name: 'base-layout.agency-shiftReports.edit',
    url: '/:shiftReportId/edit',
    component: AgencyForm,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_AGENCY_SHIFT_REPORTING, PERMISSION_READ]],
    },
  },
];
