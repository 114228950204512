import { AuditForm } from './components/audit-form';
import { Form } from './components/form';
import { PermanentsStandardHours } from './components/table';
import {
  PERMISSION_CREATE,
  PERMISSION_OBJ_COORDINATOR_PERMANENT_EMPLOYEE_STANDARD_HOURS,
  PERMISSION_OBJ_PERMANENT_EMPLOYEE,
  PERMISSION_OBJ_PERMANENT_EMPLOYEE_STANDARD_HOURS,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { BulkForm } from './components/form/bulk-edit';

export const states = [
  {
    name: 'base-layout.coordinator-permanentsStandardHours',
    url: '/permanentsStandardHours',
    component: PermanentsStandardHours,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COORDINATOR_PERMANENT_EMPLOYEE_STANDARD_HOURS, PERMISSION_READ],
        [PERMISSION_OBJ_PERMANENT_EMPLOYEE, PERMISSION_READ],
        [PERMISSION_OBJ_PERMANENT_EMPLOYEE_STANDARD_HOURS, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-permanentsStandardHours.create',
    url: '/permanentsStandardHours/new',
    component: Form,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COORDINATOR_PERMANENT_EMPLOYEE_STANDARD_HOURS, PERMISSION_READ],
        [PERMISSION_OBJ_PERMANENT_EMPLOYEE, PERMISSION_CREATE],
        [PERMISSION_OBJ_PERMANENT_EMPLOYEE_STANDARD_HOURS, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-permanentsStandardHours.edit',
    url: '/:permanentStandardHoursId/edit',
    component: Form,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COORDINATOR_PERMANENT_EMPLOYEE_STANDARD_HOURS, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-permanentsStandardHours.edit.audit',
    url: '/:recordId/audit',
    component: AuditForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COORDINATOR_PERMANENT_EMPLOYEE_STANDARD_HOURS, PERMISSION_READ],
        [PERMISSION_OBJ_PERMANENT_EMPLOYEE, PERMISSION_READ],
        [PERMISSION_OBJ_PERMANENT_EMPLOYEE_STANDARD_HOURS, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-permanentsStandardHours.bulk-edit',
    url: '/bulk-update',
    component: BulkForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COORDINATOR_PERMANENT_EMPLOYEE_STANDARD_HOURS, PERMISSION_READ],
        [PERMISSION_OBJ_PERMANENT_EMPLOYEE, PERMISSION_UPDATE],
        [PERMISSION_OBJ_PERMANENT_EMPLOYEE_STANDARD_HOURS, PERMISSION_UPDATE],
      ],
    },
  },
];
