import { useMemo, useState, useEffect } from 'react';

import {
  getDefaultSettings,
  getGenericFilters,
  getInitialTableFilters,
  getInitialTableSorter,
  saveDataToSessionStorage,
  toQueryFormat,
} from '@/components/table/utils';
import { authStore, settingsStore } from '@/stores';

import {
  saveSettingToLocalStorage,
  getInitialTableSettings,
  defaultMinWidth,
} from './utils';

export const useStoreIntegration = (props) => {
  const {
    columns,
    store,
    setFilters,
    setSorters,
    hasRequiredFilterParam = false,
    requiredFilterParam = {},
    filters,
    sorters,
    isAutoloadAllowed,
  } = props;
  const genericFilters = useMemo(() => getGenericFilters(columns), []);
  const [currentTablePagination, setCurrentTablePagination] = useState({ total: store?.total, pageSize: settingsStore.itemsPerPage })
  const onChangeTable = (...args) => {
    const params = toQueryFormat.apply(null, [genericFilters, ...args]);

    if (hasRequiredFilterParam) {
      Object.entries(requiredFilterParam).map(([key, value]) => params[key] = value);
    }
    setCurrentTablePagination(args[0])
    setFilters({ ...filters, ...args[1]});
    setSorters(args[2]);
  };

  const onChange = props.onChange || onChangeTable;
  const pagination = props.pagination !== undefined
    ? props.pagination
    : {
      total: store.count,
      showSizeChanger: true,
      onShowSizeChange: (current, size) => {
        settingsStore.changeItemsPerPage(size);
      },
      pageSize: settingsStore.itemsPerPage,
      showQuickJumper: true,
    };

  if (pagination && !pagination.pageSize) {
    pagination.pageSize = settingsStore.itemsPerPage;
  }

  useEffect(() => {
    if (isAutoloadAllowed) {
      const params = toQueryFormat(genericFilters, currentTablePagination, filters, sorters);
      store.forceLoad(params);
    }
  }, [filters, sorters, isAutoloadAllowed, currentTablePagination]);

  return {
    pagination,
    onChange,
  };
};

export const usePermissions = (props) => {
  const { columns, checkPermissions } = props;

  let allowedColumns = [...columns];

  if (checkPermissions) {
    allowedColumns = allowedColumns
      .filter(({ permissions }: any) => {
        if (!permissions) {
          return true;
        }

        // if (permissionObj) {
        //   return authStore.hasPermissions([[`${permissionObj}.${dataIndex}`, PERMISSION_READ]]);
        // }

        return authStore.hasPermissions(permissions);
      });
  }

  return {
    allowedColumns,
  };
};

export const useTableSettings = (props) => {
  const {
    allowedColumns, localStorageKey, tableRef, initialFilters = {}, initialSorters = {},
  } = props;

  const defaultSettings = getDefaultSettings(allowedColumns);

  const filtersSessionStorageKey = `${localStorageKey}-filters`;
  const sorterSessionStoragesKey = `${localStorageKey}-sorters`;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [settings, setSettings] = useState(getInitialTableSettings(localStorageKey, allowedColumns));
  const [filters, setFilters] = useState(getInitialTableFilters(filtersSessionStorageKey, initialFilters));
  const [sorters, setSorters] = useState(getInitialTableSorter(sorterSessionStoragesKey, initialSorters));

  useEffect(() => {
    saveSettingToLocalStorage(localStorageKey, settings);
  }, [settings, localStorageKey]);

  useEffect(() => {
    saveDataToSessionStorage(filtersSessionStorageKey, filters);
  }, [filters, filtersSessionStorageKey]);

  useEffect(() => {
    const { column, ...sortersWithoutColumn } = sorters;
    saveDataToSessionStorage(sorterSessionStoragesKey, sortersWithoutColumn);
  }, [sorters, sorterSessionStoragesKey]);

  const handleResize =
    ({ dataIndex, key }) =>
    (e, { size }) => {
      const { minWidth } = ((key && allowedColumns.find((col: any) => col.key === key)) ||
        allowedColumns.find((col: any) => col.dataIndex === dataIndex) ||
        {}) as any;

    if (size.width < (minWidth || defaultMinWidth)) {
      return;
    }

    const nextColumns = [...settings];
    const index = nextColumns.findIndex((col) => col.dataIndex === dataIndex);

    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width,
    };

    setSettings(nextColumns);
  };

  const shownColumns = settings
    .map((col) => {
      const found = (col.key && allowedColumns.find((c) => c.key === col.key))
        || allowedColumns.find((c) => c.dataIndex === col.dataIndex);

      if (found) {
        return {
          ...found,
          ...col,
        };
      }
      return null;
    })
    .filter((col) => col && col.visible)
    .map(((col) => ({
      ...col,
      key: col.dataIndex,
      width: col.width || col.minWidth || defaultMinWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: handleResize({
          dataIndex: col.dataIndex,
          key: col.key,
        }),
      }),
    })));

  if (tableRef) {
    tableRef.current.showSettingsModal = () => {
      setIsModalOpen(true);
    };

    tableRef.current.showFiltersModal = () => {
      setIsFilterModalOpen(true);
    };

    tableRef.current.filters = filters;
    tableRef.current.setFilters = setFilters;

    tableRef.current.sorters = sorters;
    tableRef.current.setSorters = setSorters;

    tableRef.current.reset = () => {
      setFilters({});
      setSorters({});
    };
  }

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  return {
    closeModal,
    isModalOpen,
    closeFilterModal,
    isFilterModalOpen,
    defaultSettings,
    settings,
    setSettings,
    sorters,
    setSorters,
    filters,
    setFilters,
    shownColumns,
  };
};
