import { observer } from 'mobx-react-lite';

import { TableRowSelection } from 'antd/lib/table/interface';
import { Table } from '@/components/table';
import { EmployeeType } from '@/types/enums';

import { useWeekTimesheetData } from './hooks';
import { WEEK_TABLE_STORAGE_KEY } from '../../constants';
import { TimesheetPageState } from '@/pages/timesheet/enums';

export const TimesheetTable = observer(({ transition, tableRef, selectedRows, setSelectedRowKeys }: any) => {
  const { loading, columns, store, storesLoading } = useWeekTimesheetData();

  const onRowSelectionChange = (selectedRowKeys: string[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection: TableRowSelection<any> = {
    preserveSelectedRowKeys: false,
    selectedRowKeys: selectedRows,
    onChange: onRowSelectionChange,
  };
  const onDoubleClick = (record: mpg.api.dayTimesheet.DayTimesheet) => {
    transition.router.stateService.go(TimesheetPageState.DayTimesheetTable, { id: record.id });
  };
  const initialFilters = {
    employeeType: [EmployeeType.Temporary],
  };
  return (
    <Table
      tableRef={tableRef}
      localStorageKey={WEEK_TABLE_STORAGE_KEY}
      rowKey="id"
      columns={columns}
      dataSource={store.items}
      store={store}
      checkPermissions
      loading={loading}
      onRow={(record) => ({ onDoubleClick: () => onDoubleClick(record) })}
      scroll={{ y: '100%' }}
      rowSelection={rowSelection}
      initialFilters={initialFilters}
      isAutoloadAllowed={!storesLoading}
      initialSorters={{
        columnKey: 'id',
        field: 'id',
        order: 'ascend',
      }}
    />
  );
});
