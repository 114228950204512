import React from 'react';
import type { Moment } from 'moment';

import {
  Calendar as AntdCalendar,
  AntdCalendarProps,
  Spin,
} from '@/components/antd';

import { useKeysPressed, useSelect } from './hooks';

interface CalendarProps extends AntdCalendarProps<Moment> {
  onMultiSelect?: (selectedDates: Array<Moment>) => void;
  loading?: boolean;
}

export const Calendar = ({ onMultiSelect, loading = false, ...antdProps }: CalendarProps) => {
  const { isShiftPressed, isCtrlPressed } = useKeysPressed();
  const { onSelect } = useSelect({ isCtrlPressed, isShiftPressed, onMultiSelect });

  return (
    <div className="c-calendar">
      {loading && <div className="c-calendar__spin"><Spin /></div>}
      <AntdCalendar className={`${loading && 'ant-spin-blur'}`} {...antdProps} onSelect={onSelect} />
    </div>
  );
};
