import { Form } from './components/form';
import { Lockers } from './components/table';
import {
  PERMISSION_OBJ_COORDINATOR_LOCKER,
  PERMISSION_OBJ_LOCKER,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { BulkForm } from './components/form/bulk-edit';

export const states = [
  {
    name: 'base-layout.coordinator-lockers',
    url: '/lockers',
    component: Lockers,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COORDINATOR_LOCKER, PERMISSION_READ],
        [PERMISSION_OBJ_LOCKER, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-lockers.edit',
    url: '/:lockerId/edit',
    component: Form,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COORDINATOR_LOCKER, PERMISSION_READ],
        [PERMISSION_OBJ_LOCKER, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-lockers.bulk-edit',
    url: '/bulk-update',
    component: BulkForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COORDINATOR_LOCKER, PERMISSION_READ],
        [PERMISSION_OBJ_LOCKER, PERMISSION_UPDATE],
      ],
    },
  },
];
