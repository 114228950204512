import { FormattedMessage } from 'react-intl';
import { SearchFilter } from './search-filter';
import { DateFilter } from './date-filter';
import { TimeFilter } from './time-filter';
import { BooleanValueComponent } from '../renders';
import { OptionsWithoutResetFilter } from './options-without-reset-filter';
import { IKeyValueObject } from '@/types/common';

export const getColumnSearchFields = () => ({
  filterDropdown: SearchFilter,
});

export const getOptionsWithoutResetFilterFields = (enumObject: IKeyValueObject, i18nPrefix: string) => ({
  filterDropdown: (props) => OptionsWithoutResetFilter({ ...props, items: Object.values(enumObject)
    .map((item: string) => ({ text: <FormattedMessage id={`${i18nPrefix}${item}`} />, value: item })) }),
});

export const getColumnDateFields = (showTime: boolean = false, isNotDefinedExists: boolean = true) => ({
  filterDropdown: (props) => DateFilter({ ...props, showTime, isNotDefinedExists }),
});

export const getColumnTimeFields = (isIntegerStepValue: boolean = false, convertToMinutes: boolean = false) => ({
  filterDropdown: (props) => TimeFilter({ ...props, isIntegerStepValue, convertToMinutes }),
});

export const getColumnBooleanFields = (withNullOption?: boolean) => ({
  filterMultiple: false,
  filters: withNullOption
    ? [
      { label: 'true', value: true, text: <BooleanValueComponent value /> },
      { label: 'false', value: false, text: <BooleanValueComponent value={false} /> },
      { label: 'null', value: 'null', text: <BooleanValueComponent value={null} /> },
    ]
    : [
      { label: 'true', value: true, text: <BooleanValueComponent value /> },
      { label: 'false', value: false, text: <BooleanValueComponent value={false} /> },
    ],
});

export const getColumnPresentedFields = () => ({
  filterMultiple: false,
  filters: [
    { label: 'true', value: true, text: <BooleanValueComponent value /> },
    { label: 'null', value: false, text: <BooleanValueComponent value={null} /> },
  ],
});

export const getColumnAttachmentIdFields = () => ({
  filterMultiple: false,
  filters: [
    { label: 'N/A', value: 'null', text: <BooleanValueComponent value={null} /> },
  ],
});
