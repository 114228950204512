import React from 'react';
import { TimePicker, Form, FormItemProps } from '@/components/antd';

import { Ii18nLabel } from '@/types/common';

import { getFormItemLabel } from './utils';

const { RangePicker } = TimePicker;

interface TimePickerProps extends FormItemProps, Ii18nLabel {
  disabled?: boolean;
  formState: any;
  format: string;
}

export const TimeRangePicker = (
  {
    name,
    label,
    i18nLabel,
    rules,
    disabled,
    format,
    formState,
    ...props
  }: TimePickerProps,
) => {
  const fieldLabel = getFormItemLabel(i18nLabel, label);

  return (
    <Form.Item name={name} label={fieldLabel} rules={rules}>
      <RangePicker disabled={disabled} format={format} {...props} />
    </Form.Item>
  );
};
