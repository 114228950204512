import { UIViewInjectedProps } from '@uirouter/react';
import { getFormFields } from './setup';

import { Form } from '@/pages/common/components/employees/components/form';
import { PERMISSION_OBJ_EMPLOYEE } from '@/constants/permissions';

const entity = 'coordinator-employees';

export const CoordinatorForm = ({ transition }: UIViewInjectedProps) => (
  <Form transition={transition} getFormFields={getFormFields} entity={entity} permission={PERMISSION_OBJ_EMPLOYEE} />
);
