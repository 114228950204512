import { observer } from 'mobx-react-lite';
import React from 'react';

import {
  Col, Divider, Input, Row,
} from '@/components/antd';
import { withStores } from '@/components/stores-provider';
import { getTranslatedString } from '@/utils';
import { UserSettings } from './settings';
import { TwoFactorAuth } from './two-factor-auth';

export const Profile = withStores('authStore')(
  observer((
    {
      authStore: {
        profile: {
          email,
          name = '',
          role,
        },
      },
    }: any,
  ) => (
      <Col>
        <Divider orientation="left" plain>{getTranslatedString('profile.title')}</Divider>
        <Row align="middle" className="profile-row">
          <Col flex="0 0 100px">
            {getTranslatedString('profile.email')}
            :
          </Col>
          <Col flex="0 0 160px">
            <Input defaultValue={email} name="email" className="user-settings-input" disabled />
          </Col>
        </Row>
        <Row align="middle" className="profile-row">
          <Col flex="0 0 100px">
            {getTranslatedString('profile.name')}
            :
          </Col>
          <Col flex="0 0 160px">
            <Input defaultValue={name} name="name" className="user-settings-input" disabled />
          </Col>
        </Row>
        <Row align="middle" className="profile-row">
          <Col flex="0 0 100px">
            {getTranslatedString('profile.role')}
            :
          </Col>
          <Col flex="0 0 160px">
            <Input defaultValue={role} name="role" className="user-settings-input" disabled />
          </Col>
        </Row>
        <Divider orientation="left" plain>{getTranslatedString('user-settings.title')}</Divider>
        <UserSettings />
        {/* <div className="lang"> */}
        {/* {languages} */}
        {/* </div> */}
        <Divider orientation="left" plain>{getTranslatedString('2fa.title')}</Divider>
        <TwoFactorAuth />
      </Col>
    )),
);
