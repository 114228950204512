import React, { useState, useEffect, useRef } from 'react';
import { UIView, UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import { TableRowSelection } from 'antd/lib/table/interface';

import { DownOutlined, SettingOutlined, PrinterOutlined } from '@/components/icons';
import { Button, PageHeader, Dropdown, Menu, Modal, Select } from '@/components/antd';
import { Table } from '@/components/table';
import {
  PERMISSION_DELETE,
  PERMISSION_OBJ_SHIFT_PLANNING_PERMANENT, PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { authStore, buildingStore, colorStore, shiftPlanningPermsStore, shiftStore } from '@/stores';
import { buildStoreOptions, onPrint, showError } from '@/utils/common';
import { api } from '@/api';
import { getTranslatedString } from '@/utils';
import { useMassUpdateCashedStores } from '@/utils/store';
import { ShiftPlanningStatus } from '@/types/enums';

import { useBulkUpdate } from './hooks';
import { getColumns } from './setup';
import { BulkDeleteButton, BulkUpdateButton, CreateButton, CsvUploadButton } from './buttons';
import { withPermissions } from "@/hocs/permission";
import ExportButton from "@/pages/common/components/buttons/export-button";

const entity = 'coordinator-perms-shift-planning';
const store = shiftPlanningPermsStore;
const LOCAL_STORAGE_KEY_BUILDING = 'COORDINATORS_SHIFT_PLANNING_PERMS_SELECTED_BUILDING';

export const TableView = observer(({ transition }: UIViewInjectedProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [selectedBuilding, setSelectedBuilding] = useState<mpg.api.buildings.Building>();

  const tableRef: any = useRef({});
  const { items } = store;

  const { loading } = useMassUpdateCashedStores([buildingStore, colorStore, shiftStore]);

  useEffect(() => {
    if (!loading) {
      const lastSelectedBuildingId = localStorage.getItem(LOCAL_STORAGE_KEY_BUILDING);
      const lastSelectedBuilding =
        lastSelectedBuildingId && buildingStore.items.find(({ id }) => id === lastSelectedBuildingId);
      setSelectedBuilding(lastSelectedBuilding ?? buildingStore.items[0]);
    }
  }, [loading]);

  const onBuildingChange = (buildingId: string) => {
    const building = buildingStore.items.find(({ id }) => id === buildingId);
    setSelectedBuilding(building);
    localStorage.setItem(LOCAL_STORAGE_KEY_BUILDING, buildingId);
  };

  const { importRequest, parseSettings, storeRefreshRequest } = useBulkUpdate();

  const [isAutoloadAllowed,setIsAutoloadAllowed] = useState(false)
  useEffect(() => {
    if (!loading && selectedBuilding) {
      if (tableRef?.current?.filters?.building?.id !== selectedBuilding?.id) {
        tableRef?.current?.setFilters({ ...tableRef?.current?.filters, status: [ShiftPlanningStatus.Upcoming, ShiftPlanningStatus.Running], building: selectedBuilding });
      }
    }
    setIsAutoloadAllowed(!loading && !!selectedBuilding?.id)
  }, [loading, selectedBuilding]);

  const onDoubleClick = (record) => {
    transition.router.stateService.go(`base-layout.${entity}.edit`, { shiftPlanningId: record.id });
  };

  const onAdd = () => {
    transition.router.stateService.go(`base-layout.${entity}.create`, { buildingId: selectedBuilding?.id });
  };

  const onRowSelectionChange = (currentSelectedRowKeys: string[]) => {
    setSelectedRowKeys(currentSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<any> = {
    onChange: onRowSelectionChange,
  };

  const onBulkDelete = () => {
    const onOk = () =>
      api.shiftPlanningPerms
        .bulkDelete({ ids: selectedRowKeys })
        .source.then(() => shiftPlanningPermsStore.refresh())
        .catch(showError);

    Modal.confirm({
      title: getTranslatedString('common.warning'),
      content: getTranslatedString('shiftPlanning.delete-warning-desc'),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk,
    });
  };

  const hasBulkPermissions = authStore.hasPermissions([
    [PERMISSION_OBJ_SHIFT_PLANNING_PERMANENT, PERMISSION_UPDATE],
    [PERMISSION_OBJ_SHIFT_PLANNING_PERMANENT, PERMISSION_DELETE],
  ]);

  const batchMenu = (
    <Menu>
      <BulkUpdateButton key="bulk-update" selectedRowKeys={selectedRowKeys} selectedBuilding={selectedBuilding} />
      <BulkDeleteButton key="bulk-delete" onClick={onBulkDelete} />
    </Menu>
  );

  return (
    <div className="m-shift-planning__table">
      <PageHeader
        className="r-antd-page-header r-antd-page-header--withoutMargin"
        extra={[
          <div key="filter-building" style={{ flexGrow: 1 }}>
            <Select
              showSearch
              placeholder="Select a building"
              onChange={onBuildingChange}
              filterOption={(input, option: any) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              options={buildStoreOptions(buildingStore, 'name')}
              value={selectedBuilding?.id}
              style={{ width: 200 }}
            />
          </div>,
          <Dropdown key="batch-dropdown" overlay={batchMenu} disabled={!selectedRowKeys.length || !hasBulkPermissions}>
            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              Batch Actions
              <DownOutlined />
            </a>
          </Dropdown>,
          <Button key="print" size="middle" icon={<PrinterOutlined />} onClick={onPrint} />,
          <Button
            key="setting"
            icon={<SettingOutlined />}
            size="middle"
            onClick={tableRef.current.showSettingsModal}
          />,
          <CreateButton key="create button" onClick={onAdd} />,
          <ExportButton
            store={store}
            exportToExcel={api.shiftPlanningPerms.exportToExcel}
            exportToEmail={api.shiftPlanningPerms.exportToEmail}
            withPermission={withPermissions([
              [PERMISSION_OBJ_SHIFT_PLANNING_PERMANENT, PERMISSION_READ],
            ])}
            additionalParams={{ buildingId: selectedBuilding?.id }}
            fileName="mpg-shift-planning"
          />,
          <CsvUploadButton
            key="bulk update button"
            request={importRequest}
            settings={parseSettings}
            storeRefreshRequest={storeRefreshRequest}
          />,
        ]}
      />

      <Table
        tableRef={tableRef}
        onRow={(record) => ({
          onDoubleClick: () => onDoubleClick(record),
        })}
        localStorageKey="coordinator-perms-shift-planning-table"
        rowSelection={rowSelection}
        rowKey="id"
        columns={getColumns(selectedBuilding)}
        dataSource={[...items]}
        store={store}
        checkPermissions
        permissionObj={PERMISSION_OBJ_SHIFT_PLANNING_PERMANENT}
        loading={store.loading || loading}
        scroll={{ y: '100%' }}
        hasRequiredFilterParam
        requiredFilterParam={{ building: selectedBuilding }}
        isAutoloadAllowed={isAutoloadAllowed}
        initialFilters={{
          status: [ShiftPlanningStatus.Upcoming, ShiftPlanningStatus.Running],
          building: selectedBuilding,
        }}
      />
      <UIView />
    </div>
  );
});
