import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { api } from '@/api';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import {
  agencyStore,
  areaStore,
  breaktimeStore,
  colorStore,
  permanentEmployeeStandardHoursStore,
  languageStore,
  positionStore,
  shiftStore,
} from '@/stores';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValueForBulkUpdate } from '@/utils/controller';
import { useMassUpdateCashedStores } from '@/utils/store';
import { PERMISSION_OBJ_PERMANENT_EMPLOYEE } from '@/constants/permissions';

import { getFormFields } from './setup';
import { DrawerForm } from '@/components/drawer-form';
import { SIDEBAR_WIDTH } from '@/constants';

const entity = 'coordinator-permanentsStandardHours';
const store = permanentEmployeeStandardHoursStore;

export const BulkForm = observer(({ transition }: UIViewInjectedProps) => {
  const { selectedRowKeys = [] } = transition.targetState().params();

  const selectedItems = store.items.filter(({ id }) => selectedRowKeys.includes(id));
  const isStatusFieldEditable = Boolean(selectedItems.length) && selectedItems.every(({ status }) => status === selectedItems[0].status);

  const { loading } = useMassUpdateCashedStores([
    agencyStore,
    areaStore,
    breaktimeStore,
    colorStore,
    languageStore,
    positionStore,
    shiftStore,
  ]);



  const onClose = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };

  const initValues = {
    photoUrl: [],
    status: (selectedItems[0] || {}).status,
    company: (selectedItems[0] || {}).company,
  };

  const title = getTranslatedString('employees.bulk-update');

  const formFields = getFormFields({
    initValues,
    isBulkEdit: true,
    isStatusFieldEditable,
  })
    .map((item) => ({ ...item, rules: item.rules.filter(({ required }) => !required) }))
    .filter((item) => !item.isUniqueField);

  const allowedFields = getAllowedFormFields(formFields, false, PERMISSION_OBJ_PERMANENT_EMPLOYEE);

  const resourceController = withoutRepeatValueForBulkUpdate(initValues, {
    create: (values: any) => {
      const items = selectedRowKeys.map((id: string) => ({ ...values, id }));
      return api.permanentEmployeesStandardHours.bulkUpdate({ items }).source.then(() => store.refresh());
    },
  });
  useEffect(() => {
    if (!selectedRowKeys.length) {
      onClose();
    }
  }, []);
  return (
    <DrawerForm
      onClose={onClose}
      title={title}
      initValues={initValues}
      formFields={allowedFields}
      resourceController={resourceController}
      loaderCondition={loading}
      width={(window.innerWidth - SIDEBAR_WIDTH) / 2}
    />
  );
});
