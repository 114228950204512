import { useRouter } from '@uirouter/react';
import Text from 'antd/lib/typography/Text';
import React, { useState, Dispatch, SetStateAction } from 'react';

import { SearchOutlined, LoadingOutlined } from '@/components/icons';
import {
  notification, Col, Row, Input, AutoComplete,
} from '@/components/antd';
import { api } from '@/api';
import { WrappedItemsResult } from '@/api/types';
import { getTranslatedString } from '@/utils';
import { authStore, searchStore } from '@/stores';
import { SearchEmployeeInputType, SearchEmployeeOutputType } from '@/types/enums';
import {
  PERMISSION_OBJ_AGENCY_STAFFING,
  PERMISSION_OBJ_COORDINATOR_STAFFING,
  PERMISSION_READ,
} from '@/constants/permissions';

const SEARCH_TIMEOUT_MS: number = 500;

let timeout: NodeJS.Timeout;
let currentValue: string;

const routes = {
  employee: [
    {
      routeName: 'base-layout.agency-employees.edit',
      paramName: 'employeeId',
      permissions: [[PERMISSION_OBJ_AGENCY_STAFFING, PERMISSION_READ]],
    },
    {
      routeName: 'base-layout.coordinator-employees.edit',
      paramName: 'employeeId',
      permissions: [[PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ]],
    },
  ],
  shiftWork: [
    {
      routeName: 'base-layout.coordinator-permanentsShiftWork.edit',
      paramName: 'permanentShiftWorkId',
      permissions: [[PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ]],
    },
  ],
  standardHours: [
    {
      routeName: 'base-layout.coordinator-permanentsStandardHours.edit',
      paramName: 'permanentStandardHoursId',
      permissions: [[PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ]],
    },
  ],
};

const deferredRequest = (
  value: string,
  entity: string,
  type: string,
  callback: Dispatch<SetStateAction<mpg.api.search.ResponseItem[]>>,
  setLoading: Dispatch<SetStateAction<boolean>>,
) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }

  currentValue = value;
  setLoading(true);

  const request = () => {
    api.search.get({ q: value, entity, type }).source
      .then(({ data }: WrappedItemsResult<mpg.api.search.ResponseItem[]>) => {
        if (currentValue === value) {
          callback(data.items);
        }
      })
      .finally(() => setLoading(false));
  };

  timeout = setTimeout(request, SEARCH_TIMEOUT_MS);
};

export const Search = ({ customAction }: { customAction? }) => {
  const [data, setData] = useState<mpg.api.search.ResponseItem[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const router = useRouter();

  const { entity, type } = searchStore;
  const handleSearch = (searchText: string) => {
    setSearchValue(searchText);
    searchText.length >= 3 ? deferredRequest(searchText, entity, type, setData, setLoading) : setData([]);
  };

  const handleChange = (selectedValue: string, option: any) => {
    const typedRoutes = routes[option.data?.type];
    const allowRoutes = (typedRoutes || []).filter(({ permissions }) => authStore.hasPermissions(permissions));

    if (!allowRoutes.length) {
      return;
    }
    if (customAction) {
      customAction.customAction(option.data)
        .catch((e) => {
          notification.warn({
            message: getTranslatedString('common.warning'),
            description: e.message,
          });
        })
        .finally(() => {
          setSearchValue(searchValue);
        });
    } else {
      router.stateService
        .go(allowRoutes[0].routeName, { [allowRoutes[0].paramName]: option.data.id })
        .catch((e) => {
          notification.warn({
            message: getTranslatedString('common.warning'),
            description: e.message,
          });
        })
        .finally(() => {
          setSearchValue(searchValue);
        });
    }
  };

  const getType = (employeeType: string) => {
    switch (employeeType) {
      case SearchEmployeeInputType.Employee:
        return SearchEmployeeOutputType.Temporary;
      case SearchEmployeeInputType.ShiftWork:
        return SearchEmployeeOutputType.PermanentShiftWork;
      case SearchEmployeeInputType.StandardHours:
        return SearchEmployeeOutputType.PermanentStandardHours;
      default:
        return '';
    }
  };

  const options = data
    .map(({
      id, kioskPinCode, lastName, name, type: employeeType, workSchedule,
    }: any) => {
      if (workSchedule) employeeType = workSchedule;
      return {
        label:
          <Col>
            <Row>
              {kioskPinCode}
              {' '}
              -
              {' '}
              {name}
              {' '}
              {lastName}
            </Row>
            <Row><Text type="secondary">{getType(employeeType)}</Text></Row>
          </Col>,
        value: `${id}-${employeeType}`,
        data: {
          id, type: employeeType, name, lastName,
        },
      };
    });

  const suffixIcon = loading
    ? <LoadingOutlined className="ant-select-arrow" />
    : <SearchOutlined className="ant-select-arrow" />;

  return (
    <AutoComplete
      value={searchValue}
      onSearch={handleSearch}
      onChange={handleChange}
      options={options}
      style={{ width: '100%', maxWidth: 450 }}
      virtual
    >
      <Input placeholder={getTranslatedString('common.search')} suffix={suffixIcon} />
    </AutoComplete>
  );
};
