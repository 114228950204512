import { makeAutoObservable, observable, action } from 'mobx';

import moment from 'moment/moment';
import { api } from '@/api';

import { getFullDateWithTimeZone } from '@/utils/date';
import { DATE_WITHOUT_TIME_FORMAT, getEndOfMonthAndDayDate, getStartOfMonthAndDayDate } from '@/utils/moment';
import { showError } from '@/utils/common';

export class CalendarShiftPlanningStore {
  @observable items: mpg.api.shiftPlanning.ShiftPlanning[] = [];

  @observable calendarElements: Map<string, mpg.api.shiftPlanning.ShiftPlanning[]> = new Map();

  @observable loading: boolean = false;

  currentDate: moment.Moment;

  currentBuilding: mpg.api.buildings.Building;

  constructor() {
    makeAutoObservable(this);
  }

  @action load = async (selectedBuilding: mpg.api.buildings.Building, currentDate: moment.Moment) => {
    this.items = [];
    this.calendarElements.clear();

    this.loading = true;
    this.currentDate = currentDate;
    this.currentBuilding = selectedBuilding;

    const startOfTheMonth = getStartOfMonthAndDayDate(this.currentDate);
    const endOfTheMonth = getEndOfMonthAndDayDate(this.currentDate);

    try {
      const resp = await api.shiftPlanningTemps.getAll({
        limit: 1000,
        building: this.currentBuilding,
        eventStart: [startOfTheMonth, endOfTheMonth],
      }).source;
      this.items = await resp.data.items;
      for (let i = 0; moment(startOfTheMonth).add(i, 'day').isBefore(endOfTheMonth); i++) {
        const data = this.items
          .filter(({ eventStart }) =>
            moment(startOfTheMonth)
              .add(i, 'day')
              .isSame(getFullDateWithTimeZone(eventStart, this.currentBuilding?.timezone), 'day'),
          )
          .sort((left, right) => moment.utc(moment(left.eventStart)).diff(moment.utc(right.eventStart)));
        this.calendarElements.set(moment(startOfTheMonth).add(i, 'day').format(DATE_WITHOUT_TIME_FORMAT), data);
      }
      this.loading = false;
    } catch (error) {
      showError(error);
      this.loading = false;
    }
  };

  getDateShiftPlanning(date: moment.Moment) {
    return this.calendarElements.get(moment(date).format(DATE_WITHOUT_TIME_FORMAT)) || [];
  }

  refresh() {
    this.load(this.currentBuilding, this.currentDate);
  }
}
