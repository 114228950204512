import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Space } from '@/components/antd';

import { api } from '@/api';
import { EditAction, UnassignAction } from '@/components/table/actions';
import { getColumnBooleanFields, getColumnDateFields, getColumnSearchFields } from '@/components/table/filters';
import { BooleanValueRender } from '@/components/table/renders';
import {
  PERMISSION_OBJ_COORDINATOR_LOCKER,
  PERMISSION_OBJ_LOCKER,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { buildingStore, lockerStore } from '@/stores';
import { EmployeeStatus, EmployeeType, LockerStatus } from '@/types/enums';
import { buildFilterOptionsFromEnum, buildStoreOptions, getStorePropById, showError } from '@/utils/common';
import { getDateWithoutTime } from '@/utils/moment';

const PermissionedEditAction = withPermissions([
  [PERMISSION_OBJ_COORDINATOR_LOCKER, PERMISSION_READ],
  [PERMISSION_OBJ_LOCKER, PERMISSION_UPDATE],
])(EditAction);
const PermissionedUnassignAction = withPermissions([
  [PERMISSION_OBJ_COORDINATOR_LOCKER, PERMISSION_READ],
  [PERMISSION_OBJ_LOCKER, PERMISSION_UPDATE],
])(UnassignAction);

const Actions = (_: any, { id, firstName, lastName, employeeId, buildingId }: mpg.api.lockers.Locker) => {
  const onUnassign = () => {
    const lockerParams = { employeeId: '', buildingId };

    api.lockers
      .update(id, lockerParams)
      .source.then(() => lockerStore.refresh())
      .catch(showError);
  };

  const unassignMsg = `Are You Sure to unassign ${firstName || ''} ${lastName || ''}?`;
  const disabled = !employeeId;

  return (
    <Space>
      <PermissionedEditAction uiSref={{ to: 'base-layout.coordinator-lockers.edit', params: { lockerId: id } }} />
      <PermissionedUnassignAction onConfirm={onUnassign} title={unassignMsg} disabled={disabled} />
    </Space>
  );
};

export const getColumns = () => [
  // {
  //   title: 'id',
  //   dataIndex: 'id',
  //   sorter: true,
  // },
  {
    title: 'buildingId',
    dataIndex: 'buildingId',
    filters: buildStoreOptions(buildingStore, 'name'),
    sorter: true,
    hidden: true,
    visible: false,
    render: (itemId: string) => getStorePropById<mpg.api.buildings.Building>(itemId, buildingStore),
  },
  {
    title: <FormattedMessage id="lockers.number" />,
    dataIndex: 'serial',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="lockers.name" />,
    dataIndex: 'name',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="lockers.employeeId" />,
    dataIndex: 'employeeId',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="lockers.firstName" />,
    dataIndex: 'firstName',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="lockers.lastName" />,
    dataIndex: 'lastName',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="lockers.employeeType" />,
    dataIndex: 'employeeType',
    filters: buildFilterOptionsFromEnum(EmployeeType, 'lockers.employeeType.'),
    filterMultiple: false,
    sorter: true,
    render: (employeeType: string) => employeeType && <FormattedMessage id={`lockers.employeeType.${employeeType}`} />,
  },
  {
    title: <FormattedMessage id="lockers.employeeBuilding" />,
    dataIndex: 'actualBuildingId',
    filters: buildStoreOptions(buildingStore, 'name'),
    filterMultiple: true,
    render: (itemId: string) => getStorePropById<mpg.api.buildings.Building>(itemId, buildingStore),
  },
  {
    title: <FormattedMessage id="lockers.employeeStatus" />,
    dataIndex: 'employeeStatus',
    filters: buildFilterOptionsFromEnum(EmployeeStatus, 'employees.status.'),
    filterMultiple: true,
    render: (status: string) => status && <FormattedMessage id={`employees.status.${status}`} />,
  },
  {
    title: <FormattedMessage id="lockers.dateLockerAssigned" />,
    dataIndex: 'dateLockerAssigned',
    sorter: true,
    ...getColumnDateFields(false),
    render: (item: string) => getDateWithoutTime(item),
  },
  {
    title: <FormattedMessage id="lockers.serialNumber" />,
    dataIndex: 'serialNumber',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="lockers.seriesNumber" />,
    dataIndex: 'seriesNumber',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="lockers.code1" />,
    dataIndex: 'code1',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="lockers.code2" />,
    dataIndex: 'code2',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="lockers.code3" />,
    dataIndex: 'code3',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="lockers.code4" />,
    dataIndex: 'code4',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="lockers.code5" />,
    dataIndex: 'code5',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="lockers.status" />,
    dataIndex: 'status',
    filters: buildFilterOptionsFromEnum(LockerStatus, 'lockers.status.'),
    filterMultiple: true,
    sorter: true,
    render: (status: string) => <FormattedMessage id={`lockers.status.${status}`} />,
  },
  {
    title: <FormattedMessage id="lockers.isAssigned" />,
    dataIndex: 'isAssigned',
    sorter: true,
    render: BooleanValueRender,
    ...getColumnBooleanFields(false),
  },
  {
    title: <FormattedMessage id="lockers.comment" />,
    dataIndex: 'comment',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="table.actions" />,
    dataIndex: 'actions',
    render: Actions,
  },
];
