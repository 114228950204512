import { buildStoreOptions } from '@/utils/common';
import { buildingStore } from '@/stores';
import { buildingChanged } from '@/utils/form-rules';
import { SelectOption } from '@/types/common';

interface IProps {
  initValues?: Omit<mpg.api.buildingHost.BuildingHost, 'type' | 'assignedDate'>;
  isCreate: boolean;
  onChangeBuilding?: (id: mpg.api.buildings.BuildingId) => void;
  permEmployeeOptions: SelectOption[];
  debouncedOptionsFetch: (...args: string[]) => void;
}

export const getFormFields = ({ onChangeBuilding, isCreate, permEmployeeOptions, debouncedOptionsFetch }: IProps) => [
  {
    name: 'permanentEmployeeId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'building-hosts.perm-employee',
      onSearch: (e) => debouncedOptionsFetch(e),
      options: permEmployeeOptions,
      disabled: !isCreate,
      virtual: true,
    },
    rules: [{ required: true }],
  },
  {
    name: 'buildingIds',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'building-hosts.buildings',
      options: buildStoreOptions(buildingStore, 'name'),
      dependencies: ['musterStationId'],
      mode: 'multiple',
      onChange: onChangeBuilding,
    },

    rules: [buildingChanged, { required: true }],
  },
];
