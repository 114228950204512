import { UIViewInjectedProps } from '@uirouter/react';
import { Form as AttendanceForm } from '../../common/components/attendance-reports/form';
import { AttendanceReports as AttendanceReportsComponent } from '../../common/components/attendance-reports/table';

import { PERMISSION_OBJ_AGENCY_SHIFT_REPORTING, PERMISSION_READ } from '@/constants/permissions';

const AttendanceReports = ({ transition }: UIViewInjectedProps) => {
  const tabConfig = {
    entity: 'agency-attendanceReports',
    localStorageKey: 'agency-attendanceReports-table',
    isEmployeeTypeShown: false,
  };
  return <AttendanceReportsComponent
    tabConfig={tabConfig}
    transition={transition}
  />;
};

const Form = ({ transition }: UIViewInjectedProps) => (
  <AttendanceForm
    entity="agency-attendanceReports"
    permission={PERMISSION_OBJ_AGENCY_SHIFT_REPORTING}
    isEmployeeTypeShown={false}
    isFilterWithOptions={false}
    transition={transition}
  />
);

export const states = [
  {
    name: 'base-layout.agency-attendanceReports',
    url: '/agency-attendancereports',
    component: AttendanceReports,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_AGENCY_SHIFT_REPORTING, PERMISSION_READ]],
    },
  },
  {
    name: 'base-layout.agency-attendanceReports.view',
    url: '/:attendanceReportId/view',
    component: Form,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_AGENCY_SHIFT_REPORTING, PERMISSION_READ]],
    },
  },
];
