import { useTimesheetWeeks } from '@/utils/hooks';
import { getTimesheetColumn } from '../setup';
import { useMassUpdateCashedStores } from '@/utils/store';
import { agencyStore, areaStore, buildingStore, shiftStore, weekTimesheetStore } from '@/stores';

export const useWeekTimesheetData = (): {
  loading: boolean;
  weeks: number[];
  columns: any[];
  store: any;
  storesLoading: boolean;
} => {
  const weeks = useTimesheetWeeks();

  const { loading } = useMassUpdateCashedStores([agencyStore, shiftStore, areaStore, buildingStore]);

  return {
    weeks,
    loading: loading || weekTimesheetStore.loading,
    columns: getTimesheetColumn(weeks),
    store: weekTimesheetStore,
    storesLoading: loading,
  };
};
