import { FormattedMessage } from 'react-intl';
import React from 'react';
import { withPermissions } from '@/hocs/permission';
import { Button, Menu } from '@/components/antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@/components/icons';
import { ImportButton } from '@/components/import-button';

interface CreateButtonsProps {
  permissions: [string, number][];
  i18nId: string;
  onClick: (e: any) => void;
  loading?: boolean;
  style?: any;
  disabled?: boolean;
}

export const CreateButton = ({ permissions, onClick, i18nId, style = {} }: CreateButtonsProps) => {
  const ButtonWithPermissions = withPermissions(permissions)(() => (
    <Button style={style} onClick={onClick} type="primary" shape="round" icon={<PlusOutlined />}>
      <span>
        <FormattedMessage id={i18nId} />
      </span>
    </Button>
  ));
  return <ButtonWithPermissions />;
};

interface ButtonProps {
  permissions: [string, number][];
  onClick: (e: any) => void;
}

export const BulkUpdateButton = ({ permissions, onClick }: ButtonProps) => {
  const UpdateButtonWithPermissions = withPermissions(permissions)(() => <FormattedMessage id="button.update" />);
  return (
    <Menu.Item key="update" icon={<EditOutlined />} onClick={onClick}>
      <UpdateButtonWithPermissions />
    </Menu.Item>
  );
};

export const BulkDeleteButton = ({ permissions, onClick }: ButtonProps) => {
  const BulkDeleteButtonWithPermissions = withPermissions(permissions)(() => <FormattedMessage id="button.remove" />);
  return (
    <Menu.Item key="remove" icon={<DeleteOutlined />} onClick={onClick}>
      <BulkDeleteButtonWithPermissions />
    </Menu.Item>
  );
};
export const BulkAddRateButton = ({ permissions, onClick }: ButtonProps) => {
  const BulkAddRateButtonPermissions = withPermissions(permissions)(() => <FormattedMessage id="button.add-rate" />);
  return (
    <Menu.Item key="add-rate" onClick={onClick} icon={<EditOutlined />}>
      <BulkAddRateButtonPermissions />
    </Menu.Item>
  );
};
// interface BulkCreateButtonProps
//   request: (
//     parsedItems: mpg.api.employees.EmployeeParams[],
//   ) => ApiResponse<WrappedItemsResult<mpg.api.employees.Employee[]>>;
//   storeRefreshRequest: () => ApiResponse<WrappedItemsResult<mpg.api.employees.Employee[]>>;
//   settings: ParseSettings;
// }
interface BulkCreateButtonProps {
  request: any;
  storeRefreshRequest: any;
  settings: any;
  permissions: [string, number][];
}
// TODO: fix types for this component

export const BulkCreateButton = ({ permissions, request, storeRefreshRequest, settings }: BulkCreateButtonProps) => {
  const BulkCreateButtonWithPerm = withPermissions(permissions)(() => (
    <ImportButton request={request} parseSettings={settings} storeRefreshRequest={storeRefreshRequest} />
  ));
  return <BulkCreateButtonWithPerm />;
};
