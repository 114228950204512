import { Rule } from 'antd/lib/form';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import { Space } from '@/components/antd';
import { ReadAction } from '@/components/table/actions';
import { PERMISSION_OBJ_PERMANENT_EMPLOYEE, PERMISSION_READ } from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { buildDropdownOptionsFromEnum } from '@/utils/common';
import { getTranslatedString } from '@/utils';
import { AttachmentType, Company, EmployeeStatus, EmployeeType, WorkScheduleType } from '@/types/enums';
import { emailOrPhoneRequired, phone } from '@/utils/form-rules';
import { PermEmployeeStatus } from '../table/setup';
import { DateComponent } from '@/components/form-date';

interface Props {
  initValues: Partial<
    Omit<mpg.api.permanentEmployees.PermanentEmployee, 'birthday' | 'startDate' | 'statusUpdatedOn' | 'locker'> & {
      birthday: moment.Moment;
      startDate: moment.Moment;
      statusUpdatedOn: moment.Moment;
    }
  >;
  isBulkEdit?: boolean;
  isCreate?: boolean;
  isStatusFieldEditable?: boolean;
  isMusterStationsFieldEditable?: boolean;
  isMusterStationCaptainsFieldEditable?: boolean;
  isLineFieldEditable?: boolean;
  onChangeBuilding?: (id: mpg.api.buildings.BuildingId) => void;
  musterStationsOptions?: mpg.api.musterStations.MusterStation[];
  musterStationCaptainsOptions?: mpg.api.musterStationCaptains.MusterStationCaptain[];
  linesOptions?: mpg.api.lines.Line[];
}

export const getFormFields = ({
  initValues,
  isBulkEdit = false,
  isStatusFieldEditable = true,
  isCreate = false,
}: Props) => {
  const { status = '' } = initValues;

  const statusOptionsPerm =
    status && !isCreate
      ? buildDropdownOptionsFromEnum(PermEmployeeStatus, 'permanentsStandardHours.status.')
      : [
        {
          value: EmployeeStatus.New,
          label: getTranslatedString(`permanentsStandardHours.status.${EmployeeStatus.New}`),
        },
        {
          value: EmployeeStatus.Perm,
          label: getTranslatedString(`permanentsStandardHours.status.${EmployeeStatus.Perm}`),
        },
      ];

  return [
    {
      name: 'attachmentId',
      componentName: 'upload',
      conditionalRender: () => !isBulkEdit && !isCreate,
      params: {
        i18nLabel: 'permanentsStandardHours.photo',
        style: { gridColumnStart: 1, gridColumnEnd: -1 },
        objectId: initValues.id,
        hasLabel: false,
        attachmentType: AttachmentType.PermanentPersonPhoto,
      },
      rules: [
        // {
        //   required: true,
        //   message: 'Please upload photo'
        // },
      ],
    },
    {
      name: 'employeeId',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'permanentsStandardHours.employeeId',
      },
      rules: [{ required: true }],
    },
    {
      name: 'wiegangId',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'permanentsStandardHours.wiegangId',
        disabled: true,
      },
      rules: [{ required: true }],
    },
    {
      name: 'employeeType',
      componentName: 'dropdown',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'permanentsStandardHours.employeeType',
        options: buildDropdownOptionsFromEnum([EmployeeType.Permanent], 'permanentsStandardHours.employeeType.'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'workSchedule',
      componentName: 'dropdown',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'permanentsStandardHours.workSchedule',
        options: buildDropdownOptionsFromEnum(
          [WorkScheduleType.StandardHours],
          'permanentsStandardHours.workSchedule.',
        ),
      },
      rules: [{ required: true }],
    },
    {
      name: 'status',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'permanentsStandardHours.status',
        options: statusOptionsPerm,
        disabled: !isStatusFieldEditable,
        description:
          isBulkEdit &&
          !isStatusFieldEditable &&
          'In bulk edit mode you can change status only for records with the same current status',
      },
      rules: [{ required: true }],
    },
    {
      name: 'firstName',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'permanentsStandardHours.firstName',
      },
      rules: [{ required: true }],
    },
    {
      name: 'middleName',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'permanentsStandardHours.middleName',
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'lastName',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'permanentsStandardHours.lastName',
      },
      rules: [{ required: true }],
    },
    {
      name: 'email',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'permanentsStandardHours.email',
        dependencies: ['phone'],
      },
      rules: [emailOrPhoneRequired, { type: 'email' }] as Rule[],
    },
    {
      name: 'phone',
      componentName: 'phone',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'permanentsStandardHours.phone',
        dependencies: ['email'],
      },
      rules: [emailOrPhoneRequired, phone],
    },
    {
      name: 'company',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'permanentsStandardHours.company',
        options: buildDropdownOptionsFromEnum(Company, 'permanentsStandardHours.company.'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'occupation',
      componentName: 'input',
      params: {
        i18nLabel: 'permanentsStandardHours.occupation',
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'isMusterStationCaptain',
      componentName: 'switch',
      params: {
        i18nLabel: 'permanentsStandardHours.isMusterStationCaptain',
        asDropdown: isBulkEdit,
      },
      rules: [],
    },
    {
      name: 'startDate',
      componentName: 'datepicker',
      params: {
        i18nLabel: 'permanentsStandardHours.startDate',
        format: 'MM/DD/YYYY',
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'locker',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit && !isCreate,
      params: {
        i18nLabel: 'permanentsStandardHours.locker',
        disabled: true,
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'birthday',
      componentName: 'datepicker',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'permanentsStandardHours.birthday',
        format: 'MM/DD/YYYY',
        disabledDate: (current: moment.Moment) => current && current > moment().endOf('day'),
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'terminateDescription',
      componentName: 'input',
      params: {
        i18nLabel: 'permanentsStandardHours.termDescription',
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'parkingTag',
      componentName: 'input',
      params: {
        i18nLabel: 'permanentsStandardHours.parkingTag',
      },
      rules: [
        // { required: true },
      ],
      isUniqueField: true,
    },
    {
      name: 'statusUpdatedBy',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit && !isCreate,
      params: {
        i18nLabel: 'permanentsStandardHours.statusUpdatedBy',
        disabled: true,
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'statusUpdatedOn',
      componentName: 'datepicker',
      conditionalRender: () => !isBulkEdit && !isCreate,
      params: {
        i18nLabel: 'permanentsStandardHours.statusUpdatedOn',
        showTime: true,
        format: 'MM/DD/YYYY h:mm:ss a',
        disabled: true,
      },
      rules: [
        // { required: true },
      ],
    },
  ];
};

export const getAdditionalColumns = () => {
  const actions = {
    C: 'Create',
    R: 'Read',
    U: 'Update',
    D: 'Delete',
  };
  const PermissionedReadAction = withPermissions([[PERMISSION_OBJ_PERMANENT_EMPLOYEE, PERMISSION_READ]])(ReadAction);

  const Action = (_: any, { action }: any) => <Space>{actions[action] || ''}</Space>;

  const Actions = (_: any, { id }: any) => (
    <Space>
      <PermissionedReadAction
        uiSref={{
          to: 'base-layout.coordinator-permanentsStandardHours.edit.audit',
          params: { recordId: id },
        }}
      />
    </Space>
  );

  const Difference = (_: any, { newData: recordNewData, originalData: recordOriginalData }: any) => {
    const newData = recordNewData || {};
    const originalData = recordOriginalData || {};

    const keys = Object.keys({ ...newData, ...originalData });

    const changedKeys = keys.filter((key) => newData[key] !== originalData[key]).sort();

    return (
      <Space
        style={{
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {keys.length === changedKeys.length ? 'All' : changedKeys.join(', ')}
      </Space>
    );
  };

  const User = ({ name }: any) => <Space>{name || 'deleted'}</Space>;

  return [
    {
      title: <FormattedMessage id="audit.user" />,
      dataIndex: 'user',
      render: User,
    },
    {
      title: <FormattedMessage id="audit.action" />,
      dataIndex: 'action',
      render: Action,
    },
    {
      title: <FormattedMessage id="audit.createdAt" />,
      dataIndex: 'createdAt',
      render: DateComponent,
    },
    {
      title: <FormattedMessage id="audit.changedFields" />,
      dataIndex: 'changedFields',
      render: Difference,
      minWidth: 350,
    },
    {
      title: <FormattedMessage id="table.actions" />,
      render: Actions,
      minWidth: 80,
    },
  ];
};
