import React, { useRef, useState } from 'react';
import { Button, Form } from '@/components/antd';

import { Drawer } from '@/components/drawer';
import { FormBuilderOptionItem, GenericForm, GenericFormProps } from '@/components/generic-form';
import { DownOutlined } from '@/components/icons';
import { Loader } from '@/components/loader';
import { VoidFnType } from '@/types/common';
import { SIDEBAR_WIDTH } from '@/constants';

// @ts-ignore
export interface DrawerFormProps extends GenericFormProps {
  loaderCondition: boolean;
  title: React.ReactNode;
  initValues: any;
  formFields: FormBuilderOptionItem[];
  tailLayout?: object;
  onClose: VoidFnType;
  onSuccessFinish?: VoidFnType;
  onFinish?: (values: any) => void;
  visible?: boolean;
  useDisableSubmit?: boolean;
  width?: number;
  additionalInformation?: any;
  extra?: React.ReactNode;
  headerStyle?: React.CSSProperties;
  customDisabled?: boolean;
  initialDisableSubmitValue?: boolean;
  resetFieldsBeforeDrawerOpened?: boolean;
}

export const DrawerForm = (
  {
    title,
    initValues,
    formFields,
    onClose,
    onFinish,
    onSuccessFinish,
    loaderCondition,
    visible = true,
    useDisableSubmit = true,
    width = window.innerWidth - SIDEBAR_WIDTH,
    additionalInformation,
    extra,
    headerStyle,
    customDisabled,
    initialDisableSubmitValue = true,
    resetFieldsBeforeDrawerOpened = false,
    ...props
  }: DrawerFormProps,
) => {
  const [formRef] = Form.useForm();
  const form = props.form || formRef;
  const additionalContainer = useRef<HTMLDivElement>(null);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(initialDisableSubmitValue);

  const onStartSubmit = () => {
    setLoadingSubmit(true);
  };

  const onFinishSubmit = () => {
    setLoadingSubmit(false);
    setDisableSubmit(true);
  };

  const onFieldsChange = (_, all: any[]) => {
    const disable = all.some(({ errors }: any) => Boolean(errors.length));
    if (disableSubmit !== disable) {
      setDisableSubmit(disable);
    }
  };

  const DrawerFooter = ({ onCloseFooter }: { onCloseFooter: VoidFunction }) => {
    let disabled = useDisableSubmit ? disableSubmit : false;
    if (customDisabled !== undefined) {
      disabled = customDisabled;
    }

    return (
      <div className="drawer-footer">
        <Button className="drawer-footer-button" shape="round" onClick={onCloseFooter}>
          Cancel
        </Button>
        <Button onClick={form.submit} shape="round" type="primary" loading={loadingSubmit} disabled={disabled}>
          Submit
        </Button>
      </div>
    );
  };

  const Footer = (onCloseFooter: VoidFnType) => props?.resourceController && <DrawerFooter onCloseFooter={onCloseFooter} />;

  const additionalAnchorTitle = additionalInformation?.anchorTitle || '';
  const AdditionalView = additionalInformation?.View || null;

  const onAdditionalAnchorClick = () => {
    additionalContainer.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const beforeDrawerOpened = () => {
    resetFieldsBeforeDrawerOpened && formRef.resetFields();
  };

  return (
    <Drawer
      onClose={onClose}
      beforeDrawerOpened={beforeDrawerOpened}
      visible={visible}
      title={title}
      footer={Footer}
      width={width}
      extra={extra}
      headerStyle={headerStyle}
    >
      {
        (loaderCondition)
          ? <Loader />
          : (
            <>
              {
              additionalAnchorTitle?.length
                ? (
                  <div className="anchor" onClick={onAdditionalAnchorClick}>
                    {additionalAnchorTitle}
                    {' '}
                    <DownOutlined />
                  </div>
                )
                : null
            }
              <GenericForm
                {...props}
                formRef={form}
                initialValues={initValues}
                formFields={formFields}
                onFinish={onFinish}
                onSuccessFinish={onSuccessFinish || onClose}
                onStartSubmit={onStartSubmit}
                onFinishSubmit={onFinishSubmit}
                onFieldsChange={props.onFieldsChange || onFieldsChange}
              />
              {AdditionalView && <div ref={additionalContainer}>{AdditionalView}</div>}
            </>
          )
      }
    </Drawer>
  );
};
