import React, { CSSProperties } from 'react';

interface PulsatingCircleProps {
  pulsing?: boolean;
  color: string;
  style?: CSSProperties;
}

export const PulsatingCircle = ({ pulsing = false, color, style }: PulsatingCircleProps) => (
  <div
    className={`c-circle ${pulsing && 'c-circle--pulsing'}`}
    style={{
      ...style,
      ['--color' as string]: color,
    }}
  />
);
