import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import moment from 'moment/moment';
import { agencyStore, buildingStore, colorStore, shiftStore } from '@/stores';
import { Button, PageHeader, Space } from '@/components/antd';
import { FilterSwitcher } from '@/components/filter-switcher';
import { definitions, filtersSetup } from './table/filters';
import { FilterOutlined, PrinterOutlined, SettingOutlined } from '@/components/icons';
import { onPrint } from '@/utils/common';
import ExportButton from '@/pages/common/components/buttons/export-button';
import { Table } from '@/components/table';
import { useMassUpdateCashedStores } from '@/utils/store';
import { withPermissions } from "@/hocs/permission";

export const EmployeeTab = observer(
  ({
    store,
    employeeType,
    columns,
    transition,
    permissionObj,
    exportToExcel,
    exportToEmail,
    fileName,
    entity,
    localStorageKey,
  }: any) => {
    const tableRef: any = useRef({});
    const { items } = store;
    const { loading } = useMassUpdateCashedStores([agencyStore, buildingStore, colorStore, shiftStore]);
    const onDoubleClick = (record) => {
      transition.router.stateService.go(`base-layout.${entity}.edit`, { shiftReportId: record.id });
    };

    const initialFilters = {
      employeeType,
      date: [
        moment()
          .set({
            h: 0,
            m: 0,
            s: 0,
            ms: 0,
          })
          .subtract(3, 'day')
          .toISOString(),
        moment()
          .set({
            h: 23,
            m: 59,
            s: 59,
            ms: 999,
          })
          .toISOString(),
      ],
    };
    return (
      <>
        <PageHeader
          className="r-antd-page-header"
          extra={[
            <Space align="start" key="shift-report-header">
              <FilterSwitcher
                key="filter-switcher"
                filters={tableRef.current && tableRef.current.filters}
                setFilters={tableRef.current && tableRef.current.setFilters}
                filtersSetup={filtersSetup}
                definitions={definitions}
                store={store}
              />
              <Button
                style={{ marginTop: 4 }}
                key="filters"
                size="middle"
                icon={<FilterOutlined />}
                onClick={tableRef.current && tableRef.current.showFiltersModal}
              />
              <Button style={{ marginTop: 4 }} key="print" size="middle" icon={<PrinterOutlined />} onClick={onPrint} />
              <Button
                style={{ margin: '4px 8px 0 8px' }}
                key="setting"
                size="middle"
                icon={<SettingOutlined />}
                onClick={tableRef.current && tableRef.current.showSettingsModal}
              />
              <ExportButton
                store={store}
                exportToExcel={exportToExcel}
                exportToEmail={exportToEmail}
                withPermission={withPermissions([permissionObj])}
                fileName={fileName}
              />
            </Space>,
            // <Button key="export" icon={<ExportOutlined />} type="primary" shape="circle" />,
          ]}
        />
        <Table
          tableRef={tableRef}
          onRow={(record) => ({
            onDoubleClick: () => onDoubleClick(record),
          })}
          localStorageKey={localStorageKey}
          rowKey="id"
          columns={columns}
          dataSource={[...items]}
          store={store}
          checkPermissions
          permissionObj={permissionObj}
          loading={store.loading || loading}
          scroll={{ y: '100%' }}
          filtersSetup={filtersSetup}
          initialFilters={initialFilters}
          isAutoloadAllowed={!loading}
          initialSorters={{
            columnKey: 'id',
            field: 'id',
            order: 'ascend',
          }}
        />
      </>
    );
  },
);
