import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import { Drawer } from '@/components/drawer';
import { Table } from '@/components/table';
import {
  agencyStore,
  attendanceReportDetailsStore,
  breaktimeStore,
  buildingStore,
  colorStore,
  shiftStore,
  supervisorStore,
} from '@/stores';
import { getTranslatedString } from '@/utils';
import { useTableDataByKey } from '@/utils/hooks';
import { useMassUpdateCashedStores } from '@/utils/store';
import { SIDEBAR_WIDTH } from '@/constants';

import { getColumns } from '@/pages/common/components/shift-reports/components/table/setup';

interface IProps extends UIViewInjectedProps {
  entity: string;
  permission: string;
  isEmployeeTypeShown: boolean;
  isFilterWithOptions: boolean;
}

export const Form = observer(({ entity, permission, isEmployeeTypeShown, isFilterWithOptions, transition }: IProps) => {
  const { attendanceReportId } = transition.router.globals.params;
  const params = useMemo(() => JSON.parse(decodeURIComponent(atob(attendanceReportId))), [attendanceReportId]);

  const { loading } = useMassUpdateCashedStores([
    agencyStore,
    breaktimeStore,
    buildingStore,
    colorStore,
    shiftStore,
    supervisorStore,
  ]);

  const columns = useMemo(
    () =>
      getColumns(isEmployeeTypeShown, isFilterWithOptions).map((column) => ({
        ...column,
        sorter: false,
        filters: null,
        filterDropdown: null,
      })),
    [],
  );

  const {
    dataSource,
    loading: formDataLoading,
    additionalStore: store,
    onChangeTable,
  } = useTableDataByKey(params, attendanceReportDetailsStore, columns);

  const onBack = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };

  const { firstName = '', lastName = '' } = params || {};
  const title = getTranslatedString('attendanceReports.edit-title', { 0: ` ${firstName} ${lastName}` });

  return (
    <Drawer onClose={onBack} visible title={title} width={window.innerWidth - SIDEBAR_WIDTH}>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={dataSource || []}
        store={store}
        checkPermissions
        permissionObj={permission}
        loading={store.loading || formDataLoading || loading}
        scroll={{ y: '100%' }}
        customHeight="87vh"
        onChange={onChangeTable}
      />
    </Drawer>
  );
});
