import React, { useCallback, useRef } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { Table, Button } from '@/components/antd';

import { getColumns } from './settings-table-setup';

const type = 'DraggableBodyRow';

const DraggableBodyRow = ({
  index, moveRow, className, style, ...restProps
}) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {} as any;
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item: any) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

export const SettingsTable = ({
  value, setValue, columns, defaultSettings,
}) => {
  const data = value.map(({ visible, dataIndex }) => {
    const column = columns.find((c) => c.dataIndex === dataIndex);
    return {
      ...column,
      visible,
    };
  });

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = value[dragIndex];
      setValue(
        update(value, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
    },
    [value],
  );

  const onResetToDefaultClick = () => {
    setValue(defaultSettings);
  };

  return (
    <>
      <Button type="primary" shape="round" onClick={onResetToDefaultClick}>Reset to default settings</Button>
      <br />
      <br />
      <DndProvider backend={HTML5Backend}>
        <Table
          className="setting-table"
          columns={getColumns({ value, setValue })}
          rowKey="dataIndex"
          dataSource={data}
          components={components}
          bordered={false}
          pagination={false}
          onRow={(record, index) => ({
            index,
            moveRow,
          } as any)}
        />
      </DndProvider>

    </>
  );
};
