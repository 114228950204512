import { observer } from 'mobx-react-lite';

import React, { useRef } from 'react';

import { UIView, UIViewInjectedProps } from '@uirouter/react';
import moment from 'moment/moment';
import { SettingOutlined, PrinterOutlined, FilterOutlined } from '@/components/icons';
import { Button, PageHeader } from '@/components/antd';
import { Table } from '@/components/table';
import {
  PERMISSION_OBJ_WORKING_LOG_TEMP_SHIFT,
  PERMISSION_OBJ_WORKING_LOG_PERM_SHIFT,
  PERMISSION_OBJ_WORKING_LOG_PERM_STANDARD,
  PERMISSION_READ,
} from '@/constants/permissions';
import {
  agencyStore,
  breaktimeStore,
  buildingStore,
  buildingsAreasStore,
  colorStore,
  shiftStore,
  supervisorStore,
  workingLogStore,
} from '@/stores';
import { useMassUpdateCashedStores } from '@/utils/store';

import { getColumns } from './setup';
import { onPrint } from '@/utils/common';
import { api } from '@/api';
import { withAtLeastOnePermission } from '@/hocs/permission';
import { useCheckInTurnStiles, useCheckOutTurnStiles, useWeeks } from '@/utils/hooks';
import ExportButton from '@/pages/common/components/buttons/export-button';
import { FilterSwitcher } from '@/components/filter-switcher';
import { getFiltersSetup } from './filters';

const store = workingLogStore;

interface IForm extends UIViewInjectedProps {
  entity: string;
}

export const WorkingLogs = observer(({ transition, entity }: IForm) => {
  const { items } = store;
  const tableRef: any = useRef({});
  const weeks = useWeeks();
  const checkInTurnStiles = useCheckInTurnStiles();
  const checkOutTurnStiles = useCheckOutTurnStiles();

  const { loading } = useMassUpdateCashedStores([
    agencyStore,
    breaktimeStore,
    breaktimeStore,
    buildingStore,
    buildingsAreasStore,
    colorStore,
    shiftStore,
    supervisorStore,
  ]);

  const onDoubleClick = (record) => {
    transition.router.stateService.go(`base-layout.${entity}.edit`, { workingLogId: record.id });
  };

  const now = moment();
  const initialFilters = {
    checkInDate: [
      moment(now).subtract(2, 'week').toISOString(),
      now.toISOString(),
    ],
  };

  return (
    <>
      <PageHeader
        className="r-antd-page-header"
        extra={[
          <FilterSwitcher
            key="filter-switcher"
            filters={tableRef.current && tableRef.current.filters}
            setFilters={tableRef.current && tableRef.current.setFilters}
            filtersSetup={getFiltersSetup()}
            store={store}
            setShowFilterModal={tableRef.current && tableRef.current.showFiltersModal}
          />,
          <Button
            style={{ marginTop: 4 }}
            key="filters"
            size="middle"
            icon={<FilterOutlined />}
            onClick={tableRef.current.showFiltersModal}
          />,
          <Button key="print" size="middle" icon={<PrinterOutlined />} onClick={onPrint} />,
          <Button
            size="middle"
            key="setting"
            icon={<SettingOutlined />}
            onClick={tableRef.current.showSettingsModal}
          />,
          <ExportButton
            store={store}
            exportToExcel={api.workingLogs.exportToExcel}
            exportToEmail={api.workingLogs.exportToEmail}
            withPermission={withAtLeastOnePermission([
              [PERMISSION_OBJ_WORKING_LOG_TEMP_SHIFT, PERMISSION_READ],
              [PERMISSION_OBJ_WORKING_LOG_PERM_SHIFT, PERMISSION_READ],
              [PERMISSION_OBJ_WORKING_LOG_PERM_STANDARD, PERMISSION_READ],
            ])}
            fileName="mpg-working-logs"
          />,
        ]}
      />

      <Table
        tableRef={tableRef}
        onRow={(record) => ({
          onDoubleClick: () => onDoubleClick(record),
        })}
        localStorageKey={`${entity}-table`}
        rowKey="id"
        columns={getColumns(weeks, checkInTurnStiles, checkOutTurnStiles, entity === 'agency-workingLogs')}
        dataSource={items}
        store={store}
        checkPermissions
        loading={store.loading || loading}
        scroll={{ y: '100%' }}
        filtersSetup={getFiltersSetup()}
        initialFilters={initialFilters}
        isAutoloadAllowed={!loading}
        initialSorters={{
          columnKey: 'checkInDate',
          field: 'checkInDate',
          order: 'ascend',
        }}
      />
      <UIView />
    </>
  );
});
