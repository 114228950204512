import { ReactStateDeclaration } from '@uirouter/react';
import { authStore } from '@/stores';
import { ChangeForgottenPasswordForm } from './components/change-forgoten-password';
import { ForgotPasswordForm } from './components/forgot-password';
import { AuthLoginForm } from './components/login';
import { AuthLogin2faForm } from './components/login2fa';

export const states: ReactStateDeclaration[] = [
  {
    name: 'auth-login',
    url: '/auth/login',
    component: AuthLoginForm,
  },
  {
    name: 'auth-forgot-password',
    url: '/auth/forgot-password',
    component: ForgotPasswordForm,
  },
  {
    name: 'auth-change-password',
    url: '/auth/change-password',
    component: ChangeForgottenPasswordForm,
  },
  {
    name: 'auth-login-2fa',
    url: '/auth/login/2fa',
    component: AuthLogin2faForm,
  },
  // {
  //   name: 'auth-login',
  //   url: '/auth/login',
  //   component: AuthSamlLoginForm,
  // },
  // {
  //   name: 'auth-login-advanced',
  //   url: '/auth/login-advanced',
  //   component: AuthLoginForm,
  // },
  // {
  //   name: 'auth-signup',
  //   url: '/auth/signup',
  //   component: AuthRegisterForm,
  // },
  {
    name: 'auth-logout',
    url: '/auth/logout',
    data: {
      requiresAuth: true,
    },
    redirectTo: () => {
      authStore.logout();
      return 'auth-login';
    }
  },
];
