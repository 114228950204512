import { UIView } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { Table } from '@/components/table';
import { useTableDataByKey } from '@/utils/hooks';
import { getColumns } from './setup';
import { AuditForm } from './audit-form';

export interface AuditTableViewProps {
  recordId: string;
  auditStore: any; // TODO: define store interface and use it here
  permissionObject: string;
}

export const AuditTableView = observer(({ recordId, permissionObject, auditStore }: AuditTableViewProps) => {
  const [auditRecordId, setAuditRecordId] = useState<number | null>(null);
  const onAuditFormClose = () => setAuditRecordId(null);
  const onAuditViewClick = (id: number) => setAuditRecordId(id);

  const columns = getColumns(permissionObject, onAuditViewClick);

  const {
    additionalStore,
    dataSource,
    loading: additionalDataLoading,
    onChangeTable,
  } = useTableDataByKey(recordId, auditStore, columns);

  return (
    <>
      <Table
        id="additional-content"
        rowKey="id"
        columns={columns}
        dataSource={dataSource}
        store={additionalStore}
        checkPermissions
        permissionObj={permissionObject}
        loading={additionalDataLoading}
        scroll={{ y: '100%' }}
        customHeight="80vh"
        onChange={onChangeTable}
      />
      <AuditForm
        auditRecordId={auditRecordId}
        auditStore={auditStore}
        onClose={onAuditFormClose}
      />
      <UIView />
    </>
  );
});
