import moment, { Moment } from 'moment/moment';

export function getArrayDaysFromRange(startDate: Moment, endDate: Moment) {
  const diff = moment.duration(startDate.diff(endDate)).asDays();
  const momentMethod = diff > 0 ? 'subtract' : 'add';
  const days = [];

  for (let i = 0; i <= Math.abs(diff); i++) {
    const date = moment(startDate)[momentMethod](i, 'days');
    days.push(date);
  }

  return days;
}
