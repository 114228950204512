import { states as performanceReportStates } from './pages/employee-performance';
import { HIDE_ROUTES } from '@/constants';

import { states as layoutStates } from './components/layout';
import { states as adminStates } from './pages/admin';
import { states as agreementStates } from './pages/agreement';
import { states as authStates } from './pages/auth';
import { states as performanceReportLayoutStates } from './pages/employee-performance/components/layout';
import { states as commonStates } from './pages/common';
import { states as homeStates } from './pages/home';
import { states as profileStates } from './pages/profile';
import { states as agencyEmployees } from './pages/coordinator/employees';
import { states as coordinatorEmployees } from './pages/agency/employees';
import { states as coordinatorWorkingLogs } from './pages/coordinator/working-logs';
import { states as agencyWorkingLogs } from './pages/agency/working-logs';
import { states as coordinatorBillings } from './pages/coordinator/billings';
import { states as agencyBillings } from './pages/agency/billings';
import { states as coordinatorShiftReports } from './pages/coordinator/shift-reports';
import { states as agencyShiftReports } from './pages/agency/shift-reports';
import { states as agencyShuttleReports } from './pages/shuttle-report';
import { states as coordinatorAttendanceReports } from './pages/coordinator/attendance-reports';
import { states as agencyAttendanceReports } from './pages/agency/attendance-reports';
import { states as coordinatorPermanentStandardHours } from './pages/coordinator/permanent-standard-hours';
import { states as coordinatorPermanentShiftWork } from './pages/coordinator/permanent-shift-work';
import { states as coordinatorPTOs } from './pages/coordinator/ptos';
import { states as agencyPTOs } from './pages/agency/ptos';
import { states as coordinatorLockers } from './pages/coordinator/lockers';
import { states as coordinatorTempsShiftPlanning } from './pages/coordinator/shift-planning-temps';
import { states as coordinatorPermsShiftPlanning } from './pages/coordinator/shift-planning-perms';
import { states as timesheetStates } from './pages/timesheet';
import { states as buildingHostStates } from './pages/building-host';

export const states = [
  ...layoutStates,
  ...adminStates,
  ...authStates,
  ...homeStates,
  ...profileStates,
  ...commonStates,
  ...coordinatorEmployees,
  ...agencyEmployees,
  ...coordinatorWorkingLogs,
  ...agencyWorkingLogs,
  ...coordinatorBillings,
  ...agencyBillings,
  ...agencyShuttleReports,
  ...coordinatorShiftReports,
  ...agencyShiftReports,
  ...coordinatorAttendanceReports,
  ...agencyAttendanceReports,
  ...coordinatorPermanentStandardHours,
  ...coordinatorPermanentShiftWork,
  ...coordinatorPTOs,
  ...agencyPTOs,
  ...coordinatorLockers,
  ...performanceReportLayoutStates,
  ...performanceReportStates,
  ...coordinatorTempsShiftPlanning,
  ...coordinatorPermsShiftPlanning,
  ...timesheetStates,
  ...agreementStates,
  ...buildingHostStates,
].filter(({ name }) => !HIDE_ROUTES.some((routeName: string) => name.includes(routeName)));
