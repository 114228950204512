import { getFormattedParams, paramsWithTimeZone, request } from '@/utils/request';
import { ApiResponse, WrappedItemsResult, WrappedResult } from './types';

// todo remove after filter logic refactoring
const toQueryFilter = (params) => {
  const defaultSort = 'serial:1';
  const {
    buildingId, employeeId, employeeStatus, employeeType, firstName, lastName, ...filterParams
  } = params;
  const oldFilterParams = {
    buildingId, employeeId, employeeStatus, employeeType, firstName, lastName,
  };
  const withoutBlankValue = Object.fromEntries(Object.entries(oldFilterParams).filter(([, v]) => Boolean(v)));

  if (!filterParams?.sort) {
    filterParams.sort = defaultSort;
  }

  const query = new URLSearchParams();
  Object.keys((withoutBlankValue)).forEach((key) => {
    query.append(key, withoutBlankValue[key]);
  });

  return { query, filterParams, withoutBlankValue };
};

const prepareGetAllParams = (params?: mpg.api.lockers.GetParams) => {
  const { query, filterParams } = toQueryFilter(params);
  const formattedParams = getFormattedParams(filterParams, true);
  return { query, formattedParams };
};

export default {
  getAll(params?: mpg.api.lockers.GetParams): ApiResponse<WrappedResult<mpg.api.lockers.Locker[]>> {
    const { query, formattedParams } = prepareGetAllParams(params);

    return request({
      method: 'get',
      url: `/lockers?${query}`,
      params: formattedParams,
    });
  },
  exportToExcel(params?: mpg.api.lockers.GetParams): ApiResponse<WrappedResult<Blob>> {
    const { query, formattedParams } = prepareGetAllParams(params);

    return request({
      method: 'post',
      url: `/lockers/export?${query}`,
      ...paramsWithTimeZone({ ...formattedParams }),
      config: {
        responseType: 'blob',
      },
    });
  },
  exportToEmail(params?: mpg.api.lockers.GetParams): ApiResponse<WrappedResult<Blob>> {
    const { query, formattedParams } = prepareGetAllParams(params);

    return request({
      method: 'post',
      url: `/lockers/export-by-link?${query}`,
      ...paramsWithTimeZone({ ...formattedParams }),
    });
  },
  get(id: mpg.api.lockers.LockerId): ApiResponse<WrappedResult<mpg.api.lockers.Locker>> {
    return request({
      method: 'get',
      url: `/lockers/${id}`,
    });
  },
  update(id: number, data: mpg.api.lockers.LockerParams): ApiResponse<WrappedResult<mpg.api.lockers.Locker>> {
    return request({
      method: 'patch',
      url: `/lockers/${id}`,
      data,
    });
  },
  bulkUpdate(data: { items: mpg.api.lockers.Locker[] }): ApiResponse<WrappedItemsResult<mpg.api.lockers.Locker[]>> {
    return request({
      method: 'patch',
      url: '/lockers/bulk/update',
      data,
    });
  },
};
