import React from 'react';
import { Row, Spin, SpinProps } from '@/components/antd';

interface LoaderProps extends SpinProps {
  children?: React.ReactNode;
}

export const Loader = (
  {
    children,
    size,
  }: LoaderProps,
) => (
  <Row justify="center">
    <Spin size={size}>
      {children}
    </Spin>
  </Row>
);
