import React, { useEffect, useState } from 'react';
import { Button, Drawer as AntdDrawer, DrawerProps as AntdDrawerProps } from '@/components/antd';

import { VoidFnType } from '@/types/common';
import { CloseOutlined } from '@/components/icons';

interface DrawerProps extends AntdDrawerProps {
  children: React.ReactNode;
  onClose: VoidFnType;
  footer?: (onClose: VoidFnType) => React.ReactNode;
  beforeDrawerOpened?: () => void;
}

export const Drawer = (
  {
    title,
    placement = 'right',
    children,
    closable = true,
    width = 500,
    footer,
    extra,
    headerStyle,
    beforeDrawerOpened,
    ...props
  }: DrawerProps,
) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    beforeDrawerOpened && beforeDrawerOpened();
    setVisible(props.visible);
  }, [props.visible]);

  const afterVisibleChange = (visibleValue: boolean) => {
    if (!visibleValue) {
      props.onClose();
    }
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <AntdDrawer
      afterVisibleChange={afterVisibleChange}
      title={title}
      placement={placement}
      closable={closable}
      onClose={onClose}
      visible={visible}
      width={width}
      footer={footer && footer(onClose)}
      closeIcon={<Button key="close" size="middle" icon={<CloseOutlined />} />}
      extra={extra}
      headerStyle={headerStyle}
    >
      {children}
    </AntdDrawer>
  );
};
