import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from '@/components/antd';

type IProps = {
  getFieldValue: any;
  setFieldsValues: any;
};
const Keyboard = ({ getFieldValue, setFieldsValues }: IProps) => {
  const setFieldValue = (key: string) => {
    const currentValue = getFieldValue('password');
    if (currentValue === undefined) {
      setFieldsValues({
        password: key,
      });
      return;
    }
    setFieldsValues({
      password: currentValue + key,
    });
  };

  const onDelete = () => {
    const currentValue = getFieldValue('password');

    setFieldsValues({
      password: currentValue.slice(0, -1),
    });
  };

  const values = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
  return (
    <div className="keyboard-numbers">
      <div className="keyboard-numbers_button-wrapper">
        {values.map((value) => (
          <Button onClick={() => setFieldValue(value)} className="keyboard-numbers_button">
            {value}
          </Button>
        ))}
      </div>
      <Button onClick={onDelete} className="keyboard-numbers_backspace">
        <ArrowLeftOutlined />
      </Button>
    </div>
  );
};

export default Keyboard;
