import {
  getDateWithoutTime, getDateWithoutTimeWithCustomSeparator, getTime, getTimeAMPM, getTimeSpaceAMPM,
} from '@/utils/moment';
import { WrappedItemsResult } from '@/api/types';

export const formatEventDuration = (
  { eventStart, eventEnd }: Partial<Pick<mpg.api.shiftPlanning.ShiftPlanning, 'eventStart' | 'eventEnd'>>
): string | null => (
  (eventStart && eventEnd && `${getTimeAMPM(eventStart)}-${getTimeAMPM(eventEnd)}`) ?? null
);

export const formatWorkingLog = (item: mpg.api.workingLogs.WorkingLog) => {
  const { eventEnd, eventStart } = (item && item.raw && item.raw.event) || {};

  return {
    ...item,
    checkInDate: getDateWithoutTime(item.checkInRawDate),
    checkInTime: getTime(item.checkInRawDate),
    checkOutDate: getDateWithoutTime(item.checkOutRawDate),
    checkOutTime: getTime(item.checkOutRawDate),
    shiftAssignedId: formatEventDuration({ eventStart, eventEnd }),
  };
};

export const formatWorkingLogs = (res: WrappedItemsResult<mpg.api.workingLogs.WorkingLog[]>) => ({
  ...res,
  data: {
    ...res.data,
    items: (res.data.items || []).map(formatWorkingLog),
  },
});

export const formatShiftReport = (item: mpg.api.shiftReports.ShiftReport) => ({
  ...item,
  date: getDateWithoutTimeWithCustomSeparator(item.date, '/'),
  shiftStart: getTimeAMPM(item.shiftStart),
  actualStart: getTimeSpaceAMPM(item.actualStart),
  shiftEnd: getTimeAMPM(item.shiftEnd),
  actualEnd: getTimeSpaceAMPM(item.actualEnd),
  shiftAssigned: `${getTimeAMPM(item.shiftStart)}-${getTimeAMPM(item.shiftEnd)}`,
});

export const formatShiftReports = (res: WrappedItemsResult<mpg.api.shiftReports.ShiftReport[]>) => ({
  ...res,
  data: {
    ...res.data,
    items: (res.data.items || []).map(formatShiftReport),
  },
});
