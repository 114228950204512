import React from 'react';
import { Input } from '@/components/antd';
import { CloseOutlined } from '@/components/icons';

export const SearchFilter = ({ setSelectedKeys, selectedKeys, confirm }) => {
  const onClear = () => {
    setSelectedKeys([]);
    confirm();
  };

  const suffix = selectedKeys[0]
    ? <CloseOutlined onClick={onClear} style={{ opacity: 0.5 }} />
    : <span />;

  return (
    <div style={{ padding: 8 }}>
      <Input.Search
        autoFocus
        placeholder="Search..."
        style={{ width: 200 }}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={confirm}
        onSearch={confirm}
        suffix={suffix}
      />
    </div>
  );
};
