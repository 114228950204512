import { UIView, UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';

import { api } from '@/api';
import { DrawerForm } from '@/components/drawer-form';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY } from '@/constants/permissions';
import {
  authStore, buildingStore, colorStore, shiftPlanningTempsStore, shiftStore,
} from '@/stores';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';
import { useFormDataById } from '@/utils/hooks';
import { useMassUpdateCashedStores } from '@/utils/store';
import { getDateWithPartOfDay, getFullDateWithTimeZone } from '@/utils/date';
import { EmployeeType } from '@/types/enums';
import { Form as AntdForm } from '@/components/antd';

import { useValueChanges } from './hooks';
import { getFormFields } from './setup';

const entity = 'coordinator-temps-shift-planning';
const apiEntity = 'shiftPlanningTemps';
const store = shiftPlanningTempsStore;

export const Form = observer(({ transition }: UIViewInjectedProps) => {
  const [formRef] = AntdForm.useForm();
  const isCreate = transition.router.globals.current.name === `base-layout.${entity}.create`;
  const { shiftPlanningId, buildingId } = transition.targetState().params();

  const {
    formData,
    loading: formDataLoading,
  } = useFormDataById<mpg.api.shiftPlanning.ShiftPlanning>(shiftPlanningId, apiEntity);

  const { loading } = useMassUpdateCashedStores([
    buildingStore,
    colorStore,
    shiftStore,
  ]);

  const { timezone = '' } = buildingStore.items.find((building) => building.id === formData?.buildingId) || {};

  const onBack = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };

  const initValues = !isCreate && formData
    ? {
      ...formData,
      eventStart: [
        formData.eventStart && moment.utc(getFullDateWithTimeZone(formData.eventStart, timezone)),
        formData.eventEnd && moment.utc(getFullDateWithTimeZone(formData.eventEnd, timezone)),
      ],
      colors: (formData?.colors || []).map(({ id }) => id),
      shifts: (formData?.shifts || []).map(({ id }) => id),
    }
    : {
      buildingId,
      additionalTime: 0,
    };

  const { disabledShifts, shiftsOptions, onValuesChange } = useValueChanges({
    formRef,
    initialDeps: [formData],
    initialShiftsDisable: false,
    initValues,
  });
  const filteredShiftOptions = shiftsOptions.filter((shift)=>shift.active !== false)
  const formTitle = isCreate
    ? getTranslatedString('shiftPlanning.create-new')
    : formData?.title;
  const formFields = getFormFields({
    disabledShifts, shiftsOptions: filteredShiftOptions, isCreate, hasAdminRole: authStore.hasAdminRole(), initValues
  });
  const allowedFields = getAllowedFormFields(formFields, isCreate, PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY);

  const resourceController = withoutRepeatValue(initValues, {
    create: (values: mpg.api.shiftPlanning.ShiftPlanningParams) => {
      const eventStart = moment(values.eventStart[0]).utcOffset(0, true);
      const eventEnd = moment(values.eventStart[1]).utcOffset(0, true);

      const title = getDateWithPartOfDay(eventStart);

      return api.shiftPlanningTemps
        .create({
          ...values,
          title,
          eventStart,
          eventEnd,
          employeeType: EmployeeType.Temporary,
        })
        .source
        .then(() => store.refresh());
    },
    update: (values: mpg.api.shiftPlanning.ShiftPlanningParams) => {
      const eventStart = values?.eventStart && moment(values.eventStart[0]).utcOffset(0, true);
      const eventEnd = values?.eventStart && moment(values.eventStart[1]).utcOffset(0, true);

      return api.shiftPlanningTemps.update(formData && formData.id, {
        ...values,
        buildingId: formData?.buildingId,
        eventStart,
        eventEnd,
        employeeType: EmployeeType.Temporary,
      }).source
        .then(() => store.refresh());
    },
  });

  return (
    <>
      <DrawerForm
        form={formRef}
        resourceId={shiftPlanningId}
        className="form-grid-wrapper"
        title={formTitle}
        initValues={initValues}
        formFields={allowedFields}
        resourceController={resourceController}
        loaderCondition={formDataLoading || loading}
        layout="vertical"
        onClose={onBack}
        onValuesChange={onValuesChange}
      />
      <UIView />
    </>
  );
});
