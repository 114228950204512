import axios from 'axios';

import { API_URL, AUTH_URL } from '@/constants';

interface LoginData {
  email: string;
  password: string;
}

interface RegisterData {
  email: string;
  password: string;
  repeatPassword: string;
}

interface LoginAsData {
  roleIds: number[];
}

interface ResetPasswordData {
  email: string;
  clientAppUrl: string;
}

interface ChangeForgottenPasswordData {
  password: string;
}

interface Check2faData {
  twoFaToken: string;
  twoFactorAuthCode: string;
}

interface ResendCodeData {
  twoFaToken: string;
}

interface Deactivate2faData {
  email: string;
  password: string;
}

interface Activate2faData {
  email: string;
  password: string;
  authType: 'code' | 'sms' | 'email';
  phoneNumber: string;
}

interface Turn2faData {
  twoFactorAuthCode: string;
  twoFaToken: string;
}

export function login(data: LoginData) {
  return axios({
    url: `${AUTH_URL}/login`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
}

export function loginAs(data: LoginAsData, accessToken: string) {
  return axios({
    url: `${API_URL}/auth/login-as`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data,
  });
}

export function register(data: RegisterData) {
  return axios({
    url: `${AUTH_URL}/register`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
}

export function session(accessToken: string) {
  return axios({
    url: `${API_URL}/auth/session`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

export async function permissions(accessToken: string) {
  const { data } = await axios({
    url: `${API_URL}/auth/permissions`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return data.data;
}

export async function samlProviders() {
  const { data } = await axios({
    method: 'GET',
    url: `${AUTH_URL}/saml/providers`,
  });

  return data.providers;
}

export function getResetPasswordLink(data: ResetPasswordData) {
  return axios({
    url: `${AUTH_URL}/forgotPassword`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
}

export function changeForgottenPassword(data: ChangeForgottenPasswordData, accessToken) {
  return axios({
    url: `${AUTH_URL}/changeForgottenPassword`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data,
  });
}

export function check2fa(data: Check2faData) {
  return axios({
    url: `${AUTH_URL}/2fa/login`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
}

export function resendCode(data: ResendCodeData) {
  return axios({
    url: `${AUTH_URL}/2fa/resendCode`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
}

export function deactivate2fa(data: Deactivate2faData, accessToken) {
  return axios({
    url: `${AUTH_URL}/2fa/request-deactivate`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data,
  });
}

export function activate2fa(data: Activate2faData, accessToken) {
  return axios({
    url: `${AUTH_URL}/2fa/request-activate`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data,
  });
}

export function turnOff2fa(data: Turn2faData, accessToken) {
  return axios({
    url: `${AUTH_URL}/2fa/turn-off`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data,
  });
}

export function turnOn2fa(data: Turn2faData, accessToken) {
  return axios({
    url: `${AUTH_URL}/2fa/turn-on`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data,
  });
}
