import { UISref } from '@uirouter/react';
import React from 'react';
import { Button, Popconfirm, Tooltip } from '@/components/antd';

import { DeleteOutlined, EditOutlined, EyeOutlined, HomeOutlined, UserDeleteOutlined } from '@/components/icons';
import { VoidFnType } from '@/types/common';
import { getTranslatedString } from '@/utils/intl';

export interface EditActionProps {
  className?: string;
  icon?: any;
  uiSref: {
    to: string;
    params: Record<string, unknown>;
  };
  title?: string;
  tooltipTitle?: string;
  disabled?: boolean;
}

export interface DeleteActionProps {
  onConfirm: VoidFnType;
  title?: string;
}

export interface UnassignActionProps {
  onConfirm: VoidFnType;
  title?: string;
  disabled?: boolean;
}

export const stopPropagationHandler = (event: React.MouseEvent) => {
  event.stopPropagation();
};

const ActionWrapper = ({
  children,
  uiSref: { to, params },
  disabled,
}: Pick<EditActionProps, 'uiSref' | 'disabled'> & {
  children: React.ReactNode;
}) => {
  if (disabled) {
    return <>{children}</>;
  }

  return (
    <UISref to={to} params={params}>
      <a onClick={stopPropagationHandler}>{children}</a>
    </UISref>
  );
};

export const AddAction = ({ icon, uiSref: { to, params }, title, tooltipTitle }: EditActionProps) => (
  <UISref to={to} params={params}>
    <a onClick={stopPropagationHandler}>
      <Tooltip title={tooltipTitle ? getTranslatedString(`${tooltipTitle}`) : ''}>
        <Button className={title ? 'action-button' : ''} type="primary" shape={title ? 'round' : 'circle'} icon={icon}>
          {title}
        </Button>
      </Tooltip>
    </a>
  </UISref>
);

export const EditAction = ({ uiSref: { to, params }, title, disabled }: EditActionProps) => (
  <ActionWrapper uiSref={{ to, params }} disabled={disabled}>
    <Tooltip title={getTranslatedString('table.edit')}>
      <Button type="primary" shape={title ? 'round' : 'circle'} icon={<EditOutlined />} disabled={disabled}>
        {title}
      </Button>
    </Tooltip>
  </ActionWrapper>
);

export const DeleteAction = ({ onConfirm, title }: DeleteActionProps) => (
  <Popconfirm title={title || 'Sure to delete?'} onConfirm={onConfirm} onCancel={stopPropagationHandler}>
    <Tooltip title={getTranslatedString('table.delete')}>
      <Button onClick={stopPropagationHandler} type="primary" danger shape="circle" icon={<DeleteOutlined />} />
    </Tooltip>
  </Popconfirm>
);

export const AttachmentAction = ({ uiSref: { to, params } }: EditActionProps) => (
  <UISref to={to} params={params}>
    <a onClick={stopPropagationHandler}>
      <Tooltip title={getTranslatedString('attachments.attachments')}>
        <Button type="primary" shape="circle" icon={<HomeOutlined />} />
      </Tooltip>
    </a>
  </UISref>
);

export const ReportAction = ({ onClick }: any) => (
  <a onClick={stopPropagationHandler}>
    <Tooltip title={getTranslatedString('table.report')}>
      <Button type="primary" shape="circle" onClick={onClick} icon={<HomeOutlined />} />
    </Tooltip>
  </a>
);

export const NotesAction = ({ uiSref: { to, params } }: EditActionProps) => (
  <UISref to={to} params={params}>
    <a onClick={stopPropagationHandler}>
      <Tooltip title={getTranslatedString('table.notes')}>
        <Button type="primary" shape="circle" icon={<HomeOutlined />} />
      </Tooltip>
    </a>
  </UISref>
);

export const SelectorAction = ({ uiSref: { to, params }, title }: any) => (
  <UISref to={to} params={params}>
    <a onClick={stopPropagationHandler}>
      <Button type="primary" shape="round" icon={<HomeOutlined />}>
        {title}
      </Button>
    </a>
  </UISref>
);

export const ReadAction = ({ uiSref: { to, params }, title }: EditActionProps) => (
  <UISref to={to} params={params}>
    <a onClick={stopPropagationHandler}>
      <Tooltip title={getTranslatedString('table.view')}>
        <Button type="primary" shape={title ? 'round' : 'circle'} icon={<EyeOutlined />}>
          {title}
        </Button>
      </Tooltip>
    </a>
  </UISref>
);

export const UnassignAction = ({ onConfirm, title, disabled = false }: UnassignActionProps) => (
  <Popconfirm
    disabled={disabled}
    title={title || 'Are You Sure to Unassign?'}
    onConfirm={onConfirm}
    onCancel={stopPropagationHandler}
  >
    <Tooltip title={getTranslatedString('table.unassign')}>
      <Button type="primary" danger shape="circle" icon={<UserDeleteOutlined />} disabled={disabled} />
    </Tooltip>
  </Popconfirm>
);
