import React from 'react';
import { FormattedMessage } from 'react-intl';

import { BooleanValueRender } from '@/components/table/renders';
import { agencyStore, buildingStore, colorStore, shiftStore } from '@/stores';
import {
  buildFilterOptionsFromEnum,
  buildFilterOptionsFromEnumWithTransformer,
  buildStoreOptions,
  getRoundDecimalNumber,
  getStorePropById,
} from '@/utils/common';
import { getTimeSpaceAMPM, getTimeAMPM, transformShift, getDateWithoutTime } from '@/utils/moment';

import {
  getColumnBooleanFields,
  getColumnDateFields,
  getColumnSearchFields,
  getColumnTimeFields,
} from '@/components/table/filters';
import { EmployeeStatus, Shifts } from '@/types/enums';

export const getColumns = (isEmployeeTypeShown: boolean, isFilterWithOptions: boolean) =>
  [
    {
      title: <FormattedMessage id="shiftReports.visitorId" />,
      dataIndex: 'visitorId',
      ...getColumnSearchFields(),
      sorter: true,
    },
    isEmployeeTypeShown
      ? {
          title: <FormattedMessage id="shiftReports.employeeType" />,
          dataIndex: 'employeeType',
          render: (employeeType: string) => <FormattedMessage id={`shiftReports.employeeType.${employeeType}`} />,
        }
      : null,
    {
      title: <FormattedMessage id="shiftReports.firstName" />,
      dataIndex: 'firstName',
      sorter: true,
      ...getColumnSearchFields(),
    },
    {
      title: <FormattedMessage id="shiftReports.middleName" />,
      dataIndex: 'middleName',
      sorter: true,
      ...getColumnSearchFields(),
    },
    {
      title: <FormattedMessage id="shiftReports.lastName" />,
      dataIndex: 'lastName',
      sorter: true,
      ...getColumnSearchFields(),
    },
    {
      title: <FormattedMessage id="shiftReports.status" />,
      dataIndex: 'status',
      filters: buildFilterOptionsFromEnum(EmployeeStatus, 'employees.status.'),
      filterMultiple: true,
      sorter: true,
      render: (status: string) => <FormattedMessage id={`employees.status.${status}`} />,
    },
    {
      title: <FormattedMessage id="shiftReports.agency" />,
      dataIndex: 'agencyId',
      filters: buildStoreOptions(agencyStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.agencies.Agency>(itemId, agencyStore),
    },
    {
      title: <FormattedMessage id="shiftReports.date" />,
      dataIndex: 'date',
      sorter: true,
      ...getColumnDateFields(false),
      render: (item: string) => getDateWithoutTime(item),
    },
    {
      title: <FormattedMessage id="shiftReports.shiftBuilding" />,
      dataIndex: 'shiftBuildingId',
      filters: buildStoreOptions(buildingStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.buildings.Building>(itemId, buildingStore),
    },
    {
      title: <FormattedMessage id="shiftReports.workerBuilding" />,
      dataIndex: 'workerBuildingId',
      filters: buildStoreOptions(buildingStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.buildings.Building>(itemId, buildingStore),
    },
    isFilterWithOptions
      ? {
          title: <FormattedMessage id="shiftReports.shift" />,
          dataIndex: 'shiftAssigned',
          ...getColumnSearchFields(),
          sorter: true,
          render: (value, item) => {
            const { shiftStart, shiftEnd } = item || {};
            return `${getTimeAMPM(shiftStart)}-${getTimeAMPM(shiftEnd)}`;
          },
        }
      : {
          title: <FormattedMessage id="shiftReports.shift" />,
          dataIndex: 'shiftAssigned',
          filters: buildFilterOptionsFromEnumWithTransformer(Shifts, transformShift),
          filterMultiple: true,
          sorter: true,
          render: (value, item) => {
            const { shiftStart, shiftEnd } = item || {};
            return `${getTimeAMPM(shiftStart)}-${getTimeAMPM(shiftEnd)}`;
          },
        },

    {
      title: <FormattedMessage id="shiftReports.color" />,
      dataIndex: 'colorActual',
      ...getColumnSearchFields(),
      sorter: true,
    },
    {
      title: <FormattedMessage id="shiftReports.workersPlannedShift" />,
      dataIndex: 'shiftPlannedId',
      filters: buildStoreOptions(shiftStore, 'name', null, null, buildingStore, 'buildingId'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.shifts.Shift>(itemId, shiftStore)

    },
    {
      title: <FormattedMessage id="shiftReports.workersPlannedColor" />,
      dataIndex: 'colorAssignedId',
      filters: buildStoreOptions(colorStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.colors.Colors>(itemId, colorStore),
    },
    {
      title: <FormattedMessage id="shiftReports.grossTime" />,
      dataIndex: 'grossTime',
      sorter: true,
      ...getColumnTimeFields(),
      render: (value) => getRoundDecimalNumber(value),
    },
    {
      title: <FormattedMessage id="shiftReports.netTime" />,
      dataIndex: 'netTime',
      sorter: true,
      ...getColumnTimeFields(),
      render: (value) => getRoundDecimalNumber(value),
    },
    {
      title: <FormattedMessage id="shiftReports.idleTime" />,
      dataIndex: 'idleTime',
      sorter: true,
      ...getColumnTimeFields(),
      render: (value) => getRoundDecimalNumber(value),
    },
    {
      title: <FormattedMessage id="shiftReports.overAllowedBreak" />,
      dataIndex: 'overAllowedBreak',
      sorter: true,
      render: BooleanValueRender,
      ...getColumnBooleanFields(false),
    },
    {
      title: <FormattedMessage id="shiftReports.showedUpAsPlanned" />,
      dataIndex: 'asPlanned',
      sorter: true,
      render: BooleanValueRender,
      ...getColumnBooleanFields(false),
    },
    {
      title: <FormattedMessage id="shiftReports.showedUpInDifferentShift" />,
      dataIndex: 'isDifferentShift',
      sorter: true,
      render: BooleanValueRender,
      ...getColumnBooleanFields(false),
    },
    {
      title: <FormattedMessage id="shiftReports.showedUpInDifferentBuilding" />,
      dataIndex: 'isDifferentBuilding',
      sorter: true,
      render: BooleanValueRender,
      ...getColumnBooleanFields(false),
    },
    {
      title: <FormattedMessage id="shiftReports.plannedButDidNotShowUp" />,
      dataIndex: 'notShowedUp',
      sorter: true,
      render: BooleanValueRender,
      ...getColumnBooleanFields(false),
    },
    {
      title: <FormattedMessage id="shiftReports.shiftStart" />,
      dataIndex: 'shiftStart',
      sorter: true,
      render: (value) => getTimeSpaceAMPM(value),
    },
    {
      title: <FormattedMessage id="shiftReports.actualArrival" />,
      dataIndex: 'actualStart',
      sorter: true,
      render: (value) => getTimeSpaceAMPM(value),
    },
    {
      title: <FormattedMessage id="shiftReports.lateArrival" />,
      dataIndex: 'lateArrival',
      sorter: true,
      render: BooleanValueRender,
      ...getColumnBooleanFields(false),
    },
    {
      title: <FormattedMessage id="shiftReports.lateByHours" />,
      dataIndex: 'lateArrivalBy',
      sorter: true,
      ...getColumnTimeFields(),
      render: (value) => getRoundDecimalNumber(value),
    },
    {
      title: <FormattedMessage id="shiftReports.shiftEnd" />,
      dataIndex: 'shiftEnd',
      sorter: true,
      render: (value) => getTimeSpaceAMPM(value),
    },
    {
      title: <FormattedMessage id="shiftReports.actualEnd" />,
      dataIndex: 'actualEnd',
      sorter: true,
      render: (value) => getTimeSpaceAMPM(value),
    },
    {
      title: <FormattedMessage id="shiftReports.earlyDeparture" />,
      dataIndex: 'earlyDeparture',
      sorter: true,
      render: BooleanValueRender,
      ...getColumnBooleanFields(false),
    },
    {
      title: <FormattedMessage id="shiftReports.earlyByHours" />,
      dataIndex: 'earlyDepartureBy',
      sorter: true,
      ...getColumnTimeFields(),
      render: (value) => getRoundDecimalNumber(value),
    },
    // {
    //   title: <FormattedMessage id={'shiftReports.autoCheckOut'} />,
    //   dataIndex: 'autoCheckOut',
    //   sorter: true,
    //   render: BooleanValueRender,
    //   ...getColumnBooleanFields(false),
    // },
  ].filter((item) => !!item);
