import { request } from '@/utils/request';

export default {
  getAll() {
    return request({
      method: 'get',
      url: '/dump',
      config: {
        responseType: 'blob',
      },
    });
  },
};
