import { UISref, UIView, UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { TableRowSelection } from 'antd/lib/table/interface';
import {
  DownOutlined,
  EditOutlined,
  SettingOutlined,
  PrinterOutlined,
  UserDeleteOutlined,
  ExclamationCircleOutlined,
} from '@/components/icons';
import { Button, PageHeader, Dropdown, Menu, Select, Modal } from '@/components/antd';

import { Table } from '@/components/table';
import {
  PERMISSION_OBJ_COORDINATOR_LOCKER,
  PERMISSION_OBJ_LOCKER,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { buildingStore, lockerStore } from '@/stores';
import { useMassUpdateCashedStores } from '@/utils/store';
import { buildStoreOptions, onPrint, showError } from '@/utils/common';
import { api } from '@/api';
import { getTranslatedString } from '@/utils';
import { useBulkUpdate } from '@/pages/coordinator/lockers/components/table/hooks';
import { ImportButton, ParseSettings } from '@/components/import-button';
import { ApiResponse, WrappedItemsResult } from '@/api/types';

import { getColumns } from './setup';
import ExportButton from '@/pages/common/components/buttons/export-button';

const entity = 'coordinator-lockers';
const store = lockerStore;
const LOCAL_STORAGE_KEY_BUILDING = 'COORDINATORS_LOCKERS_SELECTED_BUILDING';

const BulkUpdateButton = withPermissions([
  [PERMISSION_OBJ_COORDINATOR_LOCKER, PERMISSION_READ],
  [PERMISSION_OBJ_LOCKER, PERMISSION_UPDATE],
])(({ selectedRowKeys }: any) => (
  <Menu.Item key="update" icon={<EditOutlined />}>
    <UISref to={`base-layout.${entity}.bulk-edit`} params={{ selectedRowKeys }}>
      <a>
        <FormattedMessage id="button.update" />
      </a>
    </UISref>
  </Menu.Item>
));

interface CsvUploadButtonProps {
  request: (parsedItems: mpg.api.ptos.PTOParams[]) => ApiResponse<WrappedItemsResult<mpg.api.ptos.PTO[]>>;
  storeRefreshRequest: () => ApiResponse<WrappedItemsResult<mpg.api.ptos.PTO[]>>;
  settings: ParseSettings;
}

const CsvUploadButton = withPermissions([
  [PERMISSION_OBJ_COORDINATOR_LOCKER, PERMISSION_READ],
  [PERMISSION_OBJ_LOCKER, PERMISSION_UPDATE],
])(({ request, settings, storeRefreshRequest }: CsvUploadButtonProps) => (
  <ImportButton request={request} parseSettings={settings} storeRefreshRequest={storeRefreshRequest} />
));

const BulkUnassignButton = withPermissions([
  [PERMISSION_OBJ_COORDINATOR_LOCKER, PERMISSION_READ],
  [PERMISSION_OBJ_LOCKER, PERMISSION_UPDATE],
])(({ selectedRowKeys, buildingId }: any) => {
  const handleUnassign = () => {
    const items = selectedRowKeys.map((id: string) => ({ employeeId: '', id, buildingId }));

    return api.lockers
      .bulkUpdate({ items })
      .source.then(() => store.refresh())
      .catch(showError);
  };

  const confirm = () => {
    Modal.confirm({
      title: getTranslatedString('common.warning'),
      icon: <ExclamationCircleOutlined />,
      content: getTranslatedString('lockers.unassign-warning-desc'),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: handleUnassign,
    });
  };

  return (
    <Menu.Item key="unassign" icon={<UserDeleteOutlined />}>
      <a onClick={confirm}>
        <FormattedMessage id="table.unassign" />
      </a>
    </Menu.Item>
  );
});

export const Lockers = observer(({ transition }: UIViewInjectedProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [selectedBuilding, setSelectedBuilding] = useState<string>();

  const tableRef: any = useRef({});
  const { items } = store;

  const { importRequest, parseSettings, storeRefreshRequest } = useBulkUpdate();
  const { loading } = useMassUpdateCashedStores([buildingStore]);

  useEffect(() => {
    if (!loading) {
      const lastSelectedBuildingId = localStorage.getItem(LOCAL_STORAGE_KEY_BUILDING);
      const isExists = lastSelectedBuildingId && buildingStore.items.some(({ id }) => id === lastSelectedBuildingId);
      setSelectedBuilding(isExists ? lastSelectedBuildingId : buildingStore.items[0]?.id);
    }
  }, [loading]);
  const [isAutoloadAllowed, setIsAutoloadAllowed] = useState(false);
  useEffect(() => {
    if (!loading && selectedBuilding) {
      if (tableRef?.current?.filters?.buildingId?.[0] !== selectedBuilding) {
        tableRef?.current?.setFilters({ ...tableRef?.current?.filters, buildingId: [selectedBuilding] });
      }
    }
    setIsAutoloadAllowed(!loading && !!selectedBuilding);
  }, [loading, selectedBuilding]);

  const onDoubleClick = (record) => {
    transition.router.stateService.go(`base-layout.${entity}.edit`, { lockerId: record.id });
  };

  const onRowSelectionChange = (currentSelectedRowKeys: string[]) => {
    setSelectedRowKeys(currentSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<any> = {
    // preserveSelectedRowKeys: true,
    onChange: onRowSelectionChange,
  };

  const batchMenu = (
    <Menu>
      <BulkUpdateButton selectedRowKeys={selectedRowKeys} />
      <BulkUnassignButton selectedRowKeys={selectedRowKeys} buildingId={selectedBuilding} />
    </Menu>
  );

  const onBuildingChange = (buildingId: string) => {
    setSelectedBuilding(buildingId);
    localStorage.setItem(LOCAL_STORAGE_KEY_BUILDING, buildingId);
  };
  return (
    <>
      <PageHeader
        className="r-antd-page-header r-antd-page-header--withoutMargin"
        extra={[
          <div key="filter-building" style={{ flexGrow: 1 }}>
            <Select
              showSearch
              placeholder="Select a building"
              onChange={onBuildingChange}
              filterOption={(input, option: any) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              options={buildStoreOptions(buildingStore, 'name')}
              value={selectedBuilding}
              style={{ width: 200 }}
            />
          </div>,
          <Dropdown key="dropdown" overlay={batchMenu} disabled={!selectedRowKeys.length}>
            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              Batch Actions
              <DownOutlined />
            </a>
          </Dropdown>,
          <Button key="print" size="middle" icon={<PrinterOutlined />} onClick={onPrint} />,
          <Button
            key="setting"
            icon={<SettingOutlined />}
            size="middle"
            onClick={tableRef.current.showSettingsModal}
          />,
          <ExportButton
            store={store}
            exportToExcel={api.lockers.exportToExcel}
            exportToEmail={api.lockers.exportToEmail}
            withPermission={withPermissions([
              [PERMISSION_OBJ_COORDINATOR_LOCKER, PERMISSION_READ],
              [PERMISSION_OBJ_LOCKER, PERMISSION_READ],
            ])}
            additionalParams={{ buildingId: selectedBuilding }}
            fileName="mpg-lockers"
          />,
          <CsvUploadButton
            key="bulk update button"
            request={importRequest}
            settings={parseSettings}
            storeRefreshRequest={storeRefreshRequest}
          />,
        ]}
      />

      <Table
        tableRef={tableRef}
        onRow={(record) => ({
          onDoubleClick: () => onDoubleClick(record),
        })}
        localStorageKey="coordinator-lockers-table"
        rowSelection={rowSelection}
        rowKey="id"
        columns={getColumns()}
        dataSource={[...items]}
        store={store}
        checkPermissions
        permissionObj={PERMISSION_OBJ_LOCKER}
        loading={store.loading || loading}
        scroll={{ y: '100%' }}
        hasRequiredFilterParam
        requiredFilterParam={{ buildingId: [selectedBuilding] }}
        isAutoloadAllowed={isAutoloadAllowed}
        initialFilters={{ buildingId: [selectedBuilding] }}
        initialSorters={{
          columnKey: 'serial',
          field: 'serial',
          order: 'ascend',
        }}
      />
      <UIView />
    </>
  );
});
