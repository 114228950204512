import moment from 'moment';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';

import { FormInstance } from '@/components/antd';
import { getTranslatedString } from '@/utils/intl';
import { approversStore, musterStationsStore } from '@/stores';

export const emailOrPhoneRequired = ({ getFieldValue }: FormInstance): RuleObject => ({
  validator(): Promise<void | any> | void {
    if (getFieldValue('email') || getFieldValue('phone')) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Email or phone should be required (one of two)'));
  },
});

export const buildingChanged = ({ getFieldValue, setFieldsValue }: FormInstance): RuleObject => ({
  validator(rule, value): Promise<void | any> | void {
    if (value !== getFieldValue('buildingId')) {
      setFieldsValue({
        musterStationId: null, lineId: null, buildingId: value,
      });
    }
    return Promise.resolve();
  },
});

export const phone = (): RuleObject => ({
  validator(_, value: any): Promise<void | any> | void {
    if (!value || value.replace(/[0-9]/g, 'X') === 'XXX-XXX-XXXX') {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please use following format: XXX-XXX-XXXX'));
  },
});

export const notPastDateIfEdit = (initValues = {}) => () => ({
  validator(_, value: any): Promise<void | any> | void {
    const { field } = _;

    if (!value || initValues[field] === value) {
      return Promise.resolve();
    }

    if (moment(value).startOf('day').isBefore(moment(Date.now()).startOf('day'))) {
      return Promise.reject(new Error('Date cannot be past'));
    }

    return Promise.resolve();
  },
});

export const uniqueValue = (entity: string, field: string, store): RuleObject => ({
  validator(_, value: any): Promise<void | any> | void {
    if (!value || !store.items.some((item) => item[field] === value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(`Such ${getTranslatedString(`${entity}.${field}`)} already exists`));
  },
});

export const dateToAfterDateFrom = ({ getFieldValue, setFieldsValue }: FormInstance): RuleObject => ({
  validator(): Promise<void | any> | void {
    if (getFieldValue('to') && getFieldValue('from') && moment(getFieldValue('to')).startOf('day').isBefore(moment(getFieldValue('from')).startOf('day'))) {
      setFieldsValue({ to: null });
    }

    return Promise.resolve();
  },
});

export const startDateAfterNow = (): RuleObject => ({
  validator(_, value: StoreValue): Promise<any> {
    if (value && value[0].diff(moment()) < 0) {
      return Promise.reject(new Error('Please enter date and time greater then current time'));
    }

    return Promise.resolve();
  },
});

export const approverRule = (): RuleObject => ({
  validator: (_, value) => {
    if (!value) {
      return Promise.resolve();
    }
    const currentApprover = approversStore.items.find((approver) => approver.id === value);
    if (!currentApprover.blocked) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Approver shouldn't be blocked"));
  },
});

export const musterStationChanged = ({ setFieldsValue }: FormInstance): RuleObject => ({
  validator(_, musterStationId): Promise<void | any> | void {
    const musterStation = musterStationsStore.items.find(({id}) => id === musterStationId);
    setFieldsValue({ musterStationCaptainId: musterStation?.captainId });
    return Promise.resolve();
  },
});
