import { UIView, UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';

import { useState } from 'react';
import moment from 'moment/moment';
import { api } from '@/api';
import { DrawerForm } from '@/components/drawer-form';
import { buildingStore, buildingHostStore } from '@/stores';
import { withoutRepeatValue } from '@/utils/controller';
import { useBuildingsDependency, useFormDataById } from '@/utils/hooks';
import { useMassUpdateCashedStores } from '@/utils/store';
import { getFormFields } from './setup';
import { BuildingPageState } from '@/pages/building-host/enums';
import { usePermEmployeesOptions } from '@/pages/building-host/components/form/hooks';
import { Form as AntdForm } from '@/components/antd';
import { getTranslatedString } from '@/utils';

const store = buildingHostStore;

export const Form = observer(({ transition }: UIViewInjectedProps) => {
  const [formRef] = AntdForm.useForm();
  const isCreate = transition.router.globals.current.name === BuildingPageState.Create;
  const { buildingHostId } = transition.router.globals.params;
  const { formData, loading: formDataLoading } = useFormDataById<mpg.api.buildingHost.BuildingHost>(
    buildingHostId,
    'buildingHost',
  );
  const [customDisabledSubmit, setCustomDisabledSubmit] = useState(true);

  const { loading } = useMassUpdateCashedStores([buildingStore]);

  const onBack = () => {
    transition.router.stateService.go(BuildingPageState.BaseLayout);
  };

  const initValues = formData && {
    permanentEmployeeId: {
      label: `${formData.firstName} ${formData.lastName}`,
      value: formData.employeeBuildingId,
    },
    buildingIds: formData.buildings.map((building) => ({
      label: building.name,
      value: building.id,
    })),
    ...formData,
  };

  const { permEmployeeOptions, debouncedOptionsFetch } = usePermEmployeesOptions();

  const { onChangeBuilding } = useBuildingsDependency(initValues || {});
  const formFields = getFormFields({
    initValues,
    isCreate,
    onChangeBuilding,
    permEmployeeOptions,
    debouncedOptionsFetch,
  });
  const resourceController = withoutRepeatValue(initValues, {
    create: (values: mpg.api.buildingHost.BuildingHostParams) =>
      api.buildingHost
        .createBuildingHost({
          scopes: ['for_external_visitors'],
          assignedDate: moment(new Date()),
          ...values,
        })
        .source.then(() => store.refresh()),
    update: (values: mpg.api.buildingHost.BuildingHostParams) => api.buildingHost.updateBuildingHost(formData && formData.id, { ...values }).source.then(() => store.refresh())

  });
  const onFieldsChange = (_, all: any[]) => {
    const disable = all.some(({ errors }: any) => Boolean(errors.length));
    if (customDisabledSubmit !== disable) {
      setCustomDisabledSubmit(disable);
    }
  };
  const formTitle = isCreate
    ? getTranslatedString('building-hosts.create-title')
    : getTranslatedString('building-hosts.update-title');
  return (
    <>
      <DrawerForm
        form={formRef}
        resourceId={buildingHostId}
        className="form-grid-wrapper"
        title={formTitle}
        initValues={initValues}
        formFields={formFields}
        resourceController={resourceController}
        loaderCondition={formDataLoading || loading}
        layout="vertical"
        customDisabled={customDisabledSubmit}
        onFieldsChange={onFieldsChange}
        onClose={onBack}
      />
      <UIView />
    </>
  );
});
