import { useRouter } from '@uirouter/react';
import React, { useEffect, useState } from 'react';
import {
  Button, Col, Row, Select,
} from '@/components/antd';

import { api } from '@/api';
import { menuItems } from '@/constants/menu';
import { authStore } from '@/stores';

export const RoleSwitcher = () => {
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState<number[]>(authStore.profile.roles);
  const [loading, setLoading] = useState<boolean>(false);

  const router = useRouter();

  useEffect(() => {
    async function loadRoles() {
      setLoading(true);

      const { source } = api.roles.getAll({ limit: 100 });
      const { data: { items = [] } } = await source;

      setRoles(items);
      setLoading(false);
    }

    if (!roles.length) {
      loadRoles();
    }
  }, [roles]);

  async function onChange(newSelectedRoles: number[]) {
    setSelectedRoles(newSelectedRoles);
  }

  async function onClick() {
    const redirect = () => {
      const targetState: string = menuItems
        .find(({ permissions }) => authStore.hasPermissions(permissions))
        ?.state;

      if (targetState) {
        router.stateService.go(targetState);
      }
    };

    setLoading(true);

    try {
      await authStore.loginWithRoles(selectedRoles);
      const state = await router.stateService.reload();

      if (state.name === 'base-layout.access-denied') {
        redirect();
      }
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <Row wrap={false}>
      <Col>
        <Select
          mode="multiple"
          className="role-switcher"
          onChange={onChange}
          value={selectedRoles}
          loading={loading}
          maxTagCount="responsive"
        >
          {roles.map((role) => <Select.Option key={role.id} value={role.id}>{role.name}</Select.Option>)}
        </Select>
      </Col>
      <Col>
        <Button style={{ marginLeft: 8 }} className="c-button--primary" shape="round" onClick={onClick}>Change</Button>
      </Col>
    </Row>
  );
};
