import React from 'react';
import {
  Form, FormItemProps, Input as AntdInput, InputProps as AntdInputProps,
} from '@/components/antd';

import { Ii18nLabel } from '@/types/common';

import { getFormItemLabel } from './utils';

interface InputProps extends FormItemProps, AntdInputProps, Ii18nLabel {
  name: string;
  children: any;
  onReset: any;
  formState: any;
}

export const Phone = (componentProps: InputProps) => {
  const {
    name,
    label,
    i18nLabel,
    rules,
    disabled,
    type = 'text',
    formState,
    ...props
  } = componentProps;

  const fieldLabel = getFormItemLabel(i18nLabel, label);

  const onKeyPress = (e) => {
    if (/[^\-0-9]/g.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Form.Item {...props} name={name} label={fieldLabel} rules={rules}>
      <AntdInput disabled={disabled} type={type} onKeyPress={onKeyPress} />
    </Form.Item>
  );
};
