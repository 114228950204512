import { UIViewInjectedProps } from '@uirouter/react';
import { PTOs } from '@/pages/common/components/ptos/components/table';
import { PERMISSION_OBJ_COORDINATOR_PTO } from '@/constants/permissions';

const entity = 'coordinator-ptos';
const pagePermission = PERMISSION_OBJ_COORDINATOR_PTO;

export const PtoTable = ({ transition }: UIViewInjectedProps) => (
  <PTOs entity={entity} pagePermission={pagePermission} transition={transition} />
);
