import React, { useState } from 'react';
import { Alert } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Transition } from '@uirouter/react';
import { api } from '@/api';
import { Button, Form, Input, Space } from '@/components/antd';
import { getTranslatedString } from '@/utils';
import { PerformanceReportState } from '@/pages/employee-performance/enums';
import { employeePerformanceStore } from '@/stores';
import { useScanner } from '@/pages/employee-performance/components/access-page/hooks';

import BarCode from './assets/lanyard.png';
import Keyboard from '@/pages/employee-performance/components/access-page/keyboard';

interface IProps {
  transition: Transition;
}

export const PerformanceReportAccess = ({ transition }: IProps) => {
  const { scannerError, barCode, resetCode } = useScanner();
  const loading = !barCode && !scannerError;
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [verifyError, setVerifyError] = useState<string>();
  const [formRef] = Form.useForm();

  const onSubmit = async (formValue: { password: string }) => {
    setSubmitting(true);
    setVerifyError(undefined);
    try {
      if (barCode) {
        await api.employeePerformance.verifyEmployee(formValue.password, { barCode }).source;
      }
      employeePerformanceStore.setPin(formValue.password);
      transition.router.stateService.go(PerformanceReportState.WeeklyReportPage);
    } catch (error) {
      switch (error?.response?.status) {
        case 400:
          setVerifyError('employee-performance.match-error');
          break;
        case 403:
          setVerifyError('employee-performance.invalid-barcode');
          break;
        case 404:
          setVerifyError('employee-performance.invalid-pin');
          break;
        default:
          setVerifyError('employee-performance.unexpected-error');
      }
    } finally {
      setSubmitting(false);
    }
  };

  const onCancel = () => {
    if (barCode) {
      resetCode();
    } else {
      // todo: ask about final config, do we need home broadcast
    }
  };
  return (
    <Form
      layout="vertical"
      onFinish={(formValue) => onSubmit(formValue)}
      style={{ display: 'flex', justifyContent: 'center', marginTop: '96px' }}
      form={formRef}
    >
      <Space style={{ width: '100%', maxWidth: '580px' }} align="center" direction="vertical" size={86}>
        {verifyError || scannerError ? (
          <Alert
            style={{ minWidth: '580px' }}
            message={getTranslatedString(verifyError || 'employee-performance.scanner-error')}
            type="error"
          />
        ) : null}
        <img src="/MPG-logo.png" alt="MPG logo" />
        {loading ? (
          <div>
            <p style={{ textAlign: 'center', fontSize: '24px' }}>
              <FormattedMessage id="employee-performance.scan-text" />
            </p>
            <img src={BarCode} style={{ maxWidth: '320px' }} alt="Logo" />
          </div>
        ) : (
          <Form.Item
            label={getTranslatedString('employee-performance.pin-label')}
            name="password"
            rules={[{ required: true }]}
            style={{ minWidth: '580px' }}
            required
          >
            <Input autoComplete="off" name="password" id="password" />
          </Form.Item>
        )}
        <Space size="large">
          {loading || (
            <>
              <Button className="performance-report-button" onClick={onCancel}>
                <FormattedMessage id="employee-performance.back-button" />
              </Button>
              <Button
                className="performance-report-button"
                type="primary"
                htmlType="submit"
                disabled={submitting || (!scannerError && !barCode)}
              >
                <FormattedMessage id="employee-performance.login-button" />
              </Button>
            </>
          )}
        </Space>
        {loading || <Keyboard getFieldValue={formRef.getFieldValue} setFieldsValues={formRef.setFieldsValue} />}
      </Space>
    </Form>
  );
};
