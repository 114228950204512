import React from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Dropdown, Layout as AntdLayout, Menu, Row, Space } from '@/components/antd';
import { localeStore } from '@/stores';

interface MenuProps {
  language: string;
  availableLanguages: string[];
  change: (language: string) => void;
}

const menu = ({ language, availableLanguages, change }: MenuProps) => (
  <Menu>
    {availableLanguages.map((languageItem) => (
      <Menu.Item
        key={languageItem}
        onClick={() => {
          if (language !== languageItem) change(languageItem);
        }}
      >
        <img style={{ width: '24px', marginRight: '12px' }} src={`/${languageItem}.png`} alt="flag" />
        <span>{languageItem.toUpperCase()}</span>
      </Menu.Item>
    ))}
  </Menu>
);

export const Header = observer(() => {
  const { language, availableLanguages, change } = localeStore;

  return (
    <AntdLayout.Header className="performance-report-header">
      <Row align="middle" gutter={16} justify="space-between">
        <Row align="middle" gutter={16}>
          <Col>
            <span className="c-icon c-icon--logo" />
          </Col>
        </Row>
        <Row wrap={false} gutter={16}>
          <Col>
            <Dropdown overlay={menu({ language, availableLanguages, change })}>
              <Space>
                <img style={{ width: '24px' }} src={`/${language}.png`} alt="flag" />
                <div style={{ color: '#fff' }}>{language.toUpperCase()}</div>
              </Space>
            </Dropdown>
          </Col>
        </Row>
      </Row>
    </AntdLayout.Header>
  );
});
