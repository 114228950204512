import { FormattedMessage } from 'react-intl';
import { Space } from '@/components/antd';

import { timesheetDateTransformer } from '@/utils/date';
import { minutesToHoursDec, minutesToStringHours } from '@/utils/time';
import { PERMISSION_OBJ_TIMESHEET, PERMISSION_READ, PERMISSION_UPDATE } from '@/constants/permissions';
import { agencyStore, shiftStore } from '@/stores';
import { getStorePropById } from '@/utils/common';
import { EditAction, ReadAction } from '@/components/table/actions';
import { withPermissions } from '@/hocs/permission';

import { TimesheetPageState } from '../../enums';
import { BillableTimeRender, BooleanValueRender } from '@/components/table/renders';

const PermissionedEditAction = withPermissions([[PERMISSION_OBJ_TIMESHEET, PERMISSION_UPDATE]])(EditAction);

const PermissionedReadAction = withPermissions([[PERMISSION_OBJ_TIMESHEET, PERMISSION_READ]])(ReadAction);

const DayTimesheetActions = (_: any, { id, canEdit }: any) => (
  <Space>
    <PermissionedReadAction
      uiSref={{
        to: TimesheetPageState.DayTimesheetDetails,
        params: { recordId: id },
      }}
    />
    <PermissionedEditAction
      disabled={!canEdit}
      uiSref={{
        to: TimesheetPageState.DayTimesheetEditForm,
        params: { recordId: id },
      }}
    />
  </Space>
);

export const getDayTimesheetColumn = () => {
  const getEditableFieldValue = (value, correctedValue) => (correctedValue !== null ? correctedValue : value);

  const columns: Array<
    Record<string, unknown> & {
      permissionSubject: string;
    }
  > = [
    {
      title: <FormattedMessage id="table.actions" />,
      dataIndex: 'actions',
      fixed: 'left',
      render: DayTimesheetActions,
      permissionSubject: 'generalData',
    },
    {
      title: <FormattedMessage id="timesheet.visitorId" />,
      dataIndex: 'visitorId',
      fixed: 'left',
      permissionSubject: 'employeeDetails',
    },
    {
      title: <FormattedMessage id="timesheet.firstName" />,
      dataIndex: 'firstName',
      fixed: 'left',
      permissionSubject: 'employeeDetails',
    },
    {
      title: <FormattedMessage id="timesheet.lastName" />,
      dataIndex: 'lastName',
      fixed: 'left',
      permissionSubject: 'employeeDetails',
    },
    {
      title: <FormattedMessage id="timesheet.isEdited" />,
      key: 'isEdited',
      dataIndex: 'isEdited',
      permissionSubject: 'generalData',
      render: (value) => BooleanValueRender(value || undefined),
    },
    {
      title: <FormattedMessage id="timesheet.employeeType" />,
      dataIndex: 'employeeType',
      render: (employeeType: string) => <FormattedMessage id={`timesheet.employeeType.${employeeType}`} />,
      permissionSubject: 'employeeDetails',
    },
    {
      title: <FormattedMessage id="timesheet.shiftPlannedId" />,
      dataIndex: 'shiftPlannedId',
      render: (itemId: string) => getStorePropById<mpg.api.shifts.Shift>(itemId, shiftStore),
      permissionSubject: 'shiftDetails',
    },
    {
      title: <FormattedMessage id="timesheet.employeeBuildingName" />,
      dataIndex: 'employeeBuildingName',
      permissionSubject: 'employeeDetails',
    },
    {
      title: <FormattedMessage id="timesheet.employeeAreaName" />,
      dataIndex: 'employeeAreaName',
      permissionSubject: 'employeeDetails',
    },

    {
      title: <FormattedMessage id="timesheet.middleName" />,
      dataIndex: 'middleName',
      permissionSubject: 'employeeDetails',
    },

    {
      title: <FormattedMessage id="timesheet.weekOf" />,
      dataIndex: 'weekOf',
      render: (value) => timesheetDateTransformer.from(value),
      permissionSubject: 'generalData',
    },
    {
      title: <FormattedMessage id="timesheet.timesheetDate" />,
      dataIndex: 'timesheetDate',
      render: (value) => timesheetDateTransformer.from(value),
      permissionSubject: 'generalData',
    },
    {
      title: <FormattedMessage id="timesheet.workingBillableTime" />,
      key: 'workingBillableTime',
      dataIndex: 'workingBillableTime',
      render: (value, item) => minutesToStringHours(getEditableFieldValue(value, item.workingBillableCorrectedTime)),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="timesheet.workingBillableTimeDec" />,
      key: 'workingBillableTimeDec',
      dataIndex: 'workingBillableTime',
      render: (value, item) => minutesToHoursDec(getEditableFieldValue(value, item.workingBillableCorrectedTime)),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="timesheet.transitPaidTime" />,
      dataIndex: 'transitPaidTime',
      render: (value, item) => BillableTimeRender(getEditableFieldValue(value, item.transitPaidCorrectedTime)),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="timesheet.brakePaidTime" />,
      dataIndex: 'brakePaidTime',
      render: (value, item) => BillableTimeRender(getEditableFieldValue(value, item.brakePaidCorrectedTime)),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="timesheet.bathPaidTime" />,
      dataIndex: 'bathPaidTime',
      render: (value, item) => BillableTimeRender(getEditableFieldValue(value, item.bathPaidCorrectedTime)),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="timesheet.lunchPaidTime" />,
      dataIndex: 'lunchPaidTime',
      render: (value, item) => BillableTimeRender(getEditableFieldValue(value, item.lunchPaidCorrectedTime)),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="timesheet.lunchUnpaidTime" />,
      dataIndex: 'lunchUnpaidTime',
      render: (value, item) => BillableTimeRender(getEditableFieldValue(value, item.lunchUnpaidCorrectedTime)),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="timesheet.totalAdditionTime" />,
      key: 'totalAdditionTime',
      dataIndex: 'totalAdditionTime',
      render: (value) => (value >= 0 ? minutesToStringHours(value) : `-${minutesToStringHours(-value)}`),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="timesheet.totalAdditionTimeDec" />,
      key: 'totalAdditionTimeDec',
      dataIndex: 'totalAdditionTime',
      render: (value) => minutesToHoursDec(value),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="timesheet.totalBillableTime" />,
      key: 'totalBillableTime',
      dataIndex: 'totalBillableTime',
      render: (value) => minutesToStringHours(value),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="timesheet.totalBillableTimeDec" />,
      key: 'totalBillableTimeDec',
      dataIndex: 'totalBillableTime',
      render: (value) => minutesToHoursDec(value),
      permissionSubject: 'times',
    },
    {
      title: <FormattedMessage id="billingType" />,
      dataIndex: 'billingType',
      permissionSubject: 'employeeDetails',
      render: (value: string) => <FormattedMessage id={`billingType.${value}`} />,
    },
    {
      title: <FormattedMessage id="timesheet.agencyId" />,
      dataIndex: 'agencyId',
      render: (itemId: string) => getStorePropById<mpg.api.agencies.Agency>(itemId, agencyStore),
      permissionSubject: 'agencyDetails',
    },
    {
      title: <FormattedMessage id="timesheet.agencyInternalId" />,
      dataIndex: 'agencyInternalId',
      permissionSubject: 'agencyDetails',
    },
  ];

  return columns.map(({ permissionSubject, ...props }) => ({
    ...props,
    permissions: [
      [PERMISSION_OBJ_TIMESHEET, PERMISSION_READ],
      [`${PERMISSION_OBJ_TIMESHEET}.${permissionSubject}`, PERMISSION_READ],
    ],
  }));
};
