import { FormattedMessage } from 'react-intl';

import {
  agencyStore,
  breaktimeStore,
  buildingStore,
  buildingsAreasStore,
  colorStore,
  shiftStore,
  supervisorStore,
} from '@/stores';
import {
  getRoundDecimalNumber,
  getStorePropById,
  getStoreItemById,
  buildingsAreasFormatFn,
} from '@/utils/common';
import { BooleanValueRender } from '@/components/table/renders';
import { formatEventDuration } from '@/utils/data-formatters';

export const getColumns = () => [
  {
    title: <FormattedMessage id="workingLogs.visitorId" />,
    dataIndex: 'visitorId',
  },
  {
    title: <FormattedMessage id="workingLogs.employeeType" />,
    dataIndex: 'employeeType',
    render: (employeeType: string) => <FormattedMessage id={`workingLogs.employeeType.${employeeType}`} />,
  },
  {
    title: <FormattedMessage id="workingLogs.workSchedule" />,
    dataIndex: 'workSchedule',
    render: (workSchedule: string) => <FormattedMessage id={`workingLogs.workSchedule.${workSchedule}`} />,
  },
  {
    title: <FormattedMessage id="workingLogs.visitorFirstName" />,
    dataIndex: 'visitorFirstName',
  },
  {
    title: <FormattedMessage id="workingLogs.visitorMiddleName" />,
    dataIndex: 'visitorMiddleName',
  },
  {
    title: <FormattedMessage id="workingLogs.visitorLastName" />,
    dataIndex: 'visitorLastName',
  },
  {
    title: <FormattedMessage id="workingLogs.buildingId" />,
    dataIndex: 'buildingId',
    render: (itemId: string) => getStorePropById<mpg.api.buildings.Building>(itemId, buildingStore),
  },
  {
    title: <FormattedMessage id="workingLogs.actualBuildingId" />,
    dataIndex: 'actualBuildingId',
    render: (itemId: string) => getStorePropById<mpg.api.buildings.Building>(itemId, buildingStore),
  },
  {
    title: <FormattedMessage id="workingLogs.buildingsAreasId" />,
    dataIndex: 'buildingsAreasId',
    render: (itemId: string) => buildingsAreasFormatFn(getStoreItemById(itemId, buildingsAreasStore)),
  },
  {
    title: <FormattedMessage id="workingLogs.checkInTurnStile" />,
    dataIndex: 'checkInTurnStile',
  },
  {
    title: <FormattedMessage id="workingLogs.checkInDate" />,
    dataIndex: 'checkInDate',
  },
  {
    title: <FormattedMessage id="workingLogs.checkInTime" />,
    dataIndex: 'checkInTime',
  },
  {
    title: <FormattedMessage id="workingLogs.checkOutTurnStile" />,
    dataIndex: 'checkOutTurnStile',
  },
  {
    title: <FormattedMessage id="workingLogs.checkOutDate" />,
    dataIndex: 'checkOutDate',
  },
  {
    title: <FormattedMessage id="workingLogs.checkOutTime" />,
    dataIndex: 'checkOutTime',
  },
  {
    title: <FormattedMessage id="workingLogs.time" />,
    dataIndex: 'workingHours',
    render: (value) => getRoundDecimalNumber(value),
  },
  {
    title: <FormattedMessage id="workingLogs.supervisorId" />,
    dataIndex: 'supervisorId',
    render: (itemId: string) => getStorePropById<mpg.api.supervisors.Supervisor>(itemId, supervisorStore),
  },
  {
    title: <FormattedMessage id="workingLogs.shiftPlannedId" />,
    dataIndex: 'shiftPlannedId',
    render: (itemId: string) => getStorePropById<mpg.api.shifts.Shift>(itemId, shiftStore),
  },
  {
    title: <FormattedMessage id="workingLogs.shiftAssignedId" />,
    dataIndex: 'eventStart',
    render: (_: any, item: mpg.api.workingLogs.WorkingLog) => formatEventDuration(item ?? {}),
  },
  {
    title: <FormattedMessage id="workingLogs.colorAssignedId" />,
    dataIndex: 'colorAssignedId',
    render: (itemId: string) => getStorePropById<mpg.api.colors.Colors>(itemId, colorStore),
  },
  {
    title: <FormattedMessage id="workingLogs.colorActualId" />,
    dataIndex: 'colorActual',
  },
  {
    title: <FormattedMessage id="workingLogs.weekOf" />,
    dataIndex: 'weekOf',
  },
  {
    title: <FormattedMessage id="workingLogs.breakTimes" />,
    dataIndex: 'breakTimeId',
    render: (itemId: string) => getStorePropById<mpg.api.breaktimes.Breaktime>(itemId, breaktimeStore),
  },
  {
    title: <FormattedMessage id="workingLogs.status" />,
    dataIndex: 'status',
    render: (status: string) => <FormattedMessage id={`employees.status.${status}`} />,
  },
  {
    title: <FormattedMessage id="workingLogs.agencyId" />,
    dataIndex: 'agencyId',
    render: (itemId: string) => getStorePropById<mpg.api.agencies.Agency>(itemId, agencyStore),
  },
  {
    title: <FormattedMessage id="workingLogs.agencyInternalId" />,
    dataIndex: 'agencyInternalId',
  },
  {
    title: <FormattedMessage id="workingLogs.autoCheckOut" />,
    dataIndex: 'autoCheckOut',
    render: BooleanValueRender,
  },
];
