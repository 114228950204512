import { trace, UIRouterReact } from '@uirouter/react';
import { AUTHORIZED_USER_INITIAL_STATE } from '@/constants';

import { authHook } from './hooks/auth';
import { authorizedHook } from './hooks/authorized';
import { permissionHook } from './hooks/permission';

export const routerConfig = (router: UIRouterReact) => {
  if (process.env.NODE_ENV === 'development') {
    trace.enable(1);
  }

  router.urlService.rules.initial({ state: AUTHORIZED_USER_INITIAL_STATE });

  router.transitionService.onBefore(authHook.criteria, authHook.callback, { priority: 10 });
  router.transitionService.onBefore(authorizedHook.criteria, authorizedHook.callback, { priority: 10 });
  router.transitionService.onBefore(permissionHook.criteria, permissionHook.callback, { priority: 10 });
};
