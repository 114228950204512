import { UIViewInjectedProps } from '@uirouter/react';
import { Form as AttendanceForm } from '../../common/components/attendance-reports/form';
import { AttendanceReports as AttendanceReportsComponent } from '../../common/components/attendance-reports/table';

import { PERMISSION_OBJ_COORDINATOR_SHIFT_REPORTING, PERMISSION_READ } from '@/constants/permissions';

const AttendanceReports = ({ transition }: UIViewInjectedProps) => {
  const tabConfig = {
    entity: 'coordinator-attendanceReports',
    localStorageKey: 'coordinator-attendanceReports-table',
    isEmployeeTypeShown: true,
  };
  return <AttendanceReportsComponent
    tabConfig={tabConfig}
    transition={transition}
  />;
};

const Form = ({ transition }: UIViewInjectedProps) => (
  <AttendanceForm
    entity="coordinator-attendanceReports"
    permission={PERMISSION_OBJ_COORDINATOR_SHIFT_REPORTING}
    isEmployeeTypeShown
    isFilterWithOptions
    transition={transition}
  />
);

export const states = [
  {
    name: 'base-layout.coordinator-attendanceReports',
    url: '/attendancereports',
    component: AttendanceReports,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_COORDINATOR_SHIFT_REPORTING, PERMISSION_READ]],
    },
  },
  {
    name: 'base-layout.coordinator-attendanceReports.view',
    url: '/:attendanceReportId/view',
    component: Form,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_COORDINATOR_SHIFT_REPORTING, PERMISSION_READ]],
    },
  },
];
