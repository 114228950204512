import { ShuttleReport } from '@/pages/shuttle-report/components/table';

import {
  PERMISSION_OBJ_SHUTTLE_BUS_REPORT,
  PERMISSION_READ,
} from '@/constants/permissions';

export const states = [
  {
    name: 'base-layout.shuttleBusReport',
    url: '/shuttle-bus-report',
    component: ShuttleReport,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_SHUTTLE_BUS_REPORT, PERMISSION_READ]],

    },
  },
];
