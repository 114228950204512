import { DependencyList, useEffect } from 'react';
import moment from 'moment/moment';

interface UseDbClickProps {
  handleDbClick?: (date: moment.Moment, event: Event) => void;
  deps?: DependencyList;
}

export const useDbClick = ({ handleDbClick, deps = [] }: UseDbClickProps) => {
  useEffect(() => {
    const cells = document.getElementsByClassName('ant-picker-cell') as HTMLCollectionOf<HTMLElement & { date: string }>;

    for (let i = 0; i < cells.length; i++) {
      const date = cells[i]?.getAttribute('date');
      cells[i].ondblclick = (event: Event) => handleDbClick(moment(date), event);
    }
  }, deps);
};
