import addresses from './addresses';
import attachments from './attachments';
import busShuttleReports from './bus-shuttle-reports';
import dump from './dump';
import notes from './notes';
import roles from './roles';
import search from './search';
import settings from './settings';
import employees from './employees';
import dictionaries from './dictionaries';
import employeePerformance from './employee-preformance';
import friendlyWay from './friendlyway';
import workingLogs from './working-logs';
import shiftReports from './shift-reports';
import users from './users';
import permanentEmployeesStandardHours from './permanent-employees-standard-hours';
import permanentEmployeesShiftWork from './permanent-employees-shift-work';
import ptos from './ptos';
import lockers from './lockers';
import shiftPlanningPerms from './shift-planning-perms';
import shiftPlanningTemps from './shift-planning-temps';
import employeeMarkups from './employee-markups';
import weekTimesheet from './week-timesheet';
import dayTimesheet from './day-timesheet';
import buildingConfig from './building-config';
import agreement from './agreement';
import buildingHost from './building-host';

export const api: { [key: string]: any } = {
  dump,
  addresses,
  attachments,
  agreement,
  roles,
  search,
  settings,
  notes,
  employees,
  dictionaries,
  friendlyWay,
  workingLogs,
  shiftReports,
  employeePerformance,
  busShuttleReports,
  permanentEmployeesStandardHours,
  permanentEmployeesShiftWork,
  ptos,
  lockers,
  shiftPlanningPerms,
  shiftPlanningTemps,
  users,
  employeeMarkups,
  weekTimesheet,
  dayTimesheet,
  buildingConfig,
  buildingHost,
};
