import { EmployeeStatus, UserRole } from '@/types/enums';

export const employeeState: Record<UserRole, Record<string, EmployeeStatus[]>> = {
  [UserRole.Agency]: {
    [EmployeeStatus.New]: [EmployeeStatus.TermAgency],
    [EmployeeStatus.Temp]: [EmployeeStatus.TermAgency],
    [EmployeeStatus.TermAgency]: [EmployeeStatus.New],
    [EmployeeStatus.Perm]: [],
    [EmployeeStatus.Term]: [],
  },
  [UserRole.MPG]: {
    [EmployeeStatus.New]: [EmployeeStatus.Term, EmployeeStatus.Temp],
    [EmployeeStatus.Temp]: [EmployeeStatus.Term],
    [EmployeeStatus.Perm]: [EmployeeStatus.Term],
    [EmployeeStatus.Term]: [EmployeeStatus.New, EmployeeStatus.Temp],
    [EmployeeStatus.TermAgency]: [EmployeeStatus.New],
  },
};
