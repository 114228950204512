import { PtoForm } from './components/form';
import { PtoTable } from './components/table';
import {
  PERMISSION_CREATE,
  PERMISSION_OBJ_COORDINATOR_PTO,
  PERMISSION_OBJ_PTO,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { BulkEditForm } from './components/form/bulk-edit';

export const states = [
  {
    name: 'base-layout.coordinator-ptos',
    url: '/ptos',
    component: PtoTable,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COORDINATOR_PTO, PERMISSION_READ],
        [PERMISSION_OBJ_PTO, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-ptos.create',
    url: '/new',
    component: PtoForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COORDINATOR_PTO, PERMISSION_READ],
        [PERMISSION_OBJ_PTO, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-ptos.edit',
    url: '/:ptoId/edit',
    component: PtoForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COORDINATOR_PTO, PERMISSION_READ],
        [PERMISSION_OBJ_PTO, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-ptos.bulk-edit',
    url: '/bulk-update',
    component: BulkEditForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COORDINATOR_PTO, PERMISSION_READ],
        [PERMISSION_OBJ_PTO, PERMISSION_UPDATE],
      ],
    },
  },
];
