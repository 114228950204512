import { AuditForm } from './components/audit-form';
import { Form } from './components/form';
import { PermanentsShiftWork } from './components/table';
import {
  PERMISSION_CREATE,
  PERMISSION_OBJ_COORDINATOR_PERMANENT_EMPLOYEE_SHIFT_WORK,
  PERMISSION_OBJ_PERMANENT_EMPLOYEE,
  PERMISSION_OBJ_PERMANENT_EMPLOYEE_SHIFT_WORK,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { BulkForm } from './components/form/bulk-edit';

export const states = [
  {
    name: 'base-layout.coordinator-permanentsShiftWork',
    url: '/permanentsShiftWork',
    component: PermanentsShiftWork,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COORDINATOR_PERMANENT_EMPLOYEE_SHIFT_WORK, PERMISSION_READ],
        [PERMISSION_OBJ_PERMANENT_EMPLOYEE, PERMISSION_READ],
        [PERMISSION_OBJ_PERMANENT_EMPLOYEE_SHIFT_WORK, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-permanentsShiftWork.create',
    url: '/permanentsShiftWork/new',
    component: Form,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COORDINATOR_PERMANENT_EMPLOYEE_SHIFT_WORK, PERMISSION_READ],
        [PERMISSION_OBJ_PERMANENT_EMPLOYEE, PERMISSION_CREATE],
        [PERMISSION_OBJ_PERMANENT_EMPLOYEE_SHIFT_WORK, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-permanentsShiftWork.edit',
    url: '/:permanentShiftWorkId/edit',
    component: Form,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COORDINATOR_PERMANENT_EMPLOYEE_SHIFT_WORK, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-permanentsShiftWork.edit.audit',
    url: '/:recordId/audit',
    component: AuditForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COORDINATOR_PERMANENT_EMPLOYEE_SHIFT_WORK, PERMISSION_READ],
        [PERMISSION_OBJ_PERMANENT_EMPLOYEE, PERMISSION_READ],
        [PERMISSION_OBJ_PERMANENT_EMPLOYEE_SHIFT_WORK, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-permanentsShiftWork.bulk-edit',
    url: '/bulk-update',
    component: BulkForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COORDINATOR_PERMANENT_EMPLOYEE_SHIFT_WORK, PERMISSION_READ],
        [PERMISSION_OBJ_PERMANENT_EMPLOYEE, PERMISSION_UPDATE],
        [PERMISSION_OBJ_PERMANENT_EMPLOYEE_SHIFT_WORK, PERMISSION_UPDATE],
      ],
    },
  },
];
