import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { UISref } from '@uirouter/react';

import {
  Button, Divider, Form, Input,
} from '@/components/antd';
import { UserOutlined } from '@/components/icons';
import { authStore } from '@/stores';

import { getResponseError } from '../handlers';
import { AuthLayout } from './auth-layout';

const ForgotPassword = ({ onSuccessSendEmail }: any) => {
  let baseURL = window.location.origin;

  if (window.location.origin === undefined) {
    baseURL = `${window.location.protocol}//${window.location.host}`;
  }

  const [state, setState] = useState({
    email: '',
    baseURL: `${baseURL}/auth/change-password`,
    responseMessage: false,
    responseView: '',
    error: null,
  });

  const setStateValue = (key: string, value: string | boolean) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };

  const onSendClick = async () => {
    try {
      const response = await authStore.resetPassword(state.email, state.baseURL);
      const responseView = onSuccessSendEmail((response as any)?.success, state.email);
      setStateValue('responseView', responseView);
      setStateValue('responseMessage', (response as any)?.success);
      setStateValue('error', '');
    } catch (err) {
      setStateValue('error', getResponseError(err));
    }
  };

  const onEmailChange = (event) => setStateValue('email', event.target.value);

  return (
    <AuthLayout>
      <h3 className="auth-description"><FormattedMessage id="login.forgot-password.description" /></h3>
      <Form
        name="forgot-password"
        requiredMark="optional"
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input Email!' }]}
        >
          <Input
            prefix={<UserOutlined />}
            name="email"
            type="email"
            id="email"
            value={state.email}
            onChange={onEmailChange}
          />
        </Form.Item>

        {
          state && state.error
            ? (
              <Form.Item>
                <div className="auth-error-text">
                  {state.error}
                </div>
              </Form.Item>
            )
            : null
        }

        <Form.Item>
          <Button className="auth-button" onClick={onSendClick} type="primary" htmlType="submit" disabled={state.responseMessage}>
            <FormattedMessage id="login.forgot-password.button.send" />
          </Button>
        </Form.Item>

        {
          state && state.responseView
            ? (
              <Form.Item>
                {state.responseView}
              </Form.Item>
            )
            : (
              <Form.Item>
                <div className="auth-link">
                  <UISref to="auth-login">
                    <a>
                      <span><FormattedMessage id="button.forgot-cancel" /></span>
                    </a>
                  </UISref>
                </div>
              </Form.Item>
            )
        }
      </Form>
    </AuthLayout>
  );
};

export const ForgotPasswordForm = ({ transition }: any) => {
  const onSuccessSendEmail = (message: boolean, email: string) => {
    const onClick = () => {
      transition.router.stateService.go('auth-login');
    };

    return (
      <>
        <Divider />
        {
          message
            ? (
              <>
                <FormattedMessage id="login.forgot-password.response.true" />
                <span>{email}</span>
                <Button
                  style={{ marginTop: 24 }}
                  className="auth-button"
                  onClick={onClick}
                  type="primary"
                  htmlType="submit"
                >
                  Ok
                </Button>
              </>
            )
            : <FormattedMessage id="login.forgot-password.response.false" />
        }
      </>
    );
  };

  return (<ForgotPassword onSuccessSendEmail={onSuccessSendEmail} />);
};
