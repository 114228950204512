import { AccessDenied } from './components/access-denied';

export const states = [
  {
    // health check
    name: 'ready',
    url: '/ready',
    component: () => <div>Healthy</div>,
  },
  {
    name: 'base-layout.access-denied',
    url: '/access-denied',
    component: AccessDenied,
    data: {
      requiresAuth: true,
    },
  },
];
