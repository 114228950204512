import { buildingStore, colorStore, shiftStore } from '@/stores';

import { findItemIdByLabel } from '@/utils/common';
import { FieldDescription, ParseSettings } from '@/components/import-button';
import { EmployeeType, WorkScheduleType } from '@/types/enums';
import { CollectionStore } from '@/stores/collection';

const SPLIT_SEPARATOR = ';';

const arrSvgMap = (str: string, store: CollectionStore<any, any, any>) => {
  const arr = str
    .split(SPLIT_SEPARATOR)
    .map((item) => findItemIdByLabel(store, 'name', item.trim()));

  return arr.every(Boolean) ? arr : undefined;
};

export const fields: FieldDescription[] = [
  {
    name: 'title',
  },
  {
    name: 'description',
  },
  {
    name: 'eventStart',
  },
  {
    name: 'eventEnd',
  },
  {
    name: 'buildingId',
    svgMap: (buildingName: string) => findItemIdByLabel(buildingStore, 'name', buildingName),
  },
  {
    name: 'employeeType',
    svgMap: () => EmployeeType.Temporary,
  },

  {
    name: 'workSchedule',
    svgMap: () => WorkScheduleType.Shift,
  },
  {
    name: 'additionalTime',
    svgMap: (additionalTime: string) => (Number.isInteger(Number(additionalTime)) ? additionalTime : undefined),
  },

  {
    name: 'colors',
    svgMap: (colors: string) => arrSvgMap(colors, colorStore),
  },
  {
    name: 'shifts',
    relatedToField: 'buildingId',
    svgMap: (shifts: string, buildingName: string) => {
      const shiftIds = arrSvgMap(shifts, shiftStore) || [];
      const valid = shiftIds.every((shiftId) => shiftStore.items
        .find((shift) => shift.building.name === buildingName && shift.id === shiftId));

      if (valid) {
        return shiftIds;
      }
    },
  },
];

export const parseSettings: ParseSettings = fields
  .reduce((acc, {
    name, svgKey, svgMap, svgValidator, relatedToField,
  }: FieldDescription) => {
    acc[svgKey || name] = {
      key: name,
      map: svgMap,
      validator: svgValidator,
      relatedToField,
    };
    return acc;
  }, {});
