import { UIView, UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { api } from '@/api';
import {
  Button, Col, Form as AntdForm, Modal, Row, Space,
} from '@/components/antd';
import { DrawerForm } from '@/components/drawer-form';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { Search } from '@/components/header/search';
import { PERMISSION_OBJ_EMPLOYEE, PERMISSION_OBJ_LOCKER } from '@/constants/permissions';
import {
  buildingStore,
  lockerStore,
  searchStore,
} from '@/stores';
import { SearchEmployeeInputType } from '@/types/enums';
import { getTranslatedString } from '@/utils';
import { showError } from '@/utils/common';
import { withoutRepeatValueOnlyForUpdate } from '@/utils/controller';
import { useFormDataById } from '@/utils/hooks';
import { useMassUpdateCashedStores } from '@/utils/store';
import { ExclamationCircleOutlined } from '@/components/icons';

import { getFormFields } from './setup';

const entity = 'coordinator-lockers';
const store = lockerStore;

const getEntityType = (type: string) => {
  switch (type) {
    case SearchEmployeeInputType.Employee:
      return 'employees';
    case SearchEmployeeInputType.ShiftWork:
      return 'permanentEmployeesShiftWork';
    case SearchEmployeeInputType.StandardHours:
      return 'permanentEmployeesStandardHours';
    default:
      return '';
  }
};

const DrawerFormHeader = ({ customAction, handleUnassign, isAssigned }) => (
  <Row align="bottom">
    <Col flex="auto">
      <Space
        className="form-header-extra"
        align="start"
        direction="vertical"
      >
        <span>{getTranslatedString('lockers.chooseEmployee')}</span>
        <div className="form-header-extra-search">
          <Search customAction={{ customAction }} />
        </div>
      </Space>
    </Col>
    <Col flex="none">
      {isAssigned && <Button key="unassign-btn" type="link" onClick={handleUnassign}>Unassign Employee</Button>}
    </Col>
  </Row>
);

export const Form = observer(({ transition }: UIViewInjectedProps) => {
  searchStore.setSearchParams({
    isShowHeaderSearch: true,
  });

  const { lockerId } = transition.router.globals.params;
  const { formData, loading: formDataLoading } = useFormDataById<mpg.api.lockers.Locker>(lockerId, 'lockers');
  const [formRef] = AntdForm.useForm();
  const [customDisabledSubmit, setCustomDisabledSubmit] = useState(true);

  const { loading } = useMassUpdateCashedStores([
    buildingStore,
  ]);

  useEffect(() => () => {
    searchStore.clearParams();
  }, []);

  const customAction = async (data) => {
    const { type = '' } = data;
    const entityType = getEntityType(type);
    const { source } = api[entityType].get(data.id);
    const { data: response } = await source;
    const {
      employeeId: resEmployeeId, kioskPinCode, firstName, lastName, employeeType, buildingId, status,
    } = response;
    const employeeId = type === SearchEmployeeInputType.Employee ? kioskPinCode : resEmployeeId;

    formRef.setFieldsValue({
      employeeId,
      firstName,
      lastName,
      employeeType,
      actualBuildingId: buildingId,
      employeeStatus: status,
    });

    const hasError = formRef.getFieldsError().some(({ errors }) => Boolean(errors.length));

    if (!hasError) {
      setCustomDisabledSubmit(false);
    }
  };

  const handleUnassign = () => {
    const unassign = () => api.lockers
      .update(formData && formData.id, { employeeId: '', buildingId: formData?.buildingId }).source
      .then(() => transition.router.stateService.reload())
      .then(() => store.refresh())
      .catch(showError);

    const hasTouched = formRef.isFieldsTouched();

    if (hasTouched) {
      return Modal.confirm({
        title: getTranslatedString('common.warning'),
        icon: <ExclamationCircleOutlined />,
        content: getTranslatedString('lockers.unassign-form-warning-desc'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: unassign,
      });
    }

    return unassign();
  };

  const onBack = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };

  const initValues = formData
    && {
      ...formData,
      dateLockerAssigned: formData.dateLockerAssigned && moment(formData.dateLockerAssigned),
    };

  const { serial = '' } = formData || {};

  const title = `${getTranslatedString('lockers.locker-number')} ${serial}`;

  const formFields = getFormFields({ initValues });
  const allowedFields = getAllowedFormFields(formFields, false, PERMISSION_OBJ_LOCKER, [], PERMISSION_OBJ_EMPLOYEE);

  const resourceController = withoutRepeatValueOnlyForUpdate(initValues, {
    update: (values: mpg.api.lockers.Locker) => api.lockers.update(formData && formData.id, { ...values, buildingId: formData?.buildingId }).source
      .then(() => store.refresh()),
  });
  const onFieldsChange = (_, all: any[]) => {
    const disable = all.some(({ errors }: any) => Boolean(errors.length));
    if (customDisabledSubmit !== disable) {
      setCustomDisabledSubmit(disable);
    }
  };

  return (
    <>
      <DrawerForm
        form={formRef}
        resourceId={lockerId}
        className="form-grid-wrapper"
        title={title}
        initValues={initValues}
        formFields={allowedFields}
        resourceController={resourceController}
        loaderCondition={formDataLoading || loading}
        layout="vertical"
        onClose={onBack}
        customDisabled={customDisabledSubmit}
        onFieldsChange={onFieldsChange}
        extra={[
          <DrawerFormHeader
            key="drawer-form-header"
            customAction={customAction}
            handleUnassign={handleUnassign}
            isAssigned={formData?.isAssigned}
          />,
        ]}
        headerStyle={{ flexDirection: 'column', alignItems: 'flex-start' }}
      />
      <UIView />
    </>
  );
});


