import { AgencyAuditForm } from './components/audit-form';
import { AgencyForm } from './components/form';
import { AgencyEmployees } from './components/table';
import {
  PERMISSION_CREATE,
  PERMISSION_OBJ_AGENCY_STAFFING,
  PERMISSION_OBJ_EMPLOYEE,
  PERMISSION_OBJ_EMPLOYEE_MARKUP,
  PERMISSION_READ, PERMISSION_UPDATE,
} from '@/constants/permissions';
import { AgencyEmployeeDetails } from './components/employee-details';
import { AgencyBulkForm } from './components/form/bulk-edit';
import { AgencyEmployeeMarkupForm } from './components/employee-markup/create-update-form';
import { AgencyMarkupAuditForm } from './components/employee-markup/markup-audit-form';
import { AgencyBulkRateForm } from './components/form/bulk-edit-rate';

export const states = [
  {
    name: 'base-layout.agency-employees',
    url: '/agency-employees',
    component: AgencyEmployees,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_AGENCY_STAFFING, PERMISSION_READ]],
    },
  },
  {
    name: 'base-layout.agency-employees.create',
    url: '/new',
    component: AgencyForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_AGENCY_STAFFING, PERMISSION_READ],
        [PERMISSION_OBJ_EMPLOYEE, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.agency-employees.edit',
    url: '/:employeeId/edit?{view}',
    component: AgencyEmployeeDetails,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_AGENCY_STAFFING, PERMISSION_READ]],
    },
  },
  {
    name: 'base-layout.agency-employees.edit.audit',
    url: '/:recordId/audit',
    component: AgencyAuditForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_AGENCY_STAFFING, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.agency-employees.edit.markup-audit',
    url: '/:recordId/employee-markup-audit',
    component: AgencyMarkupAuditForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_AGENCY_STAFFING, PERMISSION_READ],
      ],
    },
  },
  {
    name: 'base-layout.agency-employees.edit.create-employee-markup',
    url: '/employee-markups/create',
    component: AgencyEmployeeMarkupForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_AGENCY_STAFFING, PERMISSION_READ],
        [PERMISSION_OBJ_EMPLOYEE_MARKUP, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.agency-employees.edit.edit-employee-markup',
    url: '/employee-markups/:markupId/edit',
    component: AgencyEmployeeMarkupForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_AGENCY_STAFFING, PERMISSION_READ],
        [PERMISSION_OBJ_EMPLOYEE_MARKUP, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.agency-employees.bulk-edit',
    url: '/bulk-update',
    component: AgencyBulkForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_AGENCY_STAFFING, PERMISSION_READ],
        [PERMISSION_OBJ_EMPLOYEE, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.agency-employees.bulk-add-rate',
    url: '/bulk-add-rate',
    component: AgencyBulkRateForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_AGENCY_STAFFING, PERMISSION_READ],
        [PERMISSION_OBJ_EMPLOYEE, PERMISSION_CREATE],
      ],
    },
  },
];
