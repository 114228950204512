import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Form, Input } from '@/components/antd';
import { LockOutlined } from '@/components/icons';
import { authStore } from '@/stores';

import { getResponseError } from '../handlers';
import { AuthLayout } from './auth-layout';

const ChangeForgottenPassword = ({ onSuccessChangePassword }: any) => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const [state, setState] = useState({
    password: '',
    confirm: '',
    error: null,
    token: '',
    responseMessage: false,
    responseView: '',
  });

  const setStateValue = (key: string, value: string) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };

  const onChangeClick = async () => {
    try {
      const response = await authStore.changeForgottenPassword(state.password, state.token);
      const responseView = onSuccessChangePassword((response as any)?.success);
      setIsValid(false);
      setStateValue('responseView', responseView);
      setStateValue('responseMessage', (response as any)?.success);
    } catch (err) {
      setStateValue('error', getResponseError(err));
    }
  };

  const onPasswordChange = (event) => setStateValue('password', event.target.value);
  const onConfirmChange = (event) => setStateValue('confirm', event.target.value);
  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token');
    setStateValue('token', token);
  }, []);
  return (
    <AuthLayout>
      <Form
        name="change-password"
        requiredMark="optional"
        layout="vertical"
      >
        {
          state && !state.responseView
            ? (
              <>
                <Form.Item
                  label="New password"
                  name="password"
                  rules={[{ required: true, message: 'Please input new password!' }]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    name="password"
                    type="password"
                    id="password"
                    value={state.password}
                    onChange={onPasswordChange}
                  />
                </Form.Item>

                <Form.Item
                  label="Confirm new password"
                  name="confirm"
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          setIsValid(true);
                          return Promise.resolve();
                        }

                        setIsValid(false);
                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    name="confirm"
                    type="password"
                    id="confirm"
                    value={state.confirm}
                    onChange={onConfirmChange}
                  />
                </Form.Item>

                <Form.Item>
                  <Button className="auth-button" onClick={onChangeClick} type="primary" htmlType="submit" disabled={!isValid}>
                    <FormattedMessage id="login.change-password.header" />
                  </Button>
                </Form.Item>

                {
                  state && state.error
                    ? (
                      <Form.Item>
                        <div className="auth-error-text">
                          {state.error}
                        </div>
                      </Form.Item>
                    )
                    : null
                }
              </>
            )
            : (
              <Form.Item>
                {state.responseView}
              </Form.Item>
            )
        }
      </Form>
    </AuthLayout>
  );
};

export const ChangeForgottenPasswordForm = ({ transition }: any) => {
  const onSuccessChangePassword = (message: boolean) => {
    const onClick = () => {
      transition.router.stateService.go('auth-login');
    };

    return (
      <>
        {message ? (
          <FormattedMessage id="login.change-password.response.true" />
        ) : (
          <FormattedMessage id="login.forgot-password.response.false" />
        )}
        <Button style={{ marginTop: 24 }} className="auth-button" onClick={onClick} type="primary" htmlType="submit">
          <FormattedMessage id="button.login" />
        </Button>
      </>
    );
  };

  return <ChangeForgottenPassword onSuccessChangePassword={onSuccessChangePassword} />;
};
