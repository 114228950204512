import { request } from '@/utils/request';

import { ApiResponse, WrappedItemsResult, WrappedResult } from './types';

export default {
  get(params?: mpg.api.notes.GetParams): ApiResponse<WrappedItemsResult<mpg.api.notes.Note[]>> {
    return request({
      method: 'GET',
      url: '/notes',
      params,
    });
  },
  create(data: mpg.api.notes.Params): ApiResponse<WrappedResult<mpg.api.notes.Note>> {
    return request({
      method: 'POST',
      url: '/notes',
      data,
    });
  },
};
