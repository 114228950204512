import { Rule } from 'antd/lib/form';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import React from 'react';
import {
  message, Button, Form, FormProps,
} from '@/components/antd';

import { showError } from '@/utils/common';

import { fieldsFactory } from '../form-fields';

type FormOp = 'create' | 'update';

export interface GenericFormResourceController {
  create?: (values: any) => Promise<any>;
  update?: (values: any, resourceId?: string | number) => Promise<any>;
}

export interface FormBuilderOptionItem {
  name: string;
  rules?: Rule[];
  component?: any;
  componentName?: string;
  params: any;
  conditionalRender?: (values: any) => boolean;
  conditionalDisabling?: (values: any) => boolean;
  id?: string;
  isUniqueField?: boolean
}

export interface GenericFormProps extends FormProps {
  resourceId?: number | string;
  resourceController?: GenericFormResourceController;
  formRef?: FormInstance;
  actions?: React.ReactNode;
  formFields: FormBuilderOptionItem[];
  formFieldsBuilder?: (options: FormBuilderOptionItem[], formState: any) => React.ReactNode;
  hasSubmit?: boolean;
  tailLayout?: object;

  onStartSubmit?: VoidFunction;
  onFinishSubmit?: VoidFunction;
  onSuccessFinish?: VoidFunction;
}

interface FormStateParams {
  initialValues: any;
  // values: any;
  // formData: any;
  form: any;
  // ignoredFields: any;
}

const buildReadonlyField = (name: string, componentName: string, component: any, params: any, formState: any, rules: Rule[]) => {
  const Field = component;
  let value = formState.initialValues[name];

  if (componentName === 'dropdown') {
    value = params.options.find((i) => i.value === value)?.label;
  }

  if (componentName === 'upload') {
    value = '';
  }

  return (
    <Field {...params} name={name} rules={rules} value={value} />
  );
};

const buildGenericField = (name: string, component: any, params: any, formState: any, rules: Rule[]) => {
  const Field = component;
  return (
    <Field {...params} name={name} rules={rules} formState={formState} />
  );
};

const FormField = ({ formState, option }: any) => {
  const {
    name, component, componentName, params, rules = [],
  } = option;
  let finalComponent = component || fieldsFactory(componentName);
  let field;

  if (params?.readOnly) {
    finalComponent = fieldsFactory('labeled-span');
    field = buildReadonlyField(name, componentName, finalComponent, params, formState, rules);
  } else {
    field = buildGenericField(name, finalComponent, params, formState, rules);
  }

  return (
    <>
      {field}
    </>
  );
};

const defaultConditionalRender = () => true;

export const buildFormFields = (
  options: FormBuilderOptionItem[],
  formState: FormStateParams,
) => {
  const { initialValues, form } = formState;

  return options
    .filter(({ conditionalRender }: FormBuilderOptionItem) => (conditionalRender || defaultConditionalRender)(formState))
    .map((option: FormBuilderOptionItem, i: number) => (
      <FormField
        key={option.id || i}
        option={option}
        initData={initialValues}
        form={form}
        formState={formState}
      />
    ));
};

export const GenericForm = (
  {
    id,
    resourceId,
    resourceController,
    initialValues,
    onStartSubmit,
    onFieldsChange,
    onValuesChange,
    onFinishSubmit,
    onSuccessFinish,
    actions,
    formFields,
    formFieldsBuilder = buildFormFields,
    hasSubmit = false,
    formRef,
    requiredMark = true,
    className,
    layout,
    ...props
  }: GenericFormProps,
) => {
  const formOp: FormOp = !resourceId ? 'create' : 'update';
  const [genericForm] = Form.useForm();
  const form = formRef || genericForm;

  const layoutFlex = {
    labelCol: props.labelCol || { span: layout === 'vertical' ? 0 : 8 },
    wrapperCol: props.wrapperCol || { span: layout === 'vertical' ? 0 : 16 },
  };

  const tailLayout = props.tailLayout || { wrapperCol: { offset: 8, span: 16 } };

  const onFinish = (values: any) => {
    if (!resourceController) {
      return props.onFinish(values);
    }

    const promise = formOp === 'create'
      ? resourceController[formOp](values)
      : resourceId
        ? resourceController[formOp](values, resourceId)
        : Promise.resolve();

    onStartSubmit();

    return promise
      .then(() => {
        if (formOp === 'create') {
          form.resetFields();
        }
        message.success('Successful Submit.');
      })
      .then(onSuccessFinish)
      .catch(showError)
      .finally(onFinishSubmit);
  };

  return (
    <Form
      className={className}
      layout={layout}
      {...layoutFlex}
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      requiredMark={requiredMark}
      onValuesChange={onValuesChange}
      onFieldsChange={onFieldsChange}
    >
      {formFieldsBuilder(formFields, { form, initialValues })}
      {
        hasSubmit && (
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
        )
      }
      {actions}
    </Form>
  );
};
