import { AgencyBillingsWorkLogs } from './components/drawer';
import { AgencyBillings } from './components/table';

import { PERMISSION_OBJ_AGENCY_BILLING, PERMISSION_READ } from '@/constants/permissions';

export const states = [
  {
    name: 'base-layout.agency-billings',
    url: '/agency-billings',
    component: AgencyBillings,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_AGENCY_BILLING, PERMISSION_READ]],
    },
  },
  {
    name: 'base-layout.agency-billings.view',
    url: '/:billingId/view',
    component: AgencyBillingsWorkLogs,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_AGENCY_BILLING, PERMISSION_READ]],
    },
  },
];
