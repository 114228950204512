import { getFormattedParams, request, requestTo } from '@/utils/request';
import { ApiResponse, WrappedItemsResult, WrappedResult } from './types';

const prepareGetAllParams = (params?: mpg.api.ptos.GetParams) => getFormattedParams(params, true);

export default {
  getAll(params?: mpg.api.ptos.GetParams): ApiResponse<WrappedResult<mpg.api.ptos.PTO[]>> {
    return requestTo('ptos-calendar', params, '', prepareGetAllParams);
  },
  get(id: mpg.api.ptos.PTOId): ApiResponse<WrappedResult<mpg.api.ptos.PTO>> {
    return request({
      method: 'get',
      url: `/ptos-calendar/${id}`,
    });
  },
  create(data: mpg.api.ptos.PTOParams): ApiResponse<WrappedResult<mpg.api.ptos.PTO>> {
    return request({
      method: 'post',
      url: '/ptos-calendar',
      data,
    });
  },
  update(id: number, data: mpg.api.ptos.PTOParams): ApiResponse<WrappedResult<mpg.api.ptos.PTO>> {
    return request({
      method: 'patch',
      url: `/ptos-calendar/${id}`,
      data,
    });
  },
  delete(id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/ptos-calendar/${id}`,
    });
  },
  bulkCreate(data: { items: mpg.api.ptos.PTOParams[] }): ApiResponse<WrappedResult<mpg.api.ptos.PTO[]>> {
    return request({
      method: 'post',
      url: '/ptos-calendar/bulk/create',
      data,
    });
  },
  bulkUpdate(data: { items: mpg.api.ptos.PTO[] }): ApiResponse<WrappedItemsResult<mpg.api.ptos.PTO[]>> {
    return request({
      method: 'post',
      url: '/ptos-calendar/bulk/update',
      data,
    });
  },
  bulkDelete(data: { ids: string[] }): ApiResponse<WrappedResult<mpg.api.ptos.PTO[]>> {
    return request({
      method: 'post',
      url: '/ptos-calendar/bulk/delete',
      data,
    });
  },
  exportToExcel(params?: mpg.api.ptos.GetParams): ApiResponse<WrappedResult<Blob>> {
    return requestTo('ptos-calendar', params, 'export', prepareGetAllParams);
  },
  exportToEmail(params?: mpg.api.ptos.GetParams): ApiResponse<WrappedResult<Blob>> {
    return requestTo('ptos-calendar', params, 'export-by-link', prepareGetAllParams);
  },
};
