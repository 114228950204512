import React, { useEffect, useState } from 'react';
import { UISref } from '@uirouter/react';
import { FormattedMessage } from 'react-intl';

import {
  Button, Form, Input, Divider,
} from '@/components/antd';
import { UserOutlined, LockOutlined } from '@/components/icons';
import { AUTHORIZED_USER_INITIAL_STATE } from '@/constants';
import { authStore } from '@/stores';

import { getResponseError } from '../handlers';
import { AuthLayout } from './auth-layout';

const Login = ({ onSuccessLogin }: any) => {
  const [state, setState] = useState({
    email: '',
    password: '',
    error: null,
  });

  useEffect(() => {
    const load = async () => {
    };
    load();
  }, []);

  const setStateValue = (key: string, value: string) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };

  const onSignInClick = async () => {
    try {
      const response = await authStore.loginWithEmail(state.email, state.password);

      if (response) {
        const { isTwoFactorAuthEnabled } = response;
        onSuccessLogin(isTwoFactorAuthEnabled);
      }
    } catch (err) {
      setStateValue('error', getResponseError(err));
    }
  };
  const onEmailChange = (event) => setStateValue('email', event.target.value);
  const onPasswordChange = (event) => setStateValue('password', event.target.value);

  return (
    <AuthLayout>
      <Form
        name="login"
        requiredMark="optional"
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input Email!' }]}
        >
          <Input
            prefix={<UserOutlined />}
            name="email"
            type="email"
            id="email"
            value={state.email}
            onChange={onEmailChange}
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input password!' }]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            name="password"
            type="password"
            id="password"
            value={state.password}
            onChange={onPasswordChange}
          />
        </Form.Item>

        {
          state && state.error
            ? (
              <Form.Item>
                <div className="auth-error-text">
                  {state.error}
                </div>
              </Form.Item>
            )
            : null
        }

        <Divider style={{ marginBottom: 0 }} />

        <Form.Item>
          <div className="auth-link">
            <UISref to="auth-forgot-password">
              <a>
                <span><FormattedMessage id="login.forgot-password.header" /></span>
              </a>
            </UISref>
          </div>
        </Form.Item>

        <Form.Item>
          <Button className="auth-button" onClick={onSignInClick} type="primary" htmlType="submit">
            Sign In
          </Button>
        </Form.Item>

        {/* <Divider plain={true}>or</Divider> */}
        {/* <Form.Item> */}
        {/* { */}
        {/* providers.map((provider: string) => { */}
        {/* const onClick = () => onSignInWithGoogleClick(provider); */}

        {/* return ( */}
        {/* <Button */}
        {/* style={{ marginBottom: '1rem' }} */}
        {/* key={provider} */}
        {/* className="auth-button" */}
        {/* htmlType="button" */}
        {/* icon={<span className="fa-google-wrapper" />} */}
        {/* onClick={onClick} */}
        {/* > */}
        {/* Sign In ({provider}) */}
        {/* </Button> */}
        {/* ); */}
        {/* }) */}
        {/* } */}
        {/* <div className="auth-link"> */}
        {/* <UISref to={'auth-signup'}> */}
        {/* <a>Register</a> */}
        {/* </UISref> */}
        {/* </div> */}
        {/* <div className="auth-link auth-link-separator">&#124;</div> */}
        {/* <div className="auth-link"> */}
        {/* <UISref to={'auth-login'}> */}
        {/* <a>Simple Login</a> */}
        {/* </UISref> */}
        {/* </div> */}
        {/* </Form.Item> */}
      </Form>
    </AuthLayout>
  );
};

export const AuthLoginForm = ({ transition }: any) => {
  const onSuccessLogin = (isTwoFactorAuthEnabled: boolean) =>
    transition.router.stateService.go(isTwoFactorAuthEnabled ? 'auth-login-2fa' : AUTHORIZED_USER_INITIAL_STATE);
  return <Login onSuccessLogin={onSuccessLogin} />;
};
