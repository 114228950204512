import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { Dropdown, Menu } from '@/components/antd';
import { CheckOutlined, CloseOutlined, DownOutlined } from '@/components/icons';

import { useTimesheetBulkActions } from './hooks/bulk-actions';

export const TimesheetBulkActions = observer(({ selectedRowKeys, resetSelection }: {
  selectedRowKeys: string[];
  resetSelection: () => void;
}) => {
  const {
    canApprove,
    canUnapprove,
    onBulkApprove,
    onBulkUnapprove,
  } = useTimesheetBulkActions({ selectedRowKeys, resetSelection });

  if (!canApprove && !canUnapprove) {
    return null;
  }

  const batchMenu = (
    <Menu>
      {canApprove
        && (
        <Menu.Item
          key="bulk-approve"
          icon={<CheckOutlined />}
          onClick={onBulkApprove}
        >
          <FormattedMessage id="timesheet.bulkApproveButtonTitle" />
        </Menu.Item>
        )}
      {canUnapprove
        && (
        <Menu.Item
          key="bulk-unapprove"
          icon={<CloseOutlined />}
          onClick={onBulkUnapprove}
        >
          <FormattedMessage id="timesheet.bulkUnapproveButtonTitle" />
        </Menu.Item>
        )}
    </Menu>
  );

  return (
    <Dropdown key="dropdown" overlay={batchMenu} disabled={!selectedRowKeys.length}>
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <FormattedMessage id="batchActions" />
        {' '}
        <DownOutlined />
      </a>
    </Dropdown>
  );
});
