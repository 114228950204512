import { parse } from 'csv-parse/browser/esm/sync';
import { UploadOutlined } from '@/components/icons';
import { Button, notification, Upload } from '@/components/antd';

import { ApiResponse, WrappedResult } from '@/api/types';
import { showError } from '@/utils/common';
import { getTranslatedString } from '@/utils';

export interface FieldDescription {
  name: string;
  svgKey?: string;
  svgMap?: (e: string, re?: string) => any;
  svgValidator?: (e: any) => boolean;
  relatedToField?: string;
}

export interface ParseSettings {
  [key: string]: {
    key: string;
    map?: (e: string) => any;
    validator?: (e: any) => boolean;
  }
}

const getErrorFields = async (settings, values) => {
  const errorFields = [];
  for (const formField of Object.values(settings) as any[]) {
    const { key, validator } = formField;
    const value = values[key];

    if (validator) {
      const result = await validator(value);
      if (!result) {
        errorFields.push(key);
      }
    }
  }
  return errorFields;
};

const readFileAsText = (file) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.readAsText(file);

  reader.onload = async () => {
    resolve(reader.result as string);
  };
});

const processChunk = async (chunk, parseSettings, request) => {
  const mappedRecords = chunk.map((record: string) => Object.entries(record).reduce((acc, [key, value]) => {
    const trimmedValue: any = (value || '').trim();
    const columnSettings = parseSettings[key];

    if (columnSettings) {
      let mappedValue = trimmedValue;

      if (columnSettings.map) {
        const relatedField = columnSettings.relatedToField && record[columnSettings.relatedToField];

        mappedValue = relatedField ? columnSettings.map(trimmedValue, relatedField) : columnSettings.map(trimmedValue);
        if (trimmedValue && mappedValue === undefined) {
          throw new Error(`Invalid ${key} value: ${trimmedValue}`);
        }
      }

      if (mappedValue || mappedValue === false) {
        acc[columnSettings.key] = mappedValue;
      }
    }

    return acc;
  }, {}));

  let areRecordsValid = true;

  for (let index = 0; index < mappedRecords.length; index++) {
    const record = mappedRecords[index];
    const errorFields = await getErrorFields(parseSettings, record);

    if (errorFields.length) {
      areRecordsValid = false;

      notification.error({
        message: 'Invalid file',
        description: <div>
          <p>
            Invalid row
            {index + 1}
            .
          </p>
          <p>
            Invalid fields: $
            {errorFields.join(', ')}
          </p>
                     </div>,
        duration: 10,
      });

      break;
    }
  }

  if (areRecordsValid) {
    await request(mappedRecords);
  }
};

const getUploadProps = ({ parseSettings, request, storeRefreshRequest }: ImportButtonProps) => ({
  accept: '.csv',
  customRequest() {
    return Promise.resolve();
  },
  async beforeUpload(file) {
    try {
      const text = await readFileAsText(file);

      let records = [];

      try {
        records = parse(text as string, {
          columns: true,
          skip_empty_lines: true,
        }) as unknown as any[];
      } catch (err) {
        notification.error({
          message: 'Invalid file',
          description: <div>
            <p>{err.message}</p>
            <p>{err.response?.data?.error?.message}</p>
                       </div>,
          duration: 10,
        });

        return Upload.LIST_IGNORE;
      }

      const chunks = [];
      const chunkSize = 100;

      for (let i = 0; i < records.length; i += chunkSize) {
        chunks.push(records.slice(i, i + chunkSize));
      }

      for (const [i, chunk] of chunks.entries() as any) {
        const chunkEndIndex = i * chunkSize + chunkSize;
        const startIndex = i * chunkSize + 1;
        const endIndex = chunkEndIndex > records.length
          ? records.length
          : chunkEndIndex;

        const title = startIndex !== endIndex
          ? `Records import ${startIndex} - ${endIndex}`
          : `Record import ${startIndex}`;
        try {
          await processChunk(chunk, parseSettings, request);
          notification.info({
            message: title,
            description: <div>
              <p>Successfully imported</p>
                         </div>,
            duration: 10,
          });
        } catch (err) {
          notification.error({
            message: title,
            description: <div>
              <p>{err.message}</p>
              <p>{err.response?.data?.error?.message}</p>
                         </div>,
            duration: 10,
          });
        }
      }

      await storeRefreshRequest();
      return Upload.LIST_IGNORE;
    } catch (err) {
      showError(err);
      try {
        await storeRefreshRequest();
        return Upload.LIST_IGNORE;
      } catch (secondErr) {
        showError(secondErr);
        return Upload.LIST_IGNORE;
      }
    }
  },
});

export interface ImportButtonProps {
  parseSettings: ParseSettings;
  request: (e: any[]) => ApiResponse<WrappedResult<any>>;
  storeRefreshRequest: () => ApiResponse<WrappedResult<any>>;
}

export const ImportButton = ({ parseSettings, request, storeRefreshRequest }: ImportButtonProps) => (
  <Upload {...(getUploadProps({ parseSettings, request, storeRefreshRequest }))}>
    <Button className="c-button--primary" shape="round" icon={<UploadOutlined />}>{getTranslatedString('button.upload')}</Button>
  </Upload>
);
