import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';

import { UIView, UIViewInjectedProps } from '@uirouter/react';
import { SettingOutlined, PrinterOutlined } from '@/components/icons';
import { Table } from '@/components/table';
import {
  PERMISSION_OBJ_WORKING_LOG_TEMP_SHIFT,
  PERMISSION_OBJ_WORKING_LOG_PERM_SHIFT,
  PERMISSION_OBJ_WORKING_LOG_PERM_STANDARD,
  PERMISSION_READ,
} from '@/constants/permissions';
import { billingStore } from '@/stores';
import { useWeeks } from '@/utils/hooks';
import { Button, PageHeader } from '@/components/antd';
import { onPrint } from '@/utils/common';
import { api } from '@/api';
import { getColumns } from './setup';
import ExportButton from '@/pages/common/components/buttons/export-button';
import { withAtLeastOnePermission } from '@/hocs/permission';

const store = billingStore;
interface IForm extends UIViewInjectedProps {
  entity: string;
  loading: boolean;
}

export const Billings = observer(({ transition, entity, loading }: IForm) => {
  const { items } = store;
  const tableRef: any = useRef({});
  const weeks = useWeeks();

  const onDoubleClick = (record) => {
    transition.router.stateService.go(`base-layout.${entity}.view`, { billingId: btoa(JSON.stringify(record)) });
  };

  return (
    <>
      <PageHeader
        className="r-antd-page-header"
        extra={[
          <Button key="print" size="middle" icon={<PrinterOutlined />} onClick={onPrint} />,
          <Button
            size="middle"
            key="setting"
            icon={<SettingOutlined />}
            onClick={tableRef.current.showSettingsModal}
          />,

          <ExportButton
            store={store}
            exportToExcel={api.workingLogs.billingsExportToExcel}
            exportToEmail={api.workingLogs.billingsExportToEmail}
            withPermission={withAtLeastOnePermission([
              [PERMISSION_OBJ_WORKING_LOG_TEMP_SHIFT, PERMISSION_READ],
              [PERMISSION_OBJ_WORKING_LOG_PERM_SHIFT, PERMISSION_READ],
              [PERMISSION_OBJ_WORKING_LOG_PERM_STANDARD, PERMISSION_READ],
            ])}
            fileName="mpg-billings"
          />,

          // <Button key="export" icon={<ExportOutlined />} type="primary" shape="circle" />,
        ]}
      />

      <Table
        tableRef={tableRef}
        onRow={(record) => ({
          onDoubleClick: () => onDoubleClick(record),
        })}
        rowKey={(record: mpg.api.workingLogs.WorkingLogReport) => JSON.stringify(record)}
        localStorageKey={`${entity}-table`}
        columns={getColumns(weeks, entity === 'agency-billings')}
        dataSource={[...items]}
        store={store}
        checkPermissions
        // permissionObj={PERMISSION_OBJ_WORKING_LOG} TODO: Not used in src\components\table\hooks.tsx
        loading={store.loading || loading}
        scroll={{ y: '100%' }}
        isAutoloadAllowed={!loading}
        initialSorters={{
          columnKey: 'visitorId',
          field: 'visitorId',
          order: 'ascend',
        }}
      />
      <UIView />
    </>
  );
});
