import jwtDecode from 'jwt-decode';
import { action, makeObservable, observable } from 'mobx';

import {
  activate2fa,
  check2fa,
  changeForgottenPassword,
  deactivate2fa,
  getResetPasswordLink,
  login,
  loginAs,
  permissions,
  resendCode,
  register,
  session,
  turnOff2fa,
  turnOn2fa,
} from '@/api/auth';
import { AUTH_URL } from '@/constants';
import { AccessTokenProvider } from '@/utils/access-token-provider';
import { SettingsStore } from './settings';

const ADMIN_ROLE_ID = 1;

export class AuthStore {
  @observable loggedIn: boolean = false;

  @observable profile: any = {};

  @observable isLastAgreementSigned: boolean;

  @observable permissions: any = {};

  constructor(
    private readonly tokenProvider: AccessTokenProvider,
    private readonly twoFactorAuthTokenProvider: AccessTokenProvider,
    private readonly settingsStore: SettingsStore,
  ) {
    makeObservable(this);
  }

  @action async completeLogin() {
    const token = this.tokenProvider.get();

    if (token) {
      const decoded = jwtDecode(token) as any;
      await this.loadPermissions(token);

      this.profile = decoded?.user;
      this.loggedIn = true;

      await Promise.allSettled([this.settingsStore.loadSettings(), this.settingsStore.loadVacationStatus()]);
    }
  }

  @action logout() {
    this.loggedIn = false;
    this.profile = {};
    this.permissions = {};
    this.tokenProvider.remove();
    this.twoFactorAuthTokenProvider.remove();
    sessionStorage.clear()
  }

  isAuthorized = () => this.loggedIn && !!this.tokenProvider.get();

  hasAdminRole = () => (this.profile?.roles || []).includes(ADMIN_ROLE_ID);

  loadPermissions = async (token: string) => {
    try {
      const perms = await permissions(token);
      this.setPermissions(perms);
    } catch (err) {
      this.setPermissions({});
    }
  };

  @action setPermissions = (perms: Record<string, number>) => {
    this.permissions = {};
    this.permissions = perms;
  };

  hasPermissions = (perms: [string, number][]) =>
    perms.reduce((final: boolean, [obj, mask]: any) => final && (this.permissions[obj] & mask) === mask, true);

  hasAtLeastOnePermission = (perms: [string, number][]) =>
    perms.reduce((final: boolean, [obj, mask]: any) => final || (this.permissions[obj] & mask) === mask, false);

  checkSession = async () => {
    const token = this.tokenProvider.get();
    try {
      if (token) {
        await session(token);
        await this.completeLogin();
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        this.tokenProvider.remove();
      }
    }
  };

  loginWithEmail = async (email: string, password: string) => {
    const response = await login({ email, password });

    const { isTwoFactorAuthEnabled } = response.data;

    if (isTwoFactorAuthEnabled) {
      const { twoFaToken } = response.data;
      this.twoFactorAuthTokenProvider.set(twoFaToken);
    } else {
      const { token } = response.data;
      this.tokenProvider.set(token);

      await this.completeLogin();
    }

    return {
      isTwoFactorAuthEnabled,
    };
  };

  resetPassword = async (email: string, clientAppUrl: string) => {
    const response = await getResetPasswordLink({ email, clientAppUrl });
    const { data } = response;

    return data;
  };

  changeForgottenPassword = async (password: string, accessToken: string) => {
    const response = await changeForgottenPassword({ password }, accessToken);
    const { data } = response;

    return data;
  };

  register = async (email: string, password: string, repeatPassword: string) => {
    await register({ email, password, repeatPassword });
  };

  loginWithSaml = async (provider: string): Promise<void> =>
    new Promise((resolve) => {
      const win = window.open(
        `${AUTH_URL}/saml/${provider}/login`,
        'saml-login',
        'height=500,width=500,left=700,top=250',
      );

      if (win) {
        const onMessage = async (event: any) => {
          window.removeEventListener('message', onMessage);
          win.close();

          this.tokenProvider.set(event.data);
          await this.completeLogin();

          resolve();
        };
        window.addEventListener('message', onMessage);
      }
    });

  loginWithRoles = async (roleIds: number[]) => {
    const accessToken = this.tokenProvider.get();
    const response = await loginAs({ roleIds }, accessToken);

    const { token } = response.data;
    this.tokenProvider.set(token);

    await this.completeLogin();
  };

  loginWith2fa = async (twoFactorAuthCode: string) => {
    const twoFaToken = this.twoFactorAuthTokenProvider.get();
    const response = await check2fa({ twoFaToken, twoFactorAuthCode });

    const { token } = response.data;
    this.tokenProvider.set(token);

    await this.completeLogin();
  };

  resend2faCode = async () => {
    const twoFaToken = this.twoFactorAuthTokenProvider.get();
    const response = await resendCode({ twoFaToken });

    return response.data;
  };

  activate2fa = async ({ email, password, authType, phoneNumber }) => {
    const accessToken = this.tokenProvider.get();
    const response = await activate2fa(
      {
        email,
        password,
        authType,
        phoneNumber,
      },
      accessToken,
    );

    const { isTwoFactorAuthEnabled, message, twoFaToken } = response.data;

    this.twoFactorAuthTokenProvider.set(twoFaToken);

    return { message, success: isTwoFactorAuthEnabled };
  };

  deactivate2fa = async ({ email, password }) => {
    const accessToken = this.tokenProvider.get();
    const response = await deactivate2fa({ email, password }, accessToken);

    const { isTwoFactorAuthEnabled, message, twoFaToken } = response.data;
    this.twoFactorAuthTokenProvider.set(twoFaToken);

    return { message, success: isTwoFactorAuthEnabled };
  };

  turnOn2fa = async ({ twoFactorAuthCode }) => {
    const accessToken = this.tokenProvider.get();
    const twoFaToken = this.twoFactorAuthTokenProvider.get();
    const response = await turnOn2fa({ twoFactorAuthCode, twoFaToken }, accessToken);
    const { status } = response.data;

    return status;
  };

  turnOff2fa = async ({ twoFactorAuthCode }) => {
    const accessToken = this.tokenProvider.get();
    const twoFaToken = this.twoFactorAuthTokenProvider.get();
    const response = await turnOff2fa({ twoFactorAuthCode, twoFaToken }, accessToken);
    const { status } = response.data;

    return status;
  };
}
