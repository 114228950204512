import { api } from '@/api';
import { employeeStore } from '@/stores';
import {getParseSettings} from './csv-parser-setup';

export const useEmployeeBulkCreate = (isCoordinatorPage: boolean) => {
  const importRequest = (parsedItems: mpg.api.employees.EmployeeParams[]) => api.employees.bulkCreate({ items: parsedItems }).source;

  const storeRefreshRequest = () => employeeStore.refresh();
  const parseSettings = getParseSettings(!isCoordinatorPage)
  return {
    importRequest,
    parseSettings,
    storeRefreshRequest,
  };
};
