import { UIView } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { PERMISSION_OBJ_EMPLOYEE } from '@/constants/permissions';
import { Table } from '@/components/table';
import { getAdditionalColumns } from '../form/setup';
import { employeeAdditionalStore } from '@/stores';
import { useTableDataByKey } from '@/utils/hooks';

export const EmployeeAuditView = observer(
  ({ employeeId, entity, permission }: { employeeId: string; entity: string; permission: string }) => {
    const columns = useMemo(
      () =>
        getAdditionalColumns({ permission, entity }).map((column) => ({
          ...column,
          sorter: false,
          filters: null,
          filterDropdown: null,
        })),
      [],
    );

    const {
      additionalStore,
      dataSource,
      loading: additionalDataLoading,
      onChangeTable,
    } = useTableDataByKey(employeeId, employeeAdditionalStore, columns);

    return (
      <>
        <Table
          id="additional-content"
          rowKey="id"
          columns={columns}
          dataSource={dataSource}
          store={additionalStore}
          checkPermissions
          permissionObj={PERMISSION_OBJ_EMPLOYEE}
          loading={additionalDataLoading}
          scroll={{ y: '100%' }}
          customHeight="80vh"
          onChange={onChangeTable}
        />
        <UIView />
      </>
    );
  },
);
