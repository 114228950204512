import { UIViewInjectedProps } from '@uirouter/react';
import { PERMISSION_OBJ_COORDINATOR_STAFFING } from '@/constants/permissions';

import { Employees } from '@/pages/common/components/employees/components/table';

const permission = PERMISSION_OBJ_COORDINATOR_STAFFING;
const entity = 'coordinator-employees';
export const CoordinatorEmployees = ({ transition }: UIViewInjectedProps) => (
  <Employees transition={transition} entity={entity} permission={permission} />
);
