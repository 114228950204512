import { observer } from 'mobx-react-lite';
import React from 'react';
import { UIView, UIViewInjectedProps } from '@uirouter/react';
import { Tabs } from '@/components/antd';

import {
  PERMISSION_OBJ_SHIFT_REPORT_TEMPORARY,
  PERMISSION_OBJ_SHIFT_REPORT_PERMANENT,
  PERMISSION_READ,
} from '@/constants/permissions';
import {
  temporaryShiftReportStore,
  permanentShiftReportStore,
  authStore,
} from '@/stores';
import { getColumns } from './setup';
import { EmployeeType } from '@/types/enums';
import { api } from '@/api';
import { EmployeeTab } from '../employee-tab';
import { ShiftReportView } from '../emuns';
import { getTranslatedString } from '@/utils';

interface IProps extends UIViewInjectedProps {
  tabConfig: { entity: string; localStorageKey: string; isEmployeeTypeShown: boolean; isFilterWithOptions: boolean };
}

export const ShiftReports = observer(({ tabConfig, transition }: IProps) => {
  const { entity, localStorageKey, isEmployeeTypeShown, isFilterWithOptions } = tabConfig;

  const columns = getColumns(isEmployeeTypeShown, isFilterWithOptions);
  return (
    <>
      <Tabs destroyInactiveTabPane className="shift-reports">
        {authStore.hasPermissions([[PERMISSION_OBJ_SHIFT_REPORT_TEMPORARY, PERMISSION_READ]]) && (
          <Tabs.TabPane tab={getTranslatedString('shiftReports.temporary-tab')} key={ShiftReportView.Temporary}>
            <EmployeeTab
              store={temporaryShiftReportStore}
              employeeType={EmployeeType.Temporary}
              columns={columns}
              transition={transition}
              permissionObj={[PERMISSION_OBJ_SHIFT_REPORT_TEMPORARY, PERMISSION_READ]}
              exportToExcel={api.shiftReports.exportToExcelTemporaryEmployeesShiftReports}
              exportToEmail={api.shiftReports.exportToEmailTemporaryEmployeesShiftReports}
              fileName="shift-report-temporary"
              entity={entity}
              localStorageKey={localStorageKey}
            />
          </Tabs.TabPane>
        )}
        {authStore.hasPermissions([[PERMISSION_OBJ_SHIFT_REPORT_PERMANENT, PERMISSION_READ]]) && (
          <Tabs.TabPane tab={getTranslatedString('shiftReports.permanents-tab')} key={ShiftReportView.Permanent}>
            <EmployeeTab
              store={permanentShiftReportStore}
              employeeType={EmployeeType.Permanent}
              columns={columns}
              transition={transition}
              permissionObj={[PERMISSION_OBJ_SHIFT_REPORT_PERMANENT, PERMISSION_READ]}
              exportToExcel={api.shiftReports.exportToExcelPermanentEmployeesShiftReports}
              exportToEmail={api.shiftReports.exportToEmailPermanentEmployeesShiftReports}
              fileName="shift-report-permanent"
              entity={entity}
              localStorageKey={localStorageKey}
            />
          </Tabs.TabPane>
        )}
      </Tabs>
      <UIView />
    </>
  );
});
