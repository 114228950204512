import { request } from '@/utils/request';
import { ApiResponse, WrappedResult } from './types';

const employeePerformanceApi = {
  getWeeklyPerformanceReport(
    id: mpg.api.employeePerformance.VisitorId,
    params: mpg.api.employeePerformance.Week,
  ): ApiResponse<WrappedResult<mpg.api.employeePerformance.WeeklyPerformanceReport>> {
    const visitorId = btoa(`${id}:`);
    return request({
      method: 'get',
      url: 'employee-performance/weekly-performance-report',
      headers: { Authorization: `Basic ${visitorId}` },
      params: { weekOf: params },
    });
  },
  getDetailedWeeklyReport(
    id: mpg.api.employeePerformance.VisitorId,
    params: mpg.api.employeePerformance.Week,
  ): ApiResponse<WrappedResult<mpg.api.employeePerformance.DetailedWeeklyReport[]>> {
    const visitorId = btoa(`${id}:`);
    return request({
      method: 'get',
      url: 'employee-performance/detailed-weekly-report',
      headers: { Authorization: `Basic ${visitorId}` },
      params: { weekOf: params },
    });
  },
  getWeekOfs(id: mpg.api.employeePerformance.VisitorId): ApiResponse<WrappedResult<mpg.api.employeePerformance.DetailedWeeklyReport[]>> {
    const visitorId = btoa(`${id}:`);
    return request({
      method: 'get',
      url: 'employee-performance/week-ofs',
      headers: { Authorization: `Basic ${visitorId}` },
    });
  },
  verifyEmployee(id: mpg.api.employeePerformance.VisitorId, data: { barCode: string }): ApiResponse<WrappedResult<void>> {
    const visitorId = btoa(`${id}:`);
    return request({
      method: 'post',
      url: 'employee-performance/verify-access',
      data,
      headers: { Authorization: `Basic ${visitorId}` },
    });
  },
  sendDetailedWeeklyReportToEmail(
    id: mpg.api.employeePerformance.VisitorId,
    params: mpg.api.employeePerformance.Week,
  ): ApiResponse<WrappedResult<void>> {
    const visitorId = btoa(`${id}:`);
    return request({
      method: 'post',
      url: 'employee-performance/detailed-weekly-report-email',
      headers: { Authorization: `Basic ${visitorId}` },
      params: { weekOf: params },
    });
  },
};

export default employeePerformanceApi;
