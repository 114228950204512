import {
  getFormattedParams, request, requestTo, getParamsWithMappedSort, getParamsWithMappedFields,
} from '@/utils/request';
import {ApiResponse, ItemsResult, WrappedAxiosItemsResult, WrappedItemsResult, WrappedResult} from './types';

const prepareGetAllParams = (params0?: mpg.api.workingLogs.GetParams) => {
  const paramsMap = {
    checkInDate: 'checkInRawDate',
    checkInTime: 'checkInRawDate',
    checkOutDate: 'checkOutRawDate',
    checkOutTime: 'checkOutRawDate',
  };
  const params = getParamsWithMappedFields(getParamsWithMappedSort(params0, paramsMap));
  return getFormattedParams(params);
};

const prepareGetAllReportParams = (params?: mpg.api.workingLogs.GetParams) => {
  const paramsMap = {
    workingHours: 'workingHoursFloat',
    overtimeHours: 'overtimeHoursFloat',
    billableTimeHours: 'billableTimeHoursFloat',
    grossTimeHours: 'grossTimeHoursFloat',
  };
  return getFormattedParams(getParamsWithMappedSort(params, paramsMap));
};

const prepareGetReportParams = (params: mpg.api.workingLogs.GetParams) => {
  const { workingHours, workingHoursFloat, ...decreasedParams } = params;
  return decreasedParams;
};

export default {
  getAll(params0?: mpg.api.workingLogs.GetParams): ApiResponse<WrappedAxiosItemsResult<mpg.api.workingLogs.WorkingLog[]>> {
    return requestTo('working-logs', params0, '', prepareGetAllParams);
  },
  get(id: mpg.api.workingLogs.WorkingLogId): ApiResponse<mpg.api.workingLogs.WorkingLog> {
    return request({
      method: 'get',
      url: `working-logs/${id}`,
    });
  },
  // unused
  create(data: mpg.api.workingLogs.WorkingLogParams): ApiResponse<mpg.api.workingLogs.WorkingLog> {
    return request({
      method: 'post',
      url: '/working-logs',
      data,
    });
  },
  // unused
  update(id: number, data: mpg.api.workingLogs.WorkingLogParams): ApiResponse<mpg.api.workingLogs.WorkingLog> {
    return request({
      method: 'patch',
      url: `/working-logs/${id}`,
      data,
    });
  },
  // unused
  delete(id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/working-logs/${id}`,
    });
  },
  // unused
  bulkCreate(data: { items: mpg.api.workingLogs.WorkingLogParams[] }): ApiResponse<WrappedResult<mpg.api.workingLogs.WorkingLog[]>> {
    return request({
      method: 'post',
      url: '/working-logs/bulk/create',
      data,
    });
  },
  // unused
  bulkUpdate(data: { items: mpg.api.workingLogs.WorkingLog[] }): ApiResponse<WrappedItemsResult<mpg.api.workingLogs.WorkingLog[]>> {
    return request({
      method: 'post',
      url: '/working-logs/bulk/update',
      data,
    });
  },
  getReportAll(params?: mpg.api.workingLogs.GetParams): ApiResponse<WrappedAxiosItemsResult<mpg.api.workingLogs.WorkingLogReport[]>> {
    return requestTo('working-logs/report', params, '', prepareGetAllReportParams);
  },
  getReport(params: mpg.api.workingLogs.GetParams): ApiResponse<WrappedAxiosItemsResult<mpg.api.workingLogs.WorkingLogReport[]>> {
    return requestTo('working-logs/report/byKey', params, '', prepareGetReportParams);
  },
  getReportWeekOfs(): ApiResponse<ItemsResult<string[]>> {
    return request({
      method: 'get',
      url: 'working-logs/report/weekOfs',
    });
  },
  getReportCheckInTurnStiles(): ApiResponse<ItemsResult<Array<string | null>>> {
    return request({
      method: 'get',
      url: 'working-logs/report/checkInTurnStiles',
    });
  },
  getReportCheckOutTurnStiles(): ApiResponse<ItemsResult<Array<string | null>>> {
    return request({
      method: 'get',
      url: 'working-logs/report/checkOutTurnStiles',
    });
  },

  exportToExcel(params0?: mpg.api.workingLogs.GetParams): ApiResponse<Blob> {
    return requestTo('working-logs', params0, 'export', prepareGetAllParams);
  },
  exportToEmail(params0?: { format: string; sort?: string; workingHoursFloat?: number; workingHours?: number }) {
    return requestTo('working-logs', params0, 'export-by-link', prepareGetAllParams);
  },
  billingsExportToExcel(params?: mpg.api.workingLogs.GetParams): ApiResponse<Blob> {
    return requestTo('working-logs/report', params, 'export', prepareGetAllReportParams);
  },
  billingsExportToEmail(params?: mpg.api.workingLogs.GetParams): ApiResponse<Blob> {
    return requestTo('working-logs/report', params, 'export-by-link', prepareGetAllReportParams);
  },
  billingsDetailsExportToExcel(params?: mpg.api.workingLogs.GetParams): ApiResponse<Blob> {
    return requestTo('working-logs/report/byKey', params, 'export', prepareGetReportParams);
  },
  billingsDetailsExportToEmail(params?: mpg.api.workingLogs.GetParams): ApiResponse<Blob> {
    return requestTo('working-logs/report/byKey', params, 'export-by-link', prepareGetReportParams);
  },
};



