import { makeAutoObservable, observable } from 'mobx';
import { api } from '@/api';
import { TimesheetPageState } from '@/pages/timesheet/enums';
import { getDayTimesheetColumn } from '@/pages/timesheet/components/day-timesheet/setup';

export class DayTimesheetStore {
  @observable list: mpg.api.dayTimesheet.DayTimesheet[];

  @observable loading: boolean;

  @observable columns: any[] = getDayTimesheetColumn();

  constructor() {
    makeAutoObservable(this);
  }

  loadTimesheetList = async (weekTimesheetId) => {
    this.loading = true;
    const { source } = await api.dayTimesheet.getAll({ weekTimesheetId, limit: 10, sort: 'timesheetDate:1' });
    const {
      data: { items = [] },
    } = await source;
    this.list = items.map((item) => ({
      ...item,
      canEdit: !item.isApproved,
    }));
    this.loading = false;
  };

  onDoubleClick = (record, transition) => {
    transition.router.stateService.go(TimesheetPageState.DayTimesheetDetails, { recordId: record.id });
  };
}
