import moment from 'moment';

import { buildStoreOptions } from '@/utils/common';
import {
  agencyStore,
  areaStore,
  colorStore,
  shiftStore,
  supervisorStore,
} from '@/stores';
import { dateToAfterDateFrom } from '@/utils/form-rules';

interface Props {
  initValues: Partial<Omit<mpg.api.ptos.PTO, 'from' | 'to'>
  & {
    from: moment.Moment,
    to: moment.Moment
  }>;
  isBulkEdit?: boolean;
  isCreate?: boolean;
  disabledDatesTo?: (currentDate) => boolean;
}

const disabledDatesFrom = (current) => current && current < moment(Date.now()).startOf('day');

export const getFormFields = ({
  isBulkEdit = false,
  disabledDatesTo,
}: Props) => [
  {
    name: 'employeeId',
    componentName: 'input',
    conditionalRender: () => !isBulkEdit,
    params: {
      i18nLabel: 'ptos.employeeId',
      disabled: true,
      disabledWithRules: true,
    },
    rules: [
      { required: true },
    ],
  },
  {
    name: 'firstName',
    componentName: 'input',
    conditionalRender: () => !isBulkEdit,
    params: {
      i18nLabel: 'ptos.firstName',
      disabled: true,
      disabledWithRules: true,
    },
    rules: [
      { required: true },
    ],
  },
  {
    name: 'lastName',
    componentName: 'input',
    conditionalRender: () => !isBulkEdit,
    params: {
      i18nLabel: 'ptos.lastName',
      disabled: true,
      disabledWithRules: true,
    },
    rules: [
      { required: true },
    ],
  },
  {
    name: 'from',
    componentName: 'datepicker',
    params: {
      i18nLabel: 'ptos.from',
      format: 'MM/DD/YYYY',
      dependencies: ['to'],
      disabledDate: disabledDatesFrom,
    },
    rules: [
      dateToAfterDateFrom,
      { required: true },
    ],
  },
  {
    name: 'to',
    componentName: 'datepicker',
    params: {
      i18nLabel: 'ptos.to',
      format: 'MM/DD/YYYY',
      dependencies: ['from'],
      disabledDate: disabledDatesTo,
    },
    rules: [
      { required: true },
    ],
  },
  {
    name: 'approval',
    componentName: 'input',
    params: {
      i18nLabel: 'ptos.approval',
    },
    rules: [
      { required: true },
    ],
  },
  {
    name: 'shiftId',
    componentName: 'dropdown',
    conditionalRender: () => !isBulkEdit,
    params: {
      i18nLabel: 'ptos.shift',
      options: buildStoreOptions(shiftStore, 'name'),
      disabled: true,
      disabledWithRules: true,
    },
    rules: [
      { required: true },
    ],
  },
  {
    name: 'colorId',
    componentName: 'dropdown',
    conditionalRender: () => !isBulkEdit,
    params: {
      i18nLabel: 'ptos.colorId',
      options: buildStoreOptions(colorStore, 'name'),
      disabled: true,
      disabledWithRules: true,
    },
    rules: [
      { required: true },
    ],
  },
  {
    name: 'areaId',
    componentName: 'dropdown',
    conditionalRender: () => !isBulkEdit,
    params: {
      i18nLabel: 'ptos.areaId',
      options: buildStoreOptions(areaStore, 'name'),
      disabled: true,
      disabledWithRules: true,
    },
    rules: [
      { required: true },
    ],
  },
  {
    name: 'supervisorId',
    componentName: 'dropdown',
    conditionalRender: () => !isBulkEdit,
    params: {
      i18nLabel: 'ptos.supervisorId',
      options: buildStoreOptions(supervisorStore, 'name'),
      disabled: true,
      disabledWithRules: true,
    },
    rules: [
      { required: true },
    ],
  },
  {
    name: 'agencyId',
    componentName: 'dropdown',
    conditionalRender: () => !isBulkEdit,
    params: {
      i18nLabel: 'ptos.agencyId',
      options: buildStoreOptions(agencyStore, 'name'),
      disabled: true,
      disabledWithRules: true,
    },
    rules: [
      { required: true },
    ],
  },
];
