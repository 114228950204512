import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { api } from '@/api';
import {
  employeeStore,
} from '@/stores';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValueForBulkUpdate } from '@/utils/controller';
import { DrawerForm } from '@/components/drawer-form';
import { SIDEBAR_WIDTH } from '@/constants';
import { getFormFields } from '../employee-markup/setup';

const store = employeeStore;

interface IProps extends UIViewInjectedProps {
  entity: string
}

export const BulkRateForm = observer(({ transition, entity }: IProps) => {
  const { selectedRowKeys = [] } = transition.targetState().params();

  const selectedItems = store.items.filter(({ id }) => selectedRowKeys.includes(id));


  const onClose = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };

  const initValues = { photoUrl: [], status: (selectedItems[0] || {}).status };

  const title = getTranslatedString('employees.bulk-add-rate');

  const formFields = getFormFields();

  const resourceController = withoutRepeatValueForBulkUpdate(initValues, {
    create: (values: any) => {
      const items = selectedRowKeys.map((employeeId: string) => ({ ...values, employeeId }));
      return api.employeeMarkups.bulkCreateMarkups({ items }).source.then(() => store.refresh());
    },
  });
  useEffect(() => {
    if (!selectedRowKeys.length) {
      onClose();
    }
  }, []);
  return (
    <DrawerForm
      onClose={onClose}
      title={title}
      initValues={initValues}
      formFields={formFields}
      resourceController={resourceController}
      loaderCondition={false}
      width={(window.innerWidth - SIDEBAR_WIDTH) / 2}
    />
  );
});
