import { ApiResponse, WrappedResult } from '@/api/types';
import { request } from '@/utils/request';

export default {
  get(): ApiResponse<WrappedResult<mpg.api.agreements.Agreement>> {
    return request({
      method: 'get',
      url: `/agreements/recent`,
    });
  },
  signAgreement(id: string): ApiResponse<WrappedResult<Blob>> {
    return request({
      method: 'post',
      url: `/agreements/${id}/sign`,
    });
  },
  getPdf(id: string): ApiResponse<WrappedResult<Blob>> {
    return request({
      method: 'get',
      url: `/agreements/${id}`,
      config: { responseType: 'blob' },
    });
  },
};
