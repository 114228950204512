import { FormattedMessage } from 'react-intl';

import React from 'react';
import { Button, Space, Tooltip } from '@/components/antd';
import { PERMISSION_READ } from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { getTranslatedString } from '@/utils';
import { EyeOutlined, MinusOutlined } from '@/components/icons';
import { DateComponent } from '@/components/form-date';
import { minutesToStringHours } from '@/utils/time';
import { getDateWithoutTime, getFormattedDate } from '@/utils/moment';
import { en } from '@/i18n/en';
import { BooleanValueComponent } from '@/components/table/renders';

const auditActionsMap = {
  C: 'Create',
  R: 'Read',
  U: 'Update',
  D: 'Delete',
};

const Action = (_: any, { action }: any) => <Space>{auditActionsMap[action] || ''}</Space>;

export const getColumns = (permissionObject: string, onViewClick: (auditRecordId: number) => void) => {
  const Actions = withPermissions([[permissionObject, PERMISSION_READ]])((record: { id: number }) => (
    <Space>
      <Tooltip title={getTranslatedString('table.view')}>
        <Button type="primary" shape="circle" icon={<EyeOutlined />} onClick={() => onViewClick(record.id)} />
      </Tooltip>
    </Space>
  ));

  const Difference = (_: any, { newData: recordNewData, originalData: recordOriginalData }: any) => {
    const newData = recordNewData || {};
    const originalData = recordOriginalData || {};

    const keys = Object.keys({ ...newData, ...originalData });

    const changedKeys = keys.filter((key) => newData[key] !== originalData[key]).sort();

    return (
      <Space
        style={{
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {keys.length === changedKeys.length ? 'All' : changedKeys.join(', ')}
      </Space>
    );
  };

  const User = ({ name }: any) => <Space>{name || 'deleted'}</Space>;

  return [
    {
      title: <FormattedMessage id="audit.user" />,
      dataIndex: 'user',
      render: User,
    },
    {
      title: <FormattedMessage id="audit.action" />,
      dataIndex: 'action',
      render: Action,
    },
    {
      title: <FormattedMessage id="audit.createdAt" />,
      dataIndex: 'createdAt',
      render: DateComponent,
    },
    {
      title: <FormattedMessage id="audit.changedFields" />,
      dataIndex: 'changedFields',
      render: Difference,
      minWidth: 350,
    },
    {
      title: <FormattedMessage id="table.actions" />,
      conditionalRender: false,
      render: Actions,
      minWidth: 80,
    },
  ].map((column) => ({
    ...column,
    sorter: false,
    filters: null,
    filterDropdown: null,
  }));
};
const RenderAuditValue = ({ value, rowKey }) => {
  const billableFields = [
    'workingBillableTime',
    'workingBillableCorrectedTime',
    'transitPaidTime',
    'transitPaidCorrectedTime',
    'notBillableTime',
    'lunchUnpaidTime',
    'lunchUnpaidCorrectedTime',
    'lunchPaidTime',
    'lunchPaidCorrectedTime',
    'brakePaidCorrectedTime',
    'brakePaidTime',
    'bathPaidTime',
    'bathPaidCorrectedTime',
    'lunchUnpaidCorrectedTime',
  ];
  const date = ['updatedAt', 'createdAt', 'firstCheckIn', 'statusUpdatedOn', 'correctedAt'];
  const dateWithoutTime = ['startDate', 'birthday', 'effectiveDate', 'firstWorkingLogDate'];
  const numberDateFormat = ['timesheetDate', 'weekOf'];
  const booleanFields = [
    'isMusterStationCaptain',
    'term',
    'trainedSlitter',
    'hasEmployeeUser',
    'designatedAccessOnly',
    'trainedOverwrap',
    'trainedSlitter',
  ];
  function convertDateFormat(dateString) {
    const match = dateString.match(/(\d{4})(0[1-9]|1[012])([012][0-9]|3[01])/);
    const [, year, month, day] = match;
    return `${month}/${day}/${year}`;
  }
  if (value === null) {
    return <MinusOutlined />;
  }
  if (numberDateFormat.includes(rowKey)) {
    const formattedDate = convertDateFormat(value.toString())
    return <>{formattedDate}</>;
  }
  if (billableFields.includes(rowKey)) {
    return <>{minutesToStringHours(value)}</>;
  }
  if (date.includes(rowKey)) {
    return <>{getFormattedDate(value)}</>;
  }
  if (dateWithoutTime.includes(rowKey)) {
    return <>{getDateWithoutTime(value)}</>;
  }
  if (booleanFields.includes(rowKey)) {
    return <BooleanValueComponent value={value} />;
  }
  return <>{value === undefined ? '' : String(value)}</>;
};

export const RenderAuditField = (value: any, props: any) => {
  const { originalData, newData, key } = props;
  const wasChanged = originalData !== newData;
  return (
    <Space
      style={{
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
        color: wasChanged ? 'red' : undefined,
      }}
    >
      <RenderAuditValue value={value} rowKey={key} />
    </Space>
  );
};

export const auditColumns = [
  {
    title: <FormattedMessage id="audit.property" />,
    dataIndex: 'key',
    render: (value) => {
      if (en[`timesheet.${value}`]) return <FormattedMessage id={`timesheet.${value}`} />;

      return <FormattedMessage id={`systemInfo.${value}`} />;
    },
  },
  {
    title: <FormattedMessage id="audit.originalData" />,
    dataIndex: 'originalData',
    render: RenderAuditField,
  },
  {
    title: <FormattedMessage id="audit.newData" />,
    dataIndex: 'newData',
    render: RenderAuditField,
  },
];
