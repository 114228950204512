import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { api } from '@/api';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { colorStore, calendarShiftPlanningStore, shiftPlanningTempsStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValueForBulkUpdate } from '@/utils/controller';
import { useMassUpdateCashedStores } from '@/utils/store';
import { PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY } from '@/constants/permissions';
import { DrawerForm } from '@/components/drawer-form';
import { SIDEBAR_WIDTH } from '@/constants';
import { Form as AntdForm } from '@/components/antd';
import { EmployeeType } from '@/types/enums';

import { getAssignColorsFormFields } from './setup';
import { useValueChanges } from './hooks';

const entity = 'coordinator-temps-shift-planning';

export const BulkAssignColorsForm = observer(({ transition }: UIViewInjectedProps) => {
  const { selectedRowKeys = [], selectedBuilding } = transition.targetState().params();
  const [formRef] = AntdForm.useForm();
  const currentView = transition.router.globals.params.view;

  const { loading } = useMassUpdateCashedStores([colorStore]);

  const onClose = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };

  const title = getTranslatedString('shiftPlanning.bulk-assign-colors');
  const initValues = {
    buildingId: selectedBuilding?.id,
  };

  const { onValuesChange } = useValueChanges({
    formRef,
    initialShiftsDisable: false,
    initValues,
  });

  const allowedFields = getAllowedFormFields(
    getAssignColorsFormFields(),
    false,
    PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY,
  );

  const resourceController = withoutRepeatValueForBulkUpdate(initValues, {
    create: (values: mpg.api.shiftPlanning.ShiftPlanningParams) => {
      const items = selectedRowKeys.map((id: string) => ({
        ...values,
        id,
        employeeType: EmployeeType.Temporary,
      }));

      return api.shiftPlanningTemps.bulkAssignColors({ items }).source.then(() => {
        if (currentView === 'calendar') {
          calendarShiftPlanningStore.refresh();
        } else {
          shiftPlanningTempsStore.refresh();
        }
      });
    },
  });
  useEffect(() => {
    if (!selectedRowKeys.length) {
      onClose();
    }
  }, []);

  return (
    <DrawerForm
      form={formRef}
      onClose={onClose}
      title={title}
      initValues={initValues}
      formFields={allowedFields}
      resourceController={resourceController}
      loaderCondition={loading}
      onValuesChange={onValuesChange}
      width={(window.innerWidth - SIDEBAR_WIDTH) / 2}
    />
  );
});
