import { useEffect, useRef, useState } from 'react';
import { api } from '@/api';
import { WrappedItemsResult } from '@/api/types';
import { SelectOption } from '@/types/common';

const useDebounce = (callback: (...args: string[]) => void, delay: number) => {
  const timeoutRef = useRef(null);
  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
    [],
  );

  const debouncedCallback = (...args: string[]) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  return debouncedCallback;
};

export const usePermEmployeesOptions = () => {
  const [permEmployeeOptions, setPermEmployeeOptions] = useState<SelectOption[]>([]);
  const request = (value: string) => {
    if (value.length >= 3) {
      api.search
        .get({ q: value, entity: 'permanent_employees' })
        .source.then(({ data }: WrappedItemsResult<mpg.api.search.ResponseItem[]>) => {
          setPermEmployeeOptions(
            data.items.map((permEmployee) => ({
              label: `${permEmployee.name} ${permEmployee.lastName}`,
              value: permEmployee.id,
            })),
          );
        });
    }
  };
  const debouncedOptionsFetch = useDebounce(request, 500);
  return { permEmployeeOptions, debouncedOptionsFetch };
};
