import { getFormattedParams, request } from '@/utils/request';
import { ApiResponse, WrappedAxiosItemsResult } from './types';

const buildingConfigApi = {
  getAll(params?: mpg.api.buildingConfig.GetParams): ApiResponse<WrappedAxiosItemsResult<mpg.api.buildingConfig.BuildingConfig[]>> {
    const formattedParams = getFormattedParams(params, true);

    return request({
      method: 'get',
      url: '/building-configs',
      params: formattedParams,
    });
  },
};

export default buildingConfigApi;
