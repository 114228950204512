import { FormattedMessage } from 'react-intl';

import { Space } from '@/components/antd';
import { ReadAction } from '@/components/table/actions';
import { PERMISSION_READ } from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { DateComponent } from '@/components/form-date';


export const getAuditColumns = (permissionObject: string, viewPath: string) => {
  const actions = {
    C: 'Create',
    R: 'Read',
    U: 'Update',
    D: 'Delete',
  };
  const PermissionedReadAction = withPermissions([[permissionObject, PERMISSION_READ]])(ReadAction);

  const Action = (_: any, { action }: any) => <Space>{actions[action] || ''}</Space>;

  const Actions = (_: any, { id }: any) => (
    <Space>
      <PermissionedReadAction uiSref={{ to: viewPath, params: { recordId: id } }} />
    </Space>
  );

  const Difference = (_: any, { newData: recordNewData, originalData: recordOriginalData }: any) => {
    const newData = recordNewData || {};
    const originalData = recordOriginalData || {};

    const keys = Object.keys({ ...newData, ...originalData });

    const changedKeys = keys.filter((key) => newData[key] !== originalData[key]).sort();

    return (
      <Space
        style={{
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {keys.length === changedKeys.length ? 'All' : changedKeys.join(', ')}
      </Space>
    );
  };

  const User = ({ name }: any) => <Space>{name || 'deleted'}</Space>;

  return [
    {
      title: <FormattedMessage id="audit.user" />,
      dataIndex: 'user',
      render: User,
    },
    {
      title: <FormattedMessage id="audit.action" />,
      dataIndex: 'action',
      render: Action,
    },
    {
      title: <FormattedMessage id="audit.createdAt" />,
      dataIndex: 'createdAt',
      render: DateComponent,
    },
    {
      title: <FormattedMessage id="audit.changedFields" />,
      dataIndex: 'changedFields',
      render: Difference,
      minWidth: 350,
    },
    {
      title: <FormattedMessage id="table.actions" />,
      render: Actions,
      minWidth: 80,
    },
  ];
};


export const getAdditionalColumns = ({permission, entity}) =>
  getAuditColumns(permission, `base-layout.${entity}.edit.audit`);

export const getEmployeeMarkupsAuditColumns = ({permission, entity}) =>
  getAuditColumns(permission, `base-layout.${entity}.edit.markup-audit`);
