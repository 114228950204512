import { GenericFormResourceController } from '@/components/generic-form';
import { getValuesDifference, getValues } from '@/utils/common';

export const withoutRepeatValue = (
  initValues: any,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { create, update = (values: any) => Promise.resolve(values) },
): GenericFormResourceController => ({
  create: (values: any) => create(getValues(values)),
  update: (values: any) => {
    const { changedItem } = getValuesDifference(initValues, values);
    return update(changedItem);
  },
});

export const withoutRepeatValueForBulkUpdate = (
  initValues: any,
  { create },
): GenericFormResourceController => ({
  create: (values: any) => {
    const { changedItem } = getValuesDifference(initValues, values);
    return create(changedItem);
  },
});

export const withoutRepeatValueOnlyForUpdate = (
  initValues: any,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  { update = (values: any) => Promise.resolve() },
): GenericFormResourceController => ({
  update: (values: any) => update(getValues(values)),
});
