import { observer } from 'mobx-react-lite';

import { useRef } from 'react';
import {
  Button, Col, Switch, InputNumber, Row,
} from '@/components/antd';
import { withStores } from '@/components/stores-provider';
import { settingsStore } from '@/stores';
import { getTranslatedString } from '@/utils';

export const UserSettings = withStores('settingsStore')(
  observer((
    {
      settingsStore: {
        itemsPerPage,
        vacationStatus,
      },
    },
  ) => {
    const itemsPerPageInput = useRef(null);
    const onClick = () => {
      settingsStore.changeItemsPerPage(itemsPerPageInput.current.value);
    };
    const onToggle = (value: boolean) => {
      settingsStore.changeVacationStatus(value);
    };

    return (
      <>
        <Row align="middle" className="profile-row">
          <Col flex="0 0 100px">
            {getTranslatedString('user-settings.vacation-status')}
            :
          </Col>
          <Col flex="0 0 190px">
            <Switch onChange={(value) => onToggle(value)} defaultChecked={vacationStatus} className="user-settings-toggle" />
          </Col>
        </Row>
        <Row align="middle" className="profile-row">
          <Col flex="0 0 100px">
            {getTranslatedString('user-settings.items-per-page')}
            :
          </Col>
          <Col flex="0 0 190px">
            <InputNumber ref={itemsPerPageInput} defaultValue={itemsPerPage} name="itemsPerPage" className="user-settings-input" min={1} />
          </Col>
          <Col flex="0 0 90px">
            <Button onClick={onClick} type="primary">Save</Button>
          </Col>
        </Row>
        {/* <Row align="middle"> */}
        {/* <Col flex="0 0 100px">{getTranslatedString('user-settings.dump-data')}:</Col> */}
        {/* <Col flex="0 0 90px"> */}
        {/* <Button onClick={onDownloadClick} type="primary">Download</Button> */}
        {/* </Col> */}
        {/* </Row> */}
      </>
    );
  }),
);
