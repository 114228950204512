import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getColumnSearchFields } from '@/components/table/filters';
import { Space } from '@/components/antd';
import { PERMISSION_BUILDING_HOST, PERMISSION_DELETE, PERMISSION_UPDATE } from '@/constants/permissions';
import { DeleteAction, EditAction } from '@/components/table/actions';
import { withPermissions } from '@/hocs/permission';
import { BuildingPageState } from '@/pages/building-host/enums';
import { api } from '@/api';
import { buildingHostStore } from '@/stores';
import { showError } from '@/utils/common';

const PermissionedEditAction = withPermissions([[PERMISSION_BUILDING_HOST, PERMISSION_UPDATE]])(EditAction);
const PermissionedDeleteAction = withPermissions([[PERMISSION_BUILDING_HOST, PERMISSION_DELETE]])(DeleteAction);

const Actions = (_: any, { id }: any) => {
  const onDelete = () => {
    api.buildingHost
      .deleteBuildingHost(id)
      .source.then(() => buildingHostStore.refresh())
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedEditAction uiSref={{ to: BuildingPageState.Update, params: { buildingHostId: id } }} />
      <PermissionedDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

export const getColumns = () => [
  {
    title: <FormattedMessage id="building-hosts.firstName" />,
    dataIndex: 'firstName',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="building-hosts.lastName" />,
    dataIndex: 'lastName',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="building-hosts.buildings" />,
    dataIndex: 'buildings',
    render: (buildings: mpg.api.buildingHost.Buildings[]) => buildings.map((building) => <div>{building.name}</div>),
    minWidth: 180,
  },
  {
    title: <FormattedMessage id="building-hosts.actions" />,
    dataIndex: 'actions',
    render: Actions,
    minWidth: 180,
  },
];
