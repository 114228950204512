export const es = {
  // performance report
  'employee-performance.range': 'Data Range',
  'employee-performance.datepicker-label': 'Choose Week of:',
  'employee-performance.worked-hours': 'Hours Worked',
  'employee-performance.total-billable-time': 'Total Hours onsite:',
  'employee-performance.billable-hours': 'Billable Hours',
  'employee-performance.base-billable-time': 'Base Billable Hours:',
  'employee-performance.overtime-hours': 'Overtime Hours:',
  'employee-performance.discipline': 'Discipline',
  'employee-performance.approval-status': 'Approval status:',
  'employee-performance.missed-shifts': 'Missed Shifts:',
  'employee-performance.under-works-shifts': 'Under Work Shifts:',
  'employee-performance.approval-true': 'Approved',
  'employee-performance.approval-false': 'Not Approved',
  'employee-performance.print': 'Print',
  'employee-performance.show-details': 'Show Detailed Records',
  'employee-performance.detailed-weekly-report.to-weekly-report': 'Back To The Main Report',
  'employee-performance.date': 'Date',
  'employee-performance.shiftAssigned': 'Shift',
  'employee-performance.building': 'Building',
  'employee-performance.grossTime': 'Gross time',
  'employee-performance.netTime': 'Net time',
  'employee-performance.totalAdditionTime': 'Additional time',
  'employee-performance.totalBillableTime': 'esTotal billable time',
  'employee-performance.lateArrival': 'Late for the shift',
  'employee-performance.earlyDeparture': 'Early departure',
  'employee-performance.notShowedUp': 'Planned but did not show up',
  'employee-performance.send-email': 'Send to my email',
  'employee-performance.send-email-success': 'Email successfully sent',
  'employee-performance.send-email-failed-error': 'Failed to send email',
  'employee-performance.send-email-again-error': 'Email already sent',
  'employee-performance.match-error': 'ID incorrecta',
  'employee-performance.scanner-error': 'El escáner no está disponible',
  'employee-performance.unexpected-error': 'Error inesperado',
  'employee-performance.invalid-barcode': 'Código de barra incorrecto',
  'employee-performance.invalid-pin': 'ID incorrecta',
  'employee-performance.pin-label': 'Ingrese su ID',
  'employee-performance.back-button': 'Regresar',
  'employee-performance.login-button': 'Log in',
  'employee-performance.scan-text': 'Escanee el código de barra',
  'employee-performance.printing-text': 'Su informe se está imprimiendo, espere.',
  'employee-performance.printing-error': 'Error de impresión',
};
