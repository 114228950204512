import React, { useEffect } from 'react';
import { UIViewInjectedProps } from '@uirouter/react';
import {
  PERMISSION_OBJ_AGENCY_STAFFING,
  PERMISSION_OBJ_COORDINATOR_STAFFING,
  PERMISSION_READ,
} from '@/constants/permissions';
import { authStore } from '@/stores';

export const Home = ({ transition }: UIViewInjectedProps) => {
  useEffect(() => {
    if (authStore.hasPermissions([[PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ]])) {
      transition.router.stateService.go('base-layout.coordinator-employees');
    } else if (authStore.hasPermissions([[PERMISSION_OBJ_AGENCY_STAFFING, PERMISSION_READ]])) {
      transition.router.stateService.go('base-layout.agency-employees');
    }
  }, [authStore.permissions]);

  return (
    <>
      Authorized user home page
    </>
  );
};
