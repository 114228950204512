import { useEffect, useState } from 'react';

import { FormInstance } from '@/components/antd';
import { shiftStore } from '@/stores';

interface UseValueChangesProps {
  formRef: FormInstance;
  initialShiftsDisable: boolean;
  initValues: Record<string, any>;
  initialDeps?: Array<any>;
}

export const useValueChanges = (
  {
    formRef,
    initialShiftsDisable,
    initValues,
    initialDeps = [],
  }: UseValueChangesProps,
) => {
  const [disabledShifts, setDisabledShifts] = useState<boolean>(initialShiftsDisable);
  const [shiftsOptions, setShiftsOptions] = useState<Array<mpg.api.shifts.Shift>>([]);

  useEffect(() => {
    const initialShiftsOptions = () => {
      if (initValues && initValues.hasOwnProperty('buildingId')) {
        return setShiftsOptions(shiftStore.items.filter(({ buildingId }) => buildingId === initValues.buildingId));
      }

      return [];
    };

    initialShiftsOptions();
  }, initialDeps);

  const handleBuildingChange = (changedValues: Partial<mpg.api.shiftPlanning.ShiftPlanning>) => {
    if (!changedValues.hasOwnProperty('buildingId')) {
      return;
    }

    if (changedValues.buildingId) {
      setDisabledShifts(false);
      formRef.setFieldsValue({ shifts: [] });
      return setShiftsOptions(shiftStore.items.filter(({ buildingId }) => buildingId === changedValues.buildingId));
    }

    formRef.setFieldsValue({ shifts: [] });
    return setDisabledShifts(true);
  };

  const onValuesChange = (changedValues: Partial<mpg.api.shiftPlanning.ShiftPlanning>) => {
    handleBuildingChange(changedValues);
  };

  return {
    disabledShifts,
    shiftsOptions,
    onValuesChange,
  };
};
