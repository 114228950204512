import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  PERMISSION_OBJ_SHUTTLE_BUS_REPORT,
  PERMISSION_READ,
} from '@/constants/permissions';
import { getColumnDateFields, getColumnSearchFields } from '@/components/table/filters';
import { buildFilterOptionsFromEnum, buildStoreOptions, getStorePropById } from '@/utils/common';
import { EmployeeType } from '@/types/enums';
import { agencyStore, colorStore, shiftStore } from '@/stores';
import { getDateWithoutTime, getTime } from '@/utils/moment';

export const getColumns = ():any[] => {
  const columns : Array<Record<string, unknown> & {
    permissionSubject: string,
  }> = [
    {
      title: <FormattedMessage id="shuttleBusReport.externalId" />,
      dataIndex: 'externalId',
      sorter: true,
      permissionSubject: 'employeeDetails',
    },
    {
      title: <FormattedMessage id="shuttleBusReport.registrationDate" />,
      dataIndex: 'registrationDate',
      sorter: true,
      permissionSubject: 'raw',
      ...getColumnDateFields(),
      render: (item:string) => getDateWithoutTime(item),
    },
    {
      title: <FormattedMessage id="shuttleBusReport.registrationTime" />,
      dataIndex: 'registrationRawDate',
      sorter: true,
      permissionSubject: 'raw',
      render: (time:string) => getTime(time),
    },
    {
      title: <FormattedMessage id="shuttleBusReport.employeeFirstName" />,
      dataIndex: 'employeeFirstName',
      sorter: true,
      permissionSubject: 'employeeDetails',
      ...getColumnSearchFields(),
    },
    {
      title: <FormattedMessage id="shuttleBusReport.employeeMiddleName" />,
      dataIndex: 'employeeMiddleName',
      sorter: true,
      permissionSubject: 'employeeDetails',
      ...getColumnSearchFields(),
    },
    {
      title: <FormattedMessage id="shuttleBusReport.employeeLastName" />,
      dataIndex: 'employeeLastName',
      sorter: true,
      permissionSubject: 'employeeDetails',
      ...getColumnSearchFields(),
    },
    {
      title: <FormattedMessage id="shuttleBusReport.employeeType" />,
      dataIndex: 'employeeType',
      sorter: true,
      permissionSubject: 'employeeDetails',
      filters: buildFilterOptionsFromEnum(EmployeeType, 'shuttleBusReport.employeeType.'),
      render: (employeeType: string) => <FormattedMessage id={`shuttleBusReport.employeeType.${employeeType}`} />,
    },
    {
      title: <FormattedMessage id="shuttleBusReport.agencyId" />,
      dataIndex: 'agencyId',
      sorter: true,
      permissionSubject: 'agencyDetails',
      filters: buildStoreOptions(agencyStore, 'name'),
      render: (agencyId: string) => getStorePropById<mpg.api.agencies.Agency>(agencyId, agencyStore),
    },
    {
      title: <FormattedMessage id="shuttleBusReport.shiftId" />,
      dataIndex: 'shiftId',
      sorter: true,
      permissionSubject: 'shiftDetails',
      filters: buildStoreOptions(shiftStore, 'name'),
      render: (shiftId: string) => getStorePropById<mpg.api.shifts.Shift>(shiftId, shiftStore),
    },
    {
      title: <FormattedMessage id="shuttleBusReport.colorId" />,
      dataIndex: 'colorId',
      sorter: true,
      permissionSubject: 'generalDetails',
      filters: buildStoreOptions(colorStore, 'name'),
      render: (colorId: string) => getStorePropById<mpg.api.colors.Colors>(colorId, colorStore),
    },

  ];
  return columns.map(({ permissionSubject, ...props }) => ({
    ...props,
    permissions: [
      [PERMISSION_OBJ_SHUTTLE_BUS_REPORT, PERMISSION_READ],
      [`${PERMISSION_OBJ_SHUTTLE_BUS_REPORT}.${permissionSubject}`, PERMISSION_READ],
    ],
  }));
};
