import moment from 'moment/moment';
import { PickerMode } from 'rc-picker/lib/interface';
import { useMemo, useState } from 'react';

import { Ii18nLabel } from '@/types/common';
import {
  DatePicker as AntdDatePicker, Form, FormItemProps, InputNumber,
} from '@/components/antd';
import { getFormattedMinutes, getTimeFromMinutes } from '@/utils/moment';

import { getFormItemLabel } from './utils';

interface DatePickerProps extends FormItemProps, Ii18nLabel {
  showTime?: boolean;
  disabled?: boolean;
  picker: Exclude<PickerMode, 'date' | 'time'>;
  formState: any;
}

export const TimePicker = ({
  name, label, i18nLabel, rules, formState, ...props
}: DatePickerProps) => {
  const { form } = formState;
  const fieldLabel = getFormItemLabel(i18nLabel, label);
  const initialValue = useMemo(
    () => (form.getFieldValue(name) !== undefined
      ? moment(getTimeFromMinutes(form.getFieldValue(name)), 'hh:mm')
      : undefined),
    [form, name],
  );

  const [errors, setErrors] = useState<string[]>([]);

  const [date, setDate] = useState(initialValue);
  const getPopupContainer = (trigger) => trigger.parentElement;

  const onChange = async (value: any, dateString: string) => {
    setErrors([]);
    setDate(value);
    const minutes = getFormattedMinutes(dateString);

    if (!Array.isArray(name)) {
      form.setFieldsValue({ [name]: minutes });
    }

    try {
      await form.validateFields([name]);
    } catch (e) {
      setErrors(form.getFieldError(name));
    }
  };
  const validationStatus = errors.length > 0 ? 'error' : '';
  const helpLabel = errors[0];
  // @ts-ignore // "required" should be in Rule, wrong typing propagation...
  const required = Boolean(rules.find((rule) => rule.required === true));

  return (
    <>
      <Form.Item
        style={{ display: 'none' }}
        name={name}
        label={fieldLabel}
        rules={rules}
      >
        <InputNumber value={form.getFieldValue(name)} />
      </Form.Item>

      <Form.Item
        label={fieldLabel}
        rules={rules}
        required={required}
        validateStatus={validationStatus}
        help={helpLabel}
      >
        <AntdDatePicker
          value={date}
          onChange={onChange}
          getPopupContainer={getPopupContainer}
          picker="time"
          // @ts-ignore // "showNow" should be in DatePicker, wrong typing propagation...
          showNow={false}
          format="HH:mm"
          {...props}
        />
      </Form.Item>
    </>
  );
};
