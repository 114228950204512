import React from 'react';
import { UISref } from '@uirouter/react';
import { FormattedMessage } from 'react-intl';

import {
  EditOutlined,
  PlusOutlined,
  DeleteOutlined,
} from '@/components/icons';
import { Button, Menu } from '@/components/antd';
import {
  PERMISSION_OBJ_SHIFT_PLANNING_PERMANENT,
  PERMISSION_CREATE,
  PERMISSION_UPDATE,
  PERMISSION_DELETE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';

import { ImportButton, ParseSettings } from '@/components/import-button';
import { ApiResponse, WrappedItemsResult } from '@/api/types';

const entity = 'coordinator-perms-shift-planning';

interface CreateButtonProps {
  onClick: (e: any) => void;
  loading?: boolean;
  style?: any;
  disabled?: boolean;
}

export const CreateButton = withPermissions([
  [PERMISSION_OBJ_SHIFT_PLANNING_PERMANENT, PERMISSION_CREATE],
])(({ onClick, style = {}, disabled = false }: CreateButtonProps) => (
  <Button
    style={style}
    onClick={onClick}
    shape="round"
    type="primary"
    icon={<PlusOutlined />}
    disabled={disabled}
  >
    <span>
      <FormattedMessage id="shiftPlanning.add-new" />
    </span>
  </Button>
));

export const BulkUpdateButton = withPermissions([
  [PERMISSION_OBJ_SHIFT_PLANNING_PERMANENT, PERMISSION_UPDATE],
])(({ selectedRowKeys, selectedBuilding }: any) => (
  <Menu.Item key="update" icon={<EditOutlined />}>
    <UISref to={`base-layout.${entity}.bulk-edit`} params={{ selectedRowKeys, selectedBuilding }}>
      <a><FormattedMessage id="button.update" /></a>
    </UISref>
  </Menu.Item>
));

interface CsvUploadButtonProps {
  request: (parsedItems: mpg.api.ptos.PTOParams[]) => ApiResponse<WrappedItemsResult<mpg.api.ptos.PTO[]>>;
  storeRefreshRequest: () => ApiResponse<WrappedItemsResult<mpg.api.ptos.PTO[]>>;
  settings: ParseSettings;
}

export const CsvUploadButton = withPermissions([
  [PERMISSION_OBJ_SHIFT_PLANNING_PERMANENT, PERMISSION_UPDATE],
])(
  ({ request, settings, storeRefreshRequest }: CsvUploadButtonProps) => (
    <ImportButton request={request} parseSettings={settings} storeRefreshRequest={storeRefreshRequest} />
  ),
);

export const BulkDeleteButton = withPermissions([
  [PERMISSION_OBJ_SHIFT_PLANNING_PERMANENT, PERMISSION_DELETE],
])(({ onClick }: any) => (
  <Menu.Item key="remove" icon={<DeleteOutlined />} onClick={onClick}>
    <FormattedMessage id="button.remove" />
  </Menu.Item>
));
