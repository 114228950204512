export const PERMISSION_READ = 1;
export const PERMISSION_CREATE = 2;
export const PERMISSION_UPDATE = 4;
export const PERMISSION_DELETE = 8;

export const PERMISSION_OBJ_ADDRESS = 'address';
export const PERMISSION_OBJ_LOT = 'lot';
export const PERMISSION_OBJ_PRODUCT = 'product';
export const PERMISSION_OBJ_PERMISSION = 'permission';
export const PERMISSION_OBJ_PERMISSION_OBJECT = 'permission_object';
export const PERMISSION_OBJ_ROLE = 'role';
export const PERMISSION_OBJ_USER = 'user';
export const PERMISSION_OBJ_EMPLOYEE = 'employee';
export const PERMISSION_OBJ_WORKING_LOG = 'working_log';
export const PERMISSION_OBJ_SHIFT_REPORT_TEMPORARY = 'shift_report_temporary';
export const PERMISSION_OBJ_SHIFT_REPORT_PERMANENT = 'shift_report_permanent';
export const PERMISSION_OBJ_WORKING_LOG_TEMP_SHIFT = 'working_log_temp_shift';
export const PERMISSION_OBJ_WORKING_LOG_PERM_SHIFT = 'working_log_perm_shift';
export const PERMISSION_OBJ_WORKING_LOG_PERM_STANDARD = 'working_log_perm_standard';
export const PERMISSION_OBJ_SHIFT_REPORTS = 'shift_reports';
export const PERMISSION_OBJ_SHUTTLE_BUS_REPORT = 'shuttleBusReport';
export const PERMISSION_BUILDING_HOST = "buildingHost"

export const PERMISSION_OBJ_PERMANENT_EMPLOYEE = 'permanentEmployee';
export const PERMISSION_OBJ_PERMANENT_EMPLOYEE_STANDARD_HOURS = 'permanentEmployee.occupation';
export const PERMISSION_OBJ_PERMANENT_EMPLOYEE_SHIFT_WORK = 'permanentEmployee.musterStationId';
export const PERMISSION_OBJ_PTO = 'ptoCalendar';
export const PERMISSION_OBJ_LOCKER = 'locker';
export const PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY = 'event_temporary';
export const PERMISSION_OBJ_SHIFT_PLANNING_PERMANENT = 'event_permanent';
export const PERMISSION_OBJ_EMPLOYEE_MARKUP = 'employeeMarkup';
export const PERMISSION_OBJ_TIMESHEET = 'timesheet';
export const TIMESHEET_PERMISSION_APPROVE_ALL = 'timesheetAction.approveAll';
export const TIMESHEET_PERMISSION_APPROVE_OWN = 'timesheetAction.approveOwn';
export const TIMESHEET_PERMISSION_UNAPPROVE_ALL = 'timesheetAction.unapproveAll';
export const PERMISSION_OBJ_EMPLOYEE_USER = 'employeeUser';

export const PERMISSION_OBJ_COORDINATOR_STAFFING = 'ui.mpg.staffing';
export const PERMISSION_OBJ_COORDINATOR_BILLING = 'ui.mpg.billing';
export const PERMISSION_OBJ_COORDINATOR_REPORTING = 'ui.mpg.workLogReports';
export const PERMISSION_OBJ_COORDINATOR_SHIFT_REPORTING = 'ui.mpg.shiftReports';
export const PERMISSION_OBJ_COORDINATOR_PERMANENT_EMPLOYEE_STANDARD_HOURS = 'ui.mpg.permanentEmployeeStandardHours';
export const PERMISSION_OBJ_COORDINATOR_PERMANENT_EMPLOYEE_SHIFT_WORK = 'ui.mpg.permanentEmployeeShiftWork';
export const PERMISSION_OBJ_COORDINATOR_PTO = 'ui.mpg.ptoCalendar';
export const PERMISSION_OBJ_COORDINATOR_LOCKER = 'ui.mpg.locker';
export const PERMISSION_OBJ_AGENCY_STAFFING = 'ui.agency.staffing';
export const PERMISSION_OBJ_AGENCY_BILLING = 'ui.agency.billing';
export const PERMISSION_OBJ_AGENCY_REPORTING = 'ui.agency.workLogReports';
export const PERMISSION_OBJ_AGENCY_SHIFT_REPORTING = 'ui.agency.shiftReports';
export const PERMISSION_OBJ_AGENCY_PTO = 'ui.agency.ptoCalendar';
