import { PERMISSION_OBJ_TIMESHEET } from '@/constants/permissions';
import { dayTimesheetAuditStore } from '@/stores';
import { AuditTableView } from '@/pages/common/audit-form';

export const DayTimesheetAuditView = ({ recordId }: { recordId: string }) => (
  <AuditTableView
    recordId={recordId}
    permissionObject={PERMISSION_OBJ_TIMESHEET}
    auditStore={dayTimesheetAuditStore}
  />
);
