import React from 'react';

import { getFullDateWithTimeZone } from '@/utils/date';
import { getTranslatedString } from '@/utils';
import {
  ClockCircleOutlined,
  BankOutlined,
  CalendarOutlined,
  UserOutlined,
  BgColorsOutlined,
  FieldTimeOutlined,
  UserSwitchOutlined,
} from '@/components/icons';
import { PulsatingCircle } from '@/components/pulsating-circle';
import { ShiftPlanningStatus, ShiftPlanningStatusColor } from '@/types/enums';

interface EventTooltipProps {
  shiftPlanning: mpg.api.shiftPlanning.ShiftPlanning;
  selectedBuilding: mpg.api.buildings.Building;
}

export const EventTooltip = ({ selectedBuilding, shiftPlanning }: EventTooltipProps) => {
  const {
    eventStart,
    eventEnd,
    title,
    description,
    colors,
    building,
    shifts,
    employeeType,
    workSchedule,
    additionalTime,
    status,
  } = shiftPlanning;

  const eventStartWithTimeZone = getFullDateWithTimeZone(eventStart, selectedBuilding?.timezone);
  const eventEndWithTimeZone = getFullDateWithTimeZone(eventEnd, selectedBuilding?.timezone);
  const shiftPlanningColors = {
    [ShiftPlanningStatus.Finished]: ShiftPlanningStatusColor.Finished,
    [ShiftPlanningStatus.Running]: ShiftPlanningStatusColor.Running,
    [ShiftPlanningStatus.Upcoming]: ShiftPlanningStatusColor.Upcoming,
  };

  return (
    <div className="m-shift-planning__tooltip">
      <h3 className="m-shift-planning__tooltip-title">
        <span className="m-shift-planning__tooltip-status">
          <PulsatingCircle
            color={shiftPlanningColors[status]}
            style={{ transform: 'translateX(-74%) translateY(-42%)' }}
            pulsing={status === ShiftPlanningStatus.Running}
          />
          <span>&ensp;</span>
          <span>&ensp;</span>
        </span>
        <span>{title}</span>
      </h3>
      <div className="m-shift-planning__tooltip-description">{description}</div>
      <div className="m-shift-planning__tooltip-items">
        <div className="m-shift-planning__tooltip-item">
          <span><CalendarOutlined /></span>
          <span>
            {eventStartWithTimeZone}
            {' '}
            -
            {eventEndWithTimeZone}
          </span>
        </div>
        <div className="m-shift-planning__tooltip-item">
          <span><BankOutlined /></span>
          <span>{building.name}</span>
        </div>
        <div className="m-shift-planning__tooltip-item">
          <span><ClockCircleOutlined /></span>
          <span>{shifts.map(({ name }) => name).join(', ')}</span>
        </div>
        <div className="m-shift-planning__tooltip-item">
          <span><UserOutlined /></span>
          <span>{getTranslatedString(`shiftPlanning.employeeType.${employeeType}`)}</span>
        </div>
        <div className="m-shift-planning__tooltip-item">
          <span><UserSwitchOutlined /></span>
          <span>{getTranslatedString(`shiftPlanning.workSchedule.temporary.${workSchedule}`)}</span>
        </div>
        <div className="m-shift-planning__tooltip-item">
          <span><FieldTimeOutlined /></span>
          <span>
            {additionalTime}
            {' '}
            hours
          </span>
        </div>
        <div className="m-shift-planning__tooltip-item">
          <span><BgColorsOutlined /></span>
          <span>{colors.map(({ name }) => name).join(', ')}</span>
        </div>
      </div>
    </div>
  );
};
