import { requestTo } from '@/utils/request';
import { ApiResponse, WrappedResult } from './types';

const prepareGetAllParams = (params?: mpg.api.busShuttleReports.GetParams) => (params);

export default {
  getAll(params?: mpg.api.busShuttleReports.GetParams): ApiResponse<WrappedResult<mpg.api.busShuttleReports.BusShuttleReport[]>> {
    return requestTo('shuttle-bus-report', params, '', prepareGetAllParams);
  },
  exportToExcel(params?: mpg.api.busShuttleReports.GetParams): ApiResponse<WrappedResult<Blob>> {
    return requestTo('shuttle-bus-report', params, 'export', prepareGetAllParams);
  },
  exportToEmail(params?: mpg.api.busShuttleReports.GetParams): ApiResponse<WrappedResult<Blob>> {
    return requestTo('shuttle-bus-report', params, 'export-by-link', prepareGetAllParams);
  },
};
