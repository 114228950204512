import { PickerMode } from 'rc-picker/lib/interface';
import React from 'react';
import { DatePicker as AntdDatePicker, Form, FormItemProps } from '@/components/antd';

import { Ii18nLabel } from '@/types/common';
import { getFormItemLabel } from './utils';

interface DatePickerProps extends FormItemProps, Ii18nLabel {
  showTime?: boolean;
  disabled?: boolean;
  picker: Exclude<PickerMode, 'date' | 'time'>;
  formState: any;
}

export const DatePicker = (
  {
    name,
    label,
    i18nLabel,
    rules,
    formState,
    ...props
  }: DatePickerProps,
) => {
  const fieldLabel = getFormItemLabel(i18nLabel, label);
  const getPopupContainer = (trigger) => trigger.parentElement;

  return (
    <Form.Item name={name} label={fieldLabel} rules={rules}>
      <AntdDatePicker getPopupContainer={getPopupContainer} {...props} />
    </Form.Item>
  );
};
