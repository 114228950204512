import { breaktimeStore, buildingStore, languageStore } from '@/stores';

import { findItemIdByLabel } from '@/utils/common';
import { FieldDescription, ParseSettings } from '@/components/import-button';
import { EmployeeType, WorkScheduleType } from '@/types/enums';

export const fields: FieldDescription[] = [
  {
    name: 'employeeId',
  },
  {
    name: 'employeeType',
    svgMap: () => EmployeeType.Permanent,
  },
  {
    name: 'workSchedule',
    svgMap: () => WorkScheduleType.StandardHours,
  },
  {
    name: 'status',
  },
  {
    name: 'firstName',
  },
  {
    name: 'middleName',
  },
  {
    name: 'lastName',
  },
  {
    name: 'email',
  },
  {
    name: 'phone',
  },
  {
    name: 'company',
  },
  {
    name: 'occupation',
  },
  {
    name: 'startDate',
  },
  {
    name: 'buildingId',
    svgMap: (e: string) => findItemIdByLabel(buildingStore, 'name', e),
  },
  {
    name: 'birthday',
  },
  {
    name: 'terminateDescription',
  },
  {
    name: 'parkingTag',
  },
  {
    name: 'invitationLanguageId',
    svgMap: (e: string) => findItemIdByLabel(languageStore, 'name', e),
  },
  {
    name: 'breakTimes',
    svgMap: (e: string) => findItemIdByLabel(breaktimeStore, 'name', e),
  },
];

export const parseSettings: ParseSettings = fields
  .reduce((acc, {
    name, svgKey, svgMap, svgValidator,
  }: FieldDescription) => {
    acc[svgKey || name] = {
      key: name,
      map: svgMap,
      validator: svgValidator,
    };
    return acc;
  }, {});
