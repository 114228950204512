import { Rule } from 'antd/lib/form';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import { Space } from '@/components/antd';
import { ReadAction } from '@/components/table/actions';
import { PERMISSION_OBJ_PERMANENT_EMPLOYEE, PERMISSION_READ } from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import {buildDropdownOptionsFromEnum, buildStoreOptions, getOptionsFilteredByActive} from '@/utils/common';
import { areaStore, buildingStore, colorStore, languageStore, musterStationCaptainsStore, positionStore, supervisorStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { AttachmentType, Company, EmployeeStatus, EmployeeType, WorkScheduleType } from '@/types/enums';
import { buildingChanged, emailOrPhoneRequired, musterStationChanged, phone } from '@/utils/form-rules';
import { PermEmployeeStatus } from '../table/setup';
import { DateComponent } from '@/components/form-date';

interface Props {
  initValues: Partial<
    Omit<mpg.api.permanentEmployees.PermanentEmployee, 'birthday' | 'startDate' | 'statusUpdatedOn' | 'locker'> & {
      birthday: moment.Moment;
      startDate: moment.Moment;
      statusUpdatedOn: moment.Moment;
      locker: string;
    }
  >;
  isBulkEdit?: boolean;
  isCreate?: boolean;
  isStatusFieldEditable?: boolean;
  isMusterStationsFieldEditable?: boolean;
  isLineFieldEditable?: boolean;
  isShiftFieldEditable?: boolean;
  onChangeBuilding?: (id: mpg.api.buildings.BuildingId) => void;
  musterStationsOptions?: mpg.api.musterStations.MusterStation[];
  linesOptions?: mpg.api.lines.Line[];
  shiftsOptions?: mpg.api.shifts.Shift[];
}

export const getFormFields = ({
  initValues,
  isBulkEdit = false,
  isStatusFieldEditable = true,
  isCreate = false,
  isMusterStationsFieldEditable = true,
  isLineFieldEditable = true,
  isShiftFieldEditable = true,
  onChangeBuilding,
  musterStationsOptions = [],
  linesOptions = [],
  shiftsOptions = [],
}: Props) => {
  const { status = '' } = initValues;

  const statusOptionsPerm =
    status && !isCreate
      ? buildDropdownOptionsFromEnum(PermEmployeeStatus, 'permanentsShiftWork.status.')
      : [
        { value: EmployeeStatus.New, label: getTranslatedString(`permanentsShiftWork.status.${EmployeeStatus.New}`) },
        {
          value: EmployeeStatus.Perm,
          label: getTranslatedString(`permanentsStandardHours.status.${EmployeeStatus.Perm}`),
        },
      ];

  return [
    {
      name: 'attachmentId',
      componentName: 'upload',
      conditionalRender: () => !isBulkEdit && !isCreate,
      params: {
        i18nLabel: 'permanentsShiftWork.photo',
        style: { gridColumnStart: 1, gridColumnEnd: -1 },
        objectId: initValues.id,
        attachmentType: AttachmentType.PermanentPersonPhoto,
        hasLabel: false,
      },
      rules: [
        // {
        //   required: true,
        //   message: 'Please upload photo'
        // },
      ],
    },
    {
      name: 'employeeId',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'permanentsShiftWork.employeeId',
      },
      rules: [{ required: true }],
    },
    {
      name: 'wiegangId',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'permanentsShiftWork.wiegangId',
        disabled: true,
      },
      rules: [{ required: true }],
    },
    {
      name: 'employeeType',
      componentName: 'dropdown',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'permanentsShiftWork.employeeType',
        options: buildDropdownOptionsFromEnum([EmployeeType.Permanent], 'permanentsShiftWork.employeeType.'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'workSchedule',
      componentName: 'dropdown',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'permanentsShiftWork.workSchedule',
        options: buildDropdownOptionsFromEnum([WorkScheduleType.ShiftWork], 'permanentsShiftWork.workSchedule.'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'status',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'permanentsShiftWork.status',
        options: statusOptionsPerm,
        disabled: !isStatusFieldEditable,
        description:
          isBulkEdit &&
          !isStatusFieldEditable &&
          'In bulk edit mode you can change status only for records with the same current status',
      },
      rules: [{ required: true }],
    },
    {
      name: 'firstName',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'permanentsShiftWork.firstName',
      },
      rules: [{ required: true }],
    },
    {
      name: 'middleName',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'permanentsShiftWork.middleName',
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'lastName',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'permanentsShiftWork.lastName',
      },
      rules: [{ required: true }],
    },
    {
      name: 'email',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'permanentsShiftWork.email',
        dependencies: ['phone'],
      },
      rules: [emailOrPhoneRequired, { type: 'email' }] as Rule[],
    },
    {
      name: 'phone',
      componentName: 'phone',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'permanentsShiftWork.phone',
        dependencies: ['email'],
      },
      rules: [emailOrPhoneRequired, phone],
    },
    {
      name: 'company',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'permanentsStandardHours.company',
        options: buildDropdownOptionsFromEnum(Company, 'permanentsStandardHours.company.'),
      },
      rules: [{ required: true }],
    },
    {
      name: 'areaId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'permanentsShiftWork.areaId',
        options: getOptionsFilteredByActive(initValues?.area, buildStoreOptions(areaStore, 'name', 'active', true)),
      },
      rules: [{ required: true }],
    },
    {
      name: 'positionId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'permanentsShiftWork.position',
        options: getOptionsFilteredByActive(initValues?.position, buildStoreOptions(positionStore, 'name', 'active', true)),
      },
      rules: [{ required: true }],
    },
    {
      name: 'supervisorId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'permanentsShiftWork.supervisorId',
        options: getOptionsFilteredByActive(initValues?.supervisor, buildStoreOptions(supervisorStore, 'name', 'active', true)),
      },
      rules: [{ required: true }],
    },
    {
      name: 'colorId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'permanentsShiftWork.colorId',
        options: getOptionsFilteredByActive(initValues?.color, buildStoreOptions(colorStore, 'name', 'active', true)),
      },
      rules: [{ required: true }],
    },
    {
      name: 'buildingId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'permanentsShiftWork.building',
        options: buildStoreOptions(buildingStore, 'name'),
        dependencies: ['musterStationId'],
        onChange: onChangeBuilding,
      },
      rules: [buildingChanged, { required: true }],
    },
    {
      name: 'musterStationId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'permanentsShiftWork.musterStations',
        options: musterStationsOptions,
        dependencies: ['buildingId'],
        disabled: !isMusterStationsFieldEditable,
        description:
          isBulkEdit &&
          !isMusterStationsFieldEditable &&
          'In bulk edit mode you can change Muster Station only for records with the same current Building',
      },
      rules: [...(isCreate ? [musterStationChanged] : []), { required: true }],
    },
    {
      name: 'musterStationCaptainId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'permanentsShiftWork.musterStationCaptains',
        options: buildStoreOptions(musterStationCaptainsStore, 'name'),
      },
      rules: [{ required: false }],
    },
    {
      name: 'isMusterStationCaptain',
      componentName: 'switch',
      params: {
        i18nLabel: 'permanentsShiftWork.isMusterStationCaptain',
        asDropdown: isBulkEdit,
      },
      rules: [],
    },
    {
      name: 'lineId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'permanentsShiftWork.line',
        options: linesOptions,
        dependencies: ['buildingId'],
        disabled: !isLineFieldEditable,
        description:
          isBulkEdit &&
          !isLineFieldEditable &&
          'In bulk edit mode you can change Line only for records with the same current Building',
      },
      rules: [{ required: true }],
    },
    {
      name: 'shiftId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'permanentsShiftWork.shift',
        options: getOptionsFilteredByActive(initValues?.shift, shiftsOptions),
        dependencies: ['buildingId'],
        disabled: !isShiftFieldEditable,
        description:
          isBulkEdit &&
          !isShiftFieldEditable &&
          'In bulk edit mode you can change Shift only for records with the same current Building',
      },
      rules: [{ required: true }],
    },
    {
      name: 'trainedSlitter',
      componentName: 'switch',
      params: {
        i18nLabel: 'permanentsShiftWork.trainedSlitter',
        asDropdown: isBulkEdit,
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'trainedOverwrap',
      componentName: 'switch',
      params: {
        i18nLabel: 'permanentsShiftWork.trainedOverwrap',
        asDropdown: isBulkEdit,
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'locker',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit && !isCreate,
      params: {
        i18nLabel: 'permanentsShiftWork.locker',
        disabled: true,
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'birthday',
      componentName: 'datepicker',
      conditionalRender: () => !isBulkEdit,
      params: {
        i18nLabel: 'permanentsShiftWork.birthday',
        format: 'MM/DD/YYYY',
        disabledDate: (current: moment.Moment) => current && current > moment().endOf('day'),
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'invitationLanguageId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'permanentsShiftWork.invitationLanguageId',
        options: buildStoreOptions(languageStore, 'name'),
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'terminateDescription',
      componentName: 'input',
      params: {
        i18nLabel: 'permanentsShiftWork.termDescription',
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'designatedAccessOnly',
      componentName: 'switch',
      params: {
        i18nLabel: 'permanentsShiftWork.designatedAccessOnly',
        asDropdown: isBulkEdit,
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'parkingTag',
      componentName: 'input',
      params: {
        i18nLabel: 'permanentsStandardHours.parkingTag',
      },
      rules: [
        // { required: true },
      ],
      isUniqueField: true
    },
    {
      name: 'statusUpdatedBy',
      componentName: 'input',
      conditionalRender: () => !isBulkEdit && !isCreate,
      params: {
        i18nLabel: 'permanentsStandardHours.statusUpdatedBy',
        disabled: true,
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'statusUpdatedOn',
      componentName: 'datepicker',
      conditionalRender: () => !isBulkEdit && !isCreate,
      params: {
        i18nLabel: 'permanentsStandardHours.statusUpdatedOn',
        showTime: true,
        format: 'MM/DD/YYYY h:mm:ss a',
        disabled: true,
      },
      rules: [
        // { required: true },
      ],
    },
  ];
};

export const getAdditionalColumns = () => {
  const actions = {
    C: 'Create',
    R: 'Read',
    U: 'Update',
    D: 'Delete',
  };
  const PermissionedReadAction = withPermissions([[PERMISSION_OBJ_PERMANENT_EMPLOYEE, PERMISSION_READ]])(ReadAction);

  const Action = (_: any, { action }: any) => <Space>{actions[action] || ''}</Space>;

  const Actions = (_: any, { id }: any) => (
    <Space>
      <PermissionedReadAction
        uiSref={{ to: 'base-layout.coordinator-permanentsShiftWork.edit.audit', params: { recordId: id } }}
      />
    </Space>
  );

  const Difference = (_: any, { newData: recordNewData, originalData: recordOriginalData }: any) => {
    const newData = recordNewData || {};
    const originalData = recordOriginalData || {};

    const keys = Object.keys({ ...newData, ...originalData });

    const changedKeys = keys.filter((key) => newData[key] !== originalData[key]).sort();

    return (
      <Space
        style={{
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {keys.length === changedKeys.length ? 'All' : changedKeys.join(', ')}
      </Space>
    );
  };

  const User = ({ name }: any) => <Space>{name || 'deleted'}</Space>;

  return [
    {
      title: <FormattedMessage id="audit.user" />,
      dataIndex: 'user',
      render: User,
    },
    {
      title: <FormattedMessage id="audit.action" />,
      dataIndex: 'action',
      render: Action,
    },
    {
      title: <FormattedMessage id="audit.createdAt" />,
      dataIndex: 'createdAt',
      render: DateComponent,
    },
    {
      title: <FormattedMessage id="audit.changedFields" />,
      dataIndex: 'changedFields',
      render: Difference,
      minWidth: 350,
    },
    {
      title: <FormattedMessage id="table.actions" />,
      render: Actions,
      minWidth: 80,
    },
  ];
};
