import {
  useOnStateChanged, StateDeclaration, UISref, UIViewInjectedProps,
} from '@uirouter/react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Layout as AntdLayout, Menu as AntdMenu } from '@/components/antd';

import { IMenuItem } from '@/constants/menu';
import { authStore } from '@/stores';
import { HIDE_ROUTES, SIDEBAR_WIDTH } from '@/constants';

const { Sider } = AntdLayout;
const { SubMenu } = AntdMenu;

interface MenuProps extends UIViewInjectedProps {
  collapsed: boolean;
  defaultSelectedKey?: string;
  menuItems: IMenuItem[];
}

export interface BaseMenuItemProps {
  state: string;
  icon: React.ReactElement;
  messageId: string;
  className?: string;
}

const BaseMenuItem = ({
  state, icon, messageId, ...rest
}: BaseMenuItemProps) => (
  <AntdMenu.Item icon={icon} {...rest}>
    <UISref to={state}>
      <a>
        <FormattedMessage id={messageId} />
      </a>
    </UISref>
  </AntdMenu.Item>
);

const renderMenuItem = ({
  key, state, messageId, permissions, icon,
}: IMenuItem) => {
  const hide = HIDE_ROUTES.some((routeName: string) => state.includes(routeName));

  if (!authStore.hasPermissions(permissions) || hide) {
    return null;
  }

  return (
    <BaseMenuItem
      key={key}
      state={state}
      messageId={messageId}
      icon={icon}
      className="menu-modified"
    />
  );
};

const renderSubMenuItem = ({
  key, messageId, permissions, icon, style, state,
}: IMenuItem, children: any) => {
  const additionalPermissions = children.map((item) => item.permissions);
  const hide = HIDE_ROUTES.some((routeName: string) => state.includes(routeName));

  if (!authStore.hasAtLeastOnePermission([...permissions, ...additionalPermissions]) || hide) {
    return null;
  }

  return (
    <SubMenu
      key={key}
      title={<FormattedMessage id={messageId} />}
      icon={icon}
      className="sub-menu"
      style={style}
    >
      {children.map(renderMenuItem)}
    </SubMenu>
  );
};

export const Menu = ({ transition, menuItems }: MenuProps) => {
  const [routerState, setRouterState] = useState(transition.router.globals.current.name);
  const selectedKey = routerState.split('.')[1];
  useOnStateChanged((state: StateDeclaration) => setRouterState(state.name));

  return (
    <Sider width={SIDEBAR_WIDTH} collapsible>
      <div className="logo">
        <UISref to="base-layout.home">
          <span className="c-icon c-icon--logo" />
        </UISref>
      </div>

      <AntdMenu className="c-menu" theme="dark" mode="inline" selectedKeys={[selectedKey]}>
        {menuItems.filter((menuItem) => menuItem.parent === 'root').map((menuItem) => {
          if (menuItem.menuType && menuItem.menuType === 'group') {
            const subMenuItems = menuItems.filter((menu) => menu.parent === menuItem.key);

            return renderSubMenuItem(menuItem, subMenuItems);
          }
          return renderMenuItem(menuItem);
        })}
      </AntdMenu>
    </Sider>
  );
};
