import { UIViewInjectedProps } from '@uirouter/react';
import { PERMISSION_OBJ_COORDINATOR_SHIFT_REPORTING, PERMISSION_READ } from '@/constants/permissions';
import { ShiftReports } from '@/pages/common/components/shift-reports/components/table';
import { Form } from '@/pages/common/components/shift-reports/components/form';

const CoordinatorForm = ({ transition }: UIViewInjectedProps) => (
  <Form
    currentPage="coordinator"
    permission={[PERMISSION_OBJ_COORDINATOR_SHIFT_REPORTING, PERMISSION_READ]}
    transition={transition}
  />
);

const CoordinatorShiftReports = ({ transition }: UIViewInjectedProps) => {
  const tabConfig = {
    entity: 'coordinator-shiftReports',
    localStorageKey: 'coordinator-shiftReports-table',
    isEmployeeTypeShown: true,
    isFilterWithOptions: true,
  };
  return <ShiftReports tabConfig={tabConfig} transition={transition} />;
};

export const states = [
  {
    name: 'base-layout.coordinator-shiftReports',
    url: '/shiftreports',
    component: CoordinatorShiftReports,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_COORDINATOR_SHIFT_REPORTING, PERMISSION_READ]],
    },
  },
  {
    name: 'base-layout.coordinator-shiftReports.edit',
    url: '/:shiftReportId/edit',
    component: CoordinatorForm,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_COORDINATOR_SHIFT_REPORTING, PERMISSION_READ]],
    },
  },
];
