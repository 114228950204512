import { CoordinatorAuditForm } from './components/audit-form';
import { CoordinatorMarkupAuditForm } from './components/employee-markup/markup-audit-form';
import { CoordinatorForm } from './components/form';
import { CoordinatorEmployees } from './components/table';
import {
  PERMISSION_CREATE,
  PERMISSION_OBJ_COORDINATOR_STAFFING,
  PERMISSION_OBJ_EMPLOYEE,
  PERMISSION_OBJ_EMPLOYEE_MARKUP,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { CoordinatorBulkForm } from './components/form/bulk-edit';
import { CoordinatorEmployeeDetails } from './components/employee-details';
import { CoordinatorEmployeeMarkupForm } from './components/employee-markup/create-update-form';
import { CoordinatorBulkRateForm } from '@/pages/coordinator/employees/components/form/bulk-edit-rate';

export const states = [
  {
    name: 'base-layout.coordinator-employees',
    url: '/employees',
    component: CoordinatorEmployees,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ]],
    },
  },
  {
    name: 'base-layout.coordinator-employees.create',
    url: '/employees/new',
    component: CoordinatorForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ],
        [PERMISSION_OBJ_EMPLOYEE, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-employees.edit',
    url: '/employees/:employeeId/edit?{view}',
    component: CoordinatorEmployeeDetails,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ]],
    },
  },
  {
    name: 'base-layout.coordinator-employees.edit.audit',
    url: '/:recordId/audit',
    component: CoordinatorAuditForm,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ]],
    },
  },
  {
    name: 'base-layout.coordinator-employees.edit.markup-audit',
    url: '/:recordId/employee-markup-audit',
    component: CoordinatorMarkupAuditForm,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ]],
    },
  },
  {
    name: 'base-layout.coordinator-employees.edit.create-employee-markup',
    url: '/employee-markups/create',
    component: CoordinatorEmployeeMarkupForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ],
        [PERMISSION_OBJ_EMPLOYEE_MARKUP, PERMISSION_CREATE],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-employees.edit.edit-employee-markup',
    url: '/employee-markups/:markupId/edit',
    component: CoordinatorEmployeeMarkupForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ],
        [PERMISSION_OBJ_EMPLOYEE_MARKUP, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-employees.bulk-edit',
    url: '/bulk-update',
    component: CoordinatorBulkForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ],
        [PERMISSION_OBJ_EMPLOYEE, PERMISSION_UPDATE],
      ],
    },
  },
  {
    name: 'base-layout.coordinator-employees.bulk-add-rate',
    url: '/bulk-add-rate',
    component: CoordinatorBulkRateForm,
    data: {
      requiresAuth: true,
      permissions: [
        [PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ],
        [PERMISSION_OBJ_EMPLOYEE, PERMISSION_CREATE],
      ],
    },
  },
];
