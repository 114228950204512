import { FormattedMessage } from 'react-intl';

import React from 'react';
import Switch from 'antd/lib/switch';
import { Drawer, Space } from '@/components/antd';
import { Table } from '@/components/table';
import { getTranslatedString } from '@/utils';
import { getFormattedDate } from '@/utils/moment';
import { SIDEBAR_WIDTH } from '@/constants';

import { auditColumns } from './setup';

export interface AuditFormProps {
  auditRecordId: number | null;
  auditStore: any;
  onClose: () => void;
}

export const AuditForm = ({ auditRecordId, auditStore, onClose }: AuditFormProps) => {
  const title = getTranslatedString('audit.audit-number', { 0: auditRecordId });
  const [isDetailedView, setIsDetailedView] = React.useState(false);

  const viewedLog = auditStore?.items.find(({ id }) => id === auditRecordId) as any;
  const userName = viewedLog?.user?.name || '';
  const createdAt = viewedLog?.createdAt || '';
  const newData = viewedLog?.newData || {};
  const originalData = viewedLog?.originalData || {};
  const keys = Object.keys({ ...newData, ...originalData }).sort();

  const tableData = keys
    .filter((key) => {
      if (isDetailedView) {
        return true;
      }
      return originalData[key] !== newData[key];
    })
    .map((key) => ({
      key,
      newData: newData[key],
      originalData: originalData[key],
    }));

  return (
    <Drawer
      onClose={onClose}
      visible={Boolean(auditRecordId)}
      title={title}
      footer={false}
      width={window.innerWidth - SIDEBAR_WIDTH}
    >
      <Space direction="vertical">
        <Space>
          <FormattedMessage id="audit.userName" />:{userName}
        </Space>

        <Space>
          <FormattedMessage id="audit.createdAt" />:{getFormattedDate(createdAt)}
        </Space>
        <Space>
          {isDetailedView ? <FormattedMessage id="audit.detailedView" /> : <FormattedMessage id="audit.changesOnly" />}

          <Switch
            checked={isDetailedView}
            onChange={(value) => {
              setIsDetailedView(value);
            }}
          />
        </Space>
        <Table rowKey="key" columns={auditColumns} dataSource={tableData} scroll={{ y: '100%' }} pagination={false} />
      </Space>
    </Drawer>
  );
};
