import React from 'react';
import moment from 'moment';
import { Col, Row } from '@/components/antd';
import { getDateWithoutTime, getFormattedDate } from '@/utils/moment';
import { CloseOutlined } from '@/components/icons';
import { agencyStore, settingsStore } from '@/stores';
import { getPreparedFilters } from '@/components/table/filters-table';
import { getTranslatedString } from '@/utils';
import { getStorePropById } from '@/utils/common';

const FilterButton = ({ value, onClick }) => (
  <div className="filter-button">
    {value}
    <CloseOutlined onClick={onClick} />
  </div>
);

export const mapFiltersToButtons = (filters, filtersSetup, definitions) => {
  let switchFilters = {};
  filtersSetup
    .filter(
      ({ name, componentName }) =>
        !(
          componentName === 'date-range-picker' &&
          Array.isArray(filters[name]) &&
          filters[name].some((date) => !moment(date).isValid())
        ),
    )
    .forEach(({ name, componentName, params }) => {
      if (typeof filters[name] === 'undefined' || filters[name] == null) {
        delete filters[name];
        return;
      }
      switchFilters = {
        ...switchFilters,
        [name]: filters[name],
      };
      const { showTime = false } = params;
      switch (componentName) {
        case 'date-range-picker':
          switchFilters = {
            ...switchFilters,
            [name]: filters[name].map((entry) => (showTime ? getFormattedDate : getDateWithoutTime)(entry)).join(' - '),
          };
          break;
        case 'switch':
          switchFilters = {
            ...switchFilters,
            [name]: definitions[`${name}:${filters[name]}`],
          };
          break;
        case 'dropdown':
          switchFilters = {
            ...switchFilters,
            [name]: `${getTranslatedString(params.i18nLabel)}: ${filters[name]
              .toString()
              .split(',')
              .map(id => getStorePropById<mpg.api.agencies.Agency>(id, agencyStore))
              .join(', ')
              }`,
          };
          break;
        default:
          break;
      }
    });
  return switchFilters;
};

export const FilterSwitcher = ({
  filtersSetup,
  setFilters,
  store,
  filters = {},
  definitions = {},
  setShowFilterModal = null,
}) => {
  const onFilterDelete = (key) => {
    const filteredOption = filtersSetup.find(({ name }) => name.toString() === key.toString());

    if (filteredOption?.rules[0]?.required) {
      setShowFilterModal();
    } else {
      const modifiedFilters = {
        ...filters,
        [key]: null,
      };
      setFilters(modifiedFilters);
      store.forceLoad({
        limit: settingsStore.itemsPerPage,
        ...getPreparedFilters(modifiedFilters),
      });
    }
  };
  return (
    <Row gutter={[8, 8]}>
      {Object.entries(mapFiltersToButtons(filters, filtersSetup, definitions)).map(([key, value]) => (
        <Col key={key}>
          <FilterButton onClick={() => onFilterDelete(key)} value={value} />
        </Col>
      ))}
    </Row>
  );
};
