import { useState, useEffect } from 'react';
import SignalRService from '@/utils/signalr-service';

export const useScanner = () => {
  const [barCode, setBarCode] = useState<string>();
  const [scannerError, setScannerError] = useState<boolean>(false);

  const onScanCode = (device: string, code: string) => {
    setBarCode(code);
  };

  const resetCode = () => {
    setBarCode(undefined);
  };

  useEffect(() => {
    const scannerHub = new SignalRService('http://localhost:8000/hubs/BarcodeScanners');
    scannerHub.setUpHub().then(() => scannerHub.setHandlers({
      BarcodeScanned: onScanCode,
    }))
      .catch(() => setScannerError(true));
    return () => {
      scannerHub?.stopHub();
    };
  }, []);

  return { scannerError, barCode, resetCode };
};
