import { PerformanceReportState } from '@/pages/employee-performance/enums';
import { PerformanceReportAccess } from '@/pages/employee-performance/components/access-page';
import { DetailedWeeklyPerformanceReport } from '@/pages/employee-performance/components/detailed-weekly-report';
import { WeeklyPerformanceReport } from '@/pages/employee-performance/components/weekly-report';

export const states = [
  {
    name: PerformanceReportState.WeeklyReportPage,
    url: '/employee-performance/weekly-report',
    component: WeeklyPerformanceReport,
    data: {
      requiresAuth: false,
    },
  },
  {
    name: PerformanceReportState.DetailedWeeklyReportPage,
    url: '/employee-performance/weekly-report-details',
    component: DetailedWeeklyPerformanceReport,
    data: {
      requiresAuth: false,
    },
  },
  {
    name: PerformanceReportState.AccessPage,
    url: '/employee-performance/access',
    component: PerformanceReportAccess,
    data: {
      requiresAuth: false,
    },
  },
];
