import { observer } from 'mobx-react-lite';

import { Table } from '@/components/table';

import { useWorkingLogData } from './hooks';

export const WorkingLogsView = observer(({ recordId }: any) => {
  const {
    loading,
    items,
    store,
    columns,
  } = useWorkingLogData({ recordId });

  return (
    <Table
      localStorageKey="timesheet-workingLogs-table"
      rowKey="id"
      columns={columns}
      dataSource={items}
      store={store}
      checkPermissions
      customHeight="80vh"
      // permissionObj={PERMISSION_OBJ_WORKING_LOG} TODO: Not used in src\components\table\hooks.tsx
      loading={loading}
      scroll={{ y: '100%' }}
      pagination={false}
    />
  );
});
