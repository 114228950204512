import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import moment from 'moment/moment';
import { agencyStore, buildingStore, colorStore, shiftStore } from '@/stores';
import { Button, PageHeader, Space } from '@/components/antd';
import { FilterSwitcher } from '@/components/filter-switcher';
import { FilterOutlined, PrinterOutlined, SettingOutlined } from '@/components/icons';
import { onPrint } from '@/utils/common';
import ExportButton from '@/pages/common/components/buttons/export-button';
import { Table } from '@/components/table';
import { getFiltersSetup } from '@/pages/common/components/attendance-reports/table/filters';
import { useMassUpdateCashedStores } from '@/utils/store';
import { withPermissions } from "@/hocs/permission";

export const AttendanceReportsTab = observer(
  ({
    store,
    employeeType,
    columns,
    transition,
    permissionObj,
    exportToExcel,
    exportToEmail,
    fileName,
    entity,
    localStorageKey,
  }: any) => {
    const tableRef: any = useRef({});
    const { items } = store;

    const onDoubleClick = (record) => {
      const {
        employeeType: employeeTypeFromRecord,
        status,
        visitorId,
        colorAssignedId,
        shiftPlannedId,
        agencyId,
        firstName,
        lastName,
      } = record;
      const getParamsObject = {
        employeeType: employeeTypeFromRecord,
        status,
        visitorId,
        colorAssignedId,
        shiftPlannedId,
        agencyId,
        firstName,
        lastName,
        start: tableRef.current && tableRef.current.filters.date && tableRef.current.filters.date[0],
        end: tableRef.current && tableRef.current.filters.date && tableRef.current.filters.date[1],
      };

      transition.router.stateService.go(`base-layout.${entity}.view`, {
        attendanceReportId: btoa(encodeURIComponent(JSON.stringify(getParamsObject))),
      });
    };
    const { loading } = useMassUpdateCashedStores([agencyStore, colorStore, shiftStore, buildingStore]);

    const initialFilters = {
      employeeType,
      date: [
        moment()
          .set({
            h: 0,
            m: 0,
            s: 0,
            ms: 0,
          })
          .subtract(3, 'day')
          .toISOString(),
        moment()
          .set({
            h: 23,
            m: 59,
            s: 59,
            ms: 999,
          })
          .toISOString(),
      ],
    };

    return (
      <>
        <PageHeader
          className="r-antd-page-header"
          extra={[
            <Space align="start" key="attendance-header">
              <FilterSwitcher
                key="filter-switcher"
                filters={tableRef.current && tableRef.current.filters}
                setFilters={tableRef.current && tableRef.current.setFilters}
                filtersSetup={getFiltersSetup()}
                store={store}
                setShowFilterModal={tableRef.current && tableRef.current.showFiltersModal}
              />
              <Button
                style={{ marginTop: 4 }}
                key="filters"
                size="middle"
                icon={<FilterOutlined />}
                onClick={tableRef.current.showFiltersModal}
              />
              <Button style={{ marginTop: 4 }} key="print" size="middle" icon={<PrinterOutlined />} onClick={onPrint} />
              <Button
                style={{ margin: '4px 8px 0 8px' }}
                key="setting"
                size="middle"
                icon={<SettingOutlined />}
                onClick={tableRef.current.showSettingsModal}
              />
              <ExportButton
                store={store}
                exportToExcel={exportToExcel}
                exportToEmail={exportToEmail}
                withPermission={withPermissions([
                  permissionObj,
                ])}
                fileName={fileName}
              />
            </Space>,
          ]}
        />
        <Table
          tableRef={tableRef}
          onRow={(record) => ({
            onDoubleClick: () => onDoubleClick(record),
          })}
          localStorageKey={localStorageKey}
          rowKey="visitorId"
          columns={columns}
          dataSource={items}
          store={store}
          checkPermissions
          permissionObj={permissionObj}
          loading={store.loading || loading}
          scroll={{ y: '100%' }}
          filtersSetup={getFiltersSetup()}
          initialFilters={initialFilters}
          isAutoloadAllowed={!loading}
          initialSorters={{
            columnKey: 'id',
            field: 'id',
            order: 'ascend',
          }}
        />
      </>
    );
  },
);
