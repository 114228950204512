import { getFormattedParams, request } from '@/utils/request';
import { ApiResponse, WrappedItemsResult, WrappedResult } from './types';

const dayTimesheetApi = {
  get(
    id: mpg.api.dayTimesheet.DayTimesheetId,
  ): ApiResponse<WrappedResult<mpg.api.dayTimesheet.DayTimesheet>> {
    return request({
      method: 'get',
      url: `/timesheet/day-timesheet/${id}`,
    });
  },
  getAll(
    params?: mpg.api.dayTimesheet.GetParams,
  ): ApiResponse<WrappedResult<mpg.api.dayTimesheet.DayTimesheet[]>> {
    const formattedParams = getFormattedParams(params);

    return request({
      method: 'get',
      url: '/timesheet/day-timesheet',
      params: formattedParams,
    });
  },
  update(
    id: mpg.api.dayTimesheet.DayTimesheetId,
    data: mpg.api.dayTimesheet.UpdateDayTimesheetParams,
  ): ApiResponse<WrappedResult<mpg.api.dayTimesheet.DayTimesheet>> {
    return request({
      method: 'patch',
      url: `/timesheet/day-timesheet/${id}`,
      data,
    });
  },
  audit(
    params: mpg.api.dayTimesheet.AuditGetParams,
  ): ApiResponse<WrappedItemsResult<mpg.api.dayTimesheet.Audit>> {
    const { id: dayTimesheetId, ...restParams } = params;

    return request({
      method: 'get',
      url: `/timesheet/day-timesheet/${dayTimesheetId}/audit`,
      params: restParams,
    });
  },
  getWorkingLogs(
    params: mpg.api.dayTimesheet.WorkingLogsParams,
  ): ApiResponse<WrappedItemsResult<mpg.api.dayTimesheet.DayTimesheetWorkingLog>> {
    const { id: dayTimesheetId, ...restParams } = params;

    return request({
      method: 'get',
      url: `/timesheet/day-timesheet/${dayTimesheetId}/working-logs`,
      params: restParams,
    });
  },
};

export default dayTimesheetApi;
