import { authStore } from '@/stores';

export const permissionHook = {
  criteria: {
    to: (state: any) => state.data?.permissions,
  },
  callback: async (transition: any) => {
    const $state = transition?.router?.stateService;

    const { permissions = [] } = transition.to().data ?? {};

    if (!authStore.hasPermissions(permissions)) {
      return $state.target('base-layout.access-denied', undefined, { location: true });
    }

    return true;
  },
};
