import { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { UIView } from '@uirouter/react';

import { Button, PageHeader } from '@/components/antd';
import { SettingOutlined } from '@/components/icons';
import { weekTimesheetStore } from '@/stores';
import { TimesheetTable } from './week-timesheet-table';
import { TimesheetBulkActions } from './bulk-actions';
import { DropdownExport } from './dropdown-export';

const store = weekTimesheetStore;

export const Timesheet = observer(({ transition }: any) => {
  const tableRef: any = useRef({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const resetSelection = () => setSelectedRowKeys([]);

  // use arrow function as tableRef is mutated in child component
  const onSettingsClick = () => tableRef.current.showSettingsModal();

  return (
    <>
      <PageHeader
        className="r-antd-page-header"
        extra={[
          <TimesheetBulkActions selectedRowKeys={selectedRowKeys} resetSelection={resetSelection} />,
          <Button size="middle" key="setting" icon={<SettingOutlined />} onClick={onSettingsClick} />,
          <DropdownExport store={store} />,
        ]}
      />
      <TimesheetTable
        tableRef={tableRef}
        transition={transition}
        selectedRows={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />
      <UIView />
    </>
  );
});
