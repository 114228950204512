import { buildingStore } from '@/stores';

import { findItemIdByLabel } from '@/utils/common';
import { FieldDescription, ParseSettings } from '@/components/import-button';
import { EmployeeType } from '@/types/enums';

export const fields: FieldDescription[] = [
  {
    name: 'serial',
  },
  {
    name: 'name',
  },
  {
    name: 'buildingId',
    svgMap: (e: string) => findItemIdByLabel(buildingStore, 'name', e),
  },
  {
    name: 'employeeId',
  },
  {
    name: 'employeeType',
    svgMap: (employeeType, employeeId) => {
      if (employeeType) {
        return employeeType;
      }

      const isDigitRegex = /^\d+$/;
      return isDigitRegex.test(employeeId)
        ? EmployeeType.Temporary
        : EmployeeType.Permanent;
    },
    relatedToField: 'employeeId',
  },
  {
    name: 'dateLockerAssigned',
  },
  {
    name: 'seriesNumber',
  },
  {
    name: 'code1',
  },
  {
    name: 'code2',
  },
  {
    name: 'code3',
  },
  {
    name: 'code4',
  },
  {
    name: 'code5',
  },
  {
    name: 'status',
  },
  {
    name: 'comment',
  },
];

export const parseSettings: ParseSettings = fields
  .reduce((acc, {
    name, svgKey, svgMap, svgValidator, relatedToField,
  }: FieldDescription) => {
    acc[svgKey || name] = {
      key: name,
      map: svgMap,
      validator: svgValidator,
      relatedToField,
    };
    return acc;
  }, {});
