import { request } from '@/utils/request';
import { ApiResponse } from './types';

export default {
  export(params?: mpg.api.friendlyWay.GetParams): ApiResponse<mpg.api.friendlyWay.Response> {
    return request({
      method: 'get',
      url: '/friendly-way-sync',
      params,
    });
  },
  exportPerms(params: mpg.api.friendlyWay.PermsGetParams): ApiResponse<mpg.api.friendlyWay.Response> {
    return request({
      method: 'get',
      url: '/friendly-way-sync/permanent',
      params,
    });
  },
};
