import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Space } from '@/components/antd';

import { api } from '@/api';
import { DeleteAction, DeleteActionProps, EditAction, EditActionProps } from '@/components/table/actions';
import {
  getColumnDateFields,
  getColumnSearchFields,
  getColumnBooleanFields,
  getColumnAttachmentIdFields,
} from '@/components/table/filters';
import { BooleanValueRender, NumberAsBooleanValueRender } from '@/components/table/renders';
import {
  PERMISSION_DELETE,
  PERMISSION_OBJ_EMPLOYEE,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import {
  agencyStore,
  areaStore,
  breaktimeStore,
  buildingStore,
  colorStore,
  employeeStore,
  languageStore,
  linesStore,
  musterStationsStore,
  musterStationCaptainsStore,
  positionStore,
  shiftStore,
  supervisorStore,
  approversStore,
} from '@/stores';
import {
  buildFilterOptionsFromEnum,
  buildStoreOptions,
  getStorePropById,
  getStorePropByIdWithExtraData,
  showError,
} from '@/utils/common';
import { getDateWithoutTime, getFormattedDate } from '@/utils/moment';
import { AccessTo, BillingType, EmployeeStatus, WorkScheduleTemporaryType } from '@/types/enums';
import { lockerFormatted } from '@/utils/locker';

interface PagePermission {
  pagePermission: string;
}

const PermissionedEditAction = ({ pagePermission, uiSref }: EditActionProps & PagePermission) => {
  const ActionWithPermission = withPermissions([
    [pagePermission, PERMISSION_READ],
    [PERMISSION_OBJ_EMPLOYEE, PERMISSION_UPDATE],
  ])(EditAction);
  return <ActionWithPermission uiSref={uiSref} />;
};

const PermissionedDeleteAction = ({ pagePermission, onConfirm }: DeleteActionProps & PagePermission) => {
  const ActionWithPermission = withPermissions([
    [pagePermission, PERMISSION_READ],
    [PERMISSION_OBJ_EMPLOYEE, PERMISSION_DELETE],
  ])(DeleteAction);
  return <ActionWithPermission onConfirm={onConfirm} />;
};

export const getColumns = (pagePermission: string, entity: string) => {
  const Actions = (_: any, { id }: any) => {
    const onDelete = () => {
      api.employees
        .delete(id)
        .source.then(() => employeeStore.refresh())
        .catch(showError);
    };

    return (
      <Space>
        <PermissionedEditAction
          pagePermission={pagePermission}
          uiSref={{ to: `base-layout.${entity}.edit`, params: { employeeId: id } }}
        />
        <PermissionedDeleteAction pagePermission={pagePermission} onConfirm={onDelete} />
      </Space>
    );
  };

  return [
    // {
    //   title: 'id',
    //   dataIndex: 'id',
    //   sorter: true,
    // },
    {
      title: <FormattedMessage id="employees.kioskPinCode" />,
      dataIndex: 'kioskPinCode',
      ...getColumnSearchFields(),
      sorter: true,
    },
    {
      title: <FormattedMessage id="employees.employeeType" />,
      dataIndex: 'employeeType',
      render: (employeeType: string) => <FormattedMessage id={`employees.employeeType.${employeeType}`} />,
    },
    {
      title: <FormattedMessage id="employees.workSchedule" />,
      dataIndex: 'workSchedule',
      filters: buildFilterOptionsFromEnum(WorkScheduleTemporaryType, 'employees.workSchedule.'),
      filterMultiple: true,
      sorter: true,
      render: (workSchedule: string) => <FormattedMessage id={`employees.workSchedule.${workSchedule}`} />,
    },
    {
      title: <FormattedMessage id="employees.agencyInternalId" />,
      dataIndex: 'agencyInternalId',
      ...getColumnSearchFields(),
      sorter: true,
    },
    {
      title: <FormattedMessage id="employees.status" />,
      dataIndex: 'status',
      filters: buildFilterOptionsFromEnum(EmployeeStatus, 'employees.status.'),
      filterMultiple: true,
      sorter: true,
      render: (status: string) => <FormattedMessage id={`employees.status.${status}`} />,
    },
    {
      title: <FormattedMessage id="employees.statusUpdatedBy" />,
      dataIndex: 'statusUpdatedBy',
      ...getColumnSearchFields(),
      sorter: true,
    },
    {
      title: <FormattedMessage id="employees.statusUpdatedOn" />,
      dataIndex: 'statusUpdatedOn',
      sorter: true,
      render: (value) => getFormattedDate(value),
      ...getColumnDateFields(true),
    },
    {
      title: <FormattedMessage id="employees.firstName" />,
      dataIndex: 'firstName',
      ...getColumnSearchFields(),
      sorter: true,
    },
    {
      title: <FormattedMessage id="employees.middleName" />,
      dataIndex: 'middleName',
      ...getColumnSearchFields(),
      sorter: true,
    },
    {
      title: <FormattedMessage id="employees.lastName" />,
      dataIndex: 'lastName',
      ...getColumnSearchFields(),
      sorter: true,
    },
    {
      title: <FormattedMessage id="employees.email" />,
      dataIndex: 'email',
      ...getColumnSearchFields(),
      sorter: true,
    },
    {
      title: <FormattedMessage id="employees.phone" />,
      dataIndex: 'phone',
      ...getColumnSearchFields(),
      sorter: true,
    },
    {
      title: <FormattedMessage id="employees.agencyId" />,
      dataIndex: 'agencyId',
      filters: buildStoreOptions(agencyStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.agencies.Agency>(itemId, agencyStore),
    },
    {
      title: <FormattedMessage id="employees.approverId" />,
      dataIndex: 'approverId',
      filters: buildStoreOptions(approversStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (approverId: string) => getStorePropById<mpg.api.agencies.Agency>(approverId, approversStore),
      permissions: [['employee.approverId', PERMISSION_READ]],
    },
    {
      title: <FormattedMessage id="employees.areaId" />,
      dataIndex: 'areaId',
      filters: buildStoreOptions(areaStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.areas.Area>(itemId, areaStore),
    },
    {
      title: <FormattedMessage id="employees.position" />,
      dataIndex: 'positionId',
      filters: buildStoreOptions(positionStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.positions.Position>(itemId, positionStore),
    },
    {
      title: <FormattedMessage id="employees.supervisorId" />,
      dataIndex: 'supervisorId',
      filters: buildStoreOptions(supervisorStore, 'name'),
      filterMultiple: true,
      sorter: true,
      minWidth: 150,
      render: (itemId: string) => getStorePropById<mpg.api.supervisors.Supervisor>(itemId, supervisorStore),
    },
    {
      title: <FormattedMessage id="employees.shift" />,
      dataIndex: 'shiftId',
      minWidth: 180,
      filters: buildStoreOptions(shiftStore, 'name', null, null, buildingStore, 'buildingId'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.shifts.Shift>(itemId, shiftStore)
    },
    {
      title: <FormattedMessage id="employees.shiftType" />,
      dataIndex: 'shiftType',
      filters: [
        { label: 'Day', value: 'day', text: 'Day' },
        { label: 'Night', value: 'night', text: 'Night' },
      ],
      render: (value:string)=> <FormattedMessage id={`employees.shiftType.${value}`} />,
      filterMultiple: false,
      sorter: true,
    },
    {
      title: <FormattedMessage id="employees.colorId" />,
      dataIndex: 'colorId',
      filters: buildStoreOptions(colorStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.colors.Colors>(itemId, colorStore),
    },
    {
      title: <FormattedMessage id="employees.startDate" />,
      dataIndex: 'startDate',
      sorter: true,
      ...getColumnDateFields(),
      render: (item: string) => getDateWithoutTime(item),
    },
    {
      title: <FormattedMessage id="employees.orientationDate" />,
      dataIndex: 'orientationDate',
      sorter: true,
      minWidth: 150,
      ...getColumnDateFields(),
      render: (item: string) => getDateWithoutTime(item),
    },
    {
      title: <FormattedMessage id="employees.building" />,
      dataIndex: 'buildingId',
      filters: buildStoreOptions(buildingStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.buildings.Building>(itemId, buildingStore),
    },
    {
      title: <FormattedMessage id="employees.musterStations" />,
      dataIndex: 'musterStationId',
      filters: buildStoreOptions(musterStationsStore, 'name', null, null, buildingStore, 'buildingId'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) =>
        getStorePropByIdWithExtraData<mpg.api.musterStations.MusterStation>({
          uid: itemId,
          store: musterStationsStore,
          labelKey: 'name',
          additionalStore: buildingStore,
          additionalLabel: 'buildingId',
        }),
    },
    {
      title: <FormattedMessage id="employees.musterStationCaptains" />,
      dataIndex: 'musterStationCaptainId',
      filters: buildStoreOptions(musterStationCaptainsStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) =>
        getStorePropById<mpg.api.musterStationCaptains.MusterStationCaptain>(itemId, musterStationCaptainsStore),
    },
    {
      title: <FormattedMessage id="employees.line" />,
      dataIndex: 'lineId',
      filters: buildStoreOptions(linesStore, 'name', null, null, buildingStore, 'buildingId'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) =>
        getStorePropByIdWithExtraData<mpg.api.lines.Line>({
          uid: itemId,
          store: linesStore,
          labelKey: 'name',
          additionalStore: buildingStore,
          additionalLabel: 'buildingId',
        }),
    },
    {
      title: <FormattedMessage id="employees.trainedSlitter" />,
      dataIndex: 'trainedSlitter',
      sorter: true,
      render: BooleanValueRender,
      ...getColumnBooleanFields(),
    },
    {
      title: <FormattedMessage id="employees.trainedOverwrap" />,
      dataIndex: 'trainedOverwrap',
      sorter: true,
      minWidth: 150,
      render: BooleanValueRender,
      ...getColumnBooleanFields(),
    },
    {
      title: <FormattedMessage id="employees.locker" />,
      dataIndex: 'locker',
      ...getColumnSearchFields(),
      sorter: true,
      render: lockerFormatted,
    },
    {
      title: <FormattedMessage id="employees.birthday" />,
      dataIndex: 'birthday',
      sorter: true,
      ...getColumnDateFields(),
      render: (item: string) => getDateWithoutTime(item),
    },
    {
      title: <FormattedMessage id="employees.dobKey" />,
      dataIndex: 'dobKey',
      ...getColumnSearchFields(),
      sorter: true,
    },
    {
      title: <FormattedMessage id="employees.invitationLanguageId" />,
      dataIndex: 'invitationLanguageId',
      filters: buildStoreOptions(languageStore, 'name'),
      filterMultiple: true,
      sorter: true,
      minWidth: 150,
      render: (itemId: string) => getStorePropById<mpg.api.languages.Language>(itemId, languageStore),
    },
    {
      title: <FormattedMessage id="employees.breakTimes" />,
      dataIndex: 'breakTimeId',
      filters: buildStoreOptions(breaktimeStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.breaktimes.Breaktime>(itemId, breaktimeStore),
    },
    // {
    //   title: <FormattedMessage id={'employees.lunchTime'} />,
    //   dataIndex: 'lunchTime',
    //   sorter: true,
    // },
    {
      title: <FormattedMessage id="employees.photo" />,
      dataIndex: 'attachmentId',
      sorter: true,
      render: BooleanValueRender,
      ...getColumnAttachmentIdFields(),
    },
    {
      title: <FormattedMessage id="employees.termDescription" />,
      dataIndex: 'termDescription',
      sorter: true,
    },
    {
      title: <FormattedMessage id="employees.crewName" />,
      dataIndex: 'crewName',
      ...getColumnSearchFields(),
      sorter: true,
    },
    {
      title: <FormattedMessage id="employees.accessToId" />,
      dataIndex: 'accessTo',
      filters: buildFilterOptionsFromEnum(AccessTo, 'employees.accessToId.'),
      filterMultiple: false,
      sorter: true,
      render: (item: string) => NumberAsBooleanValueRender(item, 'employees.accessToId.'),
    },
    // {
    //   title: <FormattedMessage id={'employees.address1'} />,
    //   dataIndex: 'address1',
    //   sorter: true,
    // },
    // {
    //   title: <FormattedMessage id={'employees.address2'} />,
    //   dataIndex: 'address2',
    //   sorter: true,
    // },
    {
      title: <FormattedMessage id="billingType" />,
      dataIndex: 'billingType',
      filters: buildFilterOptionsFromEnum(BillingType, 'billingType.'),
      sorter: true,
      render: (value: string) => <FormattedMessage id={`billingType.${value}`} />,
    },
    {
      title: <FormattedMessage id="employees.parkingTag" />,
      dataIndex: 'parkingTag',
      ...getColumnSearchFields(),
      sorter: true,
    },
    // {
    //   title: <FormattedMessage id={'employees.city'} />,
    //   dataIndex: 'city',
    //   sorter: true,
    // },
    // {
    //   title: <FormattedMessage id={'employees.zipcode'} />,
    //   dataIndex: 'zipcode',
    //   sorter: true,
    // },
    // {
    //   title: <FormattedMessage id={'employees.term'} />,
    //   dataIndex: 'term',
    //   sorter: true,
    // },
    // {
    //   title: <FormattedMessage id={'employees.comment'} />,
    //   dataIndex: 'comment',
    //   sorter: true,
    // },
    {
      title: <FormattedMessage id="table.actions" />,
      dataIndex: 'actions',
      render: Actions,
      minWidth: 180,
    },
  ];
};
