import { UISref, UIView, UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React, { useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { TableRowSelection } from 'antd/lib/table/interface';
import {
  Button, PageHeader, Dropdown, Menu, Modal, notification,
} from '@/components/antd';
import {
  PlusOutlined,
  DownOutlined,
  DeleteOutlined,
  EditOutlined,
  SyncOutlined,
  SettingOutlined, PrinterOutlined,
} from '@/components/icons';

import { Table } from '@/components/table';
import {
  PERMISSION_CREATE, PERMISSION_DELETE,
  PERMISSION_OBJ_COORDINATOR_STAFFING,
  PERMISSION_OBJ_PERMANENT_EMPLOYEE,
  PERMISSION_OBJ_PERMANENT_EMPLOYEE_STANDARD_HOURS,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import {
  authStore,
  agencyStore,
  areaStore,
  breaktimeStore,
  buildingStore,
  colorStore,
  languageStore,
  linesStore,
  musterStationsStore,
  musterStationCaptainsStore,
  permanentEmployeeStandardHoursStore,
  positionStore,
  shiftStore,
  supervisorStore,
  searchStore,
} from '@/stores';
import { WorkScheduleType } from '@/types/enums';
import { useMassUpdateCashedStores } from '@/utils/store';
import { onPrint, showError } from '@/utils/common';
import { api } from '@/api';
import { getTranslatedString } from '@/utils';
import { ImportButton, ParseSettings } from '@/components/import-button';
import { ApiResponse, WrappedItemsResult } from '@/api/types';

import { getColumns } from './setup';
import { usePermanentStandardHoursBulkCreate } from './hooks';
import ExportButton from "@/pages/common/components/buttons/export-button";

const entity = 'coordinator-permanentsStandardHours';
const store = permanentEmployeeStandardHoursStore;

interface CreateButtonProps {
  onClick: (e: any) => void;
  loading?: boolean;
  style?: any;
  disabled?: boolean;
}

const CreateButton = withPermissions([
  [PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ],
  [PERMISSION_OBJ_PERMANENT_EMPLOYEE, PERMISSION_CREATE],
  [PERMISSION_OBJ_PERMANENT_EMPLOYEE_STANDARD_HOURS, PERMISSION_CREATE],
])(({ onClick, style = {} }: CreateButtonProps) => (
  <Button style={style} onClick={onClick} type="primary" shape="round" icon={<PlusOutlined />}>
    <span>
      <FormattedMessage id="permanentsStandardHours.add-new" />
    </span>
  </Button>
));


interface BulkCreateButtonProps {
  request: (parsedItems: mpg.api.permanentEmployees.PermanentEmployeeStandardHoursParams[]) => ApiResponse<WrappedItemsResult<mpg.api.permanentEmployees.PermanentEmployee[]>>;
  storeRefreshRequest: () => ApiResponse<WrappedItemsResult<mpg.api.permanentEmployees.PermanentEmployee[]>>;
  settings: ParseSettings;
}

const BulkCreateButton = withPermissions([
  [PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ],
  [PERMISSION_OBJ_PERMANENT_EMPLOYEE, PERMISSION_CREATE],
  [PERMISSION_OBJ_PERMANENT_EMPLOYEE_STANDARD_HOURS, PERMISSION_CREATE],
])(
  ({ request, settings, storeRefreshRequest }: BulkCreateButtonProps) => (
    <ImportButton request={request} parseSettings={settings} storeRefreshRequest={storeRefreshRequest} />
  ),
);

const BulkUpdateButton = withPermissions([
  [PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ],
  [PERMISSION_OBJ_PERMANENT_EMPLOYEE, PERMISSION_UPDATE],
  [PERMISSION_OBJ_PERMANENT_EMPLOYEE_STANDARD_HOURS, PERMISSION_UPDATE],
])(({ selectedRowKeys }: any) => (
  <Menu.Item key="update" icon={<EditOutlined />}>
    <UISref to={`base-layout.${entity}.bulk-edit`} params={{ selectedRowKeys }}>
      <a><FormattedMessage id="button.update" /></a>
    </UISref>
  </Menu.Item>
));

const BulkDeleteButton = withPermissions([
  [PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ],
  [PERMISSION_OBJ_PERMANENT_EMPLOYEE, PERMISSION_DELETE],
  [PERMISSION_OBJ_PERMANENT_EMPLOYEE_STANDARD_HOURS, PERMISSION_DELETE],
])(({ onClick }: any) => (
  <Menu.Item key="remove" icon={<DeleteOutlined />} onClick={onClick}>
    <FormattedMessage id="button.remove" />
  </Menu.Item>
));

export const PermanentsStandardHours = observer(({ transition }: UIViewInjectedProps) => {
  searchStore.setSearchParams({
    entity: 'permanent_employees',
    type: 'standardHours',
    isShowHeaderSearch: true,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [fwLoading, setFwLoading] = useState<boolean>(false);

  const tableRef: any = useRef({});
  const { items } = store;

  const { loading } = useMassUpdateCashedStores([
    agencyStore,
    areaStore,
    breaktimeStore,
    buildingStore,
    colorStore,
    languageStore,
    linesStore,
    musterStationsStore,
    musterStationCaptainsStore,
    positionStore,
    shiftStore,
    supervisorStore,
  ]);

  const { importRequest, parseSettings, storeRefreshRequest } = usePermanentStandardHoursBulkCreate();

  const onAdd = () => {
    transition.router.stateService.go(`base-layout.${entity}.create`);
  };

  const onDoubleClick = (record) => {
    transition.router.stateService.go(`base-layout.${entity}.edit`, { permanentStandardHoursId: record.id });
  };

  const onRowSelectionChange = (currentSelectedRowKeys: string[]) => {
    setSelectedRowKeys(currentSelectedRowKeys);
  };

  const onFwSync = () => {
    setFwLoading(true);
    return api.friendlyWay.exportPerms({ limit: store.count, workSchedule: WorkScheduleType.StandardHours }).source
      .then((data: mpg.api.friendlyWay.Response) => {
        notification.info({
          message: 'Result',
          description: <div>
            <p>
              Errors Count:
              {data.errorsCount}
            </p>
            <p>
              {' '}
              Exported Count:
              {data.exportedCount}
            </p>
          </div>,
          duration: 10,
        });
      })
      .catch(showError)
      .finally(() => setFwLoading(false));
  };

  const rowSelection: TableRowSelection<any> = {
    // preserveSelectedRowKeys: true,
    onChange: onRowSelectionChange,
  };

  const onBulkDelete = () => {
    const onOk = () => api.permanentEmployeesStandardHours.bulkDelete({ ids: selectedRowKeys }).source
      .then(() => permanentEmployeeStandardHoursStore.refresh())
      .catch(showError);

    Modal.confirm({
      title: getTranslatedString('common.warning'),
      content: getTranslatedString('permanentsStandardHours.delete-warning-desc'),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk,
    });
  };

  const batchMenu = (
    <Menu>
      <BulkUpdateButton selectedRowKeys={selectedRowKeys} />
      <BulkDeleteButton onClick={onBulkDelete} />
    </Menu>
  );

  const hasBulkUpdatePermissions = authStore.hasPermissions([
    [PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ],
    [PERMISSION_OBJ_PERMANENT_EMPLOYEE, PERMISSION_UPDATE],
    [PERMISSION_OBJ_PERMANENT_EMPLOYEE_STANDARD_HOURS, PERMISSION_UPDATE],
  ]);

  const hasBulkDeletePermissions = authStore.hasPermissions([
    [PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ],
    [PERMISSION_OBJ_PERMANENT_EMPLOYEE, PERMISSION_DELETE],
    [PERMISSION_OBJ_PERMANENT_EMPLOYEE_STANDARD_HOURS, PERMISSION_DELETE],
  ]);

  return (
    <>
      <PageHeader
        className="r-antd-page-header"
        extra={[
          <Dropdown key="dropdown" overlay={batchMenu} disabled={!selectedRowKeys.length || (!hasBulkUpdatePermissions && !hasBulkDeletePermissions)}>
            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              Batch Actions
              {' '}
              <DownOutlined />
            </a>
          </Dropdown>,
          <Button key="print" size="middle" icon={<PrinterOutlined />} onClick={onPrint} />,
          <Button
            key="setting"
            icon={<SettingOutlined />}
            size="middle"
            onClick={tableRef.current.showSettingsModal}
          />,
          <Button
            key="fw"
            icon={<SyncOutlined />}
            shape="round"
            loading={fwLoading}
            type="primary"
            onClick={onFwSync}
          >
            Sync
          </Button>,
          <CreateButton
            key="create button"
            onClick={onAdd}
          />,
          <ExportButton
            store={store}
            exportToExcel={api.permanentEmployeesStandardHours.exportToExcel}
            exportToEmail={api.permanentEmployeesStandardHours.exportToEmail}
            withPermission={withPermissions([
              [PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ],
              [PERMISSION_OBJ_PERMANENT_EMPLOYEE, PERMISSION_READ],
              [PERMISSION_OBJ_PERMANENT_EMPLOYEE_STANDARD_HOURS, PERMISSION_READ],
            ])}
            fileName="mpg-permanentsStandardHours"
          />,
          <BulkCreateButton
            key="bulk create button"
            request={importRequest}
            settings={parseSettings}
            storeRefreshRequest={storeRefreshRequest}
          />,
        ]}
      />

      <Table
        tableRef={tableRef}
        onRow={(record) => ({
          onDoubleClick: () => onDoubleClick(record),
        })}
        localStorageKey="coordinator-permanentsStandardHours-table"
        rowSelection={rowSelection}
        rowKey="id"
        columns={getColumns()}
        dataSource={[...items]}
        store={store}
        checkPermissions
        permissionObj={PERMISSION_OBJ_PERMANENT_EMPLOYEE}
        loading={store.loading || loading}
        scroll={{ y: '100%' }}
        isAutoloadAllowed={!loading}
        initialSorters={{
          columnKey: 'lastName',
          field: 'lastName',
          order: 'ascend',
        }}
      />
      <UIView />
    </>
  );
});
