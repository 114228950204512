import { api } from '@/api';
import { lockerStore } from '@/stores';
import { parseSettings } from './csv-parser-setup';

export const useBulkUpdate = () => {
  const importRequest = (parsedItems: mpg.api.lockers.LockerParams[]) => api.lockers.bulkUpdate({ items: parsedItems }).source;

  const storeRefreshRequest = () => lockerStore.refresh();

  return {
    importRequest,
    parseSettings,
    storeRefreshRequest,
  };
};
