import React from 'react';
import { withPermissions } from '@/hocs/permission';
import {
  PERMISSION_CREATE,
  PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { Button } from '@/components/antd';
import { BgColorsOutlined, EditOutlined, PlusOutlined } from '@/components/icons';

interface BulkCreateButtonProps {
  onClick: (e: any) => void;
  disabled?: boolean;
}

export const BulkCreateButton = withPermissions([
  [PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY, PERMISSION_CREATE],
])(({ onClick, disabled = false }: BulkCreateButtonProps) => (
  <Button
    onClick={onClick}
    type="primary"
    icon={<PlusOutlined />}
    disabled={disabled}
  />
));

interface BulkUpdateButtonProps {
  onClick: (e: any) => void;
  disabled?: boolean;
}

export const BulkUpdateButton = withPermissions([
  [PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY, PERMISSION_UPDATE],
])(({ onClick, disabled = false }: BulkUpdateButtonProps) => (
  <Button
    onClick={onClick}
    type="primary"
    icon={<EditOutlined />}
    disabled={disabled}
  />
));

export const BulkAssignColorsButton = withPermissions([
  [PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY, PERMISSION_UPDATE],
])(({ onClick, disabled = false }: BulkUpdateButtonProps) => (
  <Button
    onClick={onClick}
    type="primary"
    icon={<BgColorsOutlined />}
    disabled={disabled}
  />
));
