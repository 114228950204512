import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { minutesToStringHours } from '@/utils/time';

import { useAdditionalInformationData } from './hooks';

export const EditDayTimesheetAdditionalInformation = observer(({
  dayTimesheet,
}: {
  dayTimesheet?: mpg.api.dayTimesheet.DayTimesheet;
}) => {
  const { loading, buildingConfig } = useAdditionalInformationData(dayTimesheet);

  const formattedAdditionInfoValues = {
    minBillableTime: buildingConfig ? minutesToStringHours(buildingConfig?.minBillableTime) : '',
    breakPaidTime: buildingConfig ? minutesToStringHours(buildingConfig?.breakPaidTime) : '',
    bathroomPaidTime: buildingConfig ? minutesToStringHours(buildingConfig?.bathroomPaidTime) : '',
    launchPaidTime: buildingConfig ? minutesToStringHours(buildingConfig?.launchPaidTime) : '',
  };

  if (!dayTimesheet || loading) {
    return null;
  }

  return (
    <FormattedMessage
      id="timesheet.form.editAdditionalInformation"
      values={{
        ...formattedAdditionInfoValues,
        b: (value) => <strong>{value}</strong>,
        br: <br />,
      }}
    />
  );
});
