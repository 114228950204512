import { computed, makeAutoObservable, observable } from 'mobx';
import { Transition } from '@uirouter/react';
import domtoimage from 'dom-to-image';
import { api } from '@/api';
import { PerformanceReportState } from '@/pages/employee-performance/enums';
import { notification } from '@/components/antd';
import playerAPI from '@/api/player';
import { DeviceStatus } from '@/types/enums';
import { getDateWithoutTime } from '@/utils/moment';

export class EmployeePerformanceStore {
  @observable weeklyPerformanceReport: mpg.api.employeePerformance.WeeklyPerformanceReport;

  @observable detailedPerformanceReport: mpg.api.employeePerformance.DetailedWeeklyReport[] = [];

  @observable pin: string;

  @observable.shallow weekOfs: number[] = [];

  @observable week: number;

  @observable weeklyPerformanceLoading: boolean = false;

  @observable detailedPerformanceLoading: boolean = false;

  @observable printerAvailable: boolean = false;

  @observable printing: boolean = false;

  @observable printWidth: string = 'auto';

  constructor() {
    makeAutoObservable(this);
  }

  @computed
  get weekOptions() {
    const weekOptions = this.weekOfs.map((item) => ({value: item, label: getDateWithoutTime(String(item))}));
    // this number is here to take the two most recent weeks, this array is sorted from older to newest
    return weekOptions.slice(-2)
  }

  getPrinterStatus = () => {
    playerAPI.getPrinterStatus().then((response: player.api.printer.PlayerStatusResponse) => {
      if (response.deviceStatus !== DeviceStatus.Error) {
        this.printerAvailable = true;
      }
    }).catch();
  }

  startPrint = () => {
    this.printing = true;
    this.printWidth = `457px`; // taken from badge width
/*    return playerAPI.getPrinterInfo().then((printer: player.api.printer.Printer) => {
      this.printWidth = `${printer.paperWidth}px`;
    }); */
  }

  sendForPrinting = async (node: Node) => {
    if (!node) {
      return Promise.reject();
    }
    const blob = await domtoimage.toBlob(node, { style: { opacity: 1 } });
    const formData = new FormData();
    formData.append('file', blob);

    const response = await playerAPI.printBadge(formData);
    if (!response.ok) {
      return Promise.reject();
    }
  };

  finishPrint = () => {
    this.printWidth = 'auto'
    this.printing = false;
  }

  loadDetailedData = async (transition: Transition) => {
    try {
      this.detailedPerformanceLoading = true;
      const resp = await api.employeePerformance.getDetailedWeeklyReport(this.pin, this.week).source;
      this.detailedPerformanceReport = await resp.items;
    } catch (error) {
      if (!this.pin) {
        transition.router.stateService.go(PerformanceReportState.AccessPage);
      }
      if (!this.week) {
        transition.router.stateService.go(PerformanceReportState.WeeklyReportPage);
      }
      notification.error({ message: error.message, duration: 3 });
    } finally {
      this.detailedPerformanceLoading = false;
    }
  };

  loadWeeklyData = async (params: number) => {
    try {
      this.weeklyPerformanceLoading = true;
      const resp = await api.employeePerformance.getWeeklyPerformanceReport(this.pin, params);
      this.weeklyPerformanceReport = await resp.source;
      this.setWeek(params);
    } catch (error) {
      notification.error({ message: error.message, duration: 3 });
    } finally {
      this.weeklyPerformanceLoading = false;
    }
  };

  loadWeekOfs = async (transition: Transition) => {
    try {
      const resp = await api.employeePerformance.getWeekOfs(this.pin).source;
      this.weekOfs = await resp.data.items;
    } catch (error) {
      transition.router.stateService.go(PerformanceReportState.AccessPage);
      notification.error({ message: error.message, duration: 3 });
    }
  };

  setPin = (pin: string) => {
    this.pin = pin;
  };

  setWeek = (week: number) => {
    this.week = week;
  };

  clearDetailedReport = () => {
    this.detailedPerformanceReport = [];
  };
}
