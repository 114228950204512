import moment from 'moment-timezone';

import { Period } from '@/types/common';

export const DATE_FORMAT = 'MM/DD/YYYY h:mm:ss A';
export const DATE_WITHOUT_TIME_FORMAT = 'MM/DD/YYYY';
export const DATE_WITHOUT_TIME_FORMAT_ISO = 'YYYY-MM-DD';
export const TIME_FORMAT = 'h:mm:ss A';

export const getFormattedDate = (date: string) => date && moment(date).format(DATE_FORMAT);
export const getDateWithoutTime = (date: string) => date && moment(date).format(DATE_WITHOUT_TIME_FORMAT);
export const getDateWithoutTimeISO = (date: string) => date && moment(date).format(DATE_WITHOUT_TIME_FORMAT_ISO);
export const getDateWithoutTimeWithCustomSeparator = (date: string, separator: string) => date && moment(date).format(`YYYY${separator}MM${separator}DD`);
export const getTime = (date: string) => date && moment(date).format(TIME_FORMAT);
export const getTimeAMPM = (date: string) => date && moment(date).format('h:mma');
export const getTimeSpaceAMPM = (date: string) => date && moment(date).format('h:mm A');
export const getTimeHHmm = (date: string) => date && moment(date).format('HH.mm');

export const getFormattedDateOnly = (date: string) => date && moment(date).format('YYYY/MM/DD');

export const getFormattedRangeDate = (range: Period): string => {
  if (!range) {
    return '';
  }

  const from = moment(range.from);
  const to = moment(range.to);
  const quarter = moment(range.from).startOf('quarter').quarter();

  const yearsStr = from.year() === to.year()
    ? `${to.format('YYYY')}`
    : `${from.format('YYYY')} - ${to.format('YYYY')}`;

  let periodStr = '';

  const fromMonth = from.month();
  const toMonth = to.month();

  if (toMonth === fromMonth) {
    periodStr = `${from.format('DD MMM')} - ${to.format('DD MMM')}`;
  } else {
    periodStr = new Array(
      toMonth > fromMonth
        ? toMonth - fromMonth
        : toMonth + 12 - fromMonth,
    )
      .fill(from)
      .reduce((period, date, i) => `${period}${moment(date).add(i, 'M').format('MMMM').slice(0, 1)}`, '');
  }

  return `${quarter !== 4 ? quarter + 1 : 1}. ${periodStr} ${yearsStr}`;
};

export const getFormattedQuarterDate = (date: moment.Moment): string => getFormattedRangeDate({
  from: date.toString(),
  to: moment(date.toString()).add(3, 'M').toString(),
});

export const transformShift = (shift: string): string => {
  const [start, end] = shift.split('-', 2);
  return `${moment.utc(start, 'hh:mma').local().format('hh:mma')}-${moment.utc(end, 'hh:mma').local().format('hh:mma')}`;
};

export const getFromFormattedQuarterCurrentDate = (date: moment.Moment = moment()): moment.Moment => {
  const currentMonth = moment(date).month();
  const currentQuarterNumber = Math.floor(currentMonth / 3);
  const firstMonthCurrentQuarter = moment(date).month(currentQuarterNumber * 3).day(1);

  return firstMonthCurrentQuarter;
};

export const getStartOfMonthAndDayDate = (date?: string | moment.Moment) => moment(date)
  .startOf('month')
  .startOf('day')
  .utc()
  .format();

export const getEndOfMonthAndDayDate = (date?: string | moment.Moment) => moment(date)
  .endOf('month')
  .endOf('day')
  .utc()
  .format();

export const convertLocaleDateToTimezone = (timezone: string, date?: string) => moment(date)
  .utcOffset(0, true)
  .tz(timezone, true)
  .utc()
  .format();

export const getFormattedMinutes = (date?: string): number | undefined => {
  if (!date) {
    return undefined;
  }
  const time = date && date.split(':');
  return Number(time[0]) * 60 + Number(time[1]);
};

export const getTimeFromMinutes = (minutes: number): string => {
  const hours = String(Math.floor(minutes / 60)).padStart(2, '0');
  const min = String(minutes % 60).padStart(2, '0');

  return `${hours}:${min}`;
};
