import React from 'react';
import {
  Form,
  FormItemProps,
  InputNumber as AntdInputNumber,
  InputNumberProps as AntdInputNumberProps,
} from '@/components/antd';

import { Ii18nLabel } from '@/types/common';

import { getFormItemLabel } from './utils';

interface InputProps extends FormItemProps, AntdInputNumberProps, Ii18nLabel {
  name: string;
  children: any;
  onReset: any;
  formState: any;
}

export const InputNumber = (
  {
    name,
    label,
    i18nLabel,
    rules,
    min,
    max,
    disabled,
    formatter,
    parser,
    formState,
    ...props
  }: InputProps,
) => {
  const fieldLabel = getFormItemLabel(i18nLabel, label);

  return (
    <Form.Item name={name} label={fieldLabel} rules={rules}>
      <AntdInputNumber
        disabled={disabled}
        min={min}
        max={max}
        formatter={formatter}
        parser={parser}
        type="number"
        {...props}
      />
    </Form.Item>
  );
};
