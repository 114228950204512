export const fieldsWithFilterOperator = [
  'workingHours',
  'workingHoursFloat',
  'overtimeHoursFloat',
  'lateArrivalBy',
  'earlyDepartureBy',
  'checkInTurnStile',
  'checkOutTurnStile',
  'attendance',
  'timeWorkedUnder10',
  'lateByCount',
  'earlyByCount',
  'shiftReportId',
  'grossTime',
  'netTime',
  'idleTime',
  'billableTimeHours',
  'billableTimeHoursFloat',
  'grossTimeHours',
  'grossTimeHoursFloat',
  'timesOverAllowedBreak',
  // shift-planning
  'additionalTime',

  // timesheet
  'workingBillableTime',
  'brakePaidTime',
  'bathPaidTime',
  'lunchPaidTime',
  'transitPaidTime',
  'totalAdditionTime',
  'totalBillableTime',
  'baseBillableTime',
  'overtimeTime',
  'baseBillingAmount',
  'overtimeBillingAmount',
  'totalAmount',
];

export const searchedFields = [
  'name',
  'firstName',
  'middleName',
  'lastName',
  'color',
  'visitorId',
  'shiftAssigned',
  'colorActual',
  'employeeId',
  'wiegangId',
  'occupation',
  'terminateDescription',
  'parkingTag',
  'email',
  'phone',
  'statusUpdatedBy',
  'approval',
  'serialNumber',
  'seriesNumber',
  'code1',
  'code2',
  'code3',
  'code4',
  'code5',
  'comment',
  // shift-planning
  'title',
  'description',
  // timesheet
  'agencyInternalId',
  'approverName',
];

export const excludedFields = [
  'format',
  'locker',
  'actualBuildingId',
  'employeeStatus',
  'date',
  'start',
  'end',
  'startDate',
  'to',
  'from',
  'birthday',
  'statusUpdatedOn',
  'dateLockerAssigned',
  'registrationDate',
  // shift-planning
  'eventStart',
  'eventEnd',
  'effectiveDate',
  'musterStationCaptainId',
];

export const multipleFiltersFields = [
  'agencyId',
  'areaId',
  'breakTimeId',
  'buildingId',
  'checkInTurnStile',
  'checkOutTurnStile',
  'colorAssignedId',
  'colorId',
  'invitationLanguageId',
  'musterStationId',
  'lineId',
  'positionId',
  'shiftBuildingId',
  'shiftId',
  'shiftPlannedId',
  'status',
  'supervisorId',
  'workerBuildingId',
  'buildingsAreasId',
  'actualBuildingId',
  'employeeAreaId'
];

export const getFilters = (filters: any) => {
  const oldFilters = Object.entries(filters)
    .reduce((acc, [key, value]) => {
      if (!fieldsWithFilterOperator.includes(key) && !multipleFiltersFields.includes(key)) {
        acc[key] = value;
      }

      return acc;
    }, {});

  const formattedFilters = Object.entries(filters)
    .filter(([key]) => fieldsWithFilterOperator.includes(key))
    .map(([key, value]) => {
      if (Array.isArray(value) && value.length > 1) {
        const queryOperator = value[0].split(':')[0];
        const queryString = value.map((item) => item.split(':')[1]).join(':or:');

        return `${key}:${queryOperator}:${queryString}`;
      }
      return `${key}:${value && value[0]}`;
    })
    .join(';');

  const multipleFilters = Object.entries(filters)
    .filter(([key, value]) => multipleFiltersFields.includes(key) && !fieldsWithFilterOperator.includes(key) && value && Array.isArray(value) && value.join(''))
    .map(([key, value]) => `${key}:eq:${value && Array.isArray(value) && value.join(':or:')}`)
    .join(';');

  return {
    ...oldFilters,
    filters: formattedFilters.length ? `${formattedFilters};${multipleFilters}` : `${multipleFilters}`,
  };
};

export const getFiltersNewFormat = (filters: any) => {
  const oldFilters = Object.entries(filters)
    .reduce((acc, [key, value]) => {
      if (excludedFields.includes(key)) {
        acc[key] = value;
      }

      return acc;
    }, {});

  const formattedFilters = Object.entries(filters)
    .filter(([key, value]) => !excludedFields.includes(key) && value && Array.isArray(value) && value.join(''))
    .map(([key, value]) => {
      if (fieldsWithFilterOperator.includes(key)) {
        if (Array.isArray(value) && value.length > 1) {
          const queryOperator = value[0].split(':')[0];
          const queryString = value.map((item) => item.split(':')[1]).join(':or:');

          return `${key}:${queryOperator}:${queryString}`;
        }
        return `${key}:${value && value[0]}`;
      }
      if (searchedFields.includes(key)) {
        return `${key}:incl:${value && value[0]}`;
      }
      return `${key}:eq:${value && Array.isArray(value) && value.join(':or:')}`;
    })
    .join(';');

  return {
    ...oldFilters,
    filters: formattedFilters,
  };
};
