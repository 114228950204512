import { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnsType } from 'antd/lib/table/interface';

import moment from 'moment';
import { api } from '@/api';
import { getGenericFilters, toQueryFormat } from '@/components/table/utils';
import {
  billingsDetailsStore,
  buildingStore,
  linesStore,
  musterStationsStore,
  shiftStore,
  settingsStore,
} from '@/stores';
import { showError } from '@/utils/common';
import { numericSort } from '@/utils/sort';

export const useFormDataById = <T>(uid: string, entity: string, formatFn = null) => {
  const [formData, setFormData] = useState<T>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!uid) {
      return;
    }

    setLoading(true);

    api[entity]
      .get(uid)
      .source.then((item) => setFormData(formatFn ? formatFn(item.data) : item.data))
      .catch(showError)
      .finally(() => setLoading(false));
  }, [uid, entity, formatFn]);
  return { formData, loading };
};

export const useTableDataByKey = (id: string | any, additionalStore: any, columns: ColumnsType) => {
  const [loading, setLoading] = useState<boolean>(false);
  const genericFilters = useMemo(() => getGenericFilters(columns), []);

  const params = typeof id === 'string' ? { id } : id;

  const dataSource = [...additionalStore.items];

  useEffect(() => {
    if (!id) {
      return;
    }

    setLoading(true);
    additionalStore
      .forceLoad({ ...params, skip: 0, limit: settingsStore.itemsPerPage })
      .catch(showError)
      .finally(() => setLoading(false));
  }, []);

  const onChangeTable = (...args) => {
    const newParams = toQueryFormat.apply(null, [genericFilters, ...args]);
    const fillStoreArgs = [{ ...params, ...newParams }];
    additionalStore.forceLoad(...fillStoreArgs);
  };

  return {
    additionalStore,
    dataSource,
    loading,
    onChangeTable,
  };
};

const store = billingsDetailsStore;

export const useReportFormData = <T>(
  params: mpg.api.workingLogs.WorkingLogReport,
  columns: any,
  afterLoadDictionaries = true,
) => {
  const [formData] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const genericFilters = useMemo(() => getGenericFilters(columns), []);

  const dataSource = [...store.items];

  useEffect(() => {
    if (!params || !afterLoadDictionaries) {
      return;
    }

    setLoading(true);
    store
      .forceLoad({ ...params, skip: 0, limit: settingsStore.itemsPerPage })
      .catch(showError)
      .finally(() => setLoading(false));
  }, [afterLoadDictionaries]);

  const onChangeTable = (...args) => {
    const newParams = toQueryFormat.apply(null, [genericFilters, ...args]);
    const fillStoreArgs = [{ ...params, ...newParams }];
    store.forceLoad(...fillStoreArgs);
  };

  return {
    formData,
    loading,
    onChangeTable,
    dataSource,
    store,
  };
};

export const useWeeks = () => {
  const [weeks, setWeeks] = useState<string[]>([]);

  useEffect(() => {
    api.workingLogs.getReportWeekOfs().source.then(({ data }) => setWeeks(data.items));
  }, []);

  return weeks;
};

export const useTimesheetWeeks = () => {
  const [weeks, setWeeks] = useState<number[]>([]);

  useEffect(() => {
    api.weekTimesheet
      .getWeekOfs()
      .source.then(({ data }) => data.items.sort((a, b) => b - a))
      .then((items) => setWeeks(items));
  }, []);

  return weeks;
};

export const useCheckInTurnStiles = () => {
  const [turnStiles, setTurnStiles] = useState<string[]>([]);

  useEffect(() => {
    api.workingLogs.getReportCheckInTurnStiles().source.then(({ data }) => setTurnStiles(data.items));
  }, []);

  return turnStiles;
};

export const useCheckOutTurnStiles = () => {
  const [turnStiles, setTurnStiles] = useState<string[]>([]);

  useEffect(() => {
    api.workingLogs.getReportCheckOutTurnStiles().source.then(({ data }) => setTurnStiles(data.items));
  }, []);

  return turnStiles;
};

export const useBuildingsDependency = (initValues) => {
  const [musterStationsOptions, setMusterStationsOptions] = useState<any[]>([]);
  const [linesOptions, setLinesOptions] = useState<any[]>([]);
  const [shiftsOptions, setShiftsOptions] = useState<any[]>([]);

  useEffect(() => {
    const commonInitOptions = (mappedStore, filterGetter= undefined, filterValue = undefined, withAdditionalInfo = true) =>
      mappedStore.items
        .filter((item) => filterGetter ? item[filterGetter] === filterValue && item.buildingId === initValues.buildingId : item.buildingId === initValues.buildingId)
        .sort((a, b) => numericSort(a.name, b.name))
        .map((item) => {
          const additionalInfo = withAdditionalInfo && buildingStore?.items?.find(({ id }) => id === item.buildingId)
          return {
            value: item.id,
            label: additionalInfo ? `${item.name} (${additionalInfo.name})` : item.name,
            text: additionalInfo ? `${item.name} (${additionalInfo.name})` : item.name,
          };
        });
    const initMusterStationsOptions = commonInitOptions(musterStationsStore);
    setMusterStationsOptions(initMusterStationsOptions);

    const initLinesOptions = commonInitOptions(linesStore);
    setLinesOptions(initLinesOptions);

    const initShiftsOptions = commonInitOptions(shiftStore, 'active', true, false);
    setShiftsOptions(initShiftsOptions);
  }, [initValues.buildingId,musterStationsStore.loading,linesStore.loading, shiftStore.loading]);

  const onChangeBuilding = (id: mpg.api.buildings.BuildingId) => {
    const commonOptionsMapper = (mappedStore, filterGetter = undefined, filterValue = undefined, withAdditionalInfo = true) =>
      mappedStore.items
        .filter((item) => filterGetter ? item[filterGetter] === filterValue && item.buildingId === id : item.buildingId === id)
        .sort((a, b) => numericSort(a.name, b.name))
        .map((item) => {
          const additionalInfo = withAdditionalInfo && buildingStore?.items?.find(
            ({ id: buildingItemId }) => buildingItemId === item.buildingId,
          );

          return {
            value: item.id,
            label: additionalInfo ? `${item.name} (${additionalInfo.name})` : item.name,
            text: additionalInfo ? `${item.name} (${additionalInfo.name})` : item.name,
          };
        });
    const newMusterStationsOptions = commonOptionsMapper(musterStationsStore);
    setMusterStationsOptions(newMusterStationsOptions);

    const newLinesOptions = commonOptionsMapper(linesStore);
    setLinesOptions(newLinesOptions);

    const newShiftsOptions = commonOptionsMapper(shiftStore, 'active', true, false);
    setShiftsOptions(newShiftsOptions);
  };

  return {
    linesOptions,
    musterStationsOptions,
    shiftsOptions,
    onChangeBuilding,
  };
};

export const useCurrentBuildingConfig = (buildingId: string, weekOf: number) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<mpg.api.buildingConfig.BuildingConfig>();

  const loadBuildingConfig = useCallback(() => {
    setLoading(true);

    api.buildingConfig
      .getAll({
        effectiveDate: [moment(0).format('YYYY-MM-DD'), moment(`${weekOf}`, 'YYYYMMDD').format('YYYY-MM-DD')],
        buildingId: [buildingId],
        limit: 1,
        sort: 'effectiveDate:-1',
      })
      .source.then((response) => {
        setData(response?.data?.items?.[0]);
      })
      .catch(showError)
      .finally(() => setLoading(false));
  }, [buildingId, weekOf]);

  useEffect(() => {
    if (!buildingId) {
      return;
    }

    loadBuildingConfig();
  }, [loadBuildingConfig, buildingId]);

  return { loading, buildingConfig: data };
};
