import { UIViewInjectedProps } from '@uirouter/react';
import { EmployeeDetails } from '@/pages/common/components/employees/components/employee-details';
import { PERMISSION_OBJ_EMPLOYEE, PERMISSION_OBJ_EMPLOYEE_MARKUP } from '@/constants/permissions';
import { getFormFields } from '../form/setup';

const entity = 'agency-employees';

export const AgencyEmployeeDetails = ({ transition }: UIViewInjectedProps) => (
  <EmployeeDetails
    entity={entity}
    transition={transition}
    permission={PERMISSION_OBJ_EMPLOYEE}
    markupPermission={PERMISSION_OBJ_EMPLOYEE_MARKUP}
    getFormFields={getFormFields}
  />
);
