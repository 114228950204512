import { UIView, UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { api } from '@/api';
import { Form as AntdForm, Space } from '@/components/antd';
import { DrawerForm } from '@/components/drawer-form';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { Search } from '@/components/header/search';
import { PERMISSION_OBJ_PTO } from '@/constants/permissions';
import {
  agencyStore,
  areaStore,
  colorStore,
  ptoStore,
  searchStore,
  shiftStore,
  supervisorStore,
} from '@/stores';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';
import { useFormDataById } from '@/utils/hooks';
import { useMassUpdateCashedStores } from '@/utils/store';

import { getFormFields } from './setup';
import { getFullDateWithTimeZone } from '@/utils/date';

const store = ptoStore;

type FormValues = Partial<Omit<mpg.api.ptos.PTOParams, 'from' | 'to'>
  & {
    from: moment.Moment,
    to: moment.Moment
  }>;

const normalizeFormValues = (values: FormValues) => {
  const from = values.from.utc().startOf('day').toISOString();
  const to = values.to.utc().endOf('day').toISOString();

  return { ...values, from, to };
};

const DrawerFormHeader = (customAction) => (
  <Space
    className="form-header-extra"
    align="start"
    direction="vertical"
  >
    <span>{getTranslatedString('ptos.chooseEmployee')}</span>
    <div className="form-header-extra-search">
      <Search customAction={customAction} />
    </div>
  </Space>
);

interface IForm extends UIViewInjectedProps {
  entity: string
}

export const Form = observer(({ entity, transition }: IForm) => {
  searchStore.setSearchParams({
    entity: 'employees',
    isShowHeaderSearch: true,
  });

  const isCreate = transition.router.globals.current.name === `base-layout.${entity}.create`;
  const { ptoId } = transition.router.globals.params;
  const { formData, loading: formDataLoading } = useFormDataById<mpg.api.ptos.PTO>(ptoId, 'ptos');
  const [customDisabledSubmit, setCustomDisabledSubmit] = useState(true);
  const [formRef] = AntdForm.useForm();
  const [foundTemporaryEmployee, setFoundTemporaryEmployee] = useState(undefined);

  const { loading } = useMassUpdateCashedStores([
    agencyStore,
    areaStore,
    colorStore,
    shiftStore,
    supervisorStore,
  ]);

  useEffect(() => () => {
    searchStore.clearParams();
  }, []);

  const customAction = async (data) => {
    const { source } = api.employees.get(data.id);
    const { data: response } = await source;
    const { kioskPinCode } = response;

    formRef.setFieldsValue({
      ...response,
      employeeId: kioskPinCode,
    });

    setFoundTemporaryEmployee(response);

    const hasError = formRef.getFieldsError().some(({ errors }) => Boolean(errors.length));

    if (!hasError) {
      setCustomDisabledSubmit(false);
    }
  };

  const onBack = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };

  const initValues = isCreate
    ? {}
    : !isCreate && formData
    && {
      ...formData,
      from: formData.from && moment.utc(getFullDateWithTimeZone(formData.from, formData.timezone)),
      to: formData.to && moment.utc(getFullDateWithTimeZone(formData.to, formData.timezone)),
    };

  const { firstName = '', lastName = '' } = formData || {};

  const title = isCreate
    ? getTranslatedString('ptos.create-new')
    : `${firstName} ${lastName}`;

  const disabledDatesTo = (current) => current && current < moment(formRef.getFieldValue('from') || formData?.from).startOf('day');

  const formFields = getFormFields({
    initValues,
    isCreate,
    disabledDatesTo,
  });

  const allowedFields = getAllowedFormFields(formFields, isCreate, PERMISSION_OBJ_PTO);

  const resourceController = withoutRepeatValue(initValues, {
    create: (values: FormValues) => api.ptos
      .create({ ...normalizeFormValues(values), employeeId: foundTemporaryEmployee.id })
      .source
      .then(() => store.refresh()),
    update: (values: FormValues) => api.ptos.update(formData && formData.id, {
      ...normalizeFormValues(values),
    }).source
      .then(() => store.refresh()),
  });

  const onFieldsChange = (_, all: any[]) => {
    const disable = all.some(({ errors }: any) => Boolean(errors.length));
    if (customDisabledSubmit !== disable) {
      setCustomDisabledSubmit(disable);
    }
  };

  return (
    <>
      <DrawerForm
        form={formRef}
        resourceId={ptoId}
        className="form-grid-wrapper"
        title={title}
        initValues={initValues}
        onFieldsChange={onFieldsChange}
        formFields={allowedFields}
        customDisabled={customDisabledSubmit}
        resourceController={resourceController}
        loaderCondition={formDataLoading || loading}
        layout="vertical"
        onClose={onBack}
        extra={isCreate && [
          <DrawerFormHeader
            key="drawer-form-header"
            customAction={customAction}
          />,
        ]}
        headerStyle={{ flexDirection: 'column', alignItems: 'flex-start' }}
      />
      <UIView />
    </>
  );
});


