import { api } from '@/api';
import { permanentEmployeeShiftWorkStore } from '@/stores';
import { parseSettings } from './csv-parser-setup';

export const usePermanentShiftWorkBulkCreate = () => {
  const importRequest = (parsedItems: mpg.api.permanentEmployees.PermanentEmployeeParams[]) => api.permanentEmployeesShiftWork.bulkCreate({ items: parsedItems }).source;

  const storeRefreshRequest = () => permanentEmployeeShiftWorkStore.refresh();

  return {
    importRequest,
    parseSettings,
    storeRefreshRequest,
  };
};
