import { useState, useEffect, useCallback } from 'react';
import moment, { Moment } from 'moment/moment';

import { getArrayDaysFromRange } from './utils';

enum Key {
  Shift = 'Shift',
  Command = 'Meta',
  Control = 'Control',
}

export const useKeysPressed = () => {
  const [isShiftPressed, setIsShiftPressed] = useState<boolean>(false);
  const [isCtrlPressed, setIsCtrlPressed] = useState<boolean>(false);

  useEffect(() => {
    const selectStartFn = (event: KeyboardEvent) => {
      document.onselectstart = () => !(event.key === Key.Shift && event.shiftKey);
    };

    const handleKey = (event: KeyboardEvent, isPressed: boolean) => {
      if (event.key === Key.Shift) {
        setIsShiftPressed(isPressed);
      }

      if (event.key === Key.Command || event.key === Key.Control) {
        setIsCtrlPressed(isPressed);
      }

      selectStartFn(event);
    };

    document.addEventListener('keydown', (e) => handleKey(e, true));
    document.addEventListener('keyup', (e) => handleKey(e, false));

    return () => {
      document.removeEventListener('keydown', (e) => handleKey(e, true));
      document.removeEventListener('keyup', (e) => handleKey(e, false));
    };
  }, []);

  return {
    isCtrlPressed,
    isShiftPressed,
  };
};

interface UseSelectProps {
  isCtrlPressed: boolean;
  isShiftPressed: boolean;
  onMultiSelect?: (selectedDates: Array<Moment>) => void;
}

export const useSelect = ({ isCtrlPressed, isShiftPressed, onMultiSelect }: UseSelectProps) => {
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [selectedDates, setSelectedDates] = useState<Array<Moment>>([]);

  useEffect(() => {
    onMultiSelect && onMultiSelect(selectedDates);
  }, [selectedDates]);

  useEffect(() => {
    const cells = document.getElementsByClassName('ant-picker-cell') as HTMLCollectionOf<Element & { title: string, date: string }>;

    for (let i = 0; i < cells.length; i++) {
      if (cells[i].hasAttribute('title')) {
        cells[i].setAttribute('date', cells[i]?.title);
        cells[i].removeAttribute('title');
      }

      const isSelectedCell = selectedDates
        .some((selectedDate) => selectedDate
          .isSame(moment(cells[i]?.getAttribute('date')), 'day'));

      const classListFn = isSelectedCell ? 'add' : 'remove';
      cells[i].classList[classListFn]('ant-picker-cell-selected');
    }
  }, [selectedDates, startDate]);

  const onSelect = useCallback((selectedDate: Moment) => {
    if (isShiftPressed && startDate) {
      const days = getArrayDaysFromRange(startDate, selectedDate);
      return setSelectedDates(days);
    }

    if (isCtrlPressed) {
      const alreadySelected = selectedDates.some((date) => date.isSame(selectedDate));
      return setSelectedDates((prev) => (alreadySelected
        ? prev.filter((date) => !date.isSame(selectedDate))
        : [...prev, selectedDate]));
    }

    setStartDate(selectedDate);
    setSelectedDates([selectedDate]);
  }, [isShiftPressed, isCtrlPressed, startDate, selectedDates]);

  return { onSelect };
};

interface UseDbClickProps {
  handleDbClick?: (date: moment.Moment, event: Event) => void;
}

export const useDbClick = ({ handleDbClick }: UseDbClickProps) => {
  useEffect(() => {
    const cells = document.getElementsByClassName('ant-picker-cell') as HTMLCollectionOf<Element & { date: string }>;

    const setupDbClickListeners = (method: string) => {
      for (let i = 0; i < cells.length; i++) {
        const date = cells[i]?.getAttribute('date');
        cells[i][method]('dblclick', (event: Event) => handleDbClick(moment(date), event));
      }
    };

    setupDbClickListeners('addEventListener');

    return () => {
      setupDbClickListeners('removeEventListener');
    };
  }, []);
};
