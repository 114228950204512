import { useEffect, useState } from 'react';
import { downloadBlobFile, getTranslatedString } from '@/utils';
import { showError } from '@/utils/common';
import { message } from '@/components/antd';
import { searchStore } from '@/stores';
import { ApiResponse, WrappedResult } from '@/api/types';

export const useExport = (
  store: any,
  exportToExcel: (params?: any) => ApiResponse<WrappedResult<Blob>>,
  fileName: string,
  exportToEmail?: (params?: any) => ApiResponse<WrappedResult<Blob>>,
  extraParams?: { [key: string]: string },
) => {
  const [exportMode, setExportMode] = useState(null);
  const [exportButtonDisabled, setExportButtonDisabled] = useState(false);
  let disableExportButtonTimer;
  useEffect(
    () => () => {
      clearTimeout(disableExportButtonTimer);
      searchStore.clearParams();
    },
    [],
  );
  const onFileExport = (format: string, params) => {
    const fileNames = {
      csv: `${fileName}.csv`,
      xlsx: `${fileName}.xlsx`,
    };
    setExportMode(format);
    return exportToExcel({ ...params, format })
      .source.then((data) => {
        downloadBlobFile(data, fileNames[format]);
      })
      .catch(showError)
      .finally(() => {
        setExportMode(null);
      });
  };

  const onLinkExport = async (format: string, params) => {
    try {
      setExportMode(format);
      setExportButtonDisabled(true);
      disableExportButtonTimer = setTimeout(() => {
        setExportButtonDisabled(false);
      }, 30000);
      await exportToEmail({ ...params, format });
      message.success(getTranslatedString('shiftPlanning.sendToEmailMessage'));
    } catch (err) {
      showError(err);
    } finally {
      setExportMode(null);
    }
  };

  const onExport = async (format: string, additionalParams?: { [key: string]: any }, count?: string) => {
    const { skip, limit, ...storeParams } = store.params;
    const params = { ...storeParams, ...additionalParams, ...extraParams };
    if (exportToEmail) {
      if (count || store.count <= 15000) {
        await onFileExport(format, params);
      } else {
        await onLinkExport(format, params);
      }
    } else {
      await onFileExport(format, params);
    }
  };
  return { exportMode, onExport, exportButtonDisabled };
};
