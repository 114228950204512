import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Space } from '@/components/antd';

import { api } from '@/api';
import {
  DeleteAction,
  EditAction,
} from '@/components/table/actions';
import {
  getColumnSearchFields,
  getColumnDateFields,
  getColumnBooleanFields,
} from '@/components/table/filters';
import {
  PERMISSION_DELETE,
  PERMISSION_OBJ_COORDINATOR_STAFFING,
  PERMISSION_OBJ_PERMANENT_EMPLOYEE,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { permanentEmployeeStandardHoursStore } from '@/stores';
import { buildFilterOptionsFromEnum, showError } from '@/utils/common';
import { getDateWithoutTime, getFormattedDate } from '@/utils/moment';
import { EmployeeStatus } from '@/types/enums';
import { lockerFormatted } from '@/utils/locker';
import { BooleanValueRender } from '@/components/table/renders';

const PermissionedEditAction = withPermissions([
  [PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ],
  [PERMISSION_OBJ_PERMANENT_EMPLOYEE, PERMISSION_UPDATE],
])(EditAction);
const PermissionedDeleteAction = withPermissions([
  [PERMISSION_OBJ_COORDINATOR_STAFFING, PERMISSION_READ],
  [PERMISSION_OBJ_PERMANENT_EMPLOYEE, PERMISSION_DELETE],
])(DeleteAction);

const Actions = (_: any, { id }: any) => {
  const onDelete = () => {
    api.permanentEmployeesStandardHours
      .delete(id)
      .source.then(() => permanentEmployeeStandardHoursStore.refresh())
      .catch(showError);
  };

  return (
    <Space>
      <PermissionedEditAction
        uiSref={{
          to: 'base-layout.coordinator-permanentsStandardHours.edit',
          params: { permanentStandardHoursId: id },
        }}
      />
      <PermissionedDeleteAction onConfirm={onDelete} />
    </Space>
  );
};

export const PermEmployeeStatus = {
  [EmployeeStatus.New]: EmployeeStatus.New,
  [EmployeeStatus.Perm]: EmployeeStatus.Perm,
  [EmployeeStatus.Term]: EmployeeStatus.Term,
};

export const getColumns = () => [
  {
    title: <FormattedMessage id="permanentsStandardHours.employeeId" />,
    dataIndex: 'employeeId',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="permanentsStandardHours.wiegangId" />,
    dataIndex: 'wiegangId',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="permanentsStandardHours.employeeType" />,
    dataIndex: 'employeeType',
    render: (employeeType: string) => <FormattedMessage id={`permanentsStandardHours.employeeType.${employeeType}`} />,
  },
  {
    title: <FormattedMessage id="permanentsStandardHours.workSchedule" />,
    dataIndex: 'workSchedule',
    render: (workSchedule: string) => <FormattedMessage id={`permanentsStandardHours.workSchedule.${workSchedule}`} />,
  },
  {
    title: <FormattedMessage id="permanentsStandardHours.status" />,
    dataIndex: 'status',
    filters: buildFilterOptionsFromEnum(PermEmployeeStatus, 'permanentsStandardHours.status.'),
    filterMultiple: true,
    sorter: true,
    render: (status: string) => <FormattedMessage id={`permanentsStandardHours.status.${status}`} />,
  },
  {
    title: <FormattedMessage id="permanentsStandardHours.firstName" />,
    dataIndex: 'firstName',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="permanentsStandardHours.middleName" />,
    dataIndex: 'middleName',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="permanentsStandardHours.lastName" />,
    dataIndex: 'lastName',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="permanentsStandardHours.email" />,
    dataIndex: 'email',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="permanentsStandardHours.phone" />,
    dataIndex: 'phone',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="permanentsStandardHours.company" />,
    dataIndex: 'company',
  },
  {
    title: <FormattedMessage id="permanentsStandardHours.occupation" />,
    dataIndex: 'occupation',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="permanentsStandardHours.isMusterStationCaptain" />,
    dataIndex: 'isMusterStationCaptain',
    sorter: true,
    render: BooleanValueRender,
    ...getColumnBooleanFields(),
    permissions: [['permanentEmployee.isMusterStationCaptain', PERMISSION_READ]]
  },
  {
    title: <FormattedMessage id="permanentsStandardHours.startDate" />,
    dataIndex: 'startDate',
    sorter: true,
    ...getColumnDateFields(),
    render: (item: string) => getDateWithoutTime(item),
  },
  {
    title: <FormattedMessage id="permanentsStandardHours.locker" />,
    dataIndex: 'locker',
    sorter: true,
    ...getColumnSearchFields(),
    render: lockerFormatted,
  },
  {
    title: <FormattedMessage id="permanentsStandardHours.birthday" />,
    dataIndex: 'birthday',
    ...getColumnDateFields(),
    sorter: true,
    render: (item: string) => getDateWithoutTime(item),
  },
  {
    title: <FormattedMessage id="permanentsStandardHours.termDescription" />,
    dataIndex: 'terminateDescription',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="permanentsStandardHours.parkingTag" />,
    dataIndex: 'parkingTag',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="permanentsStandardHours.statusUpdatedBy" />,
    dataIndex: 'statusUpdatedBy',
    ...getColumnSearchFields(),
    sorter: true,
  },
  {
    title: <FormattedMessage id="permanentsStandardHours.statusUpdatedOn" />,
    dataIndex: 'statusUpdatedOn',
    sorter: true,
    render: (value) => getFormattedDate(value),
    ...getColumnDateFields(true),
  },
  {
    title: <FormattedMessage id="table.actions" />,
    dataIndex: 'actions',
    render: Actions,
  },
];
