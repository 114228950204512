import {
  areaStore,
  breaktimeStore,
  buildingStore,
  colorStore,
  languageStore,
  linesStore,
  musterStationCaptainsStore,
  musterStationsStore,
  positionStore,
  shiftStore,
  supervisorStore,
} from '@/stores';

import { findItemIdByLabel } from '@/utils/common';
import { FieldDescription, ParseSettings } from '@/components/import-button';
import { EmployeeType, WorkScheduleType } from '@/types/enums';

const booleanMap = (e) => {
  if (e === 'TRUE') {
    return true;
  }
  if (e === 'FALSE') {
    return false;
  }
};

export const fields: FieldDescription[] = [
  {
    name: 'employeeId',
  },
  {
    name: 'employeeType',
    svgMap: () => EmployeeType.Permanent,
  },
  {
    name: 'workSchedule',
    svgMap: () => WorkScheduleType.ShiftWork,
  },
  {
    name: 'status',
  },
  {
    name: 'firstName',
  },
  {
    name: 'middleName',
  },
  {
    name: 'lastName',
  },
  {
    name: 'email',
  },
  {
    name: 'phone',
  },
  {
    name: 'company',
  },
  {
    name: 'startDate',
  },
  {
    name: 'areaId',
    svgMap: (e: string) => findItemIdByLabel(areaStore, 'name', e),
  },
  {
    name: 'positionId',
    svgMap: (e: string) => findItemIdByLabel(positionStore, 'name', e),
  },
  {
    name: 'supervisorId',
    svgMap: (e: string) => findItemIdByLabel(supervisorStore, 'name', e),
  },
  {
    name: 'shiftId',
    svgMap: (e: string, buildingName: string) => findItemIdByLabel(shiftStore, 'name', e, buildingStore, 'buildingId', buildingName),
    relatedToField: 'buildingId',
  },
  {
    name: 'colorId',
    svgMap: (e: string) => findItemIdByLabel(colorStore, 'name', e),
  },
  {
    name: 'lineId',
    svgMap: (e: string, re: string) => findItemIdByLabel(linesStore, 'name', e, buildingStore, 'buildingId', re),
    relatedToField: 'buildingId',
  },
  {
    name: 'buildingId',
    svgMap: (e: string) => findItemIdByLabel(buildingStore, 'name', e),
  },
  {
    name: 'musterStationId',
    svgMap: (e: string, re: string) => findItemIdByLabel(musterStationsStore, 'name', e, buildingStore, 'buildingId', re),
    relatedToField: 'buildingId',
  },
  {
    name: 'musterStationCaptainId',
    svgMap: (e: string) => findItemIdByLabel(musterStationCaptainsStore, 'name', e),
  },
  {
    name: 'trainedSlitter',
    svgMap: booleanMap,
  },
  {
    name: 'trainedOverwrap',
    svgMap: booleanMap,
  },
  {
    name: 'birthday',
  },
  {
    name: 'terminateDescription',
  },
  {
    name: 'parkingTag',
  },
  {
    name: 'invitationLanguageId',
    svgMap: (e: string) => findItemIdByLabel(languageStore, 'name', e),
  },
  {
    name: 'breakTimes',
    svgMap: (e: string) => findItemIdByLabel(breaktimeStore, 'name', e),
  },
];

export const parseSettings: ParseSettings = fields
  .reduce((acc, {
    name, svgKey, svgMap, svgValidator, relatedToField,
  }: FieldDescription) => {
    acc[svgKey || name] = {
      key: name,
      map: svgMap,
      validator: svgValidator,
      relatedToField,
    };
    return acc;
  }, {});
