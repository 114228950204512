import { UIView, UIViewInjectedProps } from '@uirouter/react';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { Alert, Typography } from 'antd';
import { PageHeader, Button, Row, Col, Space, message } from '@/components/antd';
import { Table } from '@/components/table';
import { employeePerformanceStore } from '@/stores';
import { getColumns } from './setup';
import { getTranslatedString } from '@/utils';
import { SendOutlined } from '@/components/icons';
import { PerformanceReportState } from '@/pages/employee-performance/enums';
import { minutesToStringHours } from '@/utils/time';
import { api } from '@/api';

const store = employeePerformanceStore;

const { Text } = Typography;

interface TableRefValue {
  showSettingsModal: React.MouseEventHandler<HTMLElement>;
}
interface TableRef {
  current: TableRefValue;
}

export const DetailedWeeklyPerformanceReport = observer(({ transition }: UIViewInjectedProps) => {
  const {
    detailedPerformanceReport,
    weeklyPerformanceReport,
    detailedPerformanceLoading,
    clearDetailedReport,
    pin,
    week,
  } = store;
  const [emailError, setEmailError] = useState<string>();
  const [emailButtonLoading, setEmailButtonLoading] = useState(false);
  // {} is needed because it's used in the table/hook
  const tableRef: TableRef = useRef({} as TableRefValue);
  const columns = getColumns();
  const handleGoBack = () => {
    clearDetailedReport();
    transition.router.stateService.go(PerformanceReportState.WeeklyReportPage);
  };
  const handleSendEmail = async () => {
    setEmailButtonLoading(true);
    try {
      await api.employeePerformance.sendDetailedWeeklyReportToEmail(pin, week).source;
      setEmailError(undefined);
      message.success(getTranslatedString('employee-performance.send-email-success'));
    } catch (_) {
      setEmailError('employee-performance.send-email-failed-error');
    }
    setEmailButtonLoading(false);
  };
  useEffect(() => {
    if (!detailedPerformanceLoading && !detailedPerformanceReport.length) {
      transition.router.stateService.go(PerformanceReportState.WeeklyReportPage);
    }
  }, []);
  return (
    <>
      <PageHeader
        className="r-antd-page-header"
        extra={[
          <Row justify="space-between" style={{ width: '100%', margin: '0 12px' }}>
            <Col>
              <Space size="large">
                <Button onClick={() => handleGoBack()} type="primary">
                  {getTranslatedString('employee-performance.detailed-weekly-report.to-weekly-report')}
                </Button>
              </Space>
            </Col>
            <Col>
              <Space size="large">
                {weeklyPerformanceReport?.hasEmail && (
                  <Button
                    onClick={handleSendEmail}
                    loading={emailButtonLoading}
                    style={{ width: '100%' }}
                    icon={<SendOutlined />}
                  >
                    {getTranslatedString('employee-performance.send-email')}
                  </Button>
                )}
                <Text>
                  <FormattedMessage id="employee-performance.overtime-hours" />:{' '}
                  <Text strong style={{ fontSize: '16px' }}>
                    {weeklyPerformanceReport && minutesToStringHours(weeklyPerformanceReport?.overtimeTime)}
                  </Text>
                </Text>
              </Space>
            </Col>
          </Row>,
        ]}
      />

      {emailError ? <Alert message={getTranslatedString(emailError)} type="error" /> : null}

      <Table
        tableRef={tableRef}
        localStorageKey="performance-report-table"
        rowKey="id"
        columns={columns}
        dataSource={detailedPerformanceReport}
        store={store as any}
        checkPermissions
        loading={detailedPerformanceLoading}
        scroll={{ y: '100%' }}
      />
      <UIView />
    </>
  );
});
