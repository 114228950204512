import { useRef } from 'react';
import { UIView, UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import { getColumns } from './setup';
import { PERMISSION_CREATE, PERMISSION_BUILDING_HOST } from '@/constants/permissions';
import { Table } from '@/components/table';
import { buildingHostStore } from '@/stores';
import { Button, PageHeader } from '@/components/antd';
import { withPermissions } from '@/hocs/permission';
import { PlusOutlined } from '@/components/icons';
import { BuildingPageState } from '@/pages/building-host/enums';

interface CreateButtonProps {
  onClick: (e: any) => void;
  loading?: boolean;
  style?: any;
  disabled?: boolean;
}
const CreateButton = withPermissions([[PERMISSION_BUILDING_HOST, PERMISSION_CREATE]])(
  ({ onClick, style = {} }: CreateButtonProps) => (
    <Button style={style} onClick={onClick} type="primary" shape="round" icon={<PlusOutlined />}>
      <span>
        <FormattedMessage id="building-hosts.create-button" />
      </span>
    </Button>
  ),
);

const store = buildingHostStore;

export const BuildingHost = observer(({ transition }: UIViewInjectedProps) => {
  const tableRef: any = useRef({});
  const { items, loading } = store;
  const onCreate = () => {
    transition.router.stateService.go(BuildingPageState.Create);
  };
  const onDoubleClick = (record: mpg.api.buildingHost.BuildingHost) => {
    transition.router.stateService.go(BuildingPageState.Update, { buildingHostId: record.id });
  };

  return (
    <>
      <PageHeader className="r-antd-page-header" extra={[<CreateButton key="create button" onClick={onCreate} />]} />
      <Table
        tableRef={tableRef}
        localStorageKey="building-host"
        rowKey="id"
        onRow={(record) => ({
          onDoubleClick: () => onDoubleClick(record),
        })}
        columns={getColumns()}
        dataSource={[...items]}
        store={store}
        loading={loading}
        checkPermissions
        permissionObj={PERMISSION_BUILDING_HOST}
        scroll={{ y: '100%' }}
        isAutoloadAllowed
      />
      <UIView />
    </>
  );
});
