import { UIView, UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React, { useState, useRef } from 'react';
import { TableRowSelection } from 'antd/lib/table/interface';
import { Button, PageHeader, Dropdown, Menu, Modal } from '@/components/antd';
import { DownOutlined, SettingOutlined, PrinterOutlined } from '@/components/icons';

import { Table } from '@/components/table';
import {
  PERMISSION_CREATE,
  PERMISSION_DELETE,
  PERMISSION_OBJ_PTO,
  PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { agencyStore, areaStore, buildingStore, colorStore, ptoStore, shiftStore, supervisorStore } from '@/stores';
import { useMassUpdateCashedStores } from '@/utils/store';
import { onPrint, showError } from '@/utils/common';
import { api } from '@/api';
import { getTranslatedString } from '@/utils';

import { getColumns } from './setup';
import ExportButton from '@/pages/common/components/buttons/export-button';
import { CreateButton, BulkUpdateButton, BulkDeleteButton } from '../buttons';

const store = ptoStore;

interface IForm extends UIViewInjectedProps {
  entity: string;
  pagePermission: string;
}

export const PTOs = observer(({ entity, pagePermission, transition }: IForm) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const tableRef: any = useRef({});
  const { items } = store;

  const { loading } = useMassUpdateCashedStores([
    agencyStore,
    areaStore,
    buildingStore,
    colorStore,
    shiftStore,
    supervisorStore,
  ]);

  const onAdd = () => {
    transition.router.stateService.go(`base-layout.${entity}.create`);
  };

  const onDoubleClick = (record) => {
    transition.router.stateService.go(`base-layout.${entity}.edit`, { ptoId: record.id });
  };

  const onRowSelectionChange = (currentSelectedRowKey: string[]) => {
    setSelectedRowKeys(currentSelectedRowKey);
  };

  const rowSelection: TableRowSelection<any> = {
    // preserveSelectedRowKeys: true,
    onChange: onRowSelectionChange,
  };

  const onBulkUpdate = () => {
    transition.router.stateService.go(`base-layout.${entity}.bulk-edit`, { selectedRowKeys });
  };

  const onBulkDelete = () => {
    const onOk = () =>
      api.ptos
        .bulkDelete({ ids: selectedRowKeys })
        .source.then(() => ptoStore.refresh())
        .catch(showError);

    Modal.confirm({
      title: getTranslatedString('common.warning'),
      content: getTranslatedString('ptos.delete-warning-desc'),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk,
    });
  };

  const batchMenu = (
    <Menu>
      <BulkUpdateButton
        permissions={[
          [pagePermission, PERMISSION_READ],
          [PERMISSION_OBJ_PTO, PERMISSION_UPDATE],
        ]}
        onClick={onBulkUpdate}
      />
      <BulkDeleteButton
        permissions={[
          [pagePermission, PERMISSION_READ],
          [PERMISSION_OBJ_PTO, PERMISSION_DELETE],
        ]}
        onClick={onBulkDelete}
      />
    </Menu>
  );

  return (
    <>
      <PageHeader
        className="r-antd-page-header"
        extra={[
          <Dropdown key="dropdown" overlay={batchMenu} disabled={!selectedRowKeys.length}>
            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              Batch Actions <DownOutlined />
            </a>
          </Dropdown>,
          <Button key="print" size="middle" icon={<PrinterOutlined />} onClick={onPrint} />,
          <Button
            key="setting"
            icon={<SettingOutlined />}
            size="middle"
            onClick={tableRef.current.showSettingsModal}
          />,
          <CreateButton
            key="create button"
            onClick={onAdd}
            i18nId="ptos.add-new"
            permissions={[
              [pagePermission, PERMISSION_READ],
              [PERMISSION_OBJ_PTO, PERMISSION_CREATE],
            ]}
          />,
          <ExportButton
            store={store}
            exportToExcel={api.ptos.exportToExcel}
            exportToEmail={api.ptos.exportToEmail}
            withPermission={withPermissions([[pagePermission, PERMISSION_READ]])}
            fileName="mpg-ptos"
          />,
        ]}
      />

      <Table
        tableRef={tableRef}
        onRow={(record) => ({
          onDoubleClick: () => onDoubleClick(record),
        })}
        localStorageKey="agency-ptos-table"
        rowSelection={rowSelection}
        rowKey="id"
        columns={getColumns(pagePermission, entity)}
        dataSource={[...items]}
        store={store}
        checkPermissions
        permissionObj={PERMISSION_OBJ_PTO}
        loading={store.loading || loading}
        scroll={{ y: '100%' }}
        isAutoloadAllowed={!loading}
        initialSorters={{
          columnKey: 'lastName',
          field: 'lastName',
          order: 'ascend',
        }}
      />
      <UIView />
    </>
  );
});
