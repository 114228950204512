import { EmployeeStatus, EmployeeType, LockerStatus } from '@/types/enums';
import { buildDropdownOptionsFromEnum, buildStoreOptions } from '@/utils/common';
import { buildingStore } from '@/stores';

interface Props {
  initValues: Partial<Omit<mpg.api.lockers.Locker, 'dateLockerAssigned'>
  & {
    dateLockerAssigned: moment.Moment,
  }>;
  isBulkEdit?: boolean;
}

export const getFormFields = ({
  isBulkEdit = false,
}: Props) => [
  {
    name: 'serial',
    componentName: 'input-number',
    conditionalRender: () => !isBulkEdit,
    params: {
      i18nLabel: 'lockers.number',
      disabled: true,
    },
    rules: [
      { required: true },
    ],
  },
  {
    name: 'name',
    componentName: 'input',
    conditionalRender: () => !isBulkEdit,
    params: {
      i18nLabel: 'lockers.name',
    },
    rules: [
      { max: 50 },
    ],
  },
  {
    name: 'employeeId',
    componentName: 'input',
    conditionalRender: () => !isBulkEdit,
    params: {
      i18nLabel: 'lockers.employeeId',
      disabled: true,
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'firstName',
    componentName: 'input',
    conditionalRender: () => !isBulkEdit,
    params: {
      i18nLabel: 'lockers.firstName',
      disabled: true,
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'lastName',
    componentName: 'input',
    conditionalRender: () => !isBulkEdit,
    params: {
      i18nLabel: 'lockers.lastName',
      disabled: true,
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'employeeType',
    componentName: 'dropdown',
    conditionalRender: () => !isBulkEdit,
    params: {
      i18nLabel: 'lockers.employeeType',
      options: buildDropdownOptionsFromEnum(EmployeeType, 'lockers.employeeType.'),
      disabled: true,
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'actualBuildingId',
    componentName: 'dropdown',
    conditionalRender: () => !isBulkEdit,
    params: {
      i18nLabel: 'lockers.employeeBuilding',
      options: buildStoreOptions(buildingStore, 'name'),
      disabled: true,
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'employeeStatus',
    componentName: 'dropdown',
    conditionalRender: () => !isBulkEdit,
    params: {
      i18nLabel: 'lockers.employeeStatus',
      options: buildDropdownOptionsFromEnum(EmployeeStatus, 'employees.status.'),
      disabled: true,
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'dateLockerAssigned',
    componentName: 'datepicker',
    params: {
      i18nLabel: 'lockers.dateLockerAssigned',
      format: 'MM/DD/YYYY',
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'serialNumber',
    componentName: 'input',
    params: {
      i18nLabel: 'lockers.serialNumber',
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'seriesNumber',
    componentName: 'input',
    params: {
      i18nLabel: 'lockers.seriesNumber',
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'code1',
    componentName: 'input',
    params: {
      i18nLabel: 'lockers.code1',
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'code2',
    componentName: 'input',
    params: {
      i18nLabel: 'lockers.code2',
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'code3',
    componentName: 'input',
    params: {
      i18nLabel: 'lockers.code3',
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'code4',
    componentName: 'input',
    params: {
      i18nLabel: 'lockers.code4',
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'code5',
    componentName: 'input',
    params: {
      i18nLabel: 'lockers.code5',
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'status',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'lockers.status',
      options: buildDropdownOptionsFromEnum(LockerStatus, 'lockers.status.'),
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'isAssigned',
    componentName: 'switch',
    conditionalRender: () => !isBulkEdit,
    params: {
      i18nLabel: 'lockers.isAssigned',
      asDropdown: isBulkEdit,
      disabled: true,
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'comment',
    componentName: 'input',
    params: {
      i18nLabel: 'lockers.comment',
    },
    rules: [
      // { required: true },
    ],
  },
];
