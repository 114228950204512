import React from 'react';
import { Tooltip } from '@/components/antd';
import { EventTooltip } from './cell-tooltip';

const Circle = ({ color }) => (
  <span
    className="m-shift-planning-circle"
    style={{
      ['--color' as string]: color,
    }}
  />
);

interface CalendarCellProps {
  filteredDates: any;
  selectedBuilding: mpg.api.buildings.Building;
  handleEvent: (id: string) => void;
}

export const CalendarCell = ({ filteredDates, selectedBuilding, handleEvent }: CalendarCellProps) =>
  filteredDates.map((shiftPlanning) => {
    const { id, title, colors } = shiftPlanning;

    return (
      <Tooltip key={id} title={<EventTooltip shiftPlanning={shiftPlanning} selectedBuilding={selectedBuilding} />}>
        <div id="event" className="m-shift-planning__calendar-cell-event" onDoubleClick={() => handleEvent(id)}>
          <div className="m-shift-planning__calendar-cell-title">{title}</div>
          <div className="m-shift-planning__calendar-cell-colors">
            {colors.map(({ color }) => (
              <Circle color={color} />
            ))}
          </div>
        </div>
      </Tooltip>
    );
  });
