import axios from 'axios';
import qs from 'querystring-es3';

import { API_URL, LOCAL_STORAGE_KEY_ACCESS_TOKEN } from '@/constants';
import { authStore } from '@/stores';

import { getFilters, getFiltersNewFormat } from './filters';
import { convertLocaleDateToTimezone, getDateWithoutTime } from '@/utils/moment';
import { EmployeeType } from '@/types/enums';

const defaultTimeout = 3 * 10 * 60 * 1000;
const defaultBaseURL = API_URL;

export const request = (settings: any) => {
  const {
    method = 'get',
    url,
    baseURL = null,
    data = {},
    headers = {},
    timeout = defaultTimeout,
    params = {},
    withCredentials = false,
    config = {},
    customToken,
  } = settings;

  let cancelToken: any;
  const token = customToken || localStorage.getItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN);
  const auth = headers.Authorization ? headers.Authorization : `Bearer ${token}`;
  const instance = axios.create({
    baseURL: baseURL || defaultBaseURL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
      ...headers,
    },
    timeout,
    withCredentials,
    cancelToken: new axios.CancelToken((c) => (cancelToken = c)),
    paramsSerializer: (params1: any): string => qs.stringify(params1),
    ...config,
  });

  return {
    cancel: cancelToken,
    source: instance
      .request({
        url,
        method,
        params,
        data,
      })
      .then(({ data: respData }: any) => respData)
      .catch((e) => {
        if (e && e.response) {
          const { response } = e;

          if (response.data && response.data.error) {
            const { error } = response.data;
            // Session Expired
            if (error.code === 6) {
              window.location.href = '';
            }
          }

          if (response.status === 401) {
            authStore.logout();
            window.location.href = '';
          }
        }
        throw e;
      }),
  };
};

export const getFormattedParams = (params, isNewFiltersFormat?: boolean) => {
  const { sort, limit, skip, ...filters } = params;
  const formattedFilters = isNewFiltersFormat ? getFiltersNewFormat(filters) : getFilters(filters);
  return {
    sort,
    limit,
    skip,
    ...formattedFilters,
  };
};

export const paramsWithTimeZone = (params: any) => ({
  params: {
    ...params,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

export const getParamsWithMappedSort = (params0: any, paramsMap: any) => {
  const params = { ...(params0 || {}) };

  if (params.sort) {
    const [property, order] = params.sort.split(':');
    const sortProperty = paramsMap[property] || property;
    params.sort = `${sortProperty}:${order}`;
  }

  return params;
};

export const getParamsWithMappedFields = (params: any) => {
  let mappedParams = { ...params };
  if (params.checkInDate) {
    mappedParams = { ...mappedParams, checkInRawDate: params.checkInDate };
    delete mappedParams.checkInDate;
  }
  if (params.checkOutDate) {
    mappedParams = { ...mappedParams, checkOutRawDate: params.checkOutDate };
    delete mappedParams.checkOutDate;
  }
  return mappedParams;
};

export const checkParamsWithStartEndFields = (params: any) => {
  const newParams = { ...params };

  if (!params.start) {
    newParams.start = params.date ? params.date[0] : null;
  }

  if (!params.end) {
    newParams.end = params.date ? params.date[1] : null;
  }

  return newParams;
};

export const getParamsWithMappedBirthdayField = (params: any) => {
  if (params.birthday) {
    if (Array.isArray(params.birthday)) {
      params.birthday = params.birthday.map((item) => (item === 'null' ? item : getDateWithoutTime(item)));
    } else {
      params.birthday = getDateWithoutTime(params.birthday);
    }
  }
  return params;
};

export const getFormattedParamsWithWorkScedule = (data: any, workScheduleType: string) => {
  data?.items?.forEach((param) => {
    param.workSchedule = workScheduleType;
  });

  return data;
};

/**
 * JSON, file and email are sharing the logic on backend side
 * @param url controller segment
 * @param params
 * @param dst UI, file export or link to email
 * @param paramMapper params converter
 * @returns result of request
 */
export const requestTo = <ParamType>(
  url: string,
  params: ParamType,
  dst: '' | 'export' | 'export-by-link',
  paramMapper: (params: ParamType) => any,
) =>
  request({
    method: dst === '' ? 'get' : 'post',
    url: `${url}/${dst}`,
    params: {
      ...paramMapper(params),
      ...(dst !== '' && { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }),
    },
    config: dst === 'export' && { responseType: 'blob' },
  });

export const requestWithoutAuth = (settings: any) => {
  const { method = 'get', url, baseURL = null, data = {}, headers = {}, params = {}, config = {} } = settings;

  const instance = axios.create({
    baseURL: baseURL || defaultBaseURL,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    paramsSerializer: (params1: any): string => qs.stringify(params1),
    ...config,
  });

  return instance
    .request({
      url,
      method,
      params,
      data,
    })
    .then(({ data: respData }: any) => respData);
};

export const toQueryFilter = (params: mpg.api.shiftPlanning.GetParams, employeeType: EmployeeType) => {
  const defaultSort = 'eventStart:1';
  const { building, buildingId, status, ...filterParams } = params;
  const oldFilterParams = {
    buildingId: building?.id,
    status: Array.isArray(status) ? status.join(',') : status,
  };
  const withoutBlankValue = Object.fromEntries(Object.entries(oldFilterParams).filter(([, v]) => Boolean(v)));

  if (filterParams?.eventStart && filterParams.eventStart[0] !== 'null') {
    filterParams.eventStart = [
      convertLocaleDateToTimezone(building.timezone, filterParams.eventStart[0]),
      convertLocaleDateToTimezone(building.timezone, filterParams.eventStart[1]),
    ];
  }
  if (filterParams?.eventEnd && filterParams.eventEnd[0] !== 'null') {
    filterParams.eventEnd = [
      convertLocaleDateToTimezone(building.timezone, filterParams.eventEnd[0]),
      convertLocaleDateToTimezone(building.timezone, filterParams.eventEnd[1]),
    ];
  }

  if (!filterParams?.sort) {
    filterParams.sort = defaultSort;
  }

  const query = new URLSearchParams();
  Object.keys(withoutBlankValue).forEach((key) => {
    query.append(key, withoutBlankValue[key]);
  });
  query.append('employeeType', employeeType)
  return {
    query,
    filterParams,
    withoutBlankValue,
  };
};
