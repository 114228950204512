import { action, makeAutoObservable, observable } from 'mobx';

import { api } from '@/api';

import { DEFAULT_ITEMS_PER_PAGE } from '@/constants';

export class SettingsStore {
  @observable itemsPerPage: number = DEFAULT_ITEMS_PER_PAGE;

  @observable vacationStatus: boolean = false;

  @observable isRecentAgreementSigned: boolean;

  @observable isTwoFactorAuthEnabled: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action setSettings = (settings) => {
    this.itemsPerPage = settings.items_per_page;
    this.isTwoFactorAuthEnabled = settings.twofa_auth_enabled;
  };

  @action setVacationStatus = (status: boolean) => {
    this.vacationStatus = status;
  };

  @action setIsRecentAgreementSigned = (status: boolean) => {
    this.isRecentAgreementSigned = status;
  };

  loadSettings = async () => {
    await api.settings
      .get()
      .source.then(({ data }) => this.setSettings(data))
      .catch();
  };

  loadVacationStatus = async () => {
    await api.users
      .getUserData()
      .source.then(({ data }) => {
        this.setVacationStatus(data.onVacation);
        this.setIsRecentAgreementSigned(data.isRecentAgreementSigned);
      })
      .catch();
  };

  changeItemsPerPage(itemsPerPage) {
    api.settings
      .set({
        key: 'items_per_page',
        value: itemsPerPage,
      })
      .source.then((resp) => {
        if (resp.success) {
          this.setSettings({ items_per_page: itemsPerPage });
        }
      });
  }

  changeVacationStatus(vacationStatus: boolean) {
    this.setVacationStatus(vacationStatus);
    api.users
      .updateUserData({
        onVacation: vacationStatus,
      })
      .source.then((resp) => {
        this.setVacationStatus(resp.data.onVacation);
      });
  }
}
