import { action, makeObservable, observable } from 'mobx';
import { AxiosPromise } from 'axios';
import { showError } from '@/utils/common';

import { ApiResponse, WrappedAxiosItemsResult, WrappedItemsResult } from '@/api/types';

interface IStorage<I, T> {
  data: T[];
  count: number;

  get(): T[];
  set(data): void;
  append(item): void;
  update(item): void;
  delete(id: I): void;
}

export class SimpleStoreStorage<I extends string | number, T extends { id: I }> implements IStorage<I, T> {
  @observable data: T[] | null = null;

  @observable count: number;

  constructor() {
    makeObservable(this);
  }

  get() {
    return this.data;
  }

  @action
  set(data) {
    this.data = data;
  }

  @action
  append(item) {
    this.data.push(item);
  }

  @action
  update(item: T) {
    const index = this.data.findIndex(({ id }: T) => id === item.id);

    if (index !== -1) {
      this.data[index] = item;
    }
  }

  @action
  delete(itemId: I) {
    this.data = this.data.filter(({ id }: T) => id !== itemId);
  }
}

// tslint:disable-next-line:max-classes-per-file
export class CachedStorage<I extends string | number, T extends { id: I }> extends SimpleStoreStorage<I, T> {
  private readonly factorInSeconds: number = 1000;

  private lastUpdate: number = 0;

  // todo change default life time when will fix the store (10 min)
  constructor(private readonly lifetimeSeconds: number = 10) {
    super();
  }

  expired(): boolean {
    return (Date.now() - this.lastUpdate) > (this.lifetimeSeconds * this.factorInSeconds);
  }

  get() {
    if (this.expired() || !this.data) {
      this.lastUpdate = Date.now();
      return null;
    }
    return this.data;
  }
}

interface IStoreDataLoader<P> {
  loading: boolean;
  params: P;
  load(params?: P);
  refresh();
}

// tslint:disable-next-line:max-classes-per-file
export class DummyDataLoader<P> implements IStoreDataLoader<P> {
  @observable loading: boolean = false;

  params: P;

  constructor() {
    makeObservable(this);
  }

  load() {
    return Promise.resolve([]);
  }

  refresh() {
    return Promise.resolve([]);
  }
}

// tslint:disable-next-line:max-classes-per-file
export class StoreDataLoader<T, P> implements IStoreDataLoader<P> {
  @observable loading: boolean = false;

  params: P;

  constructor(
    private readonly loaderFn: (params: P) => ApiResponse<WrappedAxiosItemsResult<T[]>>,
    private readonly formatFn: any = null,
  ) {
    this.formatFn = formatFn;

    makeObservable(this);
  }

  load(params?: P): AxiosPromise<WrappedAxiosItemsResult<T[]>> {
    this.params = params;
    return this.loaderFn(params).source
      .then((res) => (this.formatFn ? this.formatFn(res) : res));
  }

  refresh(): AxiosPromise<WrappedAxiosItemsResult<T[]>> {
    return this.loaderFn(this.params).source
      .then((res) => (this.formatFn ? this.formatFn(res) : res));
  }
}

interface IStoreRelDataLoader<I, P> {
  load(id: I, params?: P);
  loading: boolean;
  refresh();
  params: P;
}

// tslint:disable-next-line:max-classes-per-file
export class RelStoreDataLoader<I, T, P> implements IStoreRelDataLoader<I, P> {
  @observable loading: boolean = false;

  params: P;

  private id: I;

  constructor(private readonly loaderFn: (id: I, params: P) => ApiResponse<WrappedAxiosItemsResult<T[]>>) {
    makeObservable(this);
  }

  load(id: I, params?: P): AxiosPromise<WrappedAxiosItemsResult<T[]>> {
    this.params = params;
    this.id = id;
    return this.loaderFn(id, params).source;
  }

  refresh(): AxiosPromise<WrappedAxiosItemsResult<T[]>> {
    return this.loaderFn(this.id, this.params).source;
  }
}

// tslint:disable-next-line:max-classes-per-file
export class CollectionStore<I, T extends { id: I }, P> {
  constructor(
    private readonly dataLoader: IStoreDataLoader<P>,
    private readonly storage: IStorage<I, T>,
  ) {
  }

  set loading(loading: boolean) {
    this.dataLoader.loading = loading;
  }

  get loading(): boolean {
    return this.dataLoader.loading;
  }

  get params() {
    return this.dataLoader.params;
  }

  get items(): T[] {
    return this.storage.data || [];
  }

  get count(): number {
    return this.storage.count;
  }

  fillStore(items: T[], count?: number): T[] {
    this.storage.set(items);
    this.storage.count = count || items.length;

    return items;
  }

  addItem(item: T): T {
    this.storage.append(item);
    return item;
  }

  updateItem(item: T): T {
    this.storage.update(item);
    return item;
  }

  deleteItem(itemId: I): void {
    this.storage.delete(itemId);
  }

  forceLoad(params?: P): Promise<T[]> {
    this.loading = true;
    return this.dataLoader.load(params)
      .then(({ data }: WrappedItemsResult<T[]>) => this.fillStore(data.items, data.count))
      .catch(showError)
      .finally(() => this.loading = false);
  }

  ensureData(params?: P): Promise<T[]> {
    const result = this.storage.get();
    if (result === null) {
      return this.forceLoad(params);
    }
    return Promise.resolve(result);
  }

  asyncItems(params?: P): Promise<T[]> {
    return this.ensureData(params);
  }

  refresh(): Promise<T[]> {
    this.loading = true;
    return this.dataLoader.refresh()
      .then(({ data }: WrappedItemsResult<T[]>) => this.fillStore(data.items, data.count))
      .catch(showError)
      .finally(() => this.loading = false);
  }

  reset() {
    this.storage.set(null);
  }
}

// tslint:disable-next-line:max-classes-per-file
export class RelCollectionStore<I, T extends { id: I }, P> {
  constructor(
    private readonly dataLoader: IStoreRelDataLoader<I, P>,
    private readonly storage: IStorage<I, T>,
  ) {
  }

  set loading(loading: boolean) {
    this.dataLoader.loading = loading;
  }

  get loading(): boolean {
    return this.dataLoader.loading;
  }

  get params() {
    return this.dataLoader.params;
  }

  get items(): T[] {
    return this.storage.data || [];
  }

  get count(): number {
    return this.storage.count;
  }

  fillStore(items: T[], count?: number): T[] {
    this.storage.set(items);
    this.storage.count = count || items.length;

    return items;
  }

  addItem(item: T): T {
    this.storage.append(item);
    return item;
  }

  updateItem(item: T): T {
    this.storage.update(item);
    return item;
  }

  deleteItem(itemId: I): void {
    this.storage.delete(itemId);
  }

  forceLoad(id: I, params?: P): Promise<T[]> {
    this.loading = true;
    return this.dataLoader.load(id, params)
      .then(({ data }: WrappedItemsResult<T[]>) => this.fillStore(data.items, data.count))
      .catch(showError)
      .finally(() => this.loading = false);
  }

  ensureData(id: I, params?: P): Promise<T[]> {
    const result = this.storage.get();
    if (result === null) {
      return this.forceLoad(id, params);
    }
    return Promise.resolve(result);
  }

  asyncItems(id: I, params?: P): Promise<T[]> {
    return this.ensureData(id, params);
  }

  refresh(): Promise<T[]> {
    this.loading = true;
    return this.dataLoader.refresh()
      .then(({ data }: WrappedItemsResult<T[]>) => this.fillStore(data.items, data.count))
      .catch(showError)
      .finally(() => this.loading = false);
  }
}
