import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  agencyStore,
  breaktimeStore,
  buildingStore,
  buildingsAreasStore,
  colorStore,
  shiftStore,
  supervisorStore,
} from '@/stores';
import {
  buildFilterOptionsForTurnstiles,
  buildFilterOptionsFromEnum,
  buildStoreOptions,
  getRoundDecimalNumber,
  getStorePropById,
  getStoreItemById,
  buildingsAreasFormatFn,
} from '@/utils/common';
import { getColumnDateFields, getColumnSearchFields, getColumnTimeFields } from '@/components/table/filters';
import { EmployeeStatus, EmployeeType, WorkScheduleType } from '@/types/enums';
import { BooleanValueRender } from '@/components/table/renders';
import { formatEventDuration } from '@/utils/data-formatters';

export const getColumns = (weeks = [], checkInTurnStiles = [], checkOutTurnStiles = [], isAdditionalColumnsHidden = false) => {
  const columns = [
    {
      title: <FormattedMessage id="workingLogs.visitorId" />,
      dataIndex: 'visitorId',
      ...getColumnSearchFields(),
      sorter: true,
    },
    {
      title: <FormattedMessage id="workingLogs.employeeType" />,
      dataIndex: 'employeeType',
      filters: buildFilterOptionsFromEnum(EmployeeType, 'workingLogs.employeeType.'),
      filterMultiple: false,
      sorter: true,
      render: (employeeType: string) => <FormattedMessage id={`workingLogs.employeeType.${employeeType}`} />,
      hidden: isAdditionalColumnsHidden,
    },
    {
      title: <FormattedMessage id="workingLogs.workSchedule" />,
      dataIndex: 'workSchedule',
      filters: buildFilterOptionsFromEnum(WorkScheduleType, 'workingLogs.workSchedule.'),
      filterMultiple: false,
      sorter: true,
      render: (workSchedule: string) => <FormattedMessage id={`workingLogs.workSchedule.${workSchedule}`} />,
      hidden: isAdditionalColumnsHidden,
    },
    {
      title: <FormattedMessage id="workingLogs.visitorFirstName" />,
      dataIndex: 'visitorFirstName',
      sorter: true,
      ...getColumnSearchFields(),
    },
    {
      title: <FormattedMessage id="workingLogs.visitorMiddleName" />,
      dataIndex: 'visitorMiddleName',
      sorter: true,
      ...getColumnSearchFields(),
    },
    {
      title: <FormattedMessage id="workingLogs.visitorLastName" />,
      dataIndex: 'visitorLastName',
      sorter: true,
      ...getColumnSearchFields(),
    },
    {
      title: <FormattedMessage id="workingLogs.buildingId" />,
      dataIndex: 'buildingId',
      filters: buildStoreOptions(buildingStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.buildings.Building>(itemId, buildingStore),
    },
    {
      title: <FormattedMessage id="workingLogs.actualBuildingId" />,
      dataIndex: 'actualBuildingId',
      filters: buildStoreOptions(buildingStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.buildings.Building>(itemId, buildingStore),
    },
    {
      title: <FormattedMessage id="workingLogs.buildingsAreasId" />,
      dataIndex: 'buildingsAreasId',
      filters: buildStoreOptions(buildingsAreasStore, buildingsAreasFormatFn),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => buildingsAreasFormatFn(getStoreItemById(itemId, buildingsAreasStore)),
    },
    {
      title: <FormattedMessage id="workingLogs.checkInTurnStile" />,
      dataIndex: 'checkInTurnStile',
      filterMultiple: true,
      filters: buildFilterOptionsForTurnstiles(checkInTurnStiles),
      sorter: true,
    },
    {
      title: <FormattedMessage id="workingLogs.checkInDate" />,
      dataIndex: 'checkInDate',
      sorter: true,
      ...getColumnDateFields(true),
    },
    {
      title: <FormattedMessage id="workingLogs.checkInTime" />,
      dataIndex: 'checkInTime',
      sorter: true,
    },
    {
      title: <FormattedMessage id="workingLogs.checkOutTurnStile" />,
      dataIndex: 'checkOutTurnStile',
      filterMultiple: true,
      filters: buildFilterOptionsForTurnstiles(checkOutTurnStiles),
      sorter: true,
    },
    {
      title: <FormattedMessage id="workingLogs.checkOutDate" />,
      dataIndex: 'checkOutDate',
      sorter: true,
      ...getColumnDateFields(true),
    },
    {
      title: <FormattedMessage id="workingLogs.checkOutTime" />,
      dataIndex: 'checkOutTime',
      sorter: true,
    },
    {
      title: <FormattedMessage id="workingLogs.time" />,
      dataIndex: 'workingHours',
      sorter: true,
      ...getColumnTimeFields(),
      render: (value) => getRoundDecimalNumber(value),
    },
    {
      title: <FormattedMessage id="workingLogs.supervisorId" />,
      dataIndex: 'supervisorId',
      filters: buildStoreOptions(supervisorStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.supervisors.Supervisor>(itemId, supervisorStore),
    },
    {
      title: <FormattedMessage id="workingLogs.shiftPlannedId" />,
      dataIndex: 'shiftPlannedId',
      filters: buildStoreOptions(shiftStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.shifts.Shift>(itemId, shiftStore),
    },
    {
      title: <FormattedMessage id="workingLogs.shiftAssignedId" />,
      dataIndex: 'eventStart',
      sorter: true,
      render: (_: any, item: mpg.api.workingLogs.WorkingLog) => formatEventDuration(item ?? {}),
    },
    {
      title: <FormattedMessage id="workingLogs.colorAssignedId" />,
      dataIndex: 'colorAssignedId',
      filters: buildStoreOptions(colorStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.colors.Colors>(itemId, colorStore),
    },
    {
      title: <FormattedMessage id="workingLogs.colorActualId" />,
      dataIndex: 'colorActual',
      ...getColumnSearchFields(),
      sorter: true,
    },
    {
      title: <FormattedMessage id="workingLogs.weekOf" />,
      dataIndex: 'weekOf',
      sorter: true,
      filters: weeks.map((week: string) => ({ value: week, text: week })),
    },
    {
      title: <FormattedMessage id="workingLogs.breakTimes" />,
      dataIndex: 'breakTimeId',
      filters: buildStoreOptions(breaktimeStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.breaktimes.Breaktime>(itemId, breaktimeStore),
    },
    {
      title: <FormattedMessage id="workingLogs.status" />,
      dataIndex: 'status',
      filters: buildFilterOptionsFromEnum(EmployeeStatus, 'employees.status.'),
      filterMultiple: true,
      sorter: true,
      render: (status: string) => <FormattedMessage id={`employees.status.${status}`} />,
    },
    {
      title: <FormattedMessage id="workingLogs.agencyId" />,
      dataIndex: 'agencyId',
      filters: buildStoreOptions(agencyStore, 'name'),
      filterMultiple: true,
      sorter: true,
      render: (itemId: string) => getStorePropById<mpg.api.agencies.Agency>(itemId, agencyStore),
    },
    {
      title: <FormattedMessage id="workingLogs.agencyInternalId" />,
      dataIndex: 'agencyInternalId',
      sorter: true,
      ...getColumnSearchFields(),
    },
    {
      title: <FormattedMessage id="workingLogs.autoCheckOut" />,
      dataIndex: 'autoCheckOut',
      sorter: true,
      render: BooleanValueRender,
    },
  ];
  return columns.filter((column) => !column.hidden);
};
