import { CoordinatorWorkingLogsForm } from './components/form';
import { CoordinatorWorkingLogs } from './components/table';

import { PERMISSION_OBJ_COORDINATOR_REPORTING, PERMISSION_READ } from '@/constants/permissions';

export const states = [
  {
    name: 'base-layout.coordinator-workingLogs',
    url: '/worklogs',
    component: CoordinatorWorkingLogs,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_COORDINATOR_REPORTING, PERMISSION_READ]],
    },
  },
  {
    name: 'base-layout.coordinator-workingLogs.edit',
    url: '/:workingLogId/edit',
    component: CoordinatorWorkingLogsForm,
    data: {
      requiresAuth: true,
      permissions: [[PERMISSION_OBJ_COORDINATOR_REPORTING, PERMISSION_READ]],
    },
  },
];
