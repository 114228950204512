import React, { useState, useEffect, useRef } from 'react';
import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import { TableRowSelection } from 'antd/lib/table/interface';

import {
  DownOutlined,
  SettingOutlined,
  PrinterOutlined,
} from '@/components/icons';
import {
  Button, PageHeader, Dropdown, Menu, Modal,
} from '@/components/antd';
import { Table } from '@/components/table';
import {
  PERMISSION_DELETE,
  PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY, PERMISSION_READ,
  PERMISSION_UPDATE,
} from '@/constants/permissions';
import {
  authStore,
  shiftPlanningTempsStore,
} from '@/stores';
import { onPrint, showError } from '@/utils/common';
import { api } from '@/api';
import { getTranslatedString } from '@/utils';
import { ShiftPlanningStatus } from '@/types/enums';

import { useBulkUpdate } from './hooks';
import { getColumns } from './setup';
import {
  BulkAssignColorsButton,
  BulkDeleteButton, BulkUpdateButton, CreateButton, CsvUploadButton,
} from './buttons';
import { withPermissions } from "@/hocs/permission";
import ExportButton from "@/pages/common/components/buttons/export-button";

const entity = 'coordinator-temps-shift-planning';
const store = shiftPlanningTempsStore;

interface TableViewProps extends UIViewInjectedProps {
  selectedBuilding: any;
  loading: boolean;
}

export const TableView = observer(({ transition, selectedBuilding, loading }: TableViewProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const tableRef: any = useRef({});
  const { items } = store;

  const { importRequest, parseSettings, storeRefreshRequest } = useBulkUpdate();
  const [isAutoloadAllowed,setIsAutoloadAllowed] = useState(!loading && selectedBuilding?.id)
  useEffect(() => {
    if (!loading && selectedBuilding) {
      if (tableRef?.current?.filters?.building?.id !== selectedBuilding?.id) {
        tableRef?.current?.setFilters({ ...tableRef?.current?.filters, status: [ShiftPlanningStatus.Upcoming, ShiftPlanningStatus.Running], building: selectedBuilding });
      }
    }
    setIsAutoloadAllowed(!loading && selectedBuilding?.id)
  }, [loading, selectedBuilding]);

  const onDoubleClick = (record) => {
    transition.router.stateService.go(`base-layout.${entity}.edit`, { shiftPlanningId: record.id });
  };

  const onAdd = () => {
    transition.router.stateService.go(`base-layout.${entity}.create`, { buildingId: selectedBuilding?.id });
  };

  const onRowSelectionChange = (currentSelectedRowKeys: string[]) => {
    setSelectedRowKeys(currentSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<any> = {
    onChange: onRowSelectionChange,
  };

  const onBulkDelete = () => {
    const onOk = () => api.shiftPlanningTemps.bulkDelete({ ids: selectedRowKeys }).source
      .then(() => shiftPlanningTempsStore.refresh())
      .catch(showError);

    Modal.confirm({
      title: getTranslatedString('common.warning'),
      content: getTranslatedString('shiftPlanning.delete-warning-desc'),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk,
    });
  };

  const hasBulkPermissions = authStore.hasPermissions([
    [PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY, PERMISSION_UPDATE],
    [PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY, PERMISSION_DELETE],
  ]);

  const batchMenu = (
    <Menu>
      <BulkUpdateButton key="bulk-update" selectedRowKeys={selectedRowKeys} selectedBuilding={selectedBuilding} />
      <BulkAssignColorsButton key="bulk-assign-colors" selectedRowKeys={selectedRowKeys} selectedBuilding={selectedBuilding} />
      <BulkDeleteButton key="bulk-delete" onClick={onBulkDelete} />
    </Menu>
  );

  return (
    <div className="m-shift-planning__table">
      <PageHeader
        className="r-antd-page-header"
        extra={[
          <Dropdown key="batch-dropdown" overlay={batchMenu} disabled={!selectedRowKeys.length || !hasBulkPermissions}>
            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              Batch Actions
              <DownOutlined />
            </a>
          </Dropdown>,
          <Button key="print" size="middle" icon={<PrinterOutlined />} onClick={onPrint} />,
          <Button
            key="setting"
            icon={<SettingOutlined />}
            size="middle"
            onClick={tableRef.current.showSettingsModal}
          />,
          <CreateButton
            key="create button"
            onClick={onAdd}
          />,
          <ExportButton
            store={store}
            exportToExcel={api.shiftPlanningTemps.exportToExcel}
            exportToEmail={api.shiftPlanningTemps.exportToEmail}
            withPermission={withPermissions([
              [PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY, PERMISSION_READ],
            ])}
            additionalParams={{ buildingId: selectedBuilding?.id }}
            fileName="mpg-shift-planning"
          />,
          <CsvUploadButton
            key="bulk update button"
            request={importRequest}
            settings={parseSettings}
            storeRefreshRequest={storeRefreshRequest}
          />,
        ]}
      />

      <Table
        tableRef={tableRef}
        onRow={(record) => ({
          onDoubleClick: () => onDoubleClick(record),
        })}
        localStorageKey="coordinator-temps-shift-planning-table"
        rowSelection={rowSelection}
        rowKey="id"
        columns={getColumns(selectedBuilding)}
        dataSource={[...items]}
        store={store}
        checkPermissions
        permissionObj={PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY}
        loading={store.loading || loading}
        scroll={{ y: '100%' }}
        hasRequiredFilterParam
        requiredFilterParam={{ building: selectedBuilding }}
        initialFilters={{
          status: [ShiftPlanningStatus.Upcoming, ShiftPlanningStatus.Running],
          building: selectedBuilding,
        }}
        isAutoloadAllowed={isAutoloadAllowed}
      />
    </div>
  );
});
