import { getFormattedParams, request, requestTo } from '@/utils/request';
import {ApiResponse, ItemsResult, WrappedAxiosItemsResult, WrappedResult} from './types';

const prepareGetAllParams = (params?: mpg.api.weekTimesheet.GetParams) => getFormattedParams(params, true);
const prepareGetAllParamsWithOldFilters = (params?: mpg.api.weekTimesheet.GetParams) => getFormattedParams(params);

const weekTimesheetApi = {
  get(id: mpg.api.weekTimesheet.WeekTimesheetId): ApiResponse<mpg.api.weekTimesheet.WeekTimesheet> {
    return request({
      method: 'get',
      url: `/timesheet/week-timesheet/${id}`,
    });
  },
  getAll(params?: mpg.api.weekTimesheet.GetParams): ApiResponse<WrappedAxiosItemsResult<mpg.api.weekTimesheet.WeekTimesheet[]>> {
    return requestTo('timesheet/week-timesheet', params, '', prepareGetAllParams);
  },
  getWeekOfs(): ApiResponse<ItemsResult<number[]>> {
    return request({
      method: 'get',
      url: '/timesheet/week-ofs',
    });
  },
  approve(id: string) {
    return request({
      method: 'post',
      url: `/timesheet/week-timesheet/${id}/approve`,
    });
  },
  unapprove(id: string) {
    return request({
      method: 'post',
      url: `/timesheet/week-timesheet/${id}/unapprove`,
    });
  },
  exportToExcel(params?: mpg.api.weekTimesheet.GetParams): ApiResponse<Blob> {
    return requestTo('timesheet/week-timesheet', params, 'export', prepareGetAllParams);
  },
  exportToEmail(params?: mpg.api.weekTimesheet.GetParams): ApiResponse<Blob> {
    return requestTo('timesheet/week-timesheet', params, 'export-by-link', prepareGetAllParams);
  },
  exportDayTimesheetToExcel(params?: mpg.api.weekTimesheet.GetParams): ApiResponse<Blob> {
    return requestTo('timesheet/day-timesheet', params, 'export', prepareGetAllParamsWithOldFilters);
  },
  exportDayTimesheetToEmail(params?: mpg.api.weekTimesheet.GetParams): ApiResponse<Blob> {
    return requestTo('timesheet/day-timesheet', params, 'export-by-link', prepareGetAllParamsWithOldFilters);
  },
  exportRestrictedTimesheetToExcel(params?: mpg.api.weekTimesheet.GetParams): ApiResponse<Blob> {
    return requestTo('timesheet/week-timesheet/short', params, 'export', prepareGetAllParamsWithOldFilters);
  },
  exportRestrictedTimesheetToEmail(params?: mpg.api.weekTimesheet.GetParams): ApiResponse<Blob> {
    return requestTo('timesheet/week-timesheet/short', params, 'export-by-link', prepareGetAllParamsWithOldFilters);
  },
  exportMagnitToExcel(params?: mpg.api.weekTimesheet.GetParams): ApiResponse<WrappedResult<Blob>> {
    return requestTo('timesheet/magnit-day-timesheet', params, 'export', prepareGetAllParamsWithOldFilters);
  },
  bulkApprove(data: { ids: string[] }) {
    return request({
      method: 'post',
      url: '/timesheet/week-timesheet/bulk/approve',
      data,
    });
  },
  bulkUnapprove(data: { ids: string[] }) {
    return request({
      method: 'post',
      url: '/timesheet/week-timesheet/bulk/unapprove',
      data,
    });
  },
};

export default weekTimesheetApi;
